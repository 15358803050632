import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, Button, NavLink } from "react-bootstrap";
import waringSign from '../../assets/images/warnOmc.png'

Header2.propTypes = {};

function Header2({ clname = "", handleMobile }) {
  const [activeIndex, setActiveIndex] = useState(null);
  const [logoSize, setLogoSize] = useState('75%');
  const [scroll, setScroll] = useState(0);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false); // New state for the logout modal

  const navigate = useNavigate();

  const handleDropdown = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 100;
      setScroll(scrollCheck);
    });
  }, []);

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userId");
    toast.success("Logout successfully", { autoClose: 2000 });
    setTimeout(() => {
      navigate("/login");
    }, 1000);
    setIsLogoutModalOpen(false); // Close the modal after logout
  };

  // To dynamically adjust logo size on resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200) {
        setLogoSize('75%');
      } else if (window.innerWidth > 768) {
        setLogoSize('50%');
      } else {
        setLogoSize('30%');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial size adjustment

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <header
        id="header"
        className={`header header-default ${scroll ? "is-fixed is-small" : ""}`}
      >
        <div className="tf-container ct2">
          <div className="row">
            <div className="col-md-12">
              <div className="sticky-area-wrap">
                <div className="header-ct-left">
                  <div id="logo" className="logo">
                    <Link to='/candidateDashboard'>
                      <img
                        className="site-logo"
                        id="trans-logo"
                        src={logo}
                        alt="Logo"
                        style={{ maxWidth: logoSize, height: "auto" }}
                      />
                    </Link>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  {/* Left Section */}
                  <div className="header-ct-center">
                    <nav id="main-nav" className="main-nav">
                      <ul id="menu-primary-menu" className={`menu ${clname}`}>
                        <li className="sub2">
                          <Link to="/candidateDashboard" className="head-AllJobs">Dashboard</Link>
                        </li>
                        <li className="sub2">
                          <Link to="/all-jobs" className="head-AllJobs">All Jobs</Link>
                        </li>
                        <li className="mt-3">
                          <Button onClick={() => setIsLogoutModalOpen(true)} className="head-AllJobs-log">Log out</Button>
                        </li>
                      </ul>
                    </nav>
                  </div>

                  {/* Right Section: Notification */}
                  <div className="header-ct-right">
                    <div className="header-customize-item bell">
                      <span className="icon-bell"></span>
                      <div className="sub-notification">
                        <div className="sub-notification-heading">
                          <div className="sub-notification-title">Notification</div>
                          <span>3 New</span>
                        </div>
                        {/* Add your notification items here */}
                      </div>
                    </div>
                  </div>

                  {/* Mobile Navigation Trigger */}
                  <div className="nav-filter" onClick={handleMobile}>
                    <div className="nav-mobile">
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Bootstrap Logout Confirmation Modal */}
      {isLogoutModalOpen && <Modal  show={isLogoutModalOpen} centered onHide={() => setIsLogoutModalOpen(false)}>
      <Modal.Header style={{background:'#921D1E', color:'white'}}>
                <div className='d-flex align-items-center justify-content-center w-100'>
                <img src={waringSign} alt="warn" />
                </div>
        </Modal.Header>
        <Modal.Body>
        <h2 style={{color:'#921D1E', fontSize:'28px', fontFamily:'Roboto', textAlign:'center'}}>Logout!</h2>
        <p style={{ fontSize:'16px',marginTop:'20px', fontFamily:'Roboto', textAlign:'center'}}>Are you sure, you want to log out?</p>
          {/* <h6 className="fw-bold text-center" style={{fontFamily:'Roboto'}}>Are you sure, you want to log out?</h6> */}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
        <Button variant="primary" onClick={handleLogout} style={{ fontSize:'16px',fontWeight:'600', fontFamily:'Roboto', textAlign:'center', border:'1px solid #DEBBBB', color:'#921D1E', background:'#F9F3F4'}}>
            Yes
          </Button>
          <Button variant="primary" style={{ fontSize:'16px',fontWeight:'600', fontFamily:'Roboto', textAlign:'center', border:'1px solid #DEBBBB', color:'#921D1E', background:'#F9F3F4'}} onClick={() => setIsLogoutModalOpen(false)} className="me-2" >
            No
          </Button>
        </Modal.Footer>
      </Modal>}

    </>
  );
}

export default Header2;
