import React, { memo } from 'react';
import { Pagination } from 'react-bootstrap';

import { FaAngleDoubleLeft } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleDoubleRight } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";

const CustomPagination = ({ pageCount = 1, currentPage = 1, setCurrentPage }) => {
    console.log({ pageCount, currentPage, setCurrentPage });

    if (pageCount <= 1) {
        return <></>;
    }

    const renderPaginationItems = () => {
        const items = [];
        const maxPages = 3;
        const halfMaxPages = Math.floor(maxPages / 2);

        let startPage = Math.max(1, currentPage - halfMaxPages);
        let endPage = Math.min(pageCount, currentPage + halfMaxPages);

        if (currentPage <= halfMaxPages) {
            endPage = Math.min(pageCount, maxPages);
        } else if (currentPage + halfMaxPages >= pageCount) {
            startPage = Math.max(1, pageCount - maxPages + 1);
        }

        if (startPage > 1) {
            items.push(
                <Pagination.Item key="pagination1" className="shadow" active={currentPage === 1} onClick={() => setCurrentPage(1)}>
                    1
                </Pagination.Item>
            );
            if (startPage > 2) {
                items.push(<Pagination.Ellipsis key="ellipsis1" />);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <Pagination.Item key={`pagination${i}`}  className="shadow" active={currentPage === i} onClick={() => setCurrentPage(i)} style={{backgroundColor:"brown"}}>
                    {i}
                </Pagination.Item>
            );
        }

        if (endPage < pageCount) {
            if (endPage < pageCount - 1) {
                items.push(<Pagination.Ellipsis key="ellipsis2" />);
            }
            items.push(
                <Pagination.Item key={`pagination${pageCount}`} className="shadow" active={currentPage === pageCount} onClick={() => setCurrentPage(pageCount)}>
                    {pageCount}
                </Pagination.Item>
            );
        }

        return items;
    };

    return (
        <Pagination className="justify-content-center mb-0 mt-3 d-flex flex-wrap gap-2 align-items-center w-100">
            <Pagination.First className="shadow" onClick={() => setCurrentPage(1)} disabled={currentPage === 1} >
            <FaAngleDoubleLeft/>
            </Pagination.First>
            <Pagination.Prev className="shadow" disabled={currentPage === 1} onClick={() => setCurrentPage((prevPage) => prevPage - 1)}  >
            <FaAngleLeft/>
            </Pagination.Prev>
            {renderPaginationItems()}
            <Pagination.Next className="shadow" onClick={() => setCurrentPage((prevPage) => prevPage + 1)} disabled={currentPage === pageCount} >
            <FaAngleRight />
            </Pagination.Next>
            <Pagination.Last className="shadow" onClick={() => setCurrentPage(pageCount)} disabled={currentPage === pageCount} >
           
            <FaAngleDoubleRight />
            </Pagination.Last>
        </Pagination>
    );
};

export default memo(CustomPagination);
