import React, { useEffect, useState } from 'react';
import { Row, Col, InputGroup, Button, Modal, Badge } from 'react-bootstrap';
import pdf from "../../assets/images/pdf.png";
import { FaCheckCircle } from 'react-icons/fa';
import { MdDeleteOutline } from 'react-icons/md';
import AWSUpload from "../../Upload/imageUpload"; // Ensure the correct import path
import { IoEyeOutline } from "react-icons/io5";


const DomicileOfOdisha = ({ handelApplicationDetailsChange, applicationDetails, style }) => {

  const indianStates = [
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
    { value: "Andaman and Nicobar Islands", label: "Andaman and Nicobar Islands" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Dadra and Nagar Haveli and Daman and Diu", label: "Dadra and Nagar Haveli and Daman and Diu" },
    { value: "Lakshadweep", label: "Lakshadweep" },
    { value: "Delhi", label: "Delhi" },
    { value: "Puducherry", label: "Puducherry" },
  ];


  const [formData, setFormData] = useState({
    domicileOfOdisha: applicationDetails?.domicileOfOdisha || '',
    // domicileCertificateIssued: '',
    // domicileCertificateNumber: '',
    // domicileIssuedDate: '',
    // domicileCertificateUpload: null,
    // domicileCertificateVerified: false, // Add a verified state for the document
  });
  const [fieldErrors, setFieldErrors] = useState({});
  const [showIframe, setShowIframe] = useState(null); // State to show iframe modal

  useEffect(() => {
    if (formData && formData?.domicileOfOdisha) {
      handelApplicationDetailsChange(formData);
    }
  }, [formData]);

  useEffect(() => {
    if (applicationDetails) {
      setFormData({
        domicileOfOdisha: applicationDetails?.domicileOfOdisha,
        // domicileCertificateIssued: applicationDetails?.domicileCertificateIssued,
        // domicileCertificateNumber: applicationDetails?.domicileCertificateNumber,
        // domicileIssuedDate: applicationDetails?.domicileIssuedDate,
        // domicileCertificateUpload: applicationDetails?.domicileCertificateUpload || null,
        // domicileCertificateVerified: applicationDetails?.domicileCertificateVerified || false,
      });
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "domicileOfOdisha" && value === "No") {
      setFormData({
        domicileOfOdisha: "No",
        domicileCertificateIssued: "",
        domicileCertificateNumber: "",
        domicileIssuedDate: "",
        domicileCertificateUpload: null,

      });
    } else if (name === "domicileCertificateIssued" && value === "No") {
      setFormData({
        domicileOfOdisha: "No",
        domicileCertificateIssued: "",
        domicileCertificateNumber: "",
        domicileIssuedDate: "",
        domicileCertificateUpload: null,

      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleUploadImage = async (event, name) => {
    event?.preventDefault();
    const file = event?.target?.files[0];
    if (file) {
      const imageUrl = await AWSUpload(file);
      setFormData({ ...formData, [name]: imageUrl });
    } else {
      setFormData({ ...formData, [name]: null });
    }
  };

  const checkErrorFn = async (camelCaseKey, immediateValue) => {
    let errCount = 0;

    // Check if the field is required and empty
    if (!immediateValue) {
      errCount++;
      setFieldErrors((prevState) => ({
        ...prevState,
        [camelCaseKey]: {
          errors: [...new Set(["This field is required."])],
          touched: true,
        },
      }));
    } else {
      // Additional validations based on field type
      switch (camelCaseKey) {
        case "domicileCertificateNumber":
          // Certificate Number validation (alphanumeric, special characters allowed)
          const regexCertNo = /^[a-zA-Z0-9\s.,!@#$%^&*()_+=\-\\:;'"<>?/|]*$/;
          const isValidCertNo = regexCertNo.test(immediateValue);

          if (!isValidCertNo) {
            errCount++;
            setFieldErrors((prevState) => ({
              ...prevState,
              [camelCaseKey]: {
                errors: [...new Set(["Invalid certificate number."])],
                touched: true,
              },
            }));
          } else {
            setFieldErrors((prevState) => ({
              ...prevState,
              [camelCaseKey]: {
                errors: [],
                touched: true,
              },
            }));
          }
          break;

        case "domicileIssuedDate":
          // Date format validation (YYYY-MM-DD)
          const regexDate = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
          const isValidDate = regexDate.test(immediateValue);

          if (!isValidDate) {
            errCount++;
            setFieldErrors((prevState) => ({
              ...prevState,
              [camelCaseKey]: {
                errors: [...new Set(["Enter a valid date in the format YYYY-MM-DD."])],
                touched: true,
              },
            }));
          } else {
            setFieldErrors((prevState) => ({
              ...prevState,
              [camelCaseKey]: {
                errors: [],
                touched: true,
              },
            }));
          }
          break;

        case "domicileCertificateUpload": // File upload validation
          const document = immediateValue;
          const allowedDocumentTypes = ["application/pdf", "image/jpeg", "image/png"];
          const maxDocumentSize = 2 * 1024 * 1024; // 2MB size limit

          if (document) {
            // Validate file type
            // if (!allowedDocumentTypes.includes(document.type)) {
            //   errCount++;
            //   setFieldErrors((prevState) => ({
            //     ...prevState,
            //     [camelCaseKey]: {
            //       errors: [...new Set(["Only PDF, JPG, JPEG, and PNG formats are allowed."])],
            //       touched: true,
            //     },
            //   }));
            // }

            // Validate file size
            if (document.size > maxDocumentSize) {
              errCount++;
              setFieldErrors((prevState) => ({
                ...prevState,
                [camelCaseKey]: {
                  errors: [...new Set(["File size cannot exceed 2MB."])],
                  touched: true,
                },
              }));
            }

            // Check if the file has been verified
            if (!formData.domicileCertificateVerified) {
              errCount++;
              setFieldErrors((prevState) => ({
                ...prevState,
                [camelCaseKey]: {
                  errors: [...new Set(["Please Verify The File"])],
                  touched: true,
                },
              }));
            }
          } else {
            // If no file is uploaded
            errCount++;
            setFieldErrors((prevState) => ({
              ...prevState,
              [camelCaseKey]: {
                errors: [...new Set(["Please upload a valid document."])],
                touched: true,
              },
            }));
          }
          break;

        default:
          // Handle other generic validations here if necessary
          setFieldErrors((prevState) => ({
            ...prevState,
            [camelCaseKey]: {
              errors: [],
              touched: true,
            },
          }));
          break;
      }
    }

    // If no errors, clear the field error
    if (errCount < 1) {
      setFieldErrors((prevState) => ({
        ...prevState,
        [camelCaseKey]: {
          errors: [],
          touched: true,
        },
      }));
    }
  };



  return (
    <Row>
      <Col xs={12} md={3}>
        <div className="mb-2">
          <label className="form-label inputBoxHeadForm" style={style.label}>
            State of Domicile<span className="mx-1 text-danger">*</span>
          </label>
          <select
            name="domicileOfOdisha"
            value={formData.domicileOfOdisha}
            onChange={(e) => {
              setFormData({ ...formData, domicileOfOdisha: e.target.value });
              checkErrorFn("domicileOfOdisha", e.target.value);
            }}
            onBlur={() => checkErrorFn("domicileOfOdisha", formData["domicileOfOdisha"])}
            style={
              fieldErrors?.["domicileOfOdisha"]?.errors?.length > 0 &&
                fieldErrors?.["domicileOfOdisha"]?.touched
                ? { ...style.input, ...style.errorBox }
                : style.input
            }
            required
          >
            <option value="">Select</option>
            {indianStates.map((state) => (
              <option key={state.value} value={state.value}>
                {state.label}
              </option>
            ))}
          </select>
          {fieldErrors?.["domicileOfOdisha"]?.errors?.length > 0 &&
            fieldErrors?.["domicileOfOdisha"]?.touched &&
            fieldErrors?.["domicileOfOdisha"]?.errors.map((err) => (
              <span className="errorPresent" style={style.errorSpan}>
                {err}
                <br />
              </span>
            ))}
        </div>
      </Col>

      {/* {formData.domicileOfOdisha === "Yes" && (
        <>
          <Col xs={12} md={5}>
            <div className="mb-2">
              <label className="form-label inputBoxHeadForm text-wrap text-md-nowrap text-lg-nowrap" style={style.label}>
                Is the resident certificate issued by the competent authority?<span className="mx-1 text-danger">*</span>
              </label>
              <select
                name="domicileCertificateIssued"
                value={formData.domicileCertificateIssued}
                onChange={(e) => {
                  handleInputChange(e);
                  checkErrorFn("domicileCertificateIssued", e.target.value);
                }}
                onBlur={() => checkErrorFn("domicileCertificateIssued", formData['domicileCertificateIssued'])}
                style={
                  fieldErrors?.["domicileCertificateIssued"]?.errors?.length > 0 &&
                    fieldErrors?.["domicileCertificateIssued"]?.touched
                    ? { ...style.input, ...style.errorBox }
                    : style.input
                }
                required
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {fieldErrors?.["domicileCertificateIssued"]?.errors?.length > 0 &&
                fieldErrors?.["domicileCertificateIssued"]?.touched &&
                fieldErrors?.["domicileCertificateIssued"]?.errors.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>
                    {err}
                    <br />
                  </span>
                ))}
            </div>
          </Col>

          {formData.domicileCertificateIssued === "Yes" && (
            <>
              <Col xs={window.innerWidth >= 793 ? 3 : 12} md={3}>
                <div className="mb-2">
                  <label
                    className="form-label inputBoxHeadForm"
                    htmlFor="domicileCertificateNumber"
                    style={style.label}
                  >
                    Resident Certificate Number <span className="mx-1 text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="domicileCertificateNumber"
                    name="domicileCertificateNumber"
                    value={formData.domicileCertificateNumber}
                    onChange={(e) => {
                      handleInputChange(e);
                      fieldErrors?.["domicileCertificateNumber"]?.touched &&
                        checkErrorFn("domicileCertificateNumber", e.target.value);
                    }}
                    onBlur={() => checkErrorFn("domicileCertificateNumber", formData['domicileCertificateNumber'])}
                    required
                    style={
                      fieldErrors?.["domicileCertificateNumber"]?.errors?.length > 0 &&
                        fieldErrors?.["domicileCertificateNumber"]?.touched
                        ? { ...style.input, ...style.errorBox }
                        : style.input
                    }
                  />
                  {fieldErrors?.["domicileCertificateNumber"]?.errors?.length > 0 &&
                    fieldErrors?.["domicileCertificateNumber"]?.touched &&
                    fieldErrors?.["domicileCertificateNumber"]?.errors.map((err) => (
                      <span className="errorPresent" style={style.errorSpan}>
                        {err}
                        <br />
                      </span>
                    ))}
                </div>
              </Col>

              <Col xs={window.innerWidth >= 793 ? 4 : 12} md={4}>
                <div className="mb-2">
                  <label
                    className="form-label inputBoxHeadForm"
                    htmlFor="domicileIssuedDate"
                    style={style.label}
                  >
                    Resident Certificate Issued Date
                    <span className="mx-1 text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="date"
                    id="domicileIssuedDate"
                    name="domicileIssuedDate"
                    value={formData.domicileIssuedDate}
                    onChange={(e) => {
                      handleInputChange(e);
                      fieldErrors?.["domicileIssuedDate"]?.touched &&
                        checkErrorFn("domicileIssuedDate", e.target.value);
                    }}
                    onBlur={() => checkErrorFn("domicileIssuedDate", formData['domicileIssuedDate'])}
                    required
                    max={new Date().toISOString().split('T')[0]}

                    style={
                      fieldErrors?.["domicileIssuedDate"]?.errors?.length > 0 &&
                        fieldErrors?.["domicileIssuedDate"]?.touched
                        ? { ...style.input, ...style.errorBox }
                        : style.input
                    }
                  />
                  {fieldErrors?.["domicileIssuedDate"]?.errors?.length > 0 &&
                    fieldErrors?.["domicileIssuedDate"]?.touched &&
                    fieldErrors?.["domicileIssuedDate"]?.errors.map((err) => (
                      <span className="errorPresent" style={style.errorSpan}>
                        {err}
                        <br />
                      </span>
                    ))}
                </div>
              </Col>


              <Col xs={12} md={3}>
                <div className="mb-0">
                  <label className="form-label inputBoxHeadForm" style={style.label}>
                    Upload Resident Certificate <span className="mx-1 text-danger">*</span>
                  </label>
                  <button
                    style={{
                      borderRadius: '10px',
                      height: '40px',
                      padding: '5px 15px',
                      fontSize: '12px',
                      cursor: 'pointer',
                      margin: '15px 0',
                      marginTop: '0',
                      border: 'none',
                      backgroundColor:
                        fieldErrors?.["domicileCertificateUpload"]?.touched &&
                          fieldErrors?.["domicileCertificateUpload"]?.errors?.length > 0
                          ? '#921d1e'
                          : '#f0f0f0',
                      color:
                        fieldErrors?.["domicileCertificateUpload"]?.touched &&
                          fieldErrors?.["domicileCertificateUpload"]?.errors?.length > 0
                          ? '#fff'
                          : '#333',
                    }}
                    onClick={() => document.getElementById('domicileCertificateUpload_input').click()}
                    onBlur={() => checkErrorFn("domicileCertificateUpload", formData['domicileCertificateUpload'])}
                  >
                    Upload Files
                  </button>

                  <input
                    type="file"
                    id="domicileCertificateUpload_input"
                    accept=".pdf"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      handleUploadImage(e, "domicileCertificateUpload");
                      checkErrorFn("domicileCertificateUpload", e.target.value);
                    }}
                    onBlur={() => checkErrorFn("domicileCertificateUpload", formData['domicileCertificateUpload'])}
                  />
                  <br />
                  {fieldErrors?.["domicileCertificateUpload"]?.errors?.length > 0 &&
                    fieldErrors?.["domicileCertificateUpload"]?.touched &&
                    fieldErrors?.["domicileCertificateUpload"]?.errors.map((err, idx) => (
                      <span key={idx} className="errorPresent" style={style.errorSpan}>
                        {err}
                        <br />
                      </span>
                    ))
                  }
                </div>
              </Col>

              {formData.domicileCertificateUpload && (
                <React.Fragment className='docResponsive'>
                  <Col xs={12} md={1}>
                    <img
                      src={pdf}
                      alt="PDF preview"
                      style={{ width: "40px", cursor: "pointer", marginTop: '18px' }}
                      onClick={() => setShowIframe("domicileCertificateUpload")}
                    />
                  </Col>

                  {!formData.domicileCertificateVerified && (
                    <Col xs={12} md={1}>
                      <Button
                        onClick={() => setShowIframe("domicileCertificateUpload")}
                        style={{ marginLeft: 'auto', marginTop: '20px' }}
                      >
                        <span className='ps-1'>Verify</span>
                      </Button>
                    </Col>
                  )}

                  {formData.domicileCertificateVerified && (
                    <Col xs={12} md={1} className='mt-4'>
                      <Badge bg="success" >
                        <FaCheckCircle size={"18"} className="me-2" />
                        Verified
                      </Badge>
                    </Col>
                  )}

                  <Col xs={12} md={2}>
                    <Button
                      style={{ marginLeft: '0px', marginTop: '20px' }}
                      variant='outline'
                      onClick={() => setShowIframe("domicileCertificateUpload")}
                    >
                      <IoEyeOutline size={"20"} />
                    </Button>
                    <Button
                      variant="outline"
                      onClick={() => {
                        setFormData({
                          ...formData,
                          domicileCertificateUpload: null,
                          domicileCertificateVerified: false,
                        });
                      }}
                      style={{ marginLeft: '0px', marginTop: '20px' }}
                    >
                      <MdDeleteOutline size={"20"} />
                    </Button>
                  </Col>

                  {showIframe && (
                    <Modal
                      size="lg"
                      show={showIframe === "domicileCertificateUpload"}
                      onHide={() => setShowIframe(null)}
                      centered
                    >
                      <Modal.Body className="text-center">
                        <iframe
                          src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(
                            formData.domicileCertificateUpload
                          )}`}
                          width="100%"
                          style={{ border: "none", height: "80vh" }}
                          title="PDF Viewer"
                        />
                        {!formData.domicileCertificateVerified && (
                          <Button
                            className="mt-2"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                domicileCertificateVerified: true,
                              });
                              setFieldErrors((prevErrors) => ({
                                ...prevErrors,
                                domicileCertificateUpload: {
                                  errors: [], 
                                  touched: true,
                                },
                              }));

                              setShowIframe(null);
                            }}
                          >
                            Confirm Verification
                          </Button>
                        )}
                      </Modal.Body>
                    </Modal>
                  )}
                </React.Fragment>
              )}
            </>
          )}
        </>
      )} */}
    </Row>
  );
};

export default DomicileOfOdisha;
