import React, { useState, useEffect, useRef } from "react";
import Header2 from "../components/header/Header2";
import Gotop from "../components/gotop";
import Footer from "../components/footer";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import questions from "../assets/fakeData/Examination";
import TimerPage from "./examTimer";
import ExacompleteModal from "../pages/examcompltedModal";
import { useNavigate, Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import logo from "../assets/images/logo.png";
import { toast } from "react-toastify";
import HamburgerMenu from "../assets/Mobile Menu/HamburgerMenu";
function Examination() {
  const [showContent, setShowContent] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);
  const navigate = useNavigate();

  const timerRef = useRef(null);
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    toast.success("Logout  successfully", { autoClose: 1000 });
    setTimeout(() => {
      navigate("/login");
    }, 1000);
  };
  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };
  useEffect(() => {
    timerRef.current = setTimeout(() => {
      setShowContent(true);
    }, 5000);

    // Cleanup function to clear the timer on unmount
    return () => clearTimeout(timerRef.current);
  }, []);

  const formWizardRef = React.createRef();

  return (
    <>
         <HamburgerMenu clname='examination'/>
      {show && <ExacompleteModal show={show} handleClose={handleClose} />}
      {showContent ? (
        <FormWizard
          ref={formWizardRef}
          color="brown"
          onTabChange={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          onComplete={handleShow}
        >
          {questions?.map((item, index) => {
            return (
              <>
                <FormWizard.TabContent title="Section" icon="ti-check">
                  <div
                    className=" mt-2 d-flex flex-wrap align-item-center justify-content-center"
                    key={index}
                  >
                    <form className="mt-2" style={{ width: "100%" }}>
                      <div className="group-input">
                        <Row>
                          <Col>
                            <div style={{ textAlign: "left" }}>
                              {item?.questions?.map((data) => {
                                return (
                                  <div className="bg-checkout mt-3">
                                    <h6>
                                      {data?.slNo}. {data?.question}
                                    </h6>
                                    <div className="mt-4">
                                      {data.type === "textarea" ? (
                                        <textarea
                                          rows="4"
                                          style={{ width: "100%" }}
                                          placeholder={data.answer}
                                        ></textarea>
                                      ) : (
                                        data?.options.map(
                                          (option, optionIndex) => {
                                            const optionLabel = option.slice(
                                              0,
                                              2
                                            );
                                            const optionText = option.slice(3);
                                            return (
                                              <>
                                                <div
                                                  style={{ textAlign: "left" }}
                                                  key={optionIndex}
                                                >
                                                  <label>{optionLabel}</label>
                                                  <input
                                                    type="radio"
                                                    name="r1"
                                                  />
                                                  <span>{optionText}</span>
                                                </div>
                                              </>
                                            );
                                          }
                                        )
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </form>
                  </div>
                </FormWizard.TabContent>
              </>
            );
          })}
        </FormWizard>
      ) : (
        <TimerPage duration={5} onFinish={() => setShowContent(true)} />
      )}
      <style>{`
        /* Import your themify-icons CSS here if needed */
        .sample-refrence-button {
          margin: 5px;
          padding: 8px 15px;
          border: 1px solid transparent;
          border-radius: 5px;
          background-color: #2196f3;
          color: #fff;
          cursor: pointer;
        }

        .sample-refrence-button:hover {
          background-color: #fff;
          color: #2196f3;
          border: 1px solid #2196f3;
        }
      `}</style>
      <Footer />
      <Gotop />
    </>
  );
}

export default Examination;
