import React, { useEffect, useState } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import Select from 'react-select';
import { BsInfoCircle } from "react-icons/bs";

const PersonWithDisability = ({ allowedPwbdType, handelApplicationDetailsChange, applicationDetails, style }) => {
    const [formData, setFormData] = useState({
        benchmarkDisability: '',
        disabilityType: [],
        disabilityPercentage: '',
        disabilityTypewantScribe: '',
        disabilityTypephysicalLimitation: '',
        category: '',
        pwbdDeclaration: ""
    });

    const [fieldErrors, setFieldErrors] = useState({});
    const [disabilityTypeOptions, setDisabilityTypeOptions] = useState([]);
    const [isHovered, setIsHovered] = useState(false);

    // Handle hover
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const options = {
        benchmarkDisabilityTypes: [
            { value: 'TypeA', label: 'Type A' },
            { value: 'TypeB', label: 'Type B' }
        ],
        disabilityPercentages: [
            { value: '40%', label: '40%' },
            { value: '50%', label: '50%' }
        ],
        yesNoOptions: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' }
        ]
    };

    const disabilityData = {
        categories: allowedPwbdType?.map(item => ({
            label: item.Category,
            value: item.Category,
            disabilities: item.TypesOfDisabilities
        }))
    };

    useEffect(() => {
        if (applicationDetails) {
            setFormData((prevData) => ({
                ...prevData,
                ...applicationDetails
            }));
        }
    }, []);

    useEffect(() => {
        handelApplicationDetailsChange(formData);
    }, [formData]);

    const handleInputChange = (e) => {
        const { name, value, type, checked, options } = e.target;

        setFormData((prevData) => {
            if (name === "category") {
                // Handle multi-select by mapping selected options
                const selectedCategories = Array.from(options)
                    .filter(option => option.selected)
                    .map(option => option.value);

                return { ...prevData, category: selectedCategories };
            } else {
                return { ...prevData, [name]: type === 'checkbox' ? checked : value };
            }
        });
    };

    const handleCategoryChange = (selectedOption) => {
        const selectedCategory = selectedOption ? selectedOption.value : '';
        setFormData((prevData) => ({ ...prevData, category: selectedCategory }));

        // Update disability types based on the selected category
        const selectedCategoryData = disabilityData?.categories?.find(item => item.value === selectedCategory);
        if (selectedCategoryData) {
            setDisabilityTypeOptions(selectedCategoryData?.disabilities?.map(disability => ({
                label: disability,
                value: disability
            })));
        } else {
            setDisabilityTypeOptions([]);
        }

        // Check for errors after selection
        checkErrorFn("category", selectedCategory);
    };

    const checkErrorFn = async (fieldName, immediateValue) => {
        console.log(fieldName, immediateValue, "fieldName")
        let errCount = 0;

        console.log(immediateValue, "immediateValue")
        if (!immediateValue) {
            errCount++;
            setFieldErrors((prevState) => ({
                ...prevState,
                [fieldName]: { errors: ["This field is required."], touched: true }
            }));
        } else {
            switch (fieldName) {

                case "category":
                    if (!immediateValue) {
                        errCount++;
                        setFieldErrors((prevState) => ({
                            ...prevState,
                            [fieldName]: { errors: ["Invalid category."], touched: true }
                        }));
                    } else {
                        setFieldErrors((prevState) => ({ ...prevState, [fieldName]: { errors: [], touched: true } }));
                    }
                    break;

                case "disabilityType":
                    if (immediateValue.length <= 0) {
                        errCount++;
                        setFieldErrors((prevState) => ({
                            ...prevState,
                            [fieldName]: { errors: ["Invalid disability type."], touched: true }
                        }));
                    } else {
                        setFieldErrors((prevState) => ({ ...prevState, [fieldName]: { errors: [], touched: true } }));
                    }
                    break;

                // case "benchmarkDisabilityType":
                //     if (!options.benchmarkDisabilityTypes.some(option => option.value === immediateValue)) {
                //         errCount++;
                //         setFieldErrors((prevState) => ({
                //             ...prevState,
                //             [fieldName]: { errors: ["Invalid benchmark disability type."], touched: true }
                //         }));
                //     } else {
                //         setFieldErrors((prevState) => ({ ...prevState, [fieldName]: { errors: [], touched: true } }));
                //     }
                //     break;

                case "disabilityPercentage":
                    const percentage = Number(immediateValue); // Convert to number

                    if (isNaN(percentage) || percentage < 40 || percentage > 100) {
                        errCount++;
                        setFieldErrors((prevState) => ({
                            ...prevState,
                            [fieldName]: { errors: ["Disability percentage must be between 40 and 100."], touched: true }
                        }));
                    } else {
                        setFieldErrors((prevState) => ({ ...prevState, [fieldName]: { errors: [], touched: true } }));
                    }
                    break;


                case "disabilityTypewantScribe":
                case "disabilityTypephysicalLimitation":
                    if (!options.yesNoOptions.some(option => option.value === immediateValue)) {
                        errCount++;
                        setFieldErrors((prevState) => ({
                            ...prevState,
                            [fieldName]: { errors: ["Invalid selection."], touched: true }
                        }));
                    } else {
                        setFieldErrors((prevState) => ({ ...prevState, [fieldName]: { errors: [], touched: true } }));
                    }
                    break;

                case "pwbdDeclaration":
                    if (!immediateValue) {
                        errCount++;
                        setFieldErrors((prevState) => ({
                            ...prevState,
                            [fieldName]: { errors: ["Please confirm this."], touched: true }
                        }));
                    } else {
                        setFieldErrors((prevState) => ({ ...prevState, [fieldName]: { errors: [], touched: true } }));
                    }
                    break;

                default:
                    break;
            }
        }
        if (errCount === 0) {
            setFieldErrors((prevState) => ({ ...prevState, [fieldName]: { errors: [], touched: true } }));
        }
    };

    useEffect(() => {
        if (formData?.category) {
            const selectedCategoryData = disabilityData?.categories?.find(item => item.value === formData?.category);
            if (selectedCategoryData) {
                setDisabilityTypeOptions(selectedCategoryData?.disabilities?.map(disability => ({
                    label: disability,
                    value: disability
                })));
            } else {
                setDisabilityTypeOptions([]);
            }
        }

    }, [formData])

    useEffect(() => {
        console.log("formDatajhgjhgj", formData)
    }, [formData])

    return (
        <Row>
            <Col xs={12} md={12}>
                <div style={{ fontFamily: "'Roboto', sans-serif", color: "brown", fontWeight: "bold", marginBottom: "16px" }}>
                    <ol style={{ marginTop: "16px" }}>
                        <li>1. If Percentage of Benchmark Disability is below 40%, then age and fee relaxation will not be applicable.</li>
                        <li>2. By clicking on "Yes" below and submitting the information regarding disability, the applicant confirms that he/she fulfills the physical requirement as laid down in the advertisement.</li>
                        <li>3. In case of detection of any discrepancy in this regard, the candidature for the applied post is liable to be cancelled at any stage of recruitment.</li>
                        <li>4. PwBD Category Description
                            <span
                                style={{ color: "black", cursor: "pointer", marginLeft: "15px" }}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <BsInfoCircle />
                            </span>
                        </li>
                    </ol>
                </div>

                {/* Hover Effect */}
                {isHovered && (
                    <div style={{
                        position: 'absolute',
                        backgroundColor: 'white',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        padding: '15px',
                        zIndex: 100,
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        width: '400px',
                        top: '30px',
                        fontFamily: "'Roboto', sans-serif",
                        left: '50%', // To center the hover effect on the icon
                        transform: 'translateX(-50%)', // This will center it perfectly
                        transition: 'opacity 0.3s ease-in-out', // Smooth transition for fade-in
                        opacity: isHovered ? 1 : 0, // Make it fade in and out smoothly
                        pointerEvents: isHovered ? 'auto' : 'none', // Prevent interaction when hidden
                    }}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Category I(a)</td>
                                    <td>LV</td>
                                </tr>
                                <tr>
                                    <td>Category II(b)</td>
                                    <td>HH, D</td>
                                </tr>
                                <tr>
                                    <td>Category III(c)</td>
                                    <td>OA, OL, BA, BL, LC, DW, AAV</td>
                                </tr>
                                <tr>
                                    <td>Category IV(d,e)</td>
                                    <td>ASD, MI, SLD, MD involving a to d</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                )}
            </Col>
            <Col xs={12} md={6}>
                <div className="mb-1">
                    <label className="form-label inputBoxHeadForm" style={style.label}>
                        Are You a Person with Benchmark disability (40% or more)?<span className="mx-1 text-danger">*</span>
                    </label>
                    <select
                        name="benchmarkDisability"
                        value={formData.benchmarkDisability}
                        onChange={(e) => {
                            handleInputChange(e);
                            checkErrorFn("benchmarkDisability", e.target.value);
                        }}
                        onBlur={() => checkErrorFn("benchmarkDisability", formData['benchmarkDisability'])}
                        required
                        style={
                            fieldErrors?.["benchmarkDisability"]?.errors?.length > 0 &&
                                fieldErrors?.["benchmarkDisability"]?.touched
                                ? { ...style.input, ...style.errorBox }
                                : style.input
                        }
                    >
                        <option value="">Select</option>
                        {options?.yesNoOptions?.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    {fieldErrors?.["benchmarkDisability"]?.errors?.length > 0 &&
                        fieldErrors?.["benchmarkDisability"]?.touched &&
                        fieldErrors?.["benchmarkDisability"]?.errors.map((err) => (
                            <span className="errorPresent" style={style.errorSpan}>
                                {err}
                                <br />
                            </span>
                        ))}
                </div>
            </Col>

            {formData.benchmarkDisability === "Yes" && (
                <>
                    <Col xs={12} md={6}>
                        <div className="mb-1">
                            <label className="form-label inputBoxHeadForm" style={style.label}>
                                Category<span className="mx-1 text-danger">*</span>
                            </label>
                            <Select
                                name="category"
                                className="react-select"
                                value={disabilityData?.categories?.find(cat => cat.value === formData.category) || ''}
                                onChange={handleCategoryChange}
                                onBlur={() => checkErrorFn("category", formData.category)}
                                options={disabilityData.categories}
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        height: "35px", // Set the height of the control
                                        minHeight: "35px", // Match minimum height
                                    }),
                                    valueContainer: (base) => ({
                                        ...base,
                                        height: "35px", // Adjust the height of the value container
                                        padding: "0px", // Remove extra padding
                                    }),
                                    input: (base) => ({
                                        ...base,
                                        height: "35px", // Set the height of the input field
                                        margin: "0px", // Remove margins
                                        padding: "0px", // Remove padding
                                    }),
                                    indicatorsContainer: (base) => ({
                                        ...base,
                                        height: "35px", // Match the height of the indicators
                                    }),
                                    ...(fieldErrors?.["category"]?.errors?.length > 0 &&
                                        fieldErrors?.["category"]?.touched && {
                                        control: (base) => ({
                                            ...base,
                                            height: "35px",
                                            minHeight: "35px",
                                            ...style.errorBox,
                                        }),
                                    }),
                                }}
                            />

                            {fieldErrors?.["category"]?.errors?.length > 0 &&
                                fieldErrors?.["category"]?.touched &&
                                fieldErrors?.["category"]?.errors.map((err) => (
                                    <span className="errorPresent" style={style.errorSpan}>
                                        {err}
                                        <br />
                                    </span>
                                ))}
                        </div>
                    </Col>

                    <Col xs={12} md={6}>
                        <div className="mb-1">
                            <label className="form-label inputBoxHeadForm" style={style.label}>
                                Disability Type<span className="mx-1 text-danger">*</span>
                            </label>
                            <Select
                                name="disabilityType"
                                classNamePrefix="react-select"
                                className="react-select m-0 p-0"
                                isMulti
                                value={disabilityTypeOptions.filter(option => formData.disabilityType.includes(option.value))}
                                onChange={(selectedOptions) => {
                                    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                    setFormData((prevData) => ({ ...prevData, disabilityType: values }));
                                    checkErrorFn("disabilityType", values);
                                }}
                                onBlur={() => checkErrorFn("disabilityType", formData.disabilityType)}
                                options={disabilityTypeOptions}
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        height: "35px", // Set the height of the control
                                        minHeight: "35px", // Match minimum height
                                    }),
                                    valueContainer: (base) => ({
                                        ...base,
                                        height: "35px", // Adjust the height of the value container
                                        padding: "0px", // Remove extra padding
                                    }),
                                    input: (base) => ({
                                        ...base,
                                        height: "35px", // Set the height of the input field
                                        margin: "0px", // Remove margins
                                        padding: "0px", // Remove padding
                                    }),
                                    indicatorsContainer: (base) => ({
                                        ...base,
                                        height: "35px", // Match the height of the indicators
                                    }),
                                    ...(fieldErrors?.["disabilityType"]?.errors?.length > 0 &&
                                        fieldErrors?.["disabilityType"]?.touched && {
                                        control: (base) => ({
                                            ...base,
                                            height: "35px",
                                            minHeight: "35px",
                                            ...style.errorBox,
                                        }),
                                    }),
                                }}
                            />

                            {fieldErrors?.["disabilityType"]?.errors?.length > 0 &&
                                fieldErrors?.["disabilityType"]?.touched &&
                                fieldErrors?.["disabilityType"]?.errors.map((err) => (
                                    <span className="errorPresent" style={style.errorSpan}>
                                        {err}
                                        <br />
                                    </span>
                                ))}
                        </div>
                    </Col>


                    <Col xs={12} md={6}>
                        <div className="mb-1">
                            <label className="form-label inputBoxHeadForm" style={style.label}>
                                Percentage of Disability<span className="mx-1 text-danger">*</span>
                            </label>
                            <input
                                type="number"
                                id="Percentage_of_disability"
                                name="disabilityPercentage"
                                value={formData.disabilityPercentage}
                                onChange={(e) => {
                                    handleInputChange(e);
                                    checkErrorFn("disabilityPercentage", e.target.value);
                                }}
                                onBlur={() => checkErrorFn("disabilityPercentage", formData['disabilityPercentage'])}
                                min="40"
                                max="100"
                                required
                                style={
                                    fieldErrors?.["disabilityPercentage"]?.errors?.length > 0 &&
                                        fieldErrors?.["disabilityPercentage"]?.touched
                                        ? { ...style.input, ...style.errorBox }
                                        : style.input
                                }
                            />
                            {fieldErrors?.["disabilityPercentage"]?.errors?.length > 0 &&
                                fieldErrors?.["disabilityPercentage"]?.touched &&
                                fieldErrors?.["disabilityPercentage"]?.errors.map((err) => (
                                    <span className="errorPresent" style={style.errorSpan}>
                                        {err}
                                        <br />
                                    </span>
                                ))}
                        </div>
                    </Col>

                    <Col xs={12} md={6}>
                        <div className="mb-1">
                            <label className="form-label inputBoxHeadForm" style={style.label}>
                                Do You Want a Scribe?<span className="mx-1 text-danger">*</span>
                            </label>
                            <select
                                name="disabilityTypewantScribe"
                                value={formData.disabilityTypewantScribe}
                                onChange={(e) => {
                                    handleInputChange(e);
                                    checkErrorFn("disabilityTypewantScribe", e.target.value);
                                }}
                                onBlur={() => checkErrorFn("disabilityTypewantScribe", formData['disabilityTypewantScribe'])}
                                required
                                style={
                                    fieldErrors?.["disabilityTypewantScribe"]?.errors?.length > 0 &&
                                        fieldErrors?.["disabilityTypewantScribe"]?.touched
                                        ? { ...style.input, ...style.errorBox }
                                        : style.input
                                }
                            >
                                <option value="">Select</option>
                                {options?.yesNoOptions?.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            {fieldErrors?.["disabilityTypewantScribe"]?.errors?.length > 0 &&
                                fieldErrors?.["disabilityTypewantScribe"]?.touched &&
                                fieldErrors?.["disabilityTypewantScribe"]?.errors.map((err) => (
                                    <span className="errorPresent" style={style.errorSpan}>
                                        {err}
                                        <br />
                                    </span>
                                ))}
                        </div>
                    </Col>

                    <Col xs={12} md={6}>
                        <div className="mb-1">
                            <label className="form-label inputBoxHeadForm" style={style.label}>
                                Do You Have Physical Limitation?<span className="mx-1 text-danger">*</span>
                            </label>
                            <select
                                name="disabilityTypephysicalLimitation"
                                value={formData.disabilityTypephysicalLimitation}
                                onChange={(e) => {
                                    handleInputChange(e);
                                    checkErrorFn("disabilityTypephysicalLimitation", e.target.value);
                                }}
                                onBlur={() => checkErrorFn("disabilityTypephysicalLimitation", formData['disabilityTypephysicalLimitation'])}
                                required
                                style={
                                    fieldErrors?.["disabilityTypephysicalLimitation"]?.errors?.length > 0 &&
                                        fieldErrors?.["disabilityTypephysicalLimitation"]?.touched
                                        ? { ...style.input, ...style.errorBox }
                                        : style.input
                                }
                            >
                                <option value="">Select</option>
                                {options?.yesNoOptions?.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            {fieldErrors?.["disabilityTypephysicalLimitation"]?.errors?.length > 0 &&
                                fieldErrors?.["disabilityTypephysicalLimitation"]?.touched &&
                                fieldErrors?.["disabilityTypephysicalLimitation"]?.errors.map((err) => (
                                    <span className="errorPresent" style={style.errorSpan}>
                                        {err}
                                        <br />
                                    </span>
                                ))}
                        </div>
                    </Col>


                    <div style={{ display: 'flex', marginTop: "30px" }}>
                        <input
                            type="checkbox"
                            id="declaration-checkbox"
                            name="pwbdDeclaration"
                            checked={formData?.pwbdDeclaration}
                            onChange={(e) => { handleInputChange(e); }}
                            onBlur={() => checkErrorFn("pwbdDeclaration", formData['pwbdDeclaration'])}
                            style={{ marginRight: '10px' }}
                        />
                        <label htmlFor="declaration-checkbox" style={{ fontSize: '14px', lineHeight: '1.5', fontFamily: "'Roboto', sans-serif", color: "brown" }}>
                            I confirm that I fulfill the physical requirement standards for the post as laid down in the advertisement. In case of any discrepency, my candidature is laible to be cancelled.
                        </label>
                    </div>
                    {fieldErrors?.["pwbdDeclaration"]?.errors?.length > 0 &&
                        fieldErrors?.["pwbdDeclaration"]?.touched &&
                        fieldErrors?.["pwbdDeclaration"]?.errors.map((err) => (
                            <span className="errorPresent" style={style.errorSpan}>
                                {err}
                                <br />
                            </span>
                        ))}
                </>
            )}
        </Row>
    );
};

export default PersonWithDisability;