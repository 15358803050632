import React from 'react'
import { Link,NavLink,useNavigate } from 'react-router-dom';
import Header2 from '../../components/header/Header2';
import HeaderDashboard from '../../components/header/HeaderDashboard';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import logo from "../../assets/images/logo.png"
function HamburgerMenu({clname}) {
  console.log({clname})
  const navigate = useNavigate();
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };
  const handleMobile = () => {

    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
    
    
     
   
  };
  const handleLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userId");
    
    toast.success("Logout  successfully", { autoClose: 5000 });
    setTimeout(() => {
      navigate("/login");
    }, 5000);
  };
  const UserId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  return (



    <div className="menu-mobile-popup">
      <div className="modal-menu__backdrop" onClick={handleMobile}></div>
      <div className="widget-filter">
        <div className="mobile-header">
          <div id="logo" className="logo">
            <Link to="/">
              <img className="site-logo" src={logo} alt="Image" />
            </Link>
          </div>
          <Link className="title-button-group" onClick={handleMobile}>
            <i className="icon-close"></i>
          </Link>
        </div>

        <Tabs className="tf-tab">
          <div className="content-tab">
            <TabPanel className="header-ct-center menu-moblie animation-tab">
              <div className="nav-wrap">
                <nav className="main-nav mobile">
                  <ul id="menu-primary-menu" className="menu">
                    <li className="current-item">
                      <Link
                        to="/candidateDashboard"
                        className="iteam-menu"
                        onClick={() => {
                          handleToggle("dashboard");
                        }}
                      >
                        Dashboard
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/all-jobs"
                        className="iteam-menu"
                        onClick={() => {
                          handleToggle("alljoblist");
                        }}
                      >
                        All jobs
                      </Link>
                    </li>
           
                    
              
                
                  </ul>
                </nav>
              </div>
            </TabPanel>
          </div>
        </Tabs>

        <div className="header-customize-item button">
        
            <Link to="/login" onClick={handleLogout}>
              Logout
            </Link>
       
            {/* <Link to="/login">Log in</Link> */}
          
        </div>
      </div>

      <Header2 clname={clname} handleMobile={handleMobile} />
      {/* <HeaderDashboard clname={clname} handleMobile={handleMobile}/> */}
    </div>

  )
}

export default HamburgerMenu