import React from "react";
import { Link } from "react-router-dom";
import { candidateInfoFromRegistrationPage } from "../assets/fakeData/CandidateProfile";
import Header2 from "../components/header/Header2";
import Footer from "../components/footer";
import Gotop from "../components/gotop";
import omcDept from "../assets/fakeData/alldepartmnetOfOmc"
import { useParams } from "react-router-dom";
import CandidateJob from "../assets/fakeData/candidatejobinfo"
import Table from 'react-bootstrap/Table';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

function CandiateEvaluation() {
    const params = useParams("")
    const alljobofOmcdept = omcDept?.flatMap(item => item?.jobs)
    const filterdjob = alljobofOmcdept?.filter(item => item?.position == params?.id)

    const jobinfo = CandidateJob?.Job_Applications?.filter(item => item?.position == params?.id)
    console.log(jobinfo[0].position, "jobinfo")
    console.log(filterdjob, "filterdjob")
    console.log(CandidateJob, "JOb here")
    console.log(params.id, "location")


    return (
        <>
            <Header2 />


            <section className="candidates-section ">
                <div className="tf-container">
                    <div className="row d-flex mt-5" >
                        <div className="col-lg-8" >
                            <div className="wd-cv-item">
                                <div className="item-group">
                                    <div className="content-left" style={{ width: "350px" }}>
                                        <div className="avatar" style={{ marginTop: "-70px", marginLeft: "-20px" }}>
                                            <img style={{ borderRadius: "20px" }}
                                                src={candidateInfoFromRegistrationPage?.personalDetails?.image}
                                                alt="images"
                                            />
                                        </div>
                                        <div className="profile">
                                            <h6 style={{
                                                backgroundColor: 'brown',
                                                fontWeight: 'bold',
                                                fontSize: '13px',
                                                color: 'white',
                                                padding: '2px 6px',
                                                display: 'inline-block',
                                                borderRadius: "5px",
                                                width: "200px",
                                                textAlign: "center"
                                            }}>Contact Details</h6>
                                            <p>Email:{candidateInfoFromRegistrationPage.personalDetails?.email}</p>
                                            <p>Phone:{candidateInfoFromRegistrationPage.personalDetails?.phoneNumber}</p>
                                            <p>address:{candidateInfoFromRegistrationPage.personalDetails?.address}</p>
                                        </div>

                                    </div>
                                    <div className="content-right">
                                        <div className="profile">
                                            <h5>{filterdjob[0]?.position}</h5>
                                            <hr />
                                            <p>
                                                {filterdjob[0]?.description}
                                            </p>
                                        </div>

                                        <p>Notification Release</p>
                                        <div className="cv-road-map">

                                            <div className="inner">
                                                <div className="history"> {jobinfo[0]?.Recruitment_Status?.Notification_Release?.Date}</div>
                                                <div className="dot-icon">
                                                </div>
                                                <div className="content">
                                                    <h6 className="ms-4"> {jobinfo[0]?.Recruitment_Status?.Notification_Release?.Status}</h6>
                                                    <div className="ms-4">{jobinfo[0]?.Recruitment_Status?.Notification_Release?.Description}</div>

                                                </div>
                                            </div>

                                        </div>

                                        <p>Application Submission</p>
                                        <div className="cv-road-map">

                                            <div className="inner">
                                                <div className="history"> {jobinfo[0]?.Recruitment_Status?.Application_Submission?.Date}</div>
                                                <div className="dot-icon">
                                                </div>
                                                <div className="content">
                                                    <h6 className="ms-4"> {jobinfo[0]?.Recruitment_Status?.Application_Submission?.Status}</h6>
                                                    <div className="ms-4">{jobinfo[0]?.Recruitment_Status?.Application_Submission?.Description}</div>

                                                </div>
                                            </div>


                                        </div>

                                        <p> Admit Card Issuance</p>
                                        <div className="cv-road-map">

                                            <div className="inner">
                                                <div className="history"> {jobinfo[0]?.Recruitment_Status?.Admit_Card_Issuance?.Date}</div>
                                                <div className="dot-icon">
                                                </div>
                                                <div className="content">
                                                    <h6 className="ms-4"> {jobinfo[0]?.Recruitment_Status?.Admit_Card_Issuance?.Status}</h6>
                                                    <div className="ms-4">{jobinfo[0]?.Recruitment_Status?.Admit_Card_Issuance?.Description}</div>
                                                    <Link className="group-btn icon-download " style={{
                                                        backgroundColor: 'brown',
                                                        fontWeight: 'bold',
                                                        fontSize: '10px',
                                                        color: 'white',
                                                        padding: '7px 2px',
                                                        display: 'inline-block',
                                                        borderRadius: "5px",
                                                        width: "100px",
                                                        textAlign: "center",
                                                        margin: "10px"

                                                    }} to="https://omcltd.in/Upload/career_post/461165cc99f741b9bd3bc2cd8d593b28_20231219133709187.pdf" target="_blank">Admit card</Link>
                                                </div>
                                            </div>


                                        </div>
                                        {
                                            jobinfo[0]?.Recruitment_Status?.Written_Examination ?
                                                (

                                                    <>
                                                        <p> Written Examination</p>
                                                        <div className="cv-road-map">
                                                            <div className="inner">
                                                                <div className="history"> {jobinfo[0]?.Recruitment_Status?.Written_Examination?.Date}</div>
                                                                <div className="dot-icon">
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="ms-4"> {jobinfo[0]?.Recruitment_Status?.Written_Examination?.Status}</h6>
                                                                    <div className="ms-4">{jobinfo[0]?.Recruitment_Status?.Written_Examination?.Description}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className="ms-4">Interview</p>
                                                        <div className="cv-road-map">
                                                            <div className="inner">
                                                                <div className="history"> {jobinfo[0]?.Recruitment_Status?.Interview?.Date}</div>
                                                                <div className="dot-icon">
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="ms-4"> {jobinfo[0]?.Recruitment_Status?.Interview?.Status}</h6>
                                                                    <div className="ms-4">{jobinfo[0]?.Recruitment_Status?.Interview?.Description}</div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p>Document Verification</p>
                                                        <div className="cv-road-map">
                                                            <div className="inner">
                                                                <div className="history"> {jobinfo[0]?.Recruitment_Status?.Document_Verification?.Date}</div>
                                                                <div className="dot-icon">
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="ms-4"> {jobinfo[0]?.Recruitment_Status?.Document_Verification?.Status}</h6>
                                                                    <div className="ms-4">{jobinfo[0]?.Recruitment_Status?.Document_Verification?.Description}</div>
                                                                </div>
                                                            </div> </div>
                                                        <p className="ms-3">Final Merit List</p>
                                                        <div className="cv-road-map">
                                                            <div className="inner">
                                                                <div className="history"> {jobinfo[0]?.Recruitment_Status?.Final_Merit_List?.Date}</div>
                                                                <div className="dot-icon">
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="ms-4"> {jobinfo[0]?.Recruitment_Status?.Final_Merit_List?.Status}</h6>
                                                                    <div className="ms-4">{jobinfo[0]?.Recruitment_Status?.Final_Merit_List?.Description}</div>
                                                                    <Link className="group-btn icon-download " style={{
                                                                        backgroundColor: 'brown',
                                                                        fontWeight: 'bold',
                                                                        fontSize: '10px',
                                                                        color: 'white',
                                                                        padding: '7px 2px',
                                                                        display: 'inline-block',
                                                                        borderRadius: "5px",
                                                                        width: "100px",
                                                                        textAlign: "center",
                                                                        margin: "10px"

                                                                    }} to="https://omcltd.in/Upload/career_post/461165cc99f741b9bd3bc2cd8d593b28_20231219133709187.pdf" target="_blank">Offer Letter</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                    </>
                                                )
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <Tabs>
                                <div className="widget-filter st2">
                                    <h6>{filterdjob[0]?.position}</h6>
                                    <p className="icon-map-pin mt-3">{filterdjob[0]?.location}</p>
                                    <p>{filterdjob[0]?.salary}</p>
                                    <hr />
                                    <h6>Qualification:</h6>
                                    <p>{filterdjob[0]?.qualification}</p>
                                    <hr />
                                    <h6 className="mt-3">Work Experience</h6>
                                    <p>{filterdjob[0]?.work_experience}</p>
                                    <h6>Full Job Description</h6>
                                    <hr />
                                    <ul className="list-dot">
                                        {
                                            filterdjob[0]?.job_details?.map((item) => {
                                                return (
                                                    <li>{item}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                    <p style={{
                                        fontWeight: 'bold',
                                        fontSize: '20px',
                                        marginTop: "5px"
                                    }}>Vacancy List</p>
                                    <hr />
                                    <Table striped bordered hover className="text-center">
                                        <thead>
                                            <tr>
                                                <th>category</th>
                                                <th>Vacancy</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>SC</td>
                                                <td>{filterdjob[0]?.sc}</td>
                                            </tr>
                                            <tr>
                                                <td>ST</td>

                                                <td>{filterdjob[0]?.st}</td>
                                            </tr>
                                            <tr>
                                                <td>UR</td>
                                                <td>{filterdjob[0]?.ur}</td>
                                            </tr>
                                            <tr>
                                                <td>Women</td>
                                                <td>{filterdjob[0]?.women}</td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </div>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </section >

            <Footer />
            <Gotop />
        </>
    )
}

export default CandiateEvaluation