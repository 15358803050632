// InputField.js
import React from "react";

const InputField = ({
  label,
  type = "text",
  value,
  onChange,
  onBlur,
  error,
  style,
  required = false,
  options = [],
  min,
  max,
  readOnly = false,
}) => {
  return (
    <div>
      <label className="form-label" style={style.label}>
        {label}
        {required && <span className="mx-1 text-danger">*</span>}
      </label>
      {type === "select" ? (
        <select
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          style={
            error?.length > 0
              ? { ...style.input, ...style.errorBox }
              : style.input
          }
          disabled={readOnly}
        >
          <option value="">Select</option>
          {options.map((opt, idx) => (
            <option key={idx} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
      ) : (
        <input
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          style={
            error?.length > 0
              ? { ...style.input, ...style.errorBox }
              : style.input
          }
          min={min}
           max={max}
           maxLength={max}
          readOnly={readOnly}
        />
      )}
      {error?.map((err, idx) => (
        <span key={idx} className="errorPresent" style={style.errorSpan}>
          {err}
          <br />
        </span>
      ))}
    </div>
  );
};

export default InputField;
