import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import bgImage from "../../assets/images/loginBg.jpg"
import omcLogoWhite from "../../assets/images/omc-logo-white 1.png";
import { Button, Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { callApi } from "../../constant/callApi";
import CryptoJS from 'crypto-js';


function SignUp() {
  // const [showPass, setShowPass] = useState(false);
  // const [showPass2, setShowPass2] = useState(false);
  // const [showPass3, setShowPass3] = useState(false);
  // const [showPass4, setShowPass4] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [otpFieldVisible, setOtpFieldVisible] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [resendButtonVisible, setResendButtonVisible] = useState(false);
  const [resendTimer, setResendTimer] = useState(null);
  const [timer, setTimer] = useState(60); // Timer for 60 seconds
  const [otpDetails, setOtpDetails] = useState("");
  const [emailOtpDetails, setEmailOtpDetails] = useState("");

  const navigate = useNavigate();

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?]).{8,}$/;

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSignUp = (event) => {
    event.preventDefault();

    if (!/^[6-9]\d{9}$/.test(phoneNumber)) {
      setPhoneError("Only 10 digit numbers allowed");
      return;
    }

    setPhoneError("");

    if (!isValidEmail(email)) {
      toast.error("Invalid email format", { autoClose: 2000 });
      return;
    }

    const passwordValidationMessage = isValidPassword(password);
    if (passwordValidationMessage) {
      setPasswordError(passwordValidationMessage);
      return;
    }

    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    setPasswordError("");

    if (!firstName || !lastName) {
      toast.error("All fields are required", { autoClose: 2000 });
      return;
    }

    // If all validations are successful
    signUpFun();
  };

  const signUpFun = async () => {
    const hashedPassword = CryptoJS.SHA256(password).toString();
    try {
      let config = {
        method: "post",
        url: "Portal/portal/portalRegistartion",
        data: {
          phoneNumber: phoneNumber,
          email: email,
          password: hashedPassword,
          otp: otpDetails,
          emailOtp: emailOtpDetails,
          firstName: firstName,
          lastName: lastName,
        },
      };

      let response = await callApi(config);
      if (response?.data?.code === 201) {
        localStorage.setItem("userId", response?.data?.userBasicDetails?._id);
        localStorage.setItem("token", response.data.token);
        toast.success(response.data.message, { autoClose: 4000 });
        // handleClose();
        setTimeout(() => {
          navigate("/alljoblist");
        }, 2000);
      } else {
        toast.error(response.data.message, { autoClose: 4000 });
        navigate("/login");
      }
    } catch (error) {
      console.log(error, "here is error of signUp function");
      toast(error.message, { className: "danger", autoClose: 4000 });
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }
  };

  const isValidPassword = (password) => {
    if (!passwordRegex.test(password)) {
      return "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one special character, and one number.";
    }

    for (let i = 0; i < password.length - 2; i++) {
      const char1 = password.charCodeAt(i);
      const char2 = password.charCodeAt(i + 1);
      const char3 = password.charCodeAt(i + 2);
      if (char1 + 1 === char2 && char2 + 1 === char3) {
        return "Password must not have serial characters (e.g., '1234' or 'abcd').";
      }
    }
    return "";
  };

  const isValidEmail = (email) => {
    return emailRegex.test(email);
  };

  const generateOtp = async () => {
    if (!/^[6-9]\d{9}$/.test(phoneNumber)) {
      setPhoneError("Only 10 digit numbers allowed");
      return;
    }

    setPhoneError("");

    if (!isValidEmail(email)) {
      toast.error("Invalid email format", { autoClose: 2000 });
      return;
    }

    const passwordValidationMessage = isValidPassword(password);
    if (passwordValidationMessage) {
      setPasswordError(passwordValidationMessage);
      return;
    }

    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    if (!firstName || !lastName) {
      toast.error("All fields are required", { autoClose: 2000 });
      return;
    }

    setPasswordError("");

    try {
      let config = {
        method: "post",
        url: "Portal/portal/generateOtpForPortalRegistration",
        data: {
          phoneNumber: phoneNumber,
          email: email,
        },
      };

      let response = await callApi(config);
      if (response.data.code === 201) {
        setOtpFieldVisible(true);
        setOtpSent(true);
        setResendButtonVisible(true);
        startTimer(); // Start the countdown timer
        toast.success(response.data.message, { autoClose: 2000 });
      } else {
        toast.error(response.data.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error, "here is error");
      toast.error(error.message, { autoClose: 2000 });
    }
  };

  const startTimer = () => {
    setTimer(60); // Reset timer to 60 seconds
    setResendTimer(setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(resendTimer);
          setResendButtonVisible(true); // Show resend button when timer ends
          return 0;
        }
        return prev - 1;
      });
    }, 1000));
  };

  useEffect(() => {
    return () => {
      if (resendTimer) {
        clearInterval(resendTimer);
      }
    };
  }, [resendTimer]);


  return (
    <section className="login-section">
      <img src={bgImage} alt="bgImage" className="login-bg-img" />
      <div className="overlay"></div>
      <div className="signUp-mainContainer">
        <Row className="SignUpTop">
          <div className="signup-logoimgContainer">
            <img src={omcLogoWhite} alt="omcLogo" className="signup-logo" />
          </div>
        </Row>
        <Row className="SignUpBottom" onSubmit={handleSignUp}>
          <div className="signUp-form d-flex flex-column align-items-center">
            <h4 className="text-center fs-2 font-mukta">Sign Up</h4>
            <form className="signUp-form-content">
              <Row>
                <Col className="d-flex justify-content-center align-items-center">
                  <div className="form-data font-mukta">
                    <label htmlFor="firstName" className="col-sm-12 col-form-label">First Name:</label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="signUp-input"
                        id="firstName"
                        placeholder="Enter first name"
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </Col>
                <Col className="d-flex justify-content-center align-items-center">
                  <div className="form-data font-mukta">
                    <label htmlFor="lastName" className="col-sm-12 col-form-label">Last Name:</label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="signUp-input"
                        id="lastName"
                        placeholder="Enter last name"
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-center align-items-center">
                  <div className="form-data font-mukta">
                    <label htmlFor="phone" className="col-sm-12 col-form-label">Phone Number:</label>
                    <div className="col-sm-12">
                      <input
                        type="tel"
                        className="signUp-input"
                        id="phone"
                        placeholder="Enter phone number"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        pattern="[0-9]*"
                        required
                      />
                    </div>
                      {/* {phoneError && <div className="text-danger">{phoneError}</div>} */}
                  </div>
                </Col>
                <Col className="d-flex justify-content-center align-items-center">
                  <div className="form-data font-mukta">
                    <label htmlFor="email" className="col-sm-12 col-form-label">Email:</label>
                    <div className="col-sm-12">
                      <input
                        type="email"
                        className="signUp-input"
                        id="email"
                        placeholder="Enter email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row><Col>{phoneError && <div className="text-danger font-mukta errorMessage">{phoneError}</div>}</Col></Row>
              <Row>
                <Col className="d-flex justify-content-center align-items-center">
                  <div className="form-data font-mukta">
                    <label htmlFor="password" className="col-sm-12 col-form-label">Password:</label>
                    <div className="col-sm-12 position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="signUp-input"
                        id="password"
                        placeholder="Enter password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <FontAwesomeIcon
                        icon={showPassword ? faEye : faEyeSlash}
                        onClick={toggleShowPassword}
                        className="password-addon"
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col className="d-flex justify-content-center align-items-center">
                  <div className="form-data font-mukta">
                    <label htmlFor="confirmPassword" className="col-sm-12 col-form-label">Confirm Password:</label>
                    <div className="col-sm-12">
                      <input
                        type="password"
                        className="signUp-input"
                        id="confirmPassword"
                        placeholder="Confirm password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                      {/* {passwordError && <div className="text-danger">{passwordError}</div>} */}
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <Row><Col> {<div className="text-danger font-mukta errorMessage">{passwordError} </div>}</Col></Row> */}
              <Row><Col> {passwordError && <div className="text-danger font-mukta errorMessage">{passwordError}</div>}</Col></Row>
              {otpFieldVisible && (<>
                <Row>
                  <Col className="d-flex justify-content-center align-items-center">
                    <div className="form-data font-mukta">
                      <label htmlFor="otp" className="col-sm-12 col-form-label">Phone Number OTP:</label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          className="signUp-input"
                          id="otp"
                          placeholder="Enter OTP To Verify Phone Number"
                          onChange={(e) => setOtpDetails(e.target.value)}
                          maxLength="6"
                          required
                        />
                      </div>
                    </div>
                  </Col>
                  <Col className="d-flex justify-content-center align-items-center">
                    <div className="form-data font-mukta">
                      <label htmlFor="emailOtp" className="col-sm-12 col-form-label">Email OTP:</label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          className="signUp-input"
                          id="emailOtp"
                          placeholder="Enter OTP To Verify Email"
                          onChange={(e) => setEmailOtpDetails(e.target.value)}
                          maxLength="6"
                          required
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <div className="col-sm-9 offset-sm-3 mt-2">
                    {timer > 0 ? (
                      <span>Resend OTP in {timer} seconds</span>
                    ) : (
                      resendButtonVisible && (
                        <Button variant="primary" onClick={generateOtp}>
                          Resend OTP
                        </Button>
                      )
                    )}
                  </div>
                </Row>
                <Row>
                  {otpSent && (
                    <div className="mb-1 row">
                      <div className="col-sm-9 offset-sm-3">
                        <Button variant="primary" type="submit">
                          Sign Up
                        </Button>
                      </div>
                    </div>
                  )}
                </Row> */}
                <Row className="align-items-center mt-3">
                  <div className="col-sm-6 text-start">
                    {timer > 0 ? (
                      <span>Resend OTP in {timer} seconds</span>
                    ) : (
                      resendButtonVisible && (
                        <Button variant="primary" onClick={generateOtp}>
                          Resend OTP
                        </Button>
                      )
                    )}
                  </div>

                  {otpSent && (
                    <div className="col-sm-6 text-end">
                      <Button variant="primary" type="submit">
                        Sign Up
                      </Button>
                    </div>
                  )}
                </Row>
              </>
              )}
              <Row>
                {!otpFieldVisible && <div className="form-data mt-5">
                  <div className="text-center">
                    <Button variant="primary" onClick={generateOtp} className="mb-1 font-mukta">
                      Send OTP To Verify
                    </Button>
                  </div>
                </div>}
              </Row>
            </form>
          </div>
        </Row>
      </div>
    </section>
  );
}

export default SignUp;
