import React, { useEffect, useRef, useState } from "react";
import "react-form-wizard-component/dist/style.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { camelCase, capitalCase } from "change-case";
import Header2 from "../components/header/Header2";
import Gotop from "../components/gotop";
import Footer from "../components/footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Upload, { AWSUpload } from "../Upload/imageUpload";
import { FaPlus } from "react-icons/fa";
import { UploadPdf } from "../Upload/pdfupload";
import { callApi } from "../constant/callApi";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { IoCheckmarkCircleOutline, IoCloseCircleOutline, IoEyeOutline } from "react-icons/io5";
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Card,
  Form,
  ModalHeader,
  Spinner,
  Table,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HamburgerMenu from "../assets/Mobile Menu/HamburgerMenu";
import DomicileOfOdisha from "./DynamicComplexInputs/DomicileOfOdisha";
import PersonWithDisability from "./DynamicComplexInputs/PersonWithDisability";
import AreYouASportsMan from "./DynamicComplexInputs/AreYouASportsMan";
import ReservationCategory from "./DynamicComplexInputs/ReservationCategory";
import DepartmentalCandidate from "./DynamicComplexInputs/DepartmentalCandidate";
import Preloader from "../components/preloader";
import ExServicemen from "./DynamicComplexInputs/Exservicemen";
import Declaration from "./DynamicComplexInputs/declaration";
import ReactToPrint from "react-to-print";
import FinalFormSubmitModal from "./finalFormSubmitModal";
import pdf from "../assets/images/pdf.png";
import omclogo from "../assets/images/logo-removebg-preview.png"
import countriesData from '../masterFunctions/countries.json'
import paymentSuccess from '../assets/images/paymentsuccess/payment-done.png'
import { IoMdClose } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';
import ExperienceInputs from "./DynamicComplexInputs/ExperienceInputs";
import pdfIcon from "../assets/images/pdf-icon.png"
import waringSign from '../assets/images/warnOmc.png'


const DynamicApplicationFrom = () => {
  const [OpenImageModal, SetImageModalOpen] = useState(false);
  const [applicationDetails, setApplicationDetails] = useState(null);
  const [applicationForm, setApplicationForm] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [tabIndexFromDb, setTabIndexFromDb] = useState(0);
  const [checkedValues, setCheckedValues] = useState([]);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [isAICheck, setIsAICheck] = useState(false);
  const [responceAiCheck, setResponceAiCheck] = useState("");
  const [application, setApplication] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [applicationID, setApplicationID] = useState(null);
  const [Loading, setLoading] = useState(true);
  const [finalSubmitModal, setFinalSubmitModal] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [fieldErrors, setFieldErrors] = useState([]);
  const [saveAndNextLoader, setSaveAndNextLoader] = useState(false)
  const [errorFromBackend, setErrorFromBackend] = useState({})
  const [selectedCountry, setSelectedCountry] = useState('');
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isModalClosed, setIsModalClosed] = useState(false); // Additional state to track modal close
  const [closingDate, setClosingDate] = useState()
  let countryHolder = "66a66fd74db5f02d6861e873"
  let stateHolder = "66a6705e4db5f02d6861e894"

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChangeDeclare = (event) => {
    setIsChecked(event.target.checked);
    setApplicationDetails(pre => ({
        ...pre,
        finalSubmitDeclaration: event.target.checked,
    }));
};



  const [fieldValue, setFieldValue] = useState("");
  const [camelCaseKey, setCamelCaseKey] = useState("");



  useEffect(()=>{

    console.log("experience details hera",applicationDetails?.new_staticExperience?.[0]?.proofOfExperienceDocument)

  },[applicationDetails?.new_staticExperience?.[0]?.proofOfExperienceDocument])



  const handleViewFile = (key, file) => {
    setCamelCaseKey(key);
    setFieldValue(file);
    setShowIframe(key);
  };


  // Function to close the modal and reset the interaction
  const handleModalClose = () => {
    setShowErrorModal(false);
    setIsModalClosed(true); // Mark that modal is closed
  };
  // const handleSubmitForm = () => {
  //   // Add your form submission logic here
  //   if (errorFromBackend?.ageEligibilityError?.message || errorFromBackend?.experienceEligibilityError?.message) {
  //     setShowErrorModal(true); // Show modal if there are errors
  //   } else {
  //     // Proceed with form submission
  //   }
  // };

  function sentenceCase(text) {
    if (!text) return "";
    const sentences = text.split(/(?<=[.!?])\s+/);
    return sentences
      .map((sentence) => {
        return sentence
          .split("/")
          .map((part) => {
            return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
          })
          .join("/");
      })
      .join(" ");
  }

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const getApplicationById = async (id) => {
    setLoading(true);
    try {
      const config = {
        method: "post",
        url: "application/application/getApplicationById",
        data: {
          id: id,
        },
      };
      const response = await callApi(config);

      if (response.data.code === 200) {
        setApplication(response.data.data);

        setLoading(false);
      } else {
        // navigate("/alljoblist");
      }
      setIsLoading2(false);
    } catch (error) {
      // console.log(error);
      // navigate("/alljoblist");
    } finally {
      setIsLoading2(false);
    }
  };

  useEffect(() => {
    let applicationId = searchParams.get("__application_id");
    // console.log("__application_id", applicationId);
    if (applicationId) {
      getApplicationById(applicationId);
    } else {
      // navigate("/alljoblist");
    }
  }, []);

  const style = {};
  style.label = {
    color: "#000",
    marginBottom: "-10px",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "600",
    fontSize: "15px",
    // fontSize: "10px",
    // fontWeight: "400px",
  };
  style.input = {
    borderRadius: "5px",
    height: "35px",
    color: "black",
    padding: "5px",
    color: "#000",
    fontSize: "13px",
    fontWeight: "500",
    border: "2px solid #E6E6E6",
    marginTop: "4px",
    // fontSize: "10px",
    // fontWeight: "350",
  };
  style.errorSpan = {
    color: "red",
    fontSize: "12px",
  };
  style.errorBox = {
    border: "1px solid red",
  };

  const inputBoxStyle = {
    height: "35px",
    borderRadius: "5px",
    paddingLeft: "10px",
  };

  const handleCheckboxChange = (e, item) => {
    const { checked, value } = e.target; // Extract 'checked' status and 'value'

    if (checked) {
      setCheckedValues([...checkedValues, value]);
    } else {
      setCheckedValues(checkedValues.filter((v) => v !== value));
    }
  };


  const handleAddFieldSet = (template, formTab, index) => {
    const newFieldSet = {
      id: uuidv4(),
      fields: JSON.parse(JSON.stringify(template)),
      tab: camelCase(formTab),
    };
    setDynamicFields((prev) => [...prev, newFieldSet]);
  };

  const handleBlankAddFieldSet = (template, formTab, index) => {
    const DataPresent = dynamicFields.filter((e) => e.tab == camelCase(formTab))
    const isDataPresent = DataPresent.length > 0

    if (!isDataPresent) {

      const newFieldSet = {
        id: index + "Ind",
        fields: JSON.parse(JSON.stringify(template)),
        tab: camelCase(formTab),
      };
      setDynamicFields((prev) => [...prev, newFieldSet]);
    }


  };
  const handleAddFieldSetWithValuesDynamic = (values, formTab, index) => {
    let keyAll = application?.inputElements?.filter((e) => {
      return camelCase(e.formTab) == formTab;
    });
    let val_ = keyAll.map((e) => {
      return { key: camelCase(e.inputFieldName), valuess: e };
    });
    let template = [];
    for (let index = 0; index < val_?.length; index++) {
      const obKey = val_[index].key;
      let data = {
        tab: formTab,
        value: values[obKey] || " ",
        values: val_[index].valuess,
      };
      template.push(data);
    }

    const newFieldSet = {
      id: uuidv4(),
      fields: JSON.parse(JSON.stringify(template)),
      tab: formTab,
    };
    setDynamicFields((prev) => [...prev, newFieldSet]);
  };

  // Handler to delete a specific field set
  const handleDeleteFieldSet = (id, indexOfFieldSet) => {
    setDynamicFields((prev) => prev.filter((fieldSet, index) => indexOfFieldSet !== index));
  };

  const { state } = useLocation();
  console.log(state?.post?.posting?.allowedPwbdType, "state?.post?.posting?.allowedPwbdType")
  const toSentenceCase = (str) => {
    if (!str) return str;
    return str
      .toLowerCase()
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  };
  const userId = localStorage.getItem("userId");



  const collectUserApplicationData = async (tResultArray) => {
    try {
      let config = {
        method: "post",
        url: "Portal/jobApplication/portal/getApplicationByUser",
        data: {
          AdVId: state?.item?._id,
          vacancyId: state?.post?.posting?._id,
        },
      };

      let response = await callApi(config);
      if (response?.data?.code === 200) {
        if (response?.data?.data == null) {
          setApplicationDetails([]);
        } else {
          setApplicationDetails(response?.data?.data?.details);
          console.log("response?.data?.data?.details",response?.data?.data?.details)
          if (response?.data?.data?.details?.[countryHolder]) {
            const newStates = getStatesByCountry(response?.data?.data?.details?.[countryHolder]);
            setStates(newStates);
          }
          if (response?.data?.data?.details?.[stateHolder] && response?.data?.data?.details?.[countryHolder]) {
            setSelectedState(response?.data?.data?.details?.[stateHolder]);
            const newDistricts = getDistrictsByState(response?.data?.data?.details?.[countryHolder], response?.data?.data?.details?.[stateHolder]);
            setDistricts(newDistricts);
          }
          setApplicationID(response?.data?.data?.applicationSerialIds);
          setResponceAiCheck(response?.data?.data?.isAICheck);
          let dyEl = Object.keys(response?.data?.data?.details);
          setTabIndex(response?.data?.data?.lastSubmittedIndex || 0)
          setTabIndexFromDb(response?.data?.data?.lastSubmittedIndex || 0)
          for (let index = 0; index < dyEl?.length; index++) {
            const obKey = dyEl?.[index];
            const sentenceCaseKey = camelCase(obKey);

            if (Array.isArray(response?.data?.data?.details?.[obKey])) {
              for (
                let innerIndex = 0;
                innerIndex < response?.data?.data?.details?.[obKey]?.length;
                innerIndex++
              ) {
                const value =
                  response?.data?.data?.details?.[obKey]?.[innerIndex];
                handleAddFieldSetWithValuesDynamic(value, sentenceCaseKey, index);
              }
            } else {
            }
          }
        }

        toast.success(response?.data?.message, { autoClose: 2000 });
      } else {
        await handleSubmitForm("", true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateApplicationTabIndex = async (tResultArray) => {
    try {
      let config = {
        method: "post",
        url: "Portal/jobApplication/portal/getApplicationByUser",
        data: {
          AdVId: state?.item?._id,
          vacancyId: state?.post?.posting?._id,
        },
      };

      let response = await callApi(config);
      if (response?.data?.code === 200) {
        if (response?.data?.data == null) {
          setApplicationDetails([]);
        } else {
          setTabIndexFromDb(response?.data?.data?.lastSubmittedIndex || 0)
          setApplicationDetails(response?.data?.data?.details);

        }
      } else {
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setIsLoading(false);
    }
  };
  function getOrdinalSuffix(num) {
    if (num % 100 >= 11 && num % 100 <= 13) {
      return num + "th";
    }

    // Determine the suffix based on the last digit of the number
    switch (num % 10) {
      case 1:
        return num + "st";
      case 2:
        return num + "nd";
      case 3:
        return num + "rd";
      default:
        return num + "th";
    }
  }
  useEffect(() => {
    const func = async () => {
      if (state && application?.inputElements) {
        const groupedData = await groupByFormTab(application.inputElements);
        const resultArray = await convertGroupedDataToArray(groupedData);
        setApplicationForm(resultArray);
        collectUserApplicationData(userId, resultArray);
      }
    };

    func();
  }, [application?.inputElements, state]); // Ensure you only depend on specific changes

  const handleUploadImage = async (event, fieldName) => {
    console.log("filedName------", fieldName)
    event?.preventDefault();
    const file = event?.target?.files[0];
    console.log("file", file);
    if (file) {

      const imageUrl = await AWSUpload(file);
      // const imageUrl = await Upload(file)
      console.log(imageUrl, "file-imageUrl");
      setApplicationDetails({ ...applicationDetails, [fieldName]: imageUrl, [fieldName + "verified"]: false });
    } else {
      setApplicationDetails({ ...applicationDetails, [fieldName]: null, [fieldName + "verified"]: false });

    }
    // setImage(imageUrl)
  };


  const handleUploadImageArray = async (event, fieldName, updateFieldValue) => {
    event?.preventDefault();
    const file = event?.target?.files[0];
    console.log("file", file);
    if (file) {

      const imageUrl = await AWSUpload(file);
      // const imageUrl = await Upload(file)
      console.log(imageUrl, "file-imageUrl");
      updateFieldValue(imageUrl);
      // setApplicationDetails({ ...applicationDetails, [fieldName]: imageUrl ,[fieldName+"verified"]: false});
    } else {
      updateFieldValue(null);

    }
    // setImage(imageUrl)
  };

  const handleUploadImagesArray = async (event, fieldName, updateFieldValue, currentFieldValue) => {
    event?.preventDefault();
  
    const files = event?.target?.files;
    if (files && files.length > 0) {
      // Use currentFieldValue to get existing images
      const existingImages = currentFieldValue?.split(',').filter(Boolean) || [];
      const uploadPromises = Array.from(files).map(async (file) => {
        const imageUrl = await AWSUpload(file);
        return imageUrl;
      });
  
      try {
        const uploadedImageUrls = await Promise.all(uploadPromises);
        const updatedImages = [...existingImages, ...uploadedImageUrls];
        const updatedImagesString = updatedImages.join(',');
        setApplicationDetails({
          ...applicationDetails,
          [`${fieldName}verified`]: false,
        });
        updateFieldValue(updatedImagesString);
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    } else {
      // Reset images if no files are selected
      updateFieldValue(null);
  
      setApplicationDetails({
        ...applicationDetails,
        [`${fieldName}verified`]: false,
      });
    }
  };
  

  // Modified handleUploadImage function
  const handleUploadImages = async (event, fieldName) => {
    event?.preventDefault();
    const files = event?.target?.files;
    if (files && files.length > 0) {
      const existingImages = applicationDetails[fieldName]?.split(',').filter(Boolean) || [];
      const uploadPromises = Array.from(files).map(async (file) => {
        const imageUrl = await AWSUpload(file);
        return imageUrl;
      });

      try {
        const uploadedImageUrls = await Promise.all(uploadPromises);
        const updatedImages = [...existingImages, ...uploadedImageUrls];
        const updatedImagesString = updatedImages.join(',');
        setApplicationDetails({
          ...applicationDetails,
          [fieldName]: updatedImagesString,
          [fieldName + "verified"]: false,
        });
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    } else {
      // Reset images if no files are selected
      setApplicationDetails({
        ...applicationDetails,
        [fieldName]: '',
        [fieldName + "verified"]: false,
      });
    }
  };

  const groupByFormTab = (data) => {
    const groupedData = {}; // Initialize an empty object for grouping

    data?.forEach((item) => {
      const { formTab, ...values } = item; // Destructure to get the key and value
      if (!groupedData[formTab]) {
        groupedData[formTab] = [];
      }
      groupedData[formTab].push({ values });
    });

    return groupedData; // Return the grouped data object
  };
  const convertGroupedDataToArray = (groupedData) => {
    const resultArray = [];
    for (const formTab in groupedData) {
      if (groupedData.hasOwnProperty(formTab)) {
        resultArray.push({
          formTab, // FormTab key,
          isArray: groupedData?.[formTab]?.[0]?.values?.isArray,
          values: groupedData[formTab], // Array of value objects
        });
      }
    }

    return resultArray;
  };

  const toCamelCaseKeys = (obj) => {
    // console.log({ obj });
    if (Array.isArray(obj)) {
      return obj.map(toCamelCaseKeys);
    } else if (
      obj !== null &&
      obj !== undefined &&
      obj?.constructor === Object
    ) {
      return Object.keys(obj).reduce((result, key) => {
        const camelKey = camelCase(key);
        result[camelKey] = toCamelCaseKeys(obj[key]);
        return result;
      }, {});
    }
    return obj;
  };

  const [onlyWomenAllowed, setOnlyWomenAllowed] = useState(false)
  const [allowedCategory, setAllowedCategory] = useState([])

  useEffect(() => {
    let catAvailable = state?.post?.posting?.category
    let otherAllowed = catAvailable?.some(item => item?.total > item?.women);
    setOnlyWomenAllowed(!otherAllowed)
    console.log("postDetails_Cat", state?.post?.posting?.category, !otherAllowed)


  }, [state])

  // function getAllowedCategoriesByGender(data, gender) {
  //   if (applicationDetails?.['domicileOfOdisha']?.toLowerCase() == "no") {
  //     console.log("fldata", { data })
  //     return data.filter(item => item?.category === "UR").map(item => item?.category);
  //   }
  //   else if (gender === "Female") {
  //     // Return categories where women are allowed (i.e., women > 0)
  //     return data.filter(item => item?.women >= 0 || true).map(item => item?.category);
  //   } else if (gender === "Male" || gender == "Transgender") {
  //     // Return categories where men are allowed (i.e., total > women)
  //     return data.filter(item => item?.total > item?.women).map(item => item?.category);
  //   } else {
  //     return []; // Invalid gender input
  //   }
  // }

  function getAllowedCategoriesByGender(data, gender) {
    // Check if domicileOfOdisha is not "Odisha" (case-insensitive)
    if (applicationDetails?.['domicileOfOdisha']?.toLowerCase() !== "odisha") {
      return data.filter(item => item?.category === "UR").map(item => item?.category);
    }
    else if (gender === "Female" || gender === "Male" || gender === "Transgender") {
      // Return categories where women are allowed (i.e., women > 0)
      return data.map(item => item?.category);
    }
    // else if (gender === "Male" || gender === "Transgender") {
    //   // Return categories where men are allowed (i.e., total > women)
    //   return data.filter(item => item?.total > item?.women).map(item => item?.category);
    // } 
    else {
      return []; // Invalid gender input
    }
  }

  useEffect(() => {
    if (applicationDetails?.["66a37bab1b102d1ba93eee2e"] && state?.post?.posting?.category) {

      setAllowedCategory(getAllowedCategoriesByGender(state?.post?.posting?.category, applicationDetails?.["66a37bab1b102d1ba93eee2e"]))
    }
    // console.log("state?.post?.posting?.category", state)
    setClosingDate(state.item.advertisementClosingDate)



  }, [applicationDetails])

  const checkErrorsClass = () => {
    return new Promise((resolve) => {
      let Divid = 'captureErrorFor_' + tabIndex;
      const specificDivs = document.querySelectorAll(`.${Divid}`);
      let err = 0;
      let firstErrorElement = null; // To store the first error element
      console.log({ specificDivs }, { Divid });

      for (let i = 0; i < specificDivs.length; i++) {
        const div = specificDivs[i];
        const inputs = div.querySelectorAll("input, select");

        for (let j = 0; j < inputs.length; j++) {
          inputs[j].focus();
          inputs[j].blur();
        }
      }

      // Introduce a slight delay to allow DOM to update
      setTimeout(() => {
        const specificDivsWithError = document.querySelectorAll(`.${Divid}`);
        console.log({ specificDivsWithError }, { Divid });

        for (let i = 0; i < specificDivsWithError.length; i++) {
          const div = specificDivsWithError[i];
          const errorSpans = div.querySelectorAll("span.errorPresent");
          console.log({ errorSpans });

          for (let j = 0; j < errorSpans.length; j++) {
            err++;
            if (firstErrorElement === null) {
              firstErrorElement = errorSpans[j];
            }
          }
        }

        // If there's at least one error, focus on the input associated with the first error span
        if (firstErrorElement) {
          const inputToFocus = firstErrorElement.closest('div')?.querySelector("input, select");
          if (inputToFocus) {
            inputToFocus.focus();
          }
        }


        console.log({ err });
        resolve(err == 0);
      }, 300);
    });
  };

  const handleSubmitForm = async (value, isInitialSubmit) => {
    console.log("handleSubmitForm 1")
    // Reset isModalClosed when submitting the form
    setIsModalClosed(false);

    checkErrorsClass().then(async (noErrors) => {
      if (noErrors || isInitialSubmit) {
        try {
          const result = {};
          dynamicFields.forEach((entry) => {
            const record = {};
            entry?.fields.forEach((field) => {
              const tab = field?.tab;

              if (!result[tab]) {
                result[tab] = [];
              }
              const fieldName = camelCase(field.values.inputFieldName);
              record[fieldName] = field.value;
            });


            result[entry?.fields[1]?.tab]?.push(record); // Add only once
          });
          const camelCaseApplicationDetails = toCamelCaseKeys(applicationDetails);
          console.log("handleSubmitForm 2",value)

          if (value === "Submit") {
            const config = {
              method: "post",
              url: "Portal/jobApplication/portal/findApplicationByIdAndUpdateOrCreate",
              data: {
                details: {
                  ...camelCaseApplicationDetails,
                  skills: checkedValues,
                  advertisement: state?.item?._id,
                  vacancyId: state?.post?.posting?._id,
                  finalSubmit: true,
                  ...result,
                },
                userId: userId,
                dynamicApplicationForm: application,
                isAICheck: true,
                tabIndex: Number(tabIndex)

              },
            };

            const response = await callApi(config);
            if (response?.data?.code == 200) {
              await collectUserApplicationData();
              toast.success(response?.data?.message, { autoClose: 2000 });
              navigate("/candidateDashboard");
            }
          } else {
            setSaveAndNextLoader(true)
            const config = {
              method: "post",
              url: "Portal/jobApplication/portal/findApplicationByIdAndUpdateOrCreate",
              data: {
                details: {
                  ...camelCaseApplicationDetails,
                  skills: checkedValues,
                  advertisement: state?.item?._id,
                  vacancyId: state?.post?.posting?._id,
                  ...result,
                  tabIndex: tabIndex
                },
                userId: userId,
                isAICheck: isAICheck,
                dynamicApplicationForm: application,
                tabIndex: Number(tabIndex)

              },
            };

            const response = await callApi(config);
            if (response?.data?.code == 201) {

              await collectUserApplicationData()
              toast.success(response?.data?.message, { autoClose: 2000 });
            }
            setIsLoading(false);
            setSaveAndNextLoader(false)
            if (response?.data?.code == 200) {
              console.log("data", response?.data)
              await updateApplicationTabIndex()
              let allowFurtherEducation = response?.data?.ageEligibility?.isValidAgeForApplication

              let errorCount = 0
              if (!allowFurtherEducation) {
                errorCount++

                let ageEligibility = response?.data?.ageEligibility
                ageEligibility.relaxedAge ?

                  setErrorFromBackend(prevState => ({
                    ...prevState,
                    ageEligibilityError: { ...ageEligibility, message: `Your age is not eligible for this application, as the maximum base age / relaxed age for this vacancy is ${ageEligibility?.relaxedAge}, according to your inputs your age is ${ageEligibility?.applicantAge}.` }
                  })) :
                  setErrorFromBackend(prevState => ({
                    ...prevState,
                    ageEligibilityError: { ...ageEligibility, message: `${ageEligibility.reason},Your age is ${ageEligibility.applicantAge}` }
                  }));

              } else {

                setErrorFromBackend(prevState => {
                  const { ageEligibilityError, ...rest } = prevState;
                  return { ...rest };
                });
              }

              let allowFurtherExperience = response?.data?.experienceEligibility.eligible
              let relevantExpEligible = response?.data?.relevantExpEligible.eligible
              let relevantEligible = response?.data?.relevantExpEligible
              if ((!allowFurtherExperience || !relevantExpEligible) && tabIndex >= 5) {
                errorCount++

                let expEligibility = response?.data?.experienceEligibility
                setErrorFromBackend(prevState => ({
                  ...prevState,
                  experienceEligibilityError: { ...expEligibility, message: `Your experience is not eligible for this application, as the required experience is ${expEligibility?.experienceRequired} years ${relevantEligible?.relevantExperienceRequired > 0 ? `and a relevant experience required of ${relevantEligible?.relevantExperienceRequired} years` : ""}, and according to your information your experience is ${expEligibility?.experienceHave} ${relevantEligible?.relevantExperienceRequired > 0 ? `and relevant experience is ${relevantEligible?.relevantExperienceHave}` : ""}.` }
                }));
              } else {

                setErrorFromBackend(prevState => {
                  const { experienceEligibilityError, ...rest } = prevState;
                  return { ...rest };
                });
              }
              if (errorCount > 0) {
                setShowErrorModal(true); // Show the modal if errors exist
              } else {
                setTabIndex(Math.min(applicationForm?.length, tabIndex + 1));
              }
            }
          }
        } catch (error) {
          toast.error("Something went wrong. Please try again.", {
            autoClose: 2000,
          });
        } finally {
          setIsLoading(false);
        }
      } else {
        console.log("Errors found, cannot proceed.");
      }
    });



  };


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [tabIndex]);

  const renderArrayFields = (field, fieldIndex, item, fieldSet) => {
    const label = field?.values?.inputFieldName;
    // console.log({ field })
    const camelCaseKey = field?.values?._id + fieldSet.id
    const dateDifference = (date1, date2) => {
      console.log({ date1 }, { date2 })
      const diff = new Date(new Date(date2) - new Date(date1));
      return `${diff.getUTCFullYear() - 1970} Years ${diff.getUTCMonth()} Months ${diff.getUTCDate() - 1} Days`;
    };
    const updateExpValue = (value, tab) => {
      const updatedFields = dynamicFields.map((fs) => {
        if (fs.id === fieldSet.id) {
          let isCorrect = Date(fs.fields[fieldIndex + 1].value) >= Date(value);
          if (!isCorrect)
            setFieldErrors((prevState) => ({
              ...prevState,
              [camelCaseKey]: {
                errors: [...new Set([...(prevState[camelCaseKey]?.errors || []), 'Total mark should be greater then obtained mark.'])], // Append the error message
                touched: prevState?.[camelCaseKey]?.touched,
              },
            }));
          console.log("date1", value, fs.fields[fieldIndex + 1].value)
          let percentMark = dateDifference(value, fs.fields[fieldIndex + 1].value)
          if (percentMark < 0 || percentMark > 100) percentMark = 'Invalid';
          fs.fields[fieldIndex].value = value;

          fs.fields[fieldIndex + 5].value = percentMark;

          fs.fields[fieldIndex].tab = tab || camelCase(fs?.tab);
          return { ...fs };
        }
        return fs;
      });
      setDynamicFields(updatedFields);
    };
    const updateExpValue2 = (value, tab) => {
      const updatedFields = dynamicFields.map((fs) => {
        if (fs.id === fieldSet.id) {
          let isCorrect = Date(fs.fields[fieldIndex - 1].value) >= Date(value);
          if (!isCorrect)
            setFieldErrors((prevState) => ({
              ...prevState,
              [camelCaseKey]: {
                errors: [...new Set([...(prevState[camelCaseKey]?.errors || []), 'Total mark should be greater then obtained mark.'])], // Append the error message
                touched: prevState?.[camelCaseKey]?.touched,
              },
            }));
          let percentMark = dateDifference(fs.fields[fieldIndex - 1].value, value)
          if (percentMark < 0 || percentMark > 100) percentMark = 'Invalid';
          fs.fields[fieldIndex].value = value;

          fs.fields[fieldIndex + 4].value = percentMark;

          fs.fields[fieldIndex].tab = tab || camelCase(fs?.tab);
          return { ...fs };
        }
        return fs;
      });
      setDynamicFields(updatedFields);
    };





    const renderPdfInputMultiple = (
      updateFieldValue,
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails
    ) => {
      const imageUrls = fieldValue?.split(',').filter(Boolean) || [];
      const extractNameFromUrl = (url) => {
        try {
          const queryParams = new URL(url).searchParams;
          return queryParams.get("name") || ""; // Default to empty string if no name found
        } catch (error) {
          console.error("Invalid URL:", url);
          return ""; // Return empty if URL parsing fails
        }
      };
      const handleDeleteImage = (index) => {
        const updatedImageUrls = imageUrls.filter((_, idx) => idx !== index);
        const updatedImageUrlsString = updatedImageUrls.join(',');
        setApplicationDetails({
          ...applicationDetails,
          [`${camelCaseKey}verified`]: false, // Reset verified status
        });
        updateFieldValue(updatedImageUrlsString)

      };
      const handleImageNameUpdate = (name, index) => {
        const updatedImageUrls = imageUrls.map((url, idx) => {
          if (idx === index) {
            const [baseUrl] = url.split('?'); // Get the base URL without query parameters
            return `${baseUrl}?name=${encodeURIComponent(name)}`; // Add updated query string
          }
          return url; // Keep the other URLs unchanged
        });

        const updatedImageUrlsString = updatedImageUrls.join(',');
        updateFieldValue(updatedImageUrlsString)
      };

      return (
        <div className=" gap-4">
          <Row className="mt-2">
            {/* <div style={{ position: 'relative', display: 'inline-block' }}> */}
            {/* <div className="flex-wrap"> */}
            <button
              style={{
                position: 'relative',
                top: '-45px',
                left: '250px',
                width: '200px',
                borderRadius: '10px',
                height: '40px',
                padding: '5px 15px',
                fontSize: '12px',
                cursor: 'pointer',
                margin: '15px 0',
                border: 'none',
                marginBottom:"0px",
                backgroundColor:
                  fieldErrors?.[camelCaseKey]?.touched &&
                    fieldErrors?.[camelCaseKey]?.errors?.length > 0
                    ? '#921d1e'
                    : '#f0f0f0',
                color:
                  fieldErrors?.[camelCaseKey]?.touched &&
                    fieldErrors?.[camelCaseKey]?.errors?.length > 0
                    ? '#fff'
                    : '#333',
              }}
              onClick={() => document.getElementById(`${camelCaseKey}_imageInput_mul`).click()}
              onBlur={(e) => {
                checkErrorFn(input, camelCaseKey, fieldValue);
              }}
            >
<FaPlus /> <span className="ps-1">Upload FileS</span>            </button>
            {fieldErrors?.[camelCaseKey]?.errors?.length > 0 &&
              fieldErrors?.[camelCaseKey]?.touched &&
              fieldErrors?.[camelCaseKey]?.errors?.map((err, idx) => (
                <span key={idx} className="errorPresent" style={style.errorSpan}>
                  {err}
                  <br />
                </span>
              ))}
            <br />
            {fieldValue && !applicationDetails[`${camelCaseKey}verified`] && (
              <span key={'Dgf'} className="errorPresent" style={{ marginTop: '-60px', color: 'red', fontSize: '12px' }}>
                {'Please Verify The File'}
                <br />
              </span>
            )}
            {/* </div> */}


            <input
  type="file"
  accept="application/pdf"
  multiple
  id={`${camelCaseKey}_imageInput_mul`}
  style={{
    display: "none",
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  }}
  onChange={(e) => {
    handleUploadImagesArray(e, camelCaseKey, updateFieldValue, fieldValue);
    if (fieldErrors?.[camelCaseKey]?.touched) {
      checkErrorFn(input, camelCaseKey, e.target.value);
    }
    e.target.value = null;
  }}
  onBlur={(e) => {
    checkErrorFn(input, camelCaseKey, fieldValue);
  }}
/>

            {/* </div> */}
          </Row>
          <Row style={{ marginTop: '10px' }}>
            {/* Display Multiple Images */}
            {imageUrls.length > 0 && (
              <>
                <div className=" mt-0 pt-0 ms-3 ps-2">
                  {imageUrls.map((url, index) => {
                    const name = extractNameFromUrl(url); // Extract the name from the URL

                    return <>

                      <div
                        key={index}
                        className="position-relative m-2 mt-0 mb-2 pb-3 pt-0 ps-2 d-flex align-items-center gap-2 pb-2"
                        style={{ borderBottom: '1px solid #E6E6E6' }}
                      >
                        <img
                          src={pdfIcon}
                          alt={`${label} ${index + 1}`}
                          style={{
                            height: '60px',
                            width: 'auto',
                          }}
                          className="p-2 pt-0"
                          onClick={() => SetImageModalOpen(camelCaseKey)}
                        />
                        <input
                          variant="outline"
                          value={name}
                          // style={{ top: '20px', right: '75px' }}
                          className="form-control text-dark p-0 m-0"
                          style={{ ...style.input, top: '5px', right: '5px', width: "300px" }}
                          type={input.values.inputFieldType}
                          // placeholder={label}
                          onChange={(e) => {
                            handleImageNameUpdate(e.target.value, index)
                          }}
                          onBlur={(e) => {

                          }}
                          required={true}
                        // max={new Date().toISOString().split('T')[0]}

                        />
                        {!name && <span
                          className="errorPresent  position-absolute p-0 m-0"
                          style={{ ...style.errorSpan, top: "45px", right: '100px' }}

                        >
                          Please enter the file name
                        </span>}


                        <Button
                          className=" p-0 m-0"
                          variant="outline"
                          // style={{ top: '20px', right: '75px' }}
                          size="sm"
                          onClick={() => SetImageModalOpen(camelCaseKey)}
                        >
                          <IoEyeOutline size={"25"} />
                        </Button>
                        <Button
                          className="p-0 m-0 "
                          variant="outline"
                          // style={{ top: '20px', right: '45px' }}
                          size="sm"
                          onClick={() => handleDeleteImage(index)}
                        >
                          <MdDeleteOutline size={'22'} />
                        </Button>

                      </div>
                      <br /></>
                  })}
                </div>

                {!applicationDetails[`${camelCaseKey}verified`] && (
                  <div className="text-end">
                    <Button
                      className="ms-3 verifybutton verifyBtn"
                      onClick={() => SetImageModalOpen(camelCaseKey)}
                      style={{ marginLeft: 'auto' }}
                    >
                      Verify
                    </Button>
                  </div>
                )}

                {applicationDetails[`${camelCaseKey}verified`] && (
                  <div
                    style={{ display: 'flex', justifyContent: 'flex-end', paddingRight:'150px' }}
                    className={'fw-bold ms-4'}
                  >
                    <Badge bg="success">
                      <FaCheckCircle size={'18'} className="me-2" />
                      Verified
                    </Badge>
                  </div>
                )}
              </>
            )}
          </Row>
          {/* Image Modal for Verification */}
          {OpenImageModal && (
            <Modal
              size="xl"
              show={OpenImageModal == camelCaseKey}
              onHide={() => SetImageModalOpen(false)}
              centered
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Body className="text-center">
                <PdfCarousel
                  imageUrls={imageUrls}
                  label={label}
                  applicationDetails={applicationDetails}
                  setApplicationDetails={setApplicationDetails}
                  camelCaseKey={camelCaseKey}
                  SetImageModalOpen={SetImageModalOpen}
                />
              </Modal.Body>

            </Modal>
          )}
        </div>
      );
    };

    const PdfCarousel = ({
      imageUrls,
      label,
      applicationDetails,
      setApplicationDetails,
      camelCaseKey,
      SetImageModalOpen,
    }) => {
      const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

      // Reset currentImageIndex when modal opens
      React.useEffect(() => {
        setCurrentImageIndex(0);
      }, []);

      const handleNext = () => {
        if (currentImageIndex < imageUrls.length - 1) {
          setCurrentImageIndex(currentImageIndex + 1);
        }
      };

      const handlePrev = () => {
        if (currentImageIndex > 0) {
          setCurrentImageIndex(currentImageIndex - 1);
        }
      };

      const isLastImage = currentImageIndex === imageUrls.length - 1;

      return (
        <>


          <iframe
            src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(
              imageUrls[currentImageIndex]
            )}`}
            width="100%"
            style={{ border: "none", height: "80vh" }}
            title="PDF Viewer"
          />

          <div className="d-flex justify-content-center mb-2">
            <Button
              className="me-2"
              variant="secondary-primary"
              disabled={currentImageIndex === 0}
              onClick={handlePrev}
            >
              <FaArrowAltCircleLeft size={30} />
            </Button>
            <Button
              variant="secondary-primary"
              disabled={currentImageIndex === imageUrls.length - 1}
              onClick={handleNext}
            >
              <FaArrowAltCircleRight size={30} />
            </Button>
          </div>
          {!applicationDetails[`${camelCaseKey}verified`] && isLastImage && (
            <Button
              className="mt-2"
              style={{ borderRadius: '10px' }}
              onClick={() => {
                setApplicationDetails({
                  ...applicationDetails,
                  [`${camelCaseKey}verified`]: true,
                });
                SetImageModalOpen(false);
              }}
            >
              Confirm Verification
            </Button>
          )}
          <Button
            className="mt-2"
            style={{ borderRadius: "10px" }}
            variant="secondary"
            onClick={() => SetImageModalOpen(false)}
          >
            Close
          </Button>
        </>
      );
    };

    const renderPdfInput = (
      updateFieldValue,
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
    ) => (
      <div className="imageDiv d-flex align-items-center gap-4">
        <div style={{ position: "relative", display: "inline-block" }}>
          <div className="d-flex flex-column">

            <button
              className="me-4"
              style={{
                borderRadius: "10px",
                height: "40px",
                padding: "5px 15px",
                fontSize: "12px",
                cursor: "pointer",
                margin: '15px 0',
                border: "none",
                backgroundColor:
                  fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                    ? '#921d1e' :
                    "#f0f0f0", // Error background color

                // border:
                //   fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                //     ? "1px solid red"
                //     : "1px solid #ccc", // Error border    
                color:
                  fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                    ? "#fff"
                    : "#333", // Error background color
              }}
              onClick={() => document.getElementById(`${camelCaseKey}_fileInput`).click()}
            >
              Upload File
            </button>

            <br />
            {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched &&
              fieldErrors?.[camelCaseKey]?.errors?.map((err, idx) => (
                <span key={idx} className="errorPresent" style={style.errorSpan}>
                  {err}
                  <br />
                </span>

              ))
            }
            <br />
            {fieldValue && !applicationDetails[`${camelCaseKey}verified`] && <span key={"Dgf"} className="errorPresent" style={{...style.errorSpan,marginTop:"-15px"}}>
              {"Please Verify The File"}
              <br />
            </span>}
          </div>
          <input
            type="file"
            accept="application/pdf"
            id={`${camelCaseKey}_fileInput`}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              opacity: 0,
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
            onChange={(e) => {
              handleUploadImageArray(e, camelCaseKey, updateFieldValue);

              fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value);
              e.target.value = null;

            }}
            onBlur={(e) => { checkErrorFn(input, camelCaseKey, fieldValue) }}

          />
        </div>


        {fieldValue && (
          <>
            <img
              src={pdfIcon}
              alt="PDF preview"
              style={{ marginLeft: "10px",marginTop: "-55px", width: "50px", cursor: "pointer" }}
              onClick={() => {
                setShowIframe(camelCaseKey);
              }}
            />
            {!applicationDetails[`${camelCaseKey}verified`] && (
              <div style={{ display: 'flex',marginTop: "-55px", justifyContent: 'flex-end' }}>
                <Button
                  className="ms-2 verifybutton verifyBtn"
                  onClick={() => setShowIframe(camelCaseKey)}
                >
                  Verify
                </Button>
              </div>
            )}

            {applicationDetails[`${camelCaseKey}verified`] && (
              <div style={{ display: 'flex',marginTop: "-55px", justifyContent: 'flex-end' }} className={"fw-bold"}>
                <Badge bg="success" >
                  <FaCheckCircle size={"18"} className="me-2" />

                  Verified
                </Badge>
              </div>
            )}
            <div style={{ display: 'flex', marginTop: "-55px",justifyContent: 'flex-end' }}>
              <Button
                style={{ marginLeft: '0px' }}
                variant='outline'
                onClick={() => setShowIframe(camelCaseKey)}
              >
                <IoEyeOutline size={"25"} />
              </Button>
              <Button className="ms-2" variant='outline' onClick={() => { updateFieldValue(null); setApplicationDetails({ ...applicationDetails, [`${camelCaseKey}verified`]: false }) }} style={{ marginLeft: 'auto' }}>
                {/* <Badge bg="danger" > */}
                <MdDeleteOutline size={"25"} />
                {/* <span className='ps-2'>Delete</span> */}
                {/* </Badge> */}
                {/* <MdDeleteOutline size={"25"}></MdDeleteOutline> */}
              </Button>
            </div>
          </>
        )}


        {showIframe && (
          <Modal
            size="lg"
            show={showIframe === camelCaseKey}
            onHide={() => setShowIframe(false)}
            centered
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Body className="text-center">
              <iframe
                src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(
                  fieldValue
                )}`}
                width="100%"
                style={{ border: "none", height: "80vh" }}
                title="PDF Viewer"
              />
              {!applicationDetails[camelCaseKey + "verified"] && <Button
                className="mt-2"
                style={{ borderRadius: "10px" }}
                onClick={() => {
                  setApplicationDetails({
                    ...applicationDetails,
                    [`${camelCaseKey}verified`]: true,
                  });
                  setShowIframe(false);

                }}
              >
                {"Confirm Verification"}
              </Button>}
              <Button
                className="mt-2"
                style={{ borderRadius: "10px" }}
                variant="secondary"
                onClick={() => setShowIframe(false)}
              >
                Close
              </Button>
            </Modal.Body>
          </Modal>
        )}
      </div>
    );


    const renderImageInputMultiple = (
      updateFieldValue,
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
    ) => {
      const imageUrls = fieldValue?.split(',').filter(Boolean) || [];

      const handleDeleteImage = (index) => {
        const updatedImageUrls = imageUrls.filter((_, idx) => idx !== index);
        const updatedImageUrlsString = updatedImageUrls.join(',');
        setApplicationDetails({
          ...applicationDetails,
          [`${camelCaseKey}verified`]: false, // Reset verified status
        });
        updateFieldValue(updatedImageUrlsString)
      };

      return (
        <div className="imageDiv d-flex align-items-center gap-4">
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <div className="flex-wrap">
              <button
                style={{
                  borderRadius: '10px',
                  height: '40px',
                  padding: '5px 15px',
                  fontSize: '12px',
                  cursor: 'pointer',
                  margin: '15px 0',
                  border: 'none',
                  backgroundColor:
                    fieldErrors?.[camelCaseKey]?.touched &&
                      fieldErrors?.[camelCaseKey]?.errors?.length > 0
                      ? '#921d1e'
                      : '#f0f0f0',
                  color:
                    fieldErrors?.[camelCaseKey]?.touched &&
                      fieldErrors?.[camelCaseKey]?.errors?.length > 0
                      ? '#fff'
                      : '#333',
                }}
                onClick={() => document.getElementById(`${camelCaseKey}_imageInput`).click()}
                onBlur={(e) => {
                  checkErrorFn(input, camelCaseKey, fieldValue);
                }}
              >
                Upload File
              </button>
              <br />
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 &&
                fieldErrors?.[camelCaseKey]?.touched &&
                fieldErrors?.[camelCaseKey]?.errors?.map((err, idx) => (
                  <span key={idx} className="errorPresent" style={style.errorSpan}>
                    {err}
                    <br />
                  </span>
                ))}
              <br />
              {fieldValue && !applicationDetails[`${camelCaseKey}verified`] && (
                <span key={'Dgf'} className="errorPresent" style={style.errorSpan}>
                  {'Please Verify The Images'}
                  <br />
                </span>
              )}
            </div>

            <input
              type="file"
              accept="image/*"
              multiple
              id={`${camelCaseKey}_imageInput`}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                opacity: 0,
                width: '100%',
                height: '100%',
                cursor: 'pointer',
              }}
              onChange={(e) => {
                handleUploadImagesArray(e, camelCaseKey, updateFieldValue);
                fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value);
                e.target.value = null;
              }}
              onBlur={(e) => {
                checkErrorFn(input, camelCaseKey, fieldValue);
              }}
            />
          </div>

          {/* Display Multiple Images */}
          {imageUrls.length > 0 && (
            <>
              <div className="d-flex flex-wrap">
                {imageUrls.map((url, index) => (
                  <div
                    key={index}
                    className="position-relative m-2 ms-3"
                    style={{ display: 'inline-block' }}
                  >
                    <img
                      src={url}
                      alt={`${label} ${index + 1}`}
                      style={{
                        height: '50px',
                        width: 'auto',
                      }}
                      className="p-2"
                      onClick={() => SetImageModalOpen(camelCaseKey)}
                    />
                    <Button
                      className="position-absolute p-0 m-0"
                      variant="outline"
                      style={{ top: '20px', right: '-15px' }}
                      size="sm"
                      onClick={() => SetImageModalOpen(camelCaseKey)}
                    >
                      <IoEyeOutline size={"25"} />
                    </Button>
                    <Button
                      className="position-absolute p-0 m-0 "
                      variant="outline"
                      style={{ top: '20px', right: '-40px' }}
                      size="sm"
                      onClick={() => handleDeleteImage(index)}
                    >

                      <MdDeleteOutline size={'25'} />
                    </Button>

                  </div>
                ))}
              </div>

              {!applicationDetails[`${camelCaseKey}verified`] && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    className="ms-4 verifybutton verifyBtn"
                    onClick={() => SetImageModalOpen(camelCaseKey)}
                    style={{ marginLeft: 'auto' }}
                  >
                    Verify
                  </Button>
                </div>
              )}

              {applicationDetails[`${camelCaseKey}verified`] && (
                <div
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                  className={'fw-bold ms-4'}
                >
                  <Badge bg="success">
                    <FaCheckCircle size={'18'} className="me-2" />
                    Verified
                  </Badge>
                </div>
              )}
            </>
          )}

          {/* Image Modal for Verification */}
          {OpenImageModal && (
            <Modal
              size="xl"
              show={OpenImageModal == camelCaseKey}
              onHide={() => SetImageModalOpen(false)}
              centered
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Body className="text-center">
                <ImageCarousel
                  imageUrls={imageUrls}
                  label={label}
                  applicationDetails={applicationDetails}
                  setApplicationDetails={setApplicationDetails}
                  camelCaseKey={camelCaseKey}
                  SetImageModalOpen={SetImageModalOpen}
                />
              </Modal.Body>

            </Modal>
          )}
        </div>
      );
    };

    const ImageCarousel = ({
      imageUrls,
      label,
      applicationDetails,
      setApplicationDetails,
      camelCaseKey,
      SetImageModalOpen,
    }) => {
      const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

      // Reset currentImageIndex when modal opens
      React.useEffect(() => {
        setCurrentImageIndex(0);
      }, []);

      const handleNext = () => {
        if (currentImageIndex < imageUrls.length - 1) {
          setCurrentImageIndex(currentImageIndex + 1);
        }
      };

      const handlePrev = () => {
        if (currentImageIndex > 0) {
          setCurrentImageIndex(currentImageIndex - 1);
        }
      };

      const isLastImage = currentImageIndex === imageUrls.length - 1;

      return (
        <>

          <img
            src={imageUrls[currentImageIndex]}
            alt={`${label} ${currentImageIndex + 1}`}
            style={{
              maxHeight: '80vh',
              objectFit: 'contain',
              marginBottom: '10px',
            }}
          />

          <div className="d-flex justify-content-center mb-2">
            <Button
              className="me-2"
              variant="secondary-primary"
              disabled={currentImageIndex === 0}
              onClick={handlePrev}
            >
              <FaArrowAltCircleLeft size={30} />
            </Button>
            <Button
              variant="secondary-primary"
              disabled={currentImageIndex === imageUrls.length - 1}
              onClick={handleNext}
            >
              <FaArrowAltCircleRight size={30} />
            </Button>
          </div>
          {!applicationDetails[`${camelCaseKey}verified`] && isLastImage && (
            <Button
              className="mt-2"
              style={{ borderRadius: '10px' }}
              onClick={() => {
                setApplicationDetails({
                  ...applicationDetails,
                  [`${camelCaseKey}verified`]: true,
                });
                SetImageModalOpen(false);
              }}
            >
              Confirm Verification
            </Button>

          )}
          <Button
            className="mt-2"
            style={{ borderRadius: "10px" }}
            variant="secondary"
            onClick={() => SetImageModalOpen(false)}
          >
            Close
          </Button>
        </>
      );
    };

    const renderImageInput = (
      updateFieldValue,
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
    ) => (
      <div className="imageDiv d-flex align-items-center gap-4">
        <div style={{ position: "relative", display: "inline-block" }}>
          <div className="d-flex flex-column">

            <button
              className="me-4"
              style={{
                borderRadius: "10px",
                height: "40px",
                padding: "5px 15px",
                fontSize: "12px",
                cursor: "pointer",
                margin: '15px 0',
                border: "none",
                backgroundColor:
                  fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                    ? '#921d1e' :
                    "#f0f0f0", // Error background color

                // border:
                //   fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                //     ? "1px solid red"
                //     : "1px solid #ccc", // Error border    
                color:
                  fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                    ? "#fff"
                    : "#333", // Error background color
              }}
              onClick={() => document.getElementById(`${camelCaseKey}_fileInput`).click()}
            >
              Upload File
            </button>

            <br />
            {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched &&
              fieldErrors?.[camelCaseKey]?.errors?.map((err, idx) => (
                <span key={idx} className="errorPresent" style={style.errorSpan}>
                  {err}
                  <br />
                </span>

              ))
            }
            <br />
            {fieldValue && !applicationDetails[`${camelCaseKey}verified`] && <span key={"Dgf"} className="errorPresent" style={style.errorSpan}>
              {"Please Verify The Image"}
              <br />
            </span>}
          </div>
          <input
            type="file"
            accept="image/*"
            id={`${camelCaseKey}_fileInput`}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              opacity: 0,
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
            onChange={(e) => {
              handleUploadImageArray(e, camelCaseKey, updateFieldValue);

              setApplicationDetails({
                ...applicationDetails,
                [`${camelCaseKey}verified`]: true,
              })
              fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value);
              e.target.value = null;

            }}
            onBlur={(e) => { checkErrorFn(input, camelCaseKey, fieldValue) }}

          />
        </div>


        {fieldValue && (
          <>
            <img
              src={fieldValue}
              style={{ marginLeft: "10px", width: "100px", cursor: "pointer" }}
              onClick={() => {
                setShowIframe(camelCaseKey);
              }}
            />
            {!applicationDetails[`${camelCaseKey}verified`] && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  className="ms-2 verifybutton verifyBtn"
                  onClick={() => setShowIframe(camelCaseKey)}
                >
                  Verify
                </Button>
              </div>
            )}

            {applicationDetails[`${camelCaseKey}verified`] && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }} className={"fw-bold"}>
                <Badge bg="success" >
                  <FaCheckCircle size={"18"} className="me-2" />

                  Verified
                </Badge>
              </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button className="ms-2" variant='outline' onClick={() => { updateFieldValue(null); setApplicationDetails({ ...applicationDetails, [camelCaseKey + "verified"]: false }) }} style={{ marginLeft: 'auto' }}>

                <MdDeleteOutline size={"25"}></MdDeleteOutline>
              </Button>
            </div>
          </>
        )}


        {showIframe && (
          <Modal
            size="lg"
            show={showIframe === camelCaseKey}
            onHide={() => setShowIframe(false)}
            centered
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Body className="text-center">
              <img
                src={fieldValue}

                style={{
                  maxHeight: '80vh',
                  objectFit: 'contain',
                  marginBottom: '10px',
                }}
              />
              {!applicationDetails[camelCaseKey + "verified"] && <Button
                className="mt-2"
                style={{ borderRadius: "10px" }}
                onClick={() => {
                  setApplicationDetails({
                    ...applicationDetails,
                    [`${camelCaseKey}verified`]: true,
                  });
                  setShowIframe(false);

                }}
              >
                {"Confirm Verification"}
              </Button>}
              <Button
                className="mt-2"
                style={{ borderRadius: "10px" }}
                variant="secondary"
                onClick={() => setShowIframe(false)}
              >
                Close
              </Button>
            </Modal.Body>
          </Modal>
        )}
      </div>
    );




    const updateMarkValue = (value, tab) => {
      const updatedFields = dynamicFields.map((fs) => {
        if (fs.id === fieldSet.id) {
          let isCorrect = Number(fs.fields[fieldIndex - 1].value) >= Number(value);
          if (!isCorrect)
            setFieldErrors((prevState) => ({
              ...prevState,
              [camelCaseKey]: {
                errors: [...new Set([...(prevState[camelCaseKey]?.errors || []), 'Total mark should be greater then obtained mark.'])], // Append the error message
                touched: prevState?.[camelCaseKey]?.touched,
              },
            }));
          let percentMark = (Number(fs.fields[fieldIndex - 1].value) / Number(value)) * 100;
          if (percentMark < 0 || percentMark > 100) percentMark = 'Invalid';
          fs.fields[fieldIndex].value = value;

          fs.fields[fieldIndex + 1].value = Number(percentMark).toFixed(2);

          fs.fields[fieldIndex].tab = tab || camelCase(fs?.tab);
          return { ...fs };
        }
        return fs;
      });
      setDynamicFields(updatedFields);
    };
    const updateMarkValue2 = (value, tab) => {
      const updatedFields = dynamicFields.map((fs) => {
        if (fs.id === fieldSet.id) {
          let isCorrect = Number(fs.fields[fieldIndex + 1].value) >= Number(value);
          if (!isCorrect)
            setFieldErrors((prevState) => ({
              ...prevState,
              [camelCaseKey]: {
                errors: [...new Set([...(prevState[camelCaseKey]?.errors || []), 'Total mark should be greater then obtained mark.'])], // Append the error message
                touched: prevState?.[camelCaseKey]?.touched,
              },
            }));
          let percentMark = (Number(value) / Number(fs.fields[fieldIndex + 1].value)) * 100;
          if (percentMark < 0 || percentMark > 100) percentMark = 'Invalid';
          fs.fields[fieldIndex].value = value;

          fs.fields[fieldIndex + 2].value = Number(percentMark).toFixed(2);

          fs.fields[fieldIndex].tab = tab || camelCase(fs?.tab);
          return { ...fs };
        }
        return fs;
      });
      setDynamicFields(updatedFields);
    };


    const updateFieldValue = (value, tab) => {
      const updatedFields = dynamicFields.map((fs) => {
        if (fs.id === fieldSet.id) {
          fs.fields[fieldIndex].value = value;
          fs.fields[fieldIndex].tab = tab || camelCase(fs?.tab);
          return { ...fs };
        }
        return fs;
      });
      setDynamicFields(updatedFields);
    };
    const renderInput = (type, field, fieldIndex, fieldProps, item = null, idx = null) => {
      const commonProps = {
        className: "form-check-input",
        style: { border: "1px solid brown", borderRadius: "10px" },
        ...fieldProps,
      };

      const camelCaseKey = field?.values?._id + "__" + fieldSet.id
      if (type === "checkbox" || type === "radio") {
        return (
          <div className="form-check" key={`${item.label}.${idx}`}>
            <input {...commonProps} type={type} value={item.value} />
            <label
              className="form-check-label"
              htmlFor={commonProps.id}
              style={style.label}
            >
              {item.label}
            </label>
          </div>
        );


      }

      console.log("er3", { field })

      switch (label) {



        case 'Maximum Marks':

          return (
            <>
              <input
                className="form-control"
                style={
                  fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input
                }
                type={type === 'string' ? 'text' : type}
                // {...fieldProps}
                value={field?.value}
                onChange={(e) => {
                  updateFieldValue(e.target.value)
                  updateMarkValue(e.target.value);
                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(field, camelCaseKey, field?.value);
                }}
                onBlur={(e) => {
                  checkErrorFn(field, camelCaseKey, field?.value);
                }}
              />
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 &&
                fieldErrors?.[camelCaseKey]?.touched &&
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>
                    {err}
                    <br />
                  </span>
                ))}
            </>
          );
        case 'Marks Obtained':
          return (
            <>
              <input
                className="form-control"
                style={
                  fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input
                }
                type={type === 'string' ? 'text' : type}
                // {...fieldProps}
                value={field?.value}
                onChange={(e) => { updateFieldValue(e.target.value); updateMarkValue2(e.target.value) }}
                onBlur={(e) => {
                  checkErrorFn(field, camelCaseKey, field?.value);
                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(field, camelCaseKey, field?.value);
                }}
              />
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 &&
                fieldErrors?.[camelCaseKey]?.touched &&
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>
                    {err}
                    <br />
                  </span>
                ))}
            </>
          );
        case 'Percentage of Marks/CGPA/Grade':
          return (
            <>
              <input
                className="form-control"
                style={
                  fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input
                }
                type={type === 'string' ? 'text' : type}
                // {...fieldProps}
                value={field?.value}
                // onChange={(e) => updateFieldValue(e.target.value)}
                onBlur={(e) => {
                  checkErrorFn(field, camelCaseKey, field?.value);

                }}
              />
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 &&
                fieldErrors?.[camelCaseKey]?.touched &&
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>
                    {err}
                    <br />
                  </span>
                ))}
            </>
          );
          break;

        case 'Total Experience':
          return (
            <>
              <input
                className="form-control"
                style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                type={type === "string" ? "text" : type}
                // {...fieldProps}
                value={field?.value}
                // onChange={(e) => updateFieldValue(e.target.value)}
                onBlur={(e) => {
                  checkErrorFn(field, camelCaseKey, field?.value)
                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(field, camelCaseKey, field?.value)

                }}
              />
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                ))
              )}
            </>
          )
        case "Start Date of Service":
          return <>
            <input
              className="form-control"
              style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
              type={type === "string" ? "text" : type}
              // {...fieldProps}
              value={field?.value}
              onChange={(e) => {
                //  updateFieldValue(e.target.value)
                updateExpValue(e.target.value)
                fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(field, camelCaseKey, field?.value)
              }}
              onBlur={(e) => {
                checkErrorFn(field, camelCaseKey, field?.value)
              }}
            />
            {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
              fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
              ))
            )}
          </>

        case 'End date of Service':
          return (
            <>
              <input
                className="form-control"
                style={
                  fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input
                }
                type={type === 'string' ? 'text' : type}
                // {...fieldProps}
                value={field?.value}
                onChange={(e) => { updateFieldValue(e.target.value); updateExpValue2(e.target.value) }}
                onBlur={(e) => {
                  checkErrorFn(field, camelCaseKey, field?.value);
                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(field, camelCaseKey, field?.value);
                }}
              />
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 &&
                fieldErrors?.[camelCaseKey]?.touched &&
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>
                    {err}
                    <br />
                  </span>
                ))}
            </>
          );



        default:
          return (
            <>
              <input
                className="form-control"
                style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                type={type === "string" ? "text" : type}
                // {...fieldProps}
                value={field?.value}
                onChange={(e) => {
                  updateFieldValue(e.target.value)
                  checkErrorFn(field, camelCaseKey, e.target.value)
                }}
                onBlur={(e) => {
                  checkErrorFn(field, camelCaseKey, field?.value)
                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(field, camelCaseKey, field?.value)

                }}
              />
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                ))
              )}
            </>

          );
          break;
      }


    };

    const renderSelectOptionsForDesirableQualification = () =>
      applicationDetails?.vacancyId?.department?.desirableQualification?.map(
        (option, idx) => (
          <option key={`${option.qualification}.${idx}`} value={option.qualification}>
            {option.qualification}
          </option>
        )
      );

    const renderField = (field, fieldIndex) => {
      switch (field?.values?.inputFieldType) {
        case "checkbox":
          return field?.values?.inputFieldTags.map((item, idx) =>
            renderInput("checkbox", {
              checked: checkedValues.includes(item.value),
              onChange: (e) => handleCheckboxChange(e, item),
              id: `flexCheckboxDefault1.${idx}`,
            }, item, idx)
          );
        case "pdf":
          return (
            renderPdfInput(updateFieldValue, field?.value, camelCaseKey, label, field, applicationDetails, setApplicationDetails)
          );
        case "multiplePdf":
          return (
            renderPdfInputMultiple(updateFieldValue, field?.value, camelCaseKey, label, field, applicationDetails, setApplicationDetails)
          );
        case "image":
          return (
            renderImageInput(updateFieldValue, field?.value, camelCaseKey, label, field, applicationDetails, setApplicationDetails)
          );
        case "multipleImage":
          return (
            renderImageInputMultiple(updateFieldValue, field?.value, camelCaseKey, label, field, applicationDetails, setApplicationDetails)
          );
        case "radio":
          return field?.values?.inputFieldTags.map((item, idx) =>
            renderInput("radio", {
              checked: field.value === item.value,
              name: field.values.inputFieldName,
              onChange: (e) => updateFieldValue(e.target.value, item?.formTab),
              id: `flexRadioDefault1.${idx}`,
            }, item, idx)
          );

        case "select":
          return (
            <>
              <select
                style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                value={field.value}
                onChange={(e) => {
                  updateFieldValue(e.target.value)
                  checkErrorFn(field, camelCaseKey, e.target.value)

                }}
                onBlur={(e) => {
                  checkErrorFn(field, camelCaseKey, field?.value)
                }}
              >
                <option value="">Select</option>
                {label == "Course Name" && renderSelectOptionsForDesirableQualification()}
                {label !== "Course Name" && field?.values?.inputFieldTags?.map(
                  (option, idx) => (
                    <option key={`${option.id}`} value={option.label}>
                      {option.label}
                    </option>
                  )
                )}
              </select>
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                ))
              )}
            </>
          );




        default:
          return renderInput(field?.values?.inputFieldType, field, fieldIndex, {
            value: field.value,
            onChange: (e) => updateFieldValue(e.target.value),
          });
      }
    };

    return (
      <Col key={fieldIndex} xs={window.innerWidth >= 793 ? field?.values?.columnLength : 12} md={field?.values?.columnLength}>
        {field?.values?.inputFieldType === "SubHeading" ? (
          <>
            <label className="ps-0 ms-0 form-label form-heading-main">{label}</label>
          </>
        ) : (
          <>
            <label className="inputBoxHeadForm upload-head-gap" style={style.label}>
              {label}
              {field?.values?.isRequired && label && (
                <span className="mx-1 text-danger star">*</span>
              )}
            </label>
            {renderField(field, fieldIndex)}
          </>
        )}
      </Col>
    );
  };


  const checkErrorFn = async (input, camelCaseKey, fieldValue) => {
    // console.log("check this ", { input }, { camelCaseKey }, { fieldValue });
    fieldValue = fieldValue?.toString()?.trim()
    if (!input.values.isRequired && !fieldValue) {
      console.log({ fieldErrors })

      setFieldErrors(prevState => ({
        ...prevState,
        [camelCaseKey]: {
          errors: [],
          touched: true
        }
      }));
      return;
    }

    else if (input.values.isRequired && !fieldValue) {
      console.log({ fieldErrors })
      setFieldErrors(prevState => ({
        ...prevState,
        [camelCaseKey]: {
          errors: [
            ...new Set(["This field is required."])
          ], // Append the error message
          touched: true
        }
      }))
    }
    else {
      let errCount = 0
      console.log({ fieldErrors })

      let rExp = input.values.validationReg;
      console.log({ rExp });

      // rExp.forEach(reg => {
      for (let index = 0; index < rExp.length; index++) {
        const reg = rExp[index];


        // Assuming reg.applicable_regex contains the regular expression string
        const regex = new RegExp(reg.applicable_regex);

        if (!regex.test(fieldValue)) {
          errCount++
          // If the regex does not match the field value, update the fieldError state
          setFieldErrors(prevState => ({
            ...prevState,
            [camelCaseKey]: {
              errors: [
                ...new Set([...(prevState[camelCaseKey]?.errors || []), reg.error_message])
              ], // Append the error message
              touched: true
            }
          }));
        }
      };
      if (errCount < 1) {
        console.log({ fieldErrors })

        setFieldErrors(prevState => ({
          ...prevState,
          [camelCaseKey]: {
            errors: [],
            touched: true
          }
        }));
      }
    }
  };


  const getStatesByCountry = (countryName) => {
    const foundCountry = countriesData.find((country) => country.name === countryName);
    return foundCountry ? foundCountry.states.map(state => state.state) : [];
  };

  const getDistrictsByState = (countryName, stateName) => {
    const foundCountry = countriesData.find((country) => country.name === countryName);
    const foundState = foundCountry?.states.find((state) => state.state === stateName);
    return foundState ? foundState.districts : [];
  };

  const [isSameAsCorrespondence, setIsSameAsCorrespondence] = useState(false);

  // Handle checkbox change
  const handleCheckboxChange_ = (e) => {
    setIsSameAsCorrespondence(e.target.checked);
  };

  useEffect(() => {
    console.log("console.log");

    // Check if the 'age' field is already calculated to avoid redundant updates
    if (
      applicationDetails?.["66a37bab1b102d1ba93eee2f"] &&
      !applicationDetails?.age // Only proceed if 'age' is not already calculated
    ) {
      setApplicationDetails((prevDetails) => ({
        ...prevDetails,
        age: Math.floor(
          (new Date(prevDetails?.advertisement?.advertisementClosingDate) -
            new Date(
              prevDetails?.["66a37bab1b102d1ba93eee2f"]
                ?.split("/")
                .reverse()
                .join("-") || new Date() // Fallback to current date if the value is not present
            )) /
          (365.25 * 24 * 60 * 60 * 1000)
        ),
      }));
    }
  }, [applicationDetails]);

  //useEffects For Validations
  useEffect(() => {
    if (isSameAsCorrespondence) {
      setApplicationDetails((prev) => ({
        ...prev,
        // Add the properties from correspondence details you want to copy here
        ["66a672c14db5f02d6861e92f"]: prev?.["66a66fd74db5f02d6861e871"], // Example
        ["66a672c14db5f02d6861e930"]: prev?.["66a66fd74db5f02d6861e872"], // Example
        ["66a672c14db5f02d6861e931"]: prev?.["66a66fd74db5f02d6861e873"], // Example
        ["66a672c14db5f02d6861e932"]: prev?.["66a6705e4db5f02d6861e894"], // Example
        ["66a672c14db5f02d6861e933"]: prev?.["66a6705e4db5f02d6861e895"], // Example
        ["66a672c14db5f02d6861e934"]: prev?.["66a6705e4db5f02d6861e896"], // Example
        ["67456a28ba7bd153d04f3f61"]: prev?.["6745678fba7bd153d04f3e61"]     
      }));
    }

  }, [isSameAsCorrespondence]);

  useEffect(() => {
    // Assuming formData contains the data when the component loads
    if (
      applicationDetails?.["66a672c14db5f02d6861e92f"] === applicationDetails?.["66a66fd74db5f02d6861e871"] &&
      applicationDetails?.["66a672c14db5f02d6861e930"] === applicationDetails?.["66a66fd74db5f02d6861e872"] &&
      applicationDetails?.["66a672c14db5f02d6861e931"] === applicationDetails?.["66a66fd74db5f02d6861e873"] &&
      applicationDetails?.["66a672c14db5f02d6861e932"] === applicationDetails?.["66a6705e4db5f02d6861e894"] &&
      applicationDetails?.["66a672c14db5f02d6861e933"] === applicationDetails?.["66a6705e4db5f02d6861e895"] &&
      applicationDetails?.["66a672c14db5f02d6861e934"] === applicationDetails?.["66a6705e4db5f02d6861e896"] &&
      applicationDetails?.["67456a28ba7bd153d04f3f61"] === applicationDetails?.["6745678fba7bd153d04f3e61"] 
    ) {
      // If correspondence and permanent addresses are the same, set the checkbox to true
      setIsSameAsCorrespondence(true);
    } else {
      // Otherwise, set the checkbox to false
      setIsSameAsCorrespondence(false);
    }
  }, [applicationDetails]);



  useEffect(() => {
    let markTotalIn10thId = "66a687874db5f02d6861ed06"
    let markObtainedIn10thId = "66a687874db5f02d6861ed05"
    let percentageIn10thId = "66a687874db5f02d6861ed07"

    let markTotalIn12thId = "66a731359680c25e280d6e4b"
    let markObtainedIn12thId = "66a730c29680c25e280d6de8"
    let percentageIn12thId = "66a7359a9680c25e280d6f16"

    let markTotalInGrThId = "66a687874db5f02d6861ed10"
    let markObtainedInGrThId = "66a687874db5f02d6861ed0f"
    let percentageInGrThId = "66a687874db5f02d6861ed11"


    if (Number(applicationDetails?.[markTotalIn10thId] < Number(applicationDetails?.[markObtainedIn10thId]))) {
      setFieldErrors(prevState => ({
        ...prevState,
        [markTotalIn10thId]: {
          errors: [
            ...new Set([...(prevState[markTotalIn10thId]?.errors || []), "Total Mark should be greater then obtained mark"])
          ], // Append the error message
          touched: prevState?.[markTotalIn10thId]?.touched
        }
      }));
    }
    if (Number(applicationDetails?.[markTotalIn12thId] < Number(applicationDetails?.[markObtainedIn12thId]))) {
      setFieldErrors(prevState => ({
        ...prevState,
        [markTotalIn12thId]: {
          errors: [
            ...new Set([...(prevState[markTotalIn12thId]?.errors || []), "Total Mark should be greater then obtained mark"])
          ], // Append the error message
          touched: prevState?.[markTotalIn12thId]?.touched
        }
      }));
    }
    if (Number(applicationDetails?.[markTotalInGrThId] < Number(applicationDetails?.[markObtainedInGrThId]))) {
      setFieldErrors(prevState => ({
        ...prevState,
        [markTotalInGrThId]: {
          errors: [
            ...new Set([...(prevState[markTotalInGrThId]?.errors || []), "Total Mark should be greater then obtained mark"])
          ], // Append the error message
          touched: prevState?.[markTotalInGrThId]?.touched
        }
      }));
    }


  }, [applicationDetails]);
  useEffect(() => {
    let markType10th = applicationDetails?.["66a687874db5f02d6861ed04"]
    let markTotalIn10thId = "66a687874db5f02d6861ed06"
    let markObtainedIn10thId = "66a687874db5f02d6861ed05"
    let percentageIn10thId = "66a687874db5f02d6861ed07"

    let markType12th = applicationDetails?.["66a728ec9680c25e280d6bf6"]
    let markTotalIn12thId = "66a731359680c25e280d6e4b"
    let markObtainedIn12thId = "66a730c29680c25e280d6de8"
    let percentageIn12thId = "66a7359a9680c25e280d6f16"

    let markTypeGr = applicationDetails?.["66a687874db5f02d6861ed0e"]
    let markTotalInGrThId = "66a687874db5f02d6861ed10"
    let markObtainedInGrThId = "66a687874db5f02d6861ed0f"
    let percentageInGrThId = "66a687874db5f02d6861ed11"


    // Update percentage only if it's different
    // setApplicationDetails((prevState) => {
    //   const calculatePercentage = (obtained, total) => {
    //     const obtainedMarks = Number(prevState?.[obtained]);
    //     const totalMarks = Number(prevState?.[total]);
    //     return isNaN(obtainedMarks) || isNaN(totalMarks) || totalMarks === 0
    //       ? ""
    //       : ((obtainedMarks / totalMarks) * 100).toFixed(2);
    //   };

    //   const newPercentage10th = calculatePercentage(
    //     markObtainedIn10thId,
    //     markTotalIn10thId
    //   );
    //   const newPercentage12th = calculatePercentage(
    //     markObtainedIn12thId,
    //     markTotalIn12thId
    //   );
    //   const newPercentageGr = calculatePercentage(
    //     markObtainedInGrThId,
    //     markTotalInGrThId
    //   );

    //   const updatedState = { ...prevState };

    //   // Conditionally update 10th percentage
    //   if (prevState?.[markType10th] === "Percentage") {
    //     if (prevState?.[percentageIn10thId] !== newPercentage10th) {
    //       updatedState[percentageIn10thId] =
    //         newPercentage10th <= 100 ? newPercentage10th : "Invalid";
    //     }
    //   }

    //   // Conditionally update 12th percentage
    //   if (prevState?.[markType12th] === "Percentage") {
    //     if (prevState?.[percentageIn12thId] !== newPercentage12th) {
    //       updatedState[percentageIn12thId] =
    //         newPercentage12th <= 100 ? newPercentage12th : "Invalid";
    //     }
    //   }

    //   // Conditionally update Graduation percentage
    //   if (prevState?.[markTypeGr] === "Percentage") {
    //     if (prevState?.[percentageInGrThId] !== newPercentageGr) {
    //       updatedState[percentageInGrThId] =
    //         newPercentageGr <= 100 ? newPercentageGr : "Invalid";
    //     }
    //   }

    //   return updatedState;
    // });



  }, [applicationDetails]);

  useEffect(() => {
    // Define the field IDs
    const yearofPassing10thId = "66a687874db5f02d6861ed02";
    const yearofPassing12thId = "66a727079680c25e280d6b2d";
    const yearofPassingGraduationId = "66a687874db5f02d6861ed0d";
    const birthDateId = "66a37bab1b102d1ba93eee2f"

    const year12 = Number(applicationDetails?.[yearofPassing12thId]);
    const year10 = Number(applicationDetails?.[yearofPassing10thId]);
    const yearGrad = Number(applicationDetails?.[yearofPassingGraduationId]);
    const birthYear = new Date(applicationDetails?.[birthDateId])?.getFullYear();
    const year12length = applicationDetails?.[yearofPassing12thId];
    const year10length = applicationDetails?.[yearofPassing10thId];
    const yearGradlength = applicationDetails?.[yearofPassingGraduationId];

    let newFieldErrors = { ...fieldErrors };

    // Validation: Ensure the years are 4 digits
    const validateYearLength = (year, id, label) => {
      if (year?.length > 4 || year?.length < 4) {
        newFieldErrors = {
          ...newFieldErrors,
          [id]: {
            errors: [
              ...new Set([...(newFieldErrors[id]?.errors || []), `${label} must be 4 digits`]),
            ],
            touched: newFieldErrors?.[id]?.touched
          }
        };
      } else {
        newFieldErrors = {
          ...newFieldErrors,
          [id]: {
            errors: newFieldErrors?.[id]?.errors?.filter(error => error !== `${label} must be 4 digits`),
            touched: newFieldErrors?.[id]?.touched
          }
        };
      }
    };

    // Validate year length for 10th, 12th, and Graduation years
    validateYearLength(year10length, yearofPassing10thId, "10th Passing year");
    validateYearLength(year12length, yearofPassing12thId, "12th Passing year");
    validateYearLength(yearGradlength, yearofPassingGraduationId, "Graduation Passing year");

    // Check if year12 is less than year10
    if (year12 <= year10) {
      newFieldErrors = {
        ...newFieldErrors,
        [yearofPassing12thId]: {
          errors: [
            ...new Set([...(newFieldErrors[yearofPassing12thId]?.errors || []), "12th Passing year cannot be less than 10th Passing year"]),
          ],
          touched: newFieldErrors?.[yearofPassing12thId]?.touched
        },
        [yearofPassing10thId]: {
          errors: [
            ...new Set([...(newFieldErrors[yearofPassing10thId]?.errors || []), "10th Passing year cannot be greater than 12th Passing year"]),
          ],
          touched: newFieldErrors?.[yearofPassing10thId]?.touched
        }
      };
    } else {
      // Clear the error messages
      newFieldErrors = {
        ...newFieldErrors,
        [yearofPassing12thId]: {
          errors: newFieldErrors?.[yearofPassing12thId]?.errors?.filter(error => error !== "12th Passing year cannot be less than 10th Passing year"),
          touched: newFieldErrors?.[yearofPassing12thId]?.touched
        },
        [yearofPassing10thId]: {
          errors: newFieldErrors?.[yearofPassing10thId]?.errors?.filter(error => error !== "10th Passing year cannot be greater than 12th Passing year"),
          touched: newFieldErrors?.[yearofPassing10thId]?.touched
        }
      };
    }

    // Check if yearGrad is less than year10 or year12
    if (yearGrad <= year10) {
      newFieldErrors = {
        ...newFieldErrors,
        [yearofPassingGraduationId]: {
          errors: [
            ...new Set([...(newFieldErrors[yearofPassingGraduationId]?.errors || []), "Graduation Passing year cannot be less than 10th or 12th Passing year"]),
          ],
          touched: newFieldErrors?.[yearofPassingGraduationId]?.touched
        },
        ...(yearGrad <= year10 && {
          [yearofPassing10thId]: {
            errors: [
              ...new Set([...(newFieldErrors[yearofPassing10thId]?.errors || []), "10th Passing year cannot be greater than Graduation Passing year"]),
            ],
            touched: newFieldErrors?.[yearofPassing10thId]?.touched
          }
        }),
        ...(yearGrad <= year12 && {
          [yearofPassing12thId]: {
            errors: [
              ...new Set([...(newFieldErrors[yearofPassing12thId]?.errors || []), "12th Passing year cannot be greater than Graduation Passing year"]),
            ],
            touched: newFieldErrors?.[yearofPassing12thId]?.touched
          }
        }),
      };
    } else if (yearGrad <= year12 && yearGrad > year10) {
      newFieldErrors = {
        ...newFieldErrors,
        [yearofPassingGraduationId]: {
          errors: [
            ...new Set([...(newFieldErrors[yearofPassingGraduationId]?.errors || []), "Graduation Passing year cannot be less than 10th or 12th Passing year"]),
          ],
          touched: newFieldErrors?.[yearofPassingGraduationId]?.touched
        },
        [yearofPassing10thId]: {
          errors: newFieldErrors?.[yearofPassing10thId]?.errors?.filter(error => error !== "10th Passing year cannot be greater than Graduation Passing year"),
          touched: newFieldErrors?.[yearofPassing10thId]?.touched
        },
        ...(yearGrad < year12 && {
          [yearofPassing12thId]: {
            errors: [
              ...new Set([...(newFieldErrors[yearofPassing12thId]?.errors || []), "12th Passing year cannot be greater than Graduation Passing year"]),
            ],
            touched: newFieldErrors?.[yearofPassing12thId]?.touched
          }
        }),
      };
    }

    else {
      // Clear the error messages for Graduation check
      newFieldErrors = {
        ...newFieldErrors,
        [yearofPassingGraduationId]: {
          errors: newFieldErrors?.[yearofPassingGraduationId]?.errors?.filter(error => error !== "Graduation Passing year cannot be less than 10th or 12th Passing year"),
          touched: newFieldErrors?.[yearofPassingGraduationId]?.touched
        },
        [yearofPassing10thId]: {
          errors: newFieldErrors?.[yearofPassing10thId]?.errors?.filter(error => error !== "10th Passing year cannot be greater than Graduation Passing year"),
          touched: newFieldErrors?.[yearofPassing10thId]?.touched
        },
        [yearofPassing12thId]: {
          errors: newFieldErrors?.[yearofPassing12thId]?.errors?.filter(error => error !== "12th Passing year cannot be greater than Graduation Passing year"),
          touched: newFieldErrors?.[yearofPassing12thId]?.touched
        }
      };
    }

    if (birthYear >= year12) {
      console.log({ year12 }, { birthYear }, { year10 }, { yearGrad })
      newFieldErrors = {
        ...newFieldErrors,
        [yearofPassing12thId]: {
          errors: [
            ...new Set([...(newFieldErrors[yearofPassing12thId]?.errors || []), "12th cannot be greater than or equal to Birth year"]),
          ],
          touched: newFieldErrors?.[yearofPassing12thId]?.touched,
        }
      };
    } else {
      // Clear birth year related errors
      newFieldErrors = {
        ...newFieldErrors,
        [yearofPassing12thId]: {
          errors: newFieldErrors?.[yearofPassing12thId]?.errors?.filter(
            error => error !== "12th cannot be greater than or equal to Birth year"
          ),
          touched: newFieldErrors?.[yearofPassing12thId]?.touched,
        }
      };
    }

    if (birthYear >= year10) {
      newFieldErrors = {
        ...newFieldErrors,
        [yearofPassing10thId]: {
          errors: [
            ...new Set([...(newFieldErrors[yearofPassing10thId]?.errors || []), "10th Passing year cannot be less than or equal to BirthYear"]),
          ],
          touched: newFieldErrors?.[yearofPassing10thId]?.touched,
        }
      };
    } else {
      // Clear birth year related errors
      newFieldErrors = {
        ...newFieldErrors,
        [yearofPassing10thId]: {
          errors: newFieldErrors?.[yearofPassing10thId]?.errors?.filter(
            error => error !== "10th Passing year cannot be less than or equal to BirthYear"
          ),
          touched: newFieldErrors?.[yearofPassing10thId]?.touched,
        }
      };
    }
    if (birthYear >= yearGrad) {
      // console.log({year12},{birthYear},{year10},{yearGrad})
      newFieldErrors = {
        ...newFieldErrors,
        [yearofPassingGraduationId]: {
          errors: [
            ...new Set([...(newFieldErrors[yearofPassingGraduationId]?.errors || []), "Graduation year cannot be less than or equal to Birth year"]),
          ],
          touched: newFieldErrors?.[yearofPassingGraduationId]?.touched,
        }
      };
    } else {
      // Clear birth year related errors
      newFieldErrors = {
        ...newFieldErrors,
        [yearofPassingGraduationId]: {
          errors: newFieldErrors?.[yearofPassingGraduationId]?.errors?.filter(
            error => error !== "Graduation year cannot be less than or equal to Birth year"
          ),
          touched: newFieldErrors?.[yearofPassingGraduationId]?.touched,
        }
      };
    }

    setFieldErrors(newFieldErrors);


    const alternateMobileId = "66cd254c1f0526789fdae6e6"
    const mobileNumber = "mobileNumber"
    const alternateMobileNo = applicationDetails?.[alternateMobileId]
    const alternateMobile = applicationDetails?.[mobileNumber]
    const validateMobileLength = (MobileId, id, label) => {
      if (MobileId?.length > 10 || MobileId?.length < 10) {
        newFieldErrors = {
          ...newFieldErrors,
          [id]: {
            errors: [
              ...new Set([...(newFieldErrors[id]?.errors || []), `${label} must be 10 digits`]),
            ],
            touched: newFieldErrors?.[id]?.touched
          }
        };
      }
      else {
        newFieldErrors = {
          ...newFieldErrors,
          [id]: {
            errors: newFieldErrors?.[id]?.errors?.filter(error => error !== `${label} must be 10 digits`),
            touched: newFieldErrors?.[id]?.touched
          }
        };
      }
    };
    if (Number(alternateMobileNo) === alternateMobile) {
      console.log("alter", Number(alternateMobileNo))
      console.log("alternate,", alternateMobile)
      newFieldErrors = {
        ...newFieldErrors,
        [mobileNumber]: {
          errors: [
            ...new Set([...(newFieldErrors[mobileNumber]?.errors || []), "Mobile No and Alternate Mobile No. Cannot be same"]),
          ],
          touched: newFieldErrors?.[mobileNumber]?.touched
        }
      };
    }

    validateMobileLength(alternateMobileNo, alternateMobileId, "Alternate Mobile No.")


  }, [applicationDetails]);


  const renderNonArrayFields = (item) => {
    // Function to render select input
    const renderSelectInput = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage
    ) => {
      console.log(applicationDetails?.vacancyId?.department, "lables are here")

      switch (label) {
        case "Gender":
          return <>
            <select
              // className="inputBoxForm text-dark"
              style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
              placeholder={label}
              value={fieldValue}
              onChange={(e) => {
                setApplicationDetails({
                  ...applicationDetails,
                  [camelCaseKey]: e.target.value,
                })

                fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value)
              }}
              onBlur={(e) => {
                checkErrorFn(input, camelCaseKey, fieldValue)
              }}
              max={new Date().toISOString().split('T')[0]}

            >

              <option value="" className="text-muted">Select</option>
              {onlyWomenAllowed ? <>
                <option key={"femaleOnly"} value={"Female"}>
                  Female
                </option>
              </> : input?.values?.inputFieldTags.map((item, idx) => (
                <option key={idx} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
              fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
              ))
            )}
          </>
        case "Course Name (Graduation)":

          return (
            // <select
            //   // className="inputBoxForm text-dark"
            //   style={style.input}
            //   placeholder={label}
            //   value={fieldValue}
            //   onChange={(e) =>
            //     setApplicationDetails({
            //       ...applicationDetails,
            //       [camelCaseKey]: e.target.value,
            //     })
            //   }
            // >

            // </select>

            <>
              <select
                // className="inputBoxForm text-dark"
                style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                placeholder={label}
                value={fieldValue}
                onChange={(e) => {
                  setApplicationDetails({
                    ...applicationDetails,
                    [camelCaseKey]: e.target.value,
                  })

                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value)
                }}
                onBlur={(e) => {
                  checkErrorFn(input, camelCaseKey, fieldValue)
                }}
                max={new Date().toISOString().split('T')[0]}

              >

                <option value="" className="text-muted">Select</option>
                {applicationDetails?.vacancyId?.department?.essentialQualification?.map(
                  (item, idx) => (
                    <option key={idx} value={item.qualification}>
                      {item.qualification}
                    </option>
                  )
                )}
              </select>
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                ))
              )}
            </>
          );
          break;
        case "Course Name (Post Graduation)":

          return (
            // <select
            //   // className="inputBoxForm text-dark"
            //   style={style.input}
            //   placeholder={label}
            //   value={fieldValue}
            //   onChange={(e) =>
            //     setApplicationDetails({
            //       ...applicationDetails,
            //       [camelCaseKey]: e.target.value,
            //     })
            //   }
            // >

            // </select>

            <>
              <select
                // className="inputBoxForm text-dark"
                style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                placeholder={label}
                value={fieldValue}
                onChange={(e) => {
                  setApplicationDetails({
                    ...applicationDetails,
                    [camelCaseKey]: e.target.value,
                  })

                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value)
                }}
                onBlur={(e) => {
                  checkErrorFn(input, camelCaseKey, fieldValue)
                }}
                max={new Date().toISOString().split('T')[0]}

              >

                <option value="" className="text-muted">Select</option>
                {applicationDetails?.vacancyId?.department?.desirableQualification?.map(
                  (item, idx) => (
                    <option key={idx} value={item.qualification}>
                      {item.qualification}
                    </option>
                  )
                )}
              </select>
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                ))
              )}
            </>
          );
          break;
        case "Country":
          return (
            <select
              style={style.input}
              placeholder={label}
              value={fieldValue}
              onChange={(e) => {
                setApplicationDetails({
                  ...applicationDetails,
                  [camelCaseKey]: e.target.value,
                });


                const selectedCountry = e.target.value;
                setSelectedCountry(selectedCountry);

                const newStates = getStatesByCountry(selectedCountry);
                console.log({ newStates })
                setStates(newStates);
                setSelectedState(''); // Clear selected state when country changes
                setDistricts([]);
                // Clear districts when country changes
              }}

            >
              <option value="">Select Country</option>
              {countriesData.map((country) => {
                return (
                  <option key={country.name} value={country.name}>
                    {country.name}
                  </option>
                )
              }
              )}
            </select>
          );
          break;

        case "State":
          return (
            <select
              style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
              placeholder={label}
              value={fieldValue}
              onChange={(e) => {
                setApplicationDetails({
                  ...applicationDetails,
                  [camelCaseKey]: e.target.value,
                });

                const selectedState = e.target.value;
                setSelectedState(selectedState);

                const newDistricts = getDistrictsByState(selectedCountry, selectedState);
                setDistricts(newDistricts);
              }}
              disabled={!selectedCountry}
            >
              <option value="">Select State</option>
              {states.map((state, idx) => (
                <option key={idx} value={state}>
                  {state}
                </option>
              ))}
            </select>
          );
          break;

        case "District":
          return (
            <select
              style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
              placeholder={label}
              value={fieldValue}
              onChange={(e) => {
                setApplicationDetails({
                  ...applicationDetails,
                  [camelCaseKey]: e.target.value,
                });

                fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value);
              }}
              disabled={!selectedState}
            >
              <option value="">Select District</option>
              {districts.map((district, idx) => (
                <option key={idx} value={district}>
                  {district}
                </option>
              ))}
            </select>
          );
          break;


        default:
          return (
            <>
              <select
                // className="inputBoxForm text-dark"
                style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                placeholder={label}
                value={fieldValue}
                onChange={(e) => {
                  setApplicationDetails({
                    ...applicationDetails,
                    [camelCaseKey]: e.target.value,
                  })

                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value)
                }}
                onBlur={(e) => {
                  checkErrorFn(input, camelCaseKey, fieldValue)
                }}
                max={new Date().toISOString().split('T')[0]}

              >

                <option value="" className="text-muted">Select</option>
                {input?.values?.inputFieldTags.map((item, idx) => (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                ))
              )}
            </>

          );
          break;
      }
    };

    // Function to render radio input
    const renderRadioInput = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage
    ) => (
      <div className="d-flex flex-wrap flex-row">
        {input?.values?.inputFieldTags.map((item, idx) => (
          <div
            className="form-check form-check-inline"
            key={`${item.label}.${idx}`}
          >
            <input
              className="form-check-input "
              // style={{ border: "1px solid black", borderRadius: "10px" }}
              type="radio"
              name={camelCaseKey}
              id={`flexRadioDefault1.${idx}`}
              value={item.value}
              checked={fieldValue === item.value}
              onChange={(e) =>
                setApplicationDetails({
                  ...applicationDetails,
                  [camelCaseKey]: e.target.value,
                })
              }
              max={new Date().toISOString().split('T')[0]}

            />
            <label
              className="form-check-label"
              htmlFor={`flexRadioDefault1.${idx}`}
            >
              {item.label}
            </label>
          </div>
        ))}
      </div>
    );

    // Function to render note
    const renderNote = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage
    ) => (
      // <></>
      <div className="d-flex flex-wrap  fw-bolder mb-3"  >
        <div style={{ fontFamily: "'Roboto', sans-serif", color: "brown" }} dangerouslySetInnerHTML={{ __html: input?.values?.note }}></div>
      </div>
    );

    // Function to render checkbox input
    const renderCheckboxInput = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage
    ) => (
      <>
        {input?.values?.inputFieldTags.map((item, idx) => (
          <div
            className="form-check form-switch mb-2"
            key={`${item.label}.${idx}`}
          >
            <input
              className="form-check-input "
              style={{ borderRadius: "10px", height: "16px" }}
              type="checkbox"
              value={item.value}
              checked={checkedValues.includes(item.value)}
              onChange={(e) => handleCheckboxChange(e, item)}
              id={`flexCheckboxDefault1.${idx}`}
              max={new Date().toISOString().split('T')[0]}

            />
            <label
              className="form-check-label inputBoxHeadForm"
              htmlFor={`flexCheckboxDefault1.${idx}`}
            >
              {item.label}
            </label>
          </div>
        ))}
      </>
    );

    const renderImageInput = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage
    ) => (
      <div className="imageDiv d-flex align-items-center gap-4">
        <div style={{ position: "relative", display: "inline-block" }}>
          <div className="flex-wrap">
            <button
              style={{
                borderRadius: "10px",
                height: "40px",
                padding: "5px 15px",
                fontSize: "12px",
                cursor: "pointer",
                margin: '15px 0',
                border: "none",
                backgroundColor:
                  fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                    ? '#921d1e' :
                    "#f0f0f0",

                color:
                  fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                    ? "#fff"
                    : "#333",

              }}
              onClick={() => document.getElementById(`${camelCaseKey}_imageInput`).click()}
              onBlur={(e) => { checkErrorFn(input, camelCaseKey, fieldValue) }}

            >
              Upload Image
            </button>
            <br />
            {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched &&
              fieldErrors?.[camelCaseKey]?.errors?.map((err, idx) => (
                <span key={idx} className="errorPresent" style={style.errorSpan}>
                  {err}
                  <br />
                </span>

              ))
            }
            <br />
            {fieldValue && !applicationDetails[`${camelCaseKey}verified`] && <span key={"Dgf"} className="errorPresent" style={style.errorSpan}>
              {"Please Verify The Image"}
              <br />
            </span>}
          </div>

          <input
            type="file"
            accept="image/*"
            id={`${camelCaseKey}_imageInput`}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              opacity: 0,
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
            onChange={(e) => {
              console.log("file-", e)
              handleUploadImage(e, camelCaseKey);
              setApplicationDetails({
                ...applicationDetails,
                [`${camelCaseKey}verified`]: false, // Set verified status to false on image upload
              });
              fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value);
              e.target.value = null;

            }}
            onBlur={(e) => { checkErrorFn(input, camelCaseKey, fieldValue) }}

          />
        </div>

        {/* Error Display */}

        {/* Image Preview and Verification Button */}
        {applicationDetails[camelCaseKey] && (
          <>

            <img
              key={label}
              src={fieldValue}
              alt={label}
              style={{
                // border: "1px solid black",
                height: "100px",
                width: "100px",
                objectFit: 'contain'
              }}
              className="p-2 ms-4"
              onClick={() => SetImageModalOpen(camelCaseKey)}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="outline"
                onClick={() => SetImageModalOpen(camelCaseKey)}
              >
                <IoEyeOutline size={"20"} />
              </Button>
              <Button className="ms-2" variant='outline' onClick={() => handleUploadImage(null, camelCaseKey)} style={{ marginLeft: 'auto' }}>
                <MdDeleteOutline size={"25"}></MdDeleteOutline>
              </Button>
            </div>
            {!applicationDetails[`${camelCaseKey}verified`] && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  className="ms-2 verifybutton verifyBtn"
                  onClick={() => SetImageModalOpen(camelCaseKey)}
                  style={{ marginLeft: 'auto' }}
                >
                  Verify
                </Button>
              </div>
            )}

            {applicationDetails[`${camelCaseKey}verified`] && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }} className={"fw-bold"}>
                <Badge bg="success" >
                  <FaCheckCircle size={"18"} className="me-2" />

                  Verified
                </Badge>
              </div>
            )}
          </>
        )}

        {/* Image Modal for Verification */}
        {OpenImageModal && (
          <Modal
            size="sm"
            show={OpenImageModal === camelCaseKey}
            onHide={() => SetImageModalOpen(false)}
            centered
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Body className="text-center">
              <img
                src={fieldValue}
                key={label}
                alt={label}
                style={{
                  maxHeight: "60vh",
                  objectFit: "contain",
                }}
              />
              {!applicationDetails[camelCaseKey + "verified"] && <Button
                className="mt-2"
                style={{ borderRadius: "10px" }}
                onClick={() => {
                  setApplicationDetails({
                    ...applicationDetails,
                    [`${camelCaseKey}verified`]: true,
                  });
                  SetImageModalOpen(false);

                }}
              >
                {"Confirm Verification"}
              </Button>}
              <Button
                className="mt-2"
                style={{ borderRadius: "10px" }}
                variant="secondary"
                onClick={() => SetImageModalOpen(false)}
              >
                Close
              </Button>
            </Modal.Body>
          </Modal>
        )}



      </div>


    );
    const renderPdfInputMultiple = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage
    ) => {
      const imageUrls = applicationDetails[camelCaseKey]?.split(',').filter(Boolean) || [];
      const extractNameFromUrl = (url) => {
        try {
          const queryParams = new URL(url).searchParams;
          return queryParams.get("name") || ""; // Default to empty string if no name found
        } catch (error) {
          console.error("Invalid URL:", url);
          return ""; // Return empty if URL parsing fails
        }
      };
      const handleDeleteImage = (index) => {
        const updatedImageUrls = imageUrls.filter((_, idx) => idx !== index);
        const updatedImageUrlsString = updatedImageUrls.join(',');
        setApplicationDetails({
          ...applicationDetails,
          [camelCaseKey]: updatedImageUrlsString,
          [`${camelCaseKey}verified`]: false, // Reset verified status
        });
      };
      const handleImageNameUpdate = (name, index) => {
        const updatedImageUrls = imageUrls.map((url, idx) => {
          if (idx === index) {
            const [baseUrl] = url.split('?'); // Get the base URL without query parameters
            return `${baseUrl}?name=${encodeURIComponent(name)}`; // Add updated query string
          }
          return url; // Keep the other URLs unchanged
        });

        const updatedImageUrlsString = updatedImageUrls.join(',');
        setApplicationDetails({
          ...applicationDetails,
          [camelCaseKey]: updatedImageUrlsString,
          [`${camelCaseKey}verified`]: false, // Reset verified status
        });
      };
















      return (
        <div className=" gap-4">
          <Row>
            {/* <div style={{ position: 'relative', display: 'inline-block' }}> */}
            {/* <div className="flex-wrap"> */}
            <button
              style={{
                position: 'relative',
                top: '-50px',
                left: '250px',
                borderRadius: '10px',
                height: '40px',
                width: '200px',
                padding: '5px 15px',
                
                fontSize: '12px',
                cursor: 'pointer',
                margin: '15px 0',
                border: 'none',
                marginBottom:'0px',
                backgroundColor:
                  fieldErrors?.[camelCaseKey]?.touched &&
                    fieldErrors?.[camelCaseKey]?.errors?.length > 0
                    ? '#921d1e'
                    : '#f0f0f0',
                color:
                  fieldErrors?.[camelCaseKey]?.touched &&
                    fieldErrors?.[camelCaseKey]?.errors?.length > 0
                    ? '#fff'
                    : '#333',
              }}
              onClick={() => document.getElementById(`${camelCaseKey}_imageInput`).click()}
              onBlur={(e) => {
                checkErrorFn(input, camelCaseKey, fieldValue);
              }}
            >
              <FaPlus /> <span className="ps-1">Upload FileS</span>
            </button>
            {fieldErrors?.[camelCaseKey]?.errors?.length > 0 &&
              fieldErrors?.[camelCaseKey]?.touched &&
              fieldErrors?.[camelCaseKey]?.errors?.map((err, idx) => (
                <span key={idx} className="errorPresent" style={style.errorSpan}>
                  {err}
                  <br />
                </span>
              ))}
            <br />
            {fieldValue && !applicationDetails[`${camelCaseKey}verified`] && (
              <span key={'Dgf'} className="errorPresent" style={{ marginTop: '-55px', color: 'red', fontSize: '12px' }}>
                {'Please Verify The File'}
                <br />
              </span>
            )}
            {/* </div> */}


            <input

              type="file"
              accept="application/pdf"
              multiple
              id={`${camelCaseKey}_imageInput`}
              style={{
                display: "none",
                position: 'absolute',
                top: 0,
                left: 0,
                opacity: 0,
                width: '100%',
                height: '100%',
                cursor: 'pointer',
              }}
              onChange={(e) => {
                handleUploadImages(e, camelCaseKey, applicationDetails, setApplicationDetails);
                fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value);
                e.target.value = null;
              }}
              onBlur={(e) => {
                checkErrorFn(input, camelCaseKey, fieldValue);
              }}
            />
            {/* </div> */}
          </Row>
          <Row
          style={{marginTop:'-30px'}}
          >
            {/* Display Multiple Images */}
            {imageUrls.length > 0 && (
              <>
                <div className=" mt-0 pt-0 ms-3 ps-2">
                  {imageUrls.map((url, index) => {
                    const name = extractNameFromUrl(url); // Extract the name from the URL

                    return <>

                      <div
                        key={index}
                        className="m-2 mt-0 mb-2 pt-0 ps-2 d-flex align-items-center justify-content-between gap-2 pb-2"
                        style={{ borderBottom: '1px solid #E6E6E6' }}
                      >
                        <img
                          src={pdfIcon}
                          alt={`${label} ${index + 1}`}
                          style={{
                            height: '60px',
                            width: 'auto',
                          }}
                          className="p-2 pt-0"
                          onClick={() => SetImageModalOpen(camelCaseKey)}
                        />
                        <input
                          variant="outline"
                          value={name}
                          // style={{ top: '20px', right: '75px' }}
                          className="form-control text-dark p-0 m-0"
                          style={{ ...style.input, width: "300px" }}
                          type={input.values.inputFieldType}
                          // placeholder={label}
                          onChange={(e) => {
                            handleImageNameUpdate(e.target.value, index)
                          }}
                          onBlur={(e) => {

                          }}
                          required={true}
                        // max={new Date().toISOString().split('T')[0]}

                        />
                        {!name && <span
                          className="errorPresent mt-5 pt-1 position-absolute p-0 m-0"
                          style={{ ...style.errorSpan, left: '150px' }}

                        >
                          Please enter the file name
                        </span>}


                        <Button
                          className=" p-0 m-0"
                          variant="outline"
                          // style={{ top: '20px', right: '75px' }}
                          size="sm"
                          onClick={() => SetImageModalOpen(camelCaseKey)}
                        >
                          <IoEyeOutline size={"25"} />
                        </Button>
                        <Button
                          className="p-0 m-0 "
                          variant="outline"
                          // style={{ top: '20px', right: '45px' }}
                          size="sm"
                          onClick={() => handleDeleteImage(index)}
                        >
                          <MdDeleteOutline size={'25'} />
                        </Button>

                      </div>
                      <br /></>
                  })}
                </div>

                {!applicationDetails[`${camelCaseKey}verified`] && (
                  <div className="text-end">
                    <Button
                      className="ms-3 verifybutton verifyBtn"
                      onClick={() => SetImageModalOpen(camelCaseKey)}
                      style={{ marginLeft: 'auto' }}
                    >
                      Verify
                    </Button>
                  </div>
                )}

                {applicationDetails[`${camelCaseKey}verified`] && (
                  <div
                    // style={{ display: 'flex', justifyContent: 'flex-end', paddingRight:"150px" }}
                    className={'d-flex justify-content-end me-2'}
                  >
                    <Badge bg="success">
                      <FaCheckCircle size={'18'} className="me-2" />
                      Verified
                    </Badge>
                  </div>
                )}
              </>
            )}
          </Row>
          {/* Image Modal for Verification */}
          {OpenImageModal && (
            <Modal
              size="xl"
              show={OpenImageModal == camelCaseKey}
              onHide={() => SetImageModalOpen(false)}
              centered
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Body className="text-center">
                <PdfCarousel
                  imageUrls={imageUrls}
                  label={label}
                  applicationDetails={applicationDetails}
                  setApplicationDetails={setApplicationDetails}
                  camelCaseKey={camelCaseKey}
                  SetImageModalOpen={SetImageModalOpen}
                />
              </Modal.Body>

            </Modal>
          )}
        </div>
      );
    };
    const renderImageInputMultiple = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage
    ) => {
      const imageUrls = applicationDetails[camelCaseKey]?.split(',').filter(Boolean) || [];

      const handleDeleteImage = (index) => {
        const updatedImageUrls = imageUrls.filter((_, idx) => idx !== index);
        const updatedImageUrlsString = updatedImageUrls.join(',');
        setApplicationDetails({
          ...applicationDetails,
          [camelCaseKey]: updatedImageUrlsString,
          [`${camelCaseKey}verified`]: false, // Reset verified status
        });
      };

      return (
        <div className="imageDiv d-flex align-items-center gap-4">
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <div className="flex-wrap">
              <button
                style={{
                  borderRadius: '10px',
                  height: '40px',
                  padding: '5px 15px',
                  fontSize: '12px',
                  cursor: 'pointer',
                  margin: '15px 0',
                  border: 'none',
                  backgroundColor:
                    fieldErrors?.[camelCaseKey]?.touched &&
                      fieldErrors?.[camelCaseKey]?.errors?.length > 0
                      ? '#921d1e'
                      : '#f0f0f0',
                  color:
                    fieldErrors?.[camelCaseKey]?.touched &&
                      fieldErrors?.[camelCaseKey]?.errors?.length > 0
                      ? '#fff'
                      : '#333',
                }}
                onClick={() => document.getElementById(`${camelCaseKey}_imageInput`).click()}
                onBlur={(e) => {
                  checkErrorFn(input, camelCaseKey, fieldValue);
                }}
              >
                Upload Images
              </button>
              <br />
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 &&
                fieldErrors?.[camelCaseKey]?.touched &&
                fieldErrors?.[camelCaseKey]?.errors?.map((err, idx) => (
                  <span key={idx} className="errorPresent" style={style.errorSpan}>
                    {err}
                    <br />
                  </span>
                ))}
              <br />
              {fieldValue && !applicationDetails[`${camelCaseKey}verified`] && (
                <span key={'Dgf'} className="errorPresent" style={style.errorSpan}>
                  {'Please Verify The Images'}
                  <br />
                </span>
              )}
            </div>

            <input
              type="file"
              accept="image/*"
              multiple
              id={`${camelCaseKey}_imageInput`}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                opacity: 0,
                width: '100%',
                height: '100%',
                cursor: 'pointer',
              }}
              onChange={(e) => {
                handleUploadImages(e, camelCaseKey, applicationDetails, setApplicationDetails);
                fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value);
                e.target.value = null;
              }}
              onBlur={(e) => {
                checkErrorFn(input, camelCaseKey, fieldValue);
              }}
            />
          </div>

          {/* Display Multiple Images */}
          {/* Document upload  image other relevent Imgs */}
          {imageUrls.length > 0 && (
            <>
              <div className=" ">
                {imageUrls.map((url, index) => (
                  <div
                    key={index}
                    className="m-2 mt-1 ms-2 d-flex gap-3"
                    // style={{ display: 'inline-block' }}
                  >
                    <img
                      src={url}
                      alt={`${label} ${index + 1}`}
                      style={{
                        height: '60px',
                        width: '85px',
                        objectFit: "contain"
                      }}
                      className="p-2"
                      onClick={() => SetImageModalOpen(true)}
                    />
                    <Button
                      className="ms-2 p-0 m-0"
                      variant="outline"
                      // style={{ top: '20px', right: '-18px' }}
                      size="sm"
                      onClick={() => SetImageModalOpen(true)}
                    >
                      <IoEyeOutline size={"25"} />
                    </Button>
                    <Button
                      className="p-0 m-0 "
                      variant="outline"
                      // style={{ top: '20px', right: '-40px' }}

                      size="sm"
                      onClick={() => handleDeleteImage(index)}
                    >
                      <MdDeleteOutline size={'25'} />
                    </Button>
                  </div>
                ))}
              </div>

              {!applicationDetails[`${camelCaseKey}verified`] && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    className="ms-3 verifybutton verifyBtn"
                    onClick={() => SetImageModalOpen(camelCaseKey)}
                    style={{ marginLeft: 'auto' }}
                  >
                    Verify
                  </Button>
                </div>
              )}

              {applicationDetails[`${camelCaseKey}verified`] && (
                <div
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                  className={'fw-bold ms-3'}
                >
                  <Badge bg="success">
                    <FaCheckCircle size={'18'} className="me-2" />
                    Verified
                  </Badge>
                </div>
              )}
            </>
          )}

          {/* Image Modal for Verification */}
          {OpenImageModal && (
            <Modal
              size="lg"
              show={OpenImageModal == camelCaseKey}
              onHide={() => SetImageModalOpen(false)}
              centered
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Body className="text-center">
                <ImageCarousel
                  imageUrls={imageUrls}
                  label={label}
                  applicationDetails={applicationDetails}
                  setApplicationDetails={setApplicationDetails}
                  camelCaseKey={camelCaseKey}
                  SetImageModalOpen={SetImageModalOpen}
                />
              </Modal.Body>

            </Modal>
          )}
        </div>
      );
    };

    const ImageCarousel = ({
      imageUrls,
      label,
      applicationDetails,
      setApplicationDetails,
      camelCaseKey,
      SetImageModalOpen,
    }) => {
      const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

      // Reset currentImageIndex when modal opens
      React.useEffect(() => {
        setCurrentImageIndex(0);
      }, []);

      const handleNext = () => {
        if (currentImageIndex < imageUrls.length - 1) {
          setCurrentImageIndex(currentImageIndex + 1);
        }
      };

      const handlePrev = () => {
        if (currentImageIndex > 0) {
          setCurrentImageIndex(currentImageIndex - 1);
        }
      };

      const isLastImage = currentImageIndex === imageUrls.length - 1;

      return (
        <>
          <img
            src={imageUrls[currentImageIndex]}
            alt={`${label} ${currentImageIndex + 1}`}
            style={{
              maxHeight: '80vh',
              objectFit: 'contain',
              marginBottom: '10px',
            }}
          />


          <div className="d-flex justify-content-center mb-2">
            <Button
              className="me-2"
              variant="secondary-primary"
              disabled={currentImageIndex === 0}
              onClick={handlePrev}
            >
              <FaArrowAltCircleLeft size={30} />
            </Button>
            <Button
              variant="secondary-primary"
              disabled={currentImageIndex === imageUrls.length - 1}
              onClick={handleNext}
            >
              <FaArrowAltCircleRight size={30} />
            </Button>
          </div>
          {!applicationDetails[`${camelCaseKey}verified`] && isLastImage && (
            <Button
              className="mt-2"
              style={{ borderRadius: '10px' }}
              onClick={() => {
                setApplicationDetails({
                  ...applicationDetails,
                  [`${camelCaseKey}verified`]: true,
                });
                SetImageModalOpen(false);
              }}
            >
              Confirm Verification
            </Button>

          )}
          <Button
            className="mt-2"
            style={{ borderRadius: "10px" }}
            variant="secondary"
            onClick={() => SetImageModalOpen(false)}
          >
            Close
          </Button>
        </>
      );
    };


    const PdfCarousel = ({
      imageUrls,
      label,
      applicationDetails,
      setApplicationDetails,
      camelCaseKey,
      SetImageModalOpen,
    }) => {
      const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

      // Reset currentImageIndex when modal opens
      React.useEffect(() => {
        setCurrentImageIndex(0);
      }, []);

      const handleNext = () => {
        if (currentImageIndex < imageUrls.length - 1) {
          setCurrentImageIndex(currentImageIndex + 1);
        }
      };

      const handlePrev = () => {
        if (currentImageIndex > 0) {
          setCurrentImageIndex(currentImageIndex - 1);
        }
      };

      const isLastImage = currentImageIndex === imageUrls.length - 1;

      return (
        <>


          <iframe
            src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(
              imageUrls[currentImageIndex]
            )}`}
            width="100%"
            style={{ border: "none", height: "80vh" }}
            title="PDF Viewer"
          />

          <div className="d-flex justify-content-center mb-2">
            <Button
              className="me-2"
              variant="secondary-primary"
              disabled={currentImageIndex === 0}
              onClick={handlePrev}
            >
              <FaArrowAltCircleLeft size={30} />
            </Button>
            <Button
              variant="secondary-primary"
              disabled={currentImageIndex === imageUrls.length - 1}
              onClick={handleNext}
            >
              <FaArrowAltCircleRight size={30} />
            </Button>
          </div>
          {!applicationDetails[`${camelCaseKey}verified`] && isLastImage && (
            <Button
              className="mt-2"
              style={{ borderRadius: '10px' }}
              onClick={() => {
                setApplicationDetails({
                  ...applicationDetails,
                  [`${camelCaseKey}verified`]: true,
                });
                SetImageModalOpen(false);
              }}
            >
              Confirm Verification
            </Button>
          )}
          <Button
            className="mt-2"
            style={{ borderRadius: "10px" }}
            variant="secondary"
            onClick={() => SetImageModalOpen(false)}
          >
            Close
          </Button>
        </>
      );
    };


    const renderPdfInput = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage,

    ) => {
      console.log("cmlkeyins", camelCaseKey)
      // (camelCaseKey==="6719d8603ac83738a0ba5fd7" && applicationDetails["66a728ec9680c25e280d6bf6"]==="CGPA")||
      // (camelCaseKey==="6719d9653ac83738a0ba6155" && applicationDetails["66a687874db5f02d6861ed0e"]==="CGPA")

      if (camelCaseKey === "6719d53d3ac83738a0ba587a") {
        console.log("found here")
        if (applicationDetails["66a687874db5f02d6861ed04"] === "CGPA" || applicationDetails["66a687874db5f02d6861ed04"]==="Grade") {
          return <div className="imageDiv d-flex align-items-center gap-4">
            <div style={{ position: "relative", display: "inline-block" }}>
              <div className="d-flex flex-column">

                <button
                  className="me-4"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    padding: "5px 15px",
                    fontSize: "12px",
                    cursor: "pointer",
                    margin: '15px 0',
                    border: "none",
                    backgroundColor:
                      fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                        ? '#921d1e' :
                        "#f0f0f0", // Error background color

                    // border:
                    //   fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                    //     ? "1px solid red"
                    //     : "1px solid #ccc", // Error border    
                    color:
                      fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                        ? "#fff"
                        : "#333", // Error background color
                  }}
                  onClick={() => document.getElementById(`${camelCaseKey}_fileInput`).click()}
                >
                  Upload File
                </button>

                <br />
                {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched &&
                  fieldErrors?.[camelCaseKey]?.errors?.map((err, idx) => (
                    <span key={idx} className="errorPresent" style={style.errorSpan}>
                      {err}
                      <br />
                    </span>

                  ))
                }
                <br />
                {fieldValue && !applicationDetails[`${camelCaseKey}verified`] && <span key={"Dgf"} className="errorPresent" style={style.errorSpan}>
                  {"Please Verify The File"}
                  <br />
                </span>}
              </div>
              <input
                type="file"
                accept="application/pdf"
                id={`${camelCaseKey}_fileInput`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  opacity: 0,
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
                onChange={(e) => {
                  handleUploadImage(e, camelCaseKey);
                  setApplicationDetails({
                    ...applicationDetails,
                    [`${camelCaseKey}verified`]: false,
                  });

                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value);
                  e.target.value = null;

                }}
                onBlur={(e) => { checkErrorFn(input, camelCaseKey, fieldValue) }}

              />
            </div>


            {applicationDetails[camelCaseKey] && (
              <>
                <img
                  src={pdfIcon}
                  alt="PDF preview"
                  style={{ marginLeft: "10px", width: "50px", cursor: "pointer" }}
                  onClick={() => {
                    setShowIframe(camelCaseKey);
                  }}
                />
                {!applicationDetails[`${camelCaseKey}verified`] && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      className="ms-2 verifybutton verifyBtn"
                      onClick={() => setShowIframe(camelCaseKey)}
                    >
                      Verify
                    </Button>
                  </div>
                )}

                {applicationDetails[`${camelCaseKey}verified`] && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }} className={"fw-bold"}>
                    <Badge bg="success" >
                      <FaCheckCircle size={"18"} className="me-2" />

                      Verified
                    </Badge>
                  </div>
                )}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                  <Button
                    style={{ marginLeft: '0px' }}
                    variant='outline'
                    onClick={() => setShowIframe(camelCaseKey)}
                  >
                    <IoEyeOutline size={"20"} />
                  </Button>
                  <Button className="ms-2" variant='outline' onClick={() => handleUploadImage(null, camelCaseKey)} style={{ marginLeft: 'auto' }}>
                    {/* <Badge bg="danger" > */}
                    <MdDeleteOutline size={"20"} />
                    {/* <span className='ps-2'>Delete</span> */}
                    {/* </Badge> */}
                    {/* <MdDeleteOutline size={"25"}></MdDeleteOutline> */}
                  </Button>
                </div>
              </>
            )}


            {showIframe && (
              <Modal
                size="lg"
                show={showIframe === camelCaseKey}
                onHide={() => setShowIframe(false)}
                centered
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Body className="text-center">
                  <iframe
                    src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(
                      fieldValue
                    )}`}
                    width="100%"
                    style={{ border: "none", height: "80vh" }}
                    title="PDF Viewer"
                  />
                  {!applicationDetails[camelCaseKey + "verified"] && (
                    <Button
                      className="mt-2"
                      style={{ borderRadius: "10px" }}
                      onClick={() => {
                        setApplicationDetails({
                          ...applicationDetails,
                          [`${camelCaseKey}verified`]: true,
                        });
                        setShowIframe(false);
                      }}
                    >
                      Confirm Verification
                    </Button>
                  )}
                  <Button
                    className="mt-2"
                    style={{ borderRadius: "10px" }}
                    variant="secondary"
                    onClick={() => setShowIframe(false)}
                  >
                    Close
                  </Button>
                </Modal.Body>
              </Modal>
            )}

          </div>
        }
      }
      else if (camelCaseKey === "6719d8603ac83738a0ba5fd7") {
        console.log("found here")
        if (applicationDetails["66a728ec9680c25e280d6bf6"] === "CGPA" || applicationDetails["66a728ec9680c25e280d6bf6"]==="Grade") {
          return <div className="imageDiv d-flex align-items-center gap-4">
            <div style={{ position: "relative", display: "inline-block" }}>
              <div className="d-flex flex-column">

                <button
                  className="me-4"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    padding: "5px 15px",
                    fontSize: "12px",
                    cursor: "pointer",
                    margin: '15px 0',
                    border: "none",
                    backgroundColor:
                      fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                        ? '#921d1e' :
                        "#f0f0f0", // Error background color

                    // border:
                    //   fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                    //     ? "1px solid red"
                    //     : "1px solid #ccc", // Error border    
                    color:
                      fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                        ? "#fff"
                        : "#333", // Error background color
                  }}
                  onClick={() => document.getElementById(`${camelCaseKey}_fileInput`).click()}
                >
                  Upload File
                </button>

                <br />
                {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched &&
                  fieldErrors?.[camelCaseKey]?.errors?.map((err, idx) => (
                    <span key={idx} className="errorPresent" style={style.errorSpan}>
                      {err}
                      <br />
                    </span>

                  ))
                }
                <br />
                {fieldValue && !applicationDetails[`${camelCaseKey}verified`] && <span key={"Dgf"} className="errorPresent" style={style.errorSpan}>
                  {"Please Verify The File"}
                  <br />
                </span>}
              </div>
              <input
                type="file"
                accept="application/pdf"
                id={`${camelCaseKey}_fileInput`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  opacity: 0,
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
                onChange={(e) => {
                  handleUploadImage(e, camelCaseKey);
                  setApplicationDetails({
                    ...applicationDetails,
                    [`${camelCaseKey}verified`]: false,
                  });

                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value);
                  e.target.value = null;

                }}
                onBlur={(e) => { checkErrorFn(input, camelCaseKey, fieldValue) }}

              />
            </div>


            {applicationDetails[camelCaseKey] && (
              <>
                <img
                  src={pdfIcon}
                  alt="PDF preview"
                  style={{ marginLeft: "10px", width: "50px", cursor: "pointer" }}
                  onClick={() => {
                    setShowIframe(camelCaseKey);
                  }}
                />
                {!applicationDetails[`${camelCaseKey}verified`] && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      className="ms-2 verifybutton verifyBtn"
                      onClick={() => setShowIframe(camelCaseKey)}
                    >
                      Verify
                    </Button>
                  </div>
                )}

                {applicationDetails[`${camelCaseKey}verified`] && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }} className={"fw-bold"}>
                    <Badge bg="success" >
                      <FaCheckCircle size={"18"} className="me-2" />

                      Verified
                    </Badge>
                  </div>
                )}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    style={{ marginLeft: '0px' }}
                    variant='outline'
                    onClick={() => setShowIframe(camelCaseKey)}
                  >
                    <IoEyeOutline size={"20"} />
                  </Button>
                  <Button className="ms-2" variant='outline' onClick={() => handleUploadImage(null, camelCaseKey)} style={{ marginLeft: 'auto' }}>
                    {/* <Badge bg="danger" > */}
                    <MdDeleteOutline size={"20"} />
                    {/* <span className='ps-2'>Delete</span> */}
                    {/* </Badge> */}
                    {/* <MdDeleteOutline size={"25"}></MdDeleteOutline> */}
                  </Button>
                </div>
              </>
            )}


            {showIframe && (
              <Modal
                size="lg"
                show={showIframe === camelCaseKey}
                onHide={() => setShowIframe(false)}
                centered
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Body className="text-center">
                  <iframe
                    src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(
                      fieldValue
                    )}`}
                    width="100%"
                    style={{ border: "none", height: "80vh" }}
                    title="PDF Viewer"
                  />
                  {!applicationDetails[camelCaseKey + "verified"] && <Button
                    className="mt-2"
                    style={{ borderRadius: "10px" }}
                    onClick={() => {
                      setApplicationDetails({
                        ...applicationDetails,
                        [`${camelCaseKey}verified`]: true,
                      });
                      setShowIframe(false);

                    }}
                  >
                    {"Confirm Verification"}
                  </Button>}
                  <Button
                    className="mt-2"
                    style={{ borderRadius: "10px" }}
                    variant="secondary"
                    onClick={() => setShowIframe(false)}
                  >
                    Close
                  </Button>
                </Modal.Body>
              </Modal>
            )}
          </div>
        }
      }
      else if (camelCaseKey === "6719d9653ac83738a0ba6155") {
        console.log("found here")
        if (applicationDetails["66a687874db5f02d6861ed0e"] === "CGPA" || applicationDetails["66a687874db5f02d6861ed0e"] === "Grade") {
          return <div className="imageDiv d-flex align-items-center gap-4">
            <div style={{ position: "relative", display: "inline-block" }}>
              <div className="d-flex flex-column">

                <button
                  className="me-4"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    padding: "5px 15px",
                    fontSize: "12px",
                    cursor: "pointer",
                    margin: '15px 0',
                    border: "none",
                    backgroundColor:
                      fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                        ? '#921d1e' :
                        "#f0f0f0", // Error background color

                    // border:
                    //   fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                    //     ? "1px solid red"
                    //     : "1px solid #ccc", // Error border    
                    color:
                      fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                        ? "#fff"
                        : "#333", // Error background color
                  }}
                  onClick={() => document.getElementById(`${camelCaseKey}_fileInput`).click()}
                >
                  Upload File
                </button>

                <br />
                {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched &&
                  fieldErrors?.[camelCaseKey]?.errors?.map((err, idx) => (
                    <span key={idx} className="errorPresent" style={style.errorSpan}>
                      {err}
                      <br />
                    </span>

                  ))
                }
                <br />
                {fieldValue && !applicationDetails[`${camelCaseKey}verified`] && <span key={"Dgf"} className="errorPresent" style={style.errorSpan}>
                  {"Please Verify The File"}
                  <br />
                </span>}
              </div>
              <input
                type="file"
                accept="application/pdf"
                id={`${camelCaseKey}_fileInput`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  opacity: 0,
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
                onChange={(e) => {
                  handleUploadImage(e, camelCaseKey);
                  setApplicationDetails({
                    ...applicationDetails,
                    [`${camelCaseKey}verified`]: false,
                  });

                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value);
                  e.target.value = null;

                }}
                onBlur={(e) => { checkErrorFn(input, camelCaseKey, fieldValue) }}

              />
            </div>


            {applicationDetails[camelCaseKey] && (
              <>
                <img
                  src={pdfIcon}
                  alt="PDF preview"
                  style={{ marginLeft: "10px", width: "50px", cursor: "pointer" }}
                  onClick={() => {
                    setShowIframe(camelCaseKey);
                  }}
                />
                {!applicationDetails[`${camelCaseKey}verified`] && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      className="ms-2 verifybutton verifyBtn"
                      onClick={() => setShowIframe(camelCaseKey)}
                    >
                      Verify
                    </Button>
                  </div>
                )}

                {applicationDetails[`${camelCaseKey}verified`] && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }} className={"fw-bold"}>
                    <Badge bg="success" >
                      <FaCheckCircle size={"18"} className="me-2" />

                      Verified
                    </Badge>
                  </div>
                )}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    style={{ marginLeft: '0px' }}
                    variant='outline'
                    onClick={() => setShowIframe(camelCaseKey)}
                  >
                    <IoEyeOutline size={"20"} />
                  </Button>
                  <Button className="ms-2" variant='outline' onClick={() => handleUploadImage(null, camelCaseKey)} style={{ marginLeft: 'auto' }}>
                    {/* <Badge bg="danger" > */}
                    <MdDeleteOutline size={"20"} />
                    {/* <span className='ps-2'>Delete</span> */}
                    {/* </Badge> */}
                    {/* <MdDeleteOutline size={"25"}></MdDeleteOutline> */}
                  </Button>
                </div>
              </>
            )}


            {showIframe && (
              <Modal
                size="lg"
                show={showIframe === camelCaseKey}
                onHide={() => setShowIframe(false)}
                centered
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Body className="text-center">
                  <iframe
                    src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(
                      fieldValue
                    )}`}
                    width="100%"
                    style={{ border: "none", height: "80vh" }}
                    title="PDF Viewer"
                  />
                  {!applicationDetails[camelCaseKey + "verified"] && <Button
                    className="mt-2"
                    style={{ borderRadius: "10px" }}
                    onClick={() => {
                      setApplicationDetails({
                        ...applicationDetails,
                        [`${camelCaseKey}verified`]: true,
                      });
                      setShowIframe(false);

                    }}
                  >
                    {"Confirm Verification"}
                  </Button>}
                  <Button
                    className="mt-2"
                    style={{ borderRadius: "10px" }}
                    variant="secondary"
                    onClick={() => setShowIframe(false)}
                  >
                    Close
                  </Button>
                </Modal.Body>
              </Modal>
            )}
          </div>
        }
      }
      else if (camelCaseKey === '67270267df49515ae469b46d') {
        if (applicationDetails['67270267df49515ae469b465'] == "CGPA" || applicationDetails["67270267df49515ae469b465"] === "Grade") {
          return <div className="imageDiv d-flex align-items-center gap-4">
            <div style={{ position: "relative", display: "inline-block" }}>
              <div className="d-flex flex-column">

                <button
                  className="me-4"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    padding: "5px 15px",
                    fontSize: "12px",
                    cursor: "pointer",
                    margin: '15px 0',
                    border: "none",
                    backgroundColor:
                      fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                        ? '#921d1e' :
                        "#f0f0f0", // Error background color

                    // border:
                    //   fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                    //     ? "1px solid red"
                    //     : "1px solid #ccc", // Error border    
                    color:
                      fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                        ? "#fff"
                        : "#333", // Error background color
                  }}
                  onClick={() => document.getElementById(`${camelCaseKey}_fileInput`).click()}
                >
                  Upload File
                </button>

                <br />
                {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched &&
                  fieldErrors?.[camelCaseKey]?.errors?.map((err, idx) => (
                    <span key={idx} className="errorPresent" style={style.errorSpan}>
                      {err}
                      <br />
                    </span>

                  ))
                }
                <br />
                {fieldValue && !applicationDetails[`${camelCaseKey}verified`] && <span key={"Dgf"} className="errorPresent" style={style.errorSpan}>
                  {"Please Verify The File"}
                  <br />
                </span>}
              </div>
              <input
                type="file"
                accept="application/pdf"
                id={`${camelCaseKey}_fileInput`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  opacity: 0,
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
                onChange={(e) => {
                  handleUploadImage(e, camelCaseKey);
                  setApplicationDetails({
                    ...applicationDetails,
                    [`${camelCaseKey}verified`]: false,
                  });

                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value);
                  e.target.value = null;

                }}
                onBlur={(e) => { checkErrorFn(input, camelCaseKey, fieldValue) }}

              />
            </div>


            {applicationDetails[camelCaseKey] && (
              <>
                <img
                  src={pdfIcon}
                  alt="PDF preview"
                  style={{ marginLeft: "10px", width: "40px", cursor: "pointer" }}
                  onClick={() => {
                    setShowIframe(camelCaseKey);
                  }}
                />
                {!applicationDetails[`${camelCaseKey}verified`] && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      className="ms-2 verifybutton verifyBtn"
                      onClick={() => setShowIframe(camelCaseKey)}
                    >
                      Verify
                    </Button>
                  </div>
                )}

                {applicationDetails[`${camelCaseKey}verified`] && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }} className={"fw-bold"}>
                    <Badge bg="success" >
                      <FaCheckCircle size={"18"} className="me-2" />

                      Verified
                    </Badge>
                  </div>
                )}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    style={{ marginLeft: '0px' }}
                    variant='outline'
                    onClick={() => setShowIframe(camelCaseKey)}
                  >
                    <IoEyeOutline size={"20"} />
                  </Button>
                  <Button className="ms-2" variant='outline' onClick={() => handleUploadImage(null, camelCaseKey)} style={{ marginLeft: 'auto' }}>
                    {/* <Badge bg="danger" > */}
                    <MdDeleteOutline size={"20"} />
                    {/* <span className='ps-2'>Delete</span> */}
                    {/* </Badge> */}
                    {/* <MdDeleteOutline size={"25"}></MdDeleteOutline> */}
                  </Button>
                </div>
              </>
            )}


            {showIframe && (
              <Modal
                size="lg"
                show={showIframe === camelCaseKey}
                onHide={() => setShowIframe(false)}
                centered
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Body className="text-center">
                  <iframe
                    src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(
                      fieldValue
                    )}`}
                    width="100%"
                    style={{ border: "none", height: "80vh" }}
                    title="PDF Viewer"
                  />
                  {!applicationDetails[camelCaseKey + "verified"] && <Button
                    className="mt-2"
                    style={{ borderRadius: "10px" }}
                    onClick={() => {
                      setApplicationDetails({
                        ...applicationDetails,
                        [`${camelCaseKey}verified`]: true,
                      });
                      setShowIframe(false);

                    }}
                  >
                    {"Confirm Verification"}
                  </Button>}
                  <Button
                    className="mt-2"
                    style={{ borderRadius: "10px" }}
                    variant="secondary"
                    onClick={() => setShowIframe(false)}
                  >
                    Close
                  </Button>
                </Modal.Body>
              </Modal>
            )}
          </div>
        }

      }
      else {
        return <div className="imageDiv d-flex align-items-center gap-4">
          <div style={{ position: "relative", display: "inline-block" }}>
            <div className="d-flex flex-column">

              <button
                className="me-4"
                style={{
                  borderRadius: "10px",
                  height: "40px",
                  padding: "5px 15px",
                  fontSize: "12px",
                  cursor: "pointer",
                  margin: '15px 0',
                  border: "none",
                  backgroundColor:
                    fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                      ? '#921d1e' :
                      "#f0f0f0", // Error background color

                  // border:
                  //   fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                  //     ? "1px solid red"
                  //     : "1px solid #ccc", // Error border    
                  color:
                    fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                      ? "#fff"
                      : "#333", // Error background color
                }}
                onClick={() => document.getElementById(`${camelCaseKey}_fileInput`).click()}
              >
                Upload File
              </button>

              <br />
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched &&
                fieldErrors?.[camelCaseKey]?.errors?.map((err, idx) => (
                  <span key={idx} className="errorPresent" style={style.errorSpan}>
                    {err}
                    <br />
                  </span>

                ))
              }
              <br />
              {fieldValue && !applicationDetails[`${camelCaseKey}verified`] && <span key={"Dgf"} className="errorPresent" style={style.errorSpan}>
                {"Please Verify The File"}
                <br />
              </span>}
            </div>
            <input
              type="file"
              accept="application/pdf"
              id={`${camelCaseKey}_fileInput`}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0,
                width: "100%",
                height: "100%",
                cursor: "pointer",
              }}
              onChange={(e) => {
                handleUploadImage(e, camelCaseKey);
                setApplicationDetails({
                  ...applicationDetails,
                  [`${camelCaseKey}verified`]: false,
                });

                fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value);
                e.target.value = null;

              }}
              onBlur={(e) => { checkErrorFn(input, camelCaseKey, fieldValue) }}

            />
          </div>


          {applicationDetails[camelCaseKey] && (
            <>
              <img
                src={pdfIcon}
                alt="PDF preview"
                style={{ marginLeft: "10px", width: "40px", cursor: "pointer" }}
                onClick={() => {
                  setShowIframe(camelCaseKey);
                }}
              />
              {!applicationDetails[`${camelCaseKey}verified`] && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    className="ms-2 verifybutton verifyBtn"
                    onClick={() => setShowIframe(camelCaseKey)}
                  >
                    Verify
                  </Button>
                </div>
              )}

              {applicationDetails[`${camelCaseKey}verified`] && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }} className={"fw-bold"}>
                  <Badge bg="success" >
                    <FaCheckCircle size={"18"} className="me-2" />

                    Verified
                  </Badge>
                </div>
              )}
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button className="ms-2" variant='outline' onClick={() => handleUploadImage(null, camelCaseKey)} style={{ marginLeft: 'auto' }}>
                  <Badge bg="danger" >
                    <MdDeleteOutline size={"18"} />
                    <span className='ps-2'>Delete</span>
                  </Badge>
                  {/* <MdDeleteOutline size={"25"}></MdDeleteOutline> */}
                </Button>
              </div>
            </>
          )}


          {showIframe && (
            <Modal
              size="lg"
              show={showIframe === camelCaseKey}
              onHide={() => setShowIframe(false)}
              centered
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Body className="text-center">
                <iframe
                  src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(
                    fieldValue
                  )}`}
                  width="100%"
                  style={{ border: "none", height: "80vh" }}
                  title="PDF Viewer"
                />
                {!applicationDetails[camelCaseKey + "verified"] && <Button
                  className="mt-2"
                  style={{ borderRadius: "10px" }}
                  onClick={() => {
                    setApplicationDetails({
                      ...applicationDetails,
                      [`${camelCaseKey}verified`]: true,
                    });
                    setShowIframe(false);

                  }}
                >
                  {"Confirm Verification"}
                </Button>}
                <Button
                  className="mt-2"
                  style={{ borderRadius: "10px" }}
                  variant="secondary"
                  onClick={() => setShowIframe(false)}
                >
                  Close
                </Button>
              </Modal.Body>
            </Modal>
          )}
        </div>
      }
    };


    // Function to render textarea input
    const renderTextareaInput = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage
    ) => {
      switch (label) {
        default:
          return (
            <Form.Control
              as="textarea"
              // style={{ border: "1px solid black", borderRadius: "10px", height: "100px" }}
              className=" "
              placeholder={label}
              value={fieldValue}
              onChange={(e) =>
                setApplicationDetails({
                  ...applicationDetails,
                  [camelCaseKey]: e.target.value,
                })
              }
              required={input.values.isRequired}
              maxLength={1000}
              minLength={500}
            />
          );
          break;
      }
    };

    const handelApplicationDetailsChange = (data) => {
      setApplicationDetails({
        ...applicationDetails,
        ...data,
      });
    };

    const renderTextInput = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage
    ) => {

      let renderType = applicationDetails?.['66a687874db5f02d6861ed04']
      console.log("renderType", { renderType })
      // Define the IDs for 10th Grade
      const markType10th = applicationDetails?.["66a687874db5f02d6861ed04"];
      const markTotalIn10thId = "66a687874db5f02d6861ed06";
      const markObtainedIn10thId = "66a687874db5f02d6861ed05";
      const percentageIn10thId = "66a687874db5f02d6861ed07";
      console.log(markType10th, "markType10th")
      // Define the IDs for 12th Grade
      const markType12th = applicationDetails?.["66a728ec9680c25e280d6bf6"];
      console.log(markType12th, "markType12th")
      const markTotalIn12thId = "66a731359680c25e280d6e4b";
      const markObtainedIn12thId = "66a730c29680c25e280d6de8";
      const percentageIn12thId = "66a7359a9680c25e280d6f16";

      // Define the IDs for Graduate/Higher Secondary
      const markTypeGr = applicationDetails?.["66a687874db5f02d6861ed0e"];

      const markTotalInGrThId = "66a687874db5f02d6861ed10";
      const markObtainedInGrThId = "66a687874db5f02d6861ed0f";
      const percentageInGrThId = "66a687874db5f02d6861ed11";

      const markTypePostGr = applicationDetails?.['67270267df49515ae469b465']
      const markTotalInPostGrThId = "67270267df49515ae469b469";
      const markObtainedInPostGrThId = "67270267df49515ae469b467";
      const percentageInPostGrThId = "67270267df49515ae469b46b";
      const updateMarkValueNonArray = (camelCaseKey, value) => {
        console.log("came11")

        // Helper function to calculate and update percentage
        const calculateAndUpdatePercentage = (obtained, total, percentageId) => {


          let percentage = 0;

          if (total > 0) {
            percentage = (obtained / total) * 100;

            percentage = percentage.toFixed(2).toString()
          }
          console.log("came11 Updating obtained:", obtained, "with total:", total, percentageId, percentage);

          // Update the percentage field with two decimal places
          return ({ percentageId, percentage })

        };

        // Determine which group the camelCaseKey belongs to and update accordingly
        console.log("came11Updating key:", camelCaseKey, "with value:", value);

        switch (camelCaseKey) {
          // 10th Grade
          case markObtainedIn10thId: {
            const obtained = parseFloat(value) || 0;
            const total = parseFloat(applicationDetails[markTotalIn10thId]) || 0;
            if (markType10th == "Precentage") {

              return calculateAndUpdatePercentage(obtained, total, percentageIn10thId);
            }
            break;
          }
          case markTotalIn10thId: {
            const total = parseFloat(value) || 0;
            const obtained = parseFloat(applicationDetails[markObtainedIn10thId]) || 0;
            if (markType10th == "Precentage") {

              return calculateAndUpdatePercentage(obtained, total, percentageIn10thId);
            }
            break;
          }

          // 12th Grade
          case markObtainedIn12thId: {
            const obtained = parseFloat(value) || 0;
            const total = parseFloat(applicationDetails[markTotalIn12thId]) || 0;
            if (markType12th == "Percentage") {

              return calculateAndUpdatePercentage(obtained, total, percentageIn12thId);
            }
            break;
          }
          case markTotalIn12thId: {
            const total = parseFloat(value) || 0;
            const obtained = parseFloat(applicationDetails[markObtainedIn12thId]) || 0;
            if (markType12th == "Percentage") {

              return calculateAndUpdatePercentage(obtained, total, percentageIn12thId);
            }
            break;
          }

          // Graduate/Higher Secondary
          case markObtainedInGrThId: {
            const obtained = parseFloat(value) || 0;
            const total = parseFloat(applicationDetails[markTotalInGrThId]) || 0;
            if (markTypeGr == "Percentage") {

              return calculateAndUpdatePercentage(obtained, total, percentageInGrThId)
            }
            break;
          }
          case markTotalInGrThId: {
            const total = parseFloat(value) || 0;
            const obtained = parseFloat(applicationDetails[markObtainedInGrThId]) || 0;
            if (markTypeGr == "Percentage") {

              return calculateAndUpdatePercentage(obtained, total, percentageInGrThId);
            }
            break;
          }

          case markObtainedInPostGrThId: {

            const obtained = parseFloat(value) || 0;
            const total = parseFloat(applicationDetails[markTotalInPostGrThId]) || 0;
            if (markTypePostGr == "Percentage") {

              return calculateAndUpdatePercentage(obtained, total, percentageInPostGrThId);
            }
            break;
          }
          case markTotalInPostGrThId: {
            const total = parseFloat(value) || 0;
            const obtained = parseFloat(applicationDetails[markObtainedInPostGrThId]) || 0;
            if (markTypePostGr == "Percentage") {

              return calculateAndUpdatePercentage(obtained, total, percentageInPostGrThId);
            }
            break;
          }

          default:
            // If the key does not match any of the above, do nothing
            break;
        }

        // // Finally, update the applicationDetails with the new value
        // setApplicationDetails(prevDetails => ({
        //     ...prevDetails,
        //     [camelCaseKey]: value,
        // }));
      };


      switch (label) {


        case "Marks Obtained":
        case "Marks obtained":
        case "Maximum Mark":
        case "Maximum mark":
          return (
            <>
              <input
                className="form-control text-dark"
                style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                type={input.values.inputFieldType}
                // placeholder={label}
                value={fieldValue}
                onChange={(e) => {
                  let data___ = updateMarkValueNonArray(camelCaseKey, e.target.value)
                  setApplicationDetails({
                    ...applicationDetails,
                    [camelCaseKey]: e.target.value,
                    [data___?.percentageId]: data___?.percentage
                  })
                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value)
                }}
                onBlur={(e) => {
                  checkErrorFn(input, camelCaseKey, fieldValue)
                }}
                required={input.values.isRequired}
                max={new Date().toISOString().split('T')[0]}

              />
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                ))
              )}
            </>

          );
          break;
        case "Percentage of Mark":
          return (
            renderType == "Precentage" ?
              <input
                className="form-control text-dark"
                style={style.input}
                type={input.values.inputFieldType}
                // placeholder={label}

                defaultValue={"test"}
                value={fieldValue || ""}
                // onChange={(e) =>

                //   setApplicationDetails({
                //     ...applicationDetails,
                //     [camelCaseKey]: `${applicationDetails?.vacancyId?.grade?.designationPrefix} (${applicationDetails?.vacancyId?.department?.name}), Grade - ${applicationDetails?.vacancyId?.grade?.gradeName}`,
                //   })
                // }
                required={input.values.isRequired}

              /> :
              renderType == "CGPA" ?
                <>
                  <input
                    className="form-control text-dark"
                    style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                    type={input.values.inputFieldType}
                    // placeholder={label}
                    value={fieldValue}
                    onChange={(e) => {
                      setApplicationDetails({
                        ...applicationDetails,
                        [camelCaseKey]: e.target.value,
                      })
                      fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value)
                    }}
                    onBlur={(e) => {
                      checkErrorFn(input, camelCaseKey, fieldValue)
                    }}
                    required={input.values.isRequired}
                    max={new Date().toISOString().split('T')[0]}

                  />
                  {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                    fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                      <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                    ))
                  )}
                </>
                :
                renderType == "Grade" ?
                  <>

                    <input
                      className="form-control text-dark"
                      style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                      type={input.values.inputFieldType}
                      // placeholder={label}
                      value={fieldValue}
                      onChange={(e) => {
                        setApplicationDetails({
                          ...applicationDetails,
                          [camelCaseKey]: e.target.value,
                        })
                        fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value)
                      }}
                      onBlur={(e) => {
                        checkErrorFn(input, camelCaseKey, fieldValue)
                      }}
                      required={input.values.isRequired}
                      max={new Date().toISOString().split('T')[0]}

                    />
                    {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                      fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                        <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                      ))
                    )}
                  </> : <></>

          )


        case "Grade":

          return (
            camelCaseKey === "673b18a3b182bc6699a164a4" && markType10th === "Grade" ? (
              <>
                <input
                  className="form-control text-dark"
                  style={
                    fieldErrors?.[camelCaseKey]?.errors?.length > 0 &&
                      fieldErrors?.[camelCaseKey]?.touched
                      ? { ...style.input, ...style.errorBox }
                      : style.input
                  }
                  type={input.values.inputFieldType}
                  value={fieldValue}
                  onChange={(e) => {
                    setApplicationDetails({
                      ...applicationDetails,
                      [camelCaseKey]: e.target.value,
                    });
                    if (fieldErrors?.[camelCaseKey]?.touched) {
                      checkErrorFn(input, camelCaseKey, e.target.value);
                    }
                  }}
                  onBlur={(e) => {
                    checkErrorFn(input, camelCaseKey, fieldValue);
                  }}
                  required={input.values.isRequired}
                  max={new Date().toISOString().split("T")[0]}
                />
                {fieldErrors?.[camelCaseKey]?.errors?.length > 0 &&
                  fieldErrors?.[camelCaseKey]?.touched &&
                  fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                    <span className="errorPresent" style={style.errorSpan}>
                      {err}
                      <br />
                    </span>
                  ))}
              </>
            ) : camelCaseKey === "673b3b84b182bc6699a175fd" && markType12th === "Grade" ? (
              <>
                <input
                  className="form-control text-dark"
                  style={
                    fieldErrors?.[camelCaseKey]?.errors?.length > 0 &&
                      fieldErrors?.[camelCaseKey]?.touched
                      ? { ...style.input, ...style.errorBox }
                      : style.input
                  }
                  type={input.values.inputFieldType}
                  value={fieldValue}
                  onChange={(e) => {
                    setApplicationDetails({
                      ...applicationDetails,
                      [camelCaseKey]: e.target.value,
                    });
                    if (fieldErrors?.[camelCaseKey]?.touched) {
                      checkErrorFn(input, camelCaseKey, e.target.value);
                    }
                  }}
                  onBlur={(e) => {
                    checkErrorFn(input, camelCaseKey, fieldValue);
                  }}
                  required={input.values.isRequired}
                  max={new Date().toISOString().split("T")[0]}
                />
                {fieldErrors?.[camelCaseKey]?.errors?.length > 0 &&
                  fieldErrors?.[camelCaseKey]?.touched &&
                  fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                    <span className="errorPresent" style={style.errorSpan}>
                      {err}
                      <br />
                    </span>
                  ))}
              </>
            ) :
              camelCaseKey === "673c52c4b182bc6699a19cae" && markTypeGr == "Grade" ?

                (<>
                  <input
                    className="form-control text-dark"
                    style={
                      fieldErrors?.[camelCaseKey]?.errors?.length > 0 &&
                        fieldErrors?.[camelCaseKey]?.touched
                        ? { ...style.input, ...style.errorBox }
                        : style.input
                    }
                    type={input.values.inputFieldType}
                    value={fieldValue}
                    onChange={(e) => {
                      setApplicationDetails({
                        ...applicationDetails,
                        [camelCaseKey]: e.target.value,
                      });
                      if (fieldErrors?.[camelCaseKey]?.touched) {
                        checkErrorFn(input, camelCaseKey, e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      checkErrorFn(input, camelCaseKey, fieldValue);
                    }}
                    required={input.values.isRequired}
                    max={new Date().toISOString().split("T")[0]}
                  />
                  {fieldErrors?.[camelCaseKey]?.errors?.length > 0 &&
                    fieldErrors?.[camelCaseKey]?.touched &&
                    fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                      <span className="errorPresent" style={style.errorSpan}>
                        {err}
                        <br />
                      </span>
                    ))}
                </>

                ) : (<></>)
          )









        case "Application ID":
          return (
            <>
              <input
                className="form-control text-dark"
                style={style.input}
                type={input.values.inputFieldType}
                // placeholder={label}
                value={fieldValue || applicationID}
                onChange={(e) =>
                  setApplicationDetails({
                    ...applicationDetails,
                    [camelCaseKey]: applicationID,
                  })
                }
                disabled={true}

                required={input.values.isRequired}
                max={new Date().toISOString().split('T')[0]}

              />

            </>
          );
          break;

        case "Advertisement Details":
          return (
            <input
              className="form-control text-dark"
              style={style.input}
              type={input.values.inputFieldType}
              value={
                fieldValue ||
                `${applicationDetails?.advertisement?.advertisementHeading} (No. ${applicationDetails?.advertisement?.advertisementNumber})`
              }
              onChange={(e) =>
                setApplicationDetails({
                  ...applicationDetails,
                  [camelCaseKey]: `${applicationDetails?.advertisement?.advertisementHeading} (No. ${applicationDetails?.advertisement?.advertisementNumber})`,
                })
              }
              disabled={true}
              required={input.values.isRequired}
              max={
                input.values.inputFieldType === "date"
                  ? new Date().toISOString().split("T")[0]
                  : undefined
              }
            />
          );

          break;

        case "Post Name":
          return (
            <input
              className="form-control text-dark"
              style={style.input}
              type={input.values.inputFieldType}
              // placeholder={label}
              disabled={true}
              value={
                fieldValue ||
                `${applicationDetails?.vacancyId?.grade?.designationPrefix} (${applicationDetails?.vacancyId?.department?.name}), Grade - ${applicationDetails?.vacancyId?.grade?.gradeName}`
              }
              onChange={(e) =>
                setApplicationDetails({
                  ...applicationDetails,
                  [camelCaseKey]: `${applicationDetails?.vacancyId?.grade?.designationPrefix} (${applicationDetails?.vacancyId?.department?.name}), Grade - ${applicationDetails?.vacancyId?.grade?.gradeName}`,
                })
              }
              required={input.values.isRequired}
              max={
                input.values.inputFieldType === "date"
                  ? new Date().toISOString().split("T")[0]
                  : undefined
              }
            />
          );
          break;

        case "Age":
          return (
            <input
              className="form-control text-dark"
              style={style.input}
              type={input.values.inputFieldType}
              // placeholder={label}
              value={`${applicationDetails?.age}`}
              // onChange={(e) =>
              //   setApplicationDetails({
              //     ...applicationDetails,
              //     [camelCaseKey]: `${applicationDetails?.vacancyId?.grade?.designationPrefix} (${applicationDetails?.vacancyId?.department?.name}), Grade - ${applicationDetails?.vacancyId?.grade?.gradeName}`,
              //   })
              // }
              disabled

              required={input.values.isRequired}
              max={
                input.values.inputFieldType === "date"
                  ? new Date().toISOString().split("T")[0]
                  : undefined
              }
            />
          );
          break;
        case "Date Of Birth":
          return (
            <>
              <input
                className="form-control text-dark"
                style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                type={input.values.inputFieldType}
                // placeholder={label}
                value={fieldValue}
                // onChange={(e) => {
                //   setApplicationDetails({
                //     ...applicationDetails,
                //     [camelCaseKey]: e.target.value,
                //     ["age"]: Math.floor(
                //       (new Date(
                //         applicationDetails?.advertisement?.advertisementClosingDate
                //       ) -
                //         new Date(
                //           e?.target?.value?.split("/").reverse().join("-")
                //         )) /
                //       (365.25 * 24 * 60 * 60 * 1000)
                //     ),
                //   })
                //   fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value)

                // }
                // }
                // onBlur={(e) => {
                //   checkErrorFn(input, camelCaseKey, fieldValue)
                // }}

                disabled
                required={input.values.isRequired}
                max={new Date().toISOString().split('T')[0]}

              />
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                ))
              )}
            </>
          );
          break;

        case "Applicant's Full Name":
        case "Mobile Number":
        case "Email":
          return (
            <input
              className="form-control text-dark"
              style={style.input}
              type={input.values.inputFieldType}
              // placeholder={label}
              disabled={true}
              value={applicationDetails?.[`${camelCase(label)}`]}
              required={input.values.isRequired}
              onChange={() => { }}
            />
          );
          break;
        case "Alternate Mobile Number":
          return (
            <>
              <input
                className="form-control text-dark"
                style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                type={input.values.inputFieldType}
                // placeholder={label}
                value={fieldValue}
                onChange={(e) => {

                  setApplicationDetails({
                    ...applicationDetails,
                    [camelCaseKey]: e.target.value,
                  })
                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value)
                }}
                onBlur={(e) => {
                  checkErrorFn(input, camelCaseKey, fieldValue)
                }}
                required={input.values.isRequired}
                max={new Date().toISOString().split('T')[0]}

              />
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                ))
              )}
              {applicationDetails[camelCaseKey] == applicationDetails?.['mobileNumber'] &&

                <span className="errorPresent" style={style.errorSpan}>{'Cannot be same as registered number'}<br /></span>
              }
            </>
          );
          break;
        default:
          return (
            <>
              <input
                className="form-control text-dark"
                style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                type={input.values.inputFieldType}
                // placeholder={label}
                value={fieldValue}
                onChange={(e) => {
                  setApplicationDetails({
                    ...applicationDetails,
                    [camelCaseKey]: e.target.value,
                  })
                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, e.target.value)
                }}
                onBlur={(e) => {
                  checkErrorFn(input, camelCaseKey, fieldValue)
                }}
                required={input.values.isRequired}
                max={new Date().toISOString().split('T')[0]}

              />
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                ))
              )}
            </>

          );
          break;
      }
    };


    const renderInput = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage
    ) => {
      switch (input.values.inputFieldType) {
        // case "date":
        //   return renderDateInput(
        //     fieldValue,
        //     camelCaseKey,
        //     label,
        //     input,
        //     applicationDetails,
        //     setApplicationDetails,
        //     checkedValues,
        //     handleCheckboxChange,
        //     handleUploadImage
        //   );
        case "select":
          return renderSelectInput(
            fieldValue,
            camelCaseKey,
            label,
            input,
            applicationDetails,
            setApplicationDetails,
            checkedValues,
            handleCheckboxChange,
            handleUploadImage
          );
        case "radio":
          return renderRadioInput(
            fieldValue,
            camelCaseKey,
            label,
            input,
            applicationDetails,
            setApplicationDetails,
            checkedValues,
            handleCheckboxChange,
            handleUploadImage
          );
        case "note":
          return renderNote(
            fieldValue,
            camelCaseKey,
            label,
            input,
            applicationDetails,
            setApplicationDetails,
            checkedValues,
            handleCheckboxChange,
            handleUploadImage
          );
        case "checkbox":
          return renderCheckboxInput(
            fieldValue,
            camelCaseKey,
            label,
            input,
            applicationDetails,
            setApplicationDetails,
            checkedValues,
            handleCheckboxChange,
            handleUploadImage
          );
        case "image":
          return renderImageInput(
            fieldValue,
            camelCaseKey,
            label,
            input,
            applicationDetails,
            setApplicationDetails,
            checkedValues,
            handleCheckboxChange,
            handleUploadImage
          );
        case "multipleImage":
          return renderImageInputMultiple(
            fieldValue,
            camelCaseKey,
            label,
            input,
            applicationDetails,
            setApplicationDetails,
            checkedValues,
            handleCheckboxChange,
            handleUploadImage
          );
        case "pdf":
          return renderPdfInput(
            fieldValue,
            camelCaseKey,
            label,
            input,
            applicationDetails,
            setApplicationDetails,
            checkedValues,
            handleCheckboxChange,
            handleUploadImage
          );

        case "multiplePdf":
          return renderPdfInputMultiple(
            fieldValue,
            camelCaseKey,
            label,
            input,
            applicationDetails,
            setApplicationDetails,
            checkedValues,
            handleCheckboxChange,
            handleUploadImage
          );

        case "textarea":
          return renderTextareaInput(
            fieldValue,
            camelCaseKey,
            label,
            input,
            applicationDetails,
            setApplicationDetails,
            checkedValues,
            handleCheckboxChange,
            handleUploadImage
          );
        case "DomicileOfOdisha":
          return (
            <DomicileOfOdisha
              handelApplicationDetailsChange={(d) =>
                handelApplicationDetailsChange(d)
              }
              applicationDetails={applicationDetails}
              style={style}
            />
          );
        case "DepartmentalCandidate":
          return (
            <DepartmentalCandidate
              handelApplicationDetailsChange={(d) =>
                handelApplicationDetailsChange(d)
              }
              applicationDetails={applicationDetails}
              closingDate={closingDate}
              style={style}
            />
          );
        case "AreYouASportsMan":
          return (
            <AreYouASportsMan
              handelApplicationDetailsChange={(d) =>
                handelApplicationDetailsChange(d)
              }
              applicationDetails={applicationDetails}
              style={style}
            />
          );
        case "ReservationCategory":
          return (
            <ReservationCategory
              handelApplicationDetailsChange={(d) =>
                handelApplicationDetailsChange(d)
              }
              applicationDetails={applicationDetails}
              style={style}
              allowedCategory={allowedCategory}
            />
          );
        case "ExServicemen":
          return (
            <ExServicemen
              handelApplicationDetailsChange={(d) =>
                handelApplicationDetailsChange(d)
              }
              applicationDetails={applicationDetails}
              style={style}
              closingDate={closingDate}
            />
          );
        case "PersonWithDisability":
          return (
            <PersonWithDisability
              handelApplicationDetailsChange={(d) =>
                handelApplicationDetailsChange(d)
              }
              allowedPwbdType={state?.post?.posting?.allowedPwbdType}
              applicationDetails={applicationDetails}
              style={style}
            />
          );
        case "Experience-Static":
          return (
            <ExperienceInputs
              checkErrorFn={checkErrorFn}
              setApplicationDetails={setApplicationDetails}
              applicationDetails={applicationDetails}
              style={style}
              postandvacancyDetails={state.post?.posting}
              applicationID={applicationID}
            />
          );

        default:
          return renderTextInput(
            fieldValue,
            camelCaseKey,
            label,
            input,
            applicationDetails,
            setApplicationDetails,
            checkedValues,
            handleCheckboxChange,
            handleUploadImage
          );
      }
    };


    const renderGroupedInputs = (inputs) => {
      let groupedElements = [];
      let currentSubheading = null;
      let currentGroup = [];

      inputs.forEach((input, idx) => {
        const camelCaseKey = camelCase(input.values._id);
        const label = input.values.inputFieldName;
        console.log("lable", label)

        const fieldValue = applicationDetails ? applicationDetails[camelCaseKey] : "";

        if (input.values.inputFieldType === "SubHeading") {
          // Push the current group to groupedElements if there's any
          if (currentGroup.length > 0) {
            groupedElements.push(
              <Card key={currentSubheading} className="mb-2 dynamicFormcards">
                <Col key={idx} xs={window.innerWidth >= 793 ? input?.values?.columnLength : 12} md={input.values.columnLength} className="">                  <Row>{currentGroup}</Row>
                </Col>
              </Card>
            );
            currentGroup = [];
          }
          currentSubheading = idx;
          currentGroup.push(
            <label className="form-label form-heading-main">
              {label === " Document Upload"
                ? <>
                  {label}
                  <span style={{
                    color: 'rgba(255, 0, 0, 1)',
                    margin: "10px",
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    fontSize: '11px',
                    lineHeight: '10.55px'

                  }} className="Documentdploadvalidation">
                    (Only JPG and PDF files are accepted, with a maximum file size of 1 MB.)
                  </span>
                </>

                :
                label === "Permanent Address" ? <>
                  {label}
                  <div className="form-check m-0 p-0" key={`pA_checkbox`}>
                    <input id="pA_checkbox" type={"checkbox"} checked={isSameAsCorrespondence}
                      onChange={handleCheckboxChange_}
                    />
                    <label
                      htmlFor={"pA_checkbox"}
                      style={{ ...style.label, marginLeft: "5px", }}
                    >
                      Same as correspondence address
                    </label>
                  </div>
                </>
                  :
                  label === "Grade" ?

                    <>
                    </>
                    :

                    label}
            </label>
            //  </Col>
          );
        } else {
          currentGroup.push(
            <Col key={idx} xs={window.innerWidth >= 793 ? input?.values?.columnLength : 12} md={input.values.columnLength} className="mb-2">              <>

              {
                camelCaseKey === "6719d53d3ac83738a0ba587a" ? (
                  applicationDetails["66a687874db5f02d6861ed04"] === "CGPA" || applicationDetails["66a687874db5f02d6861ed04"] === "Grade" ? (
                    <label
                      className="inputBoxHeadForm upload-head-gap mt-2"
                      style={style.label}
                    >
                      {label}
                      {input.values.isRequired && label && (
                        <span className="mx-1 text-danger star">*</span>
                      )}
                    </label>
                  ) : <></>
                ) :


                  camelCaseKey === "6719d8603ac83738a0ba5fd7" ? (
                    applicationDetails["66a728ec9680c25e280d6bf6"] === "CGPA" || applicationDetails["66a728ec9680c25e280d6bf6"] === "Grade" ? (
                      <label
                        className="inputBoxHeadForm upload-head-gap mt-2"
                        style={style.label}
                      >
                        {label}
                        {input.values.isRequired && label && (
                          <span className="mx-1 text-danger star">*</span>
                        )}
                      </label>
                    ) : <></>
                  ) :

                    camelCaseKey === "6719d9653ac83738a0ba6155" ? (
                      applicationDetails["66a687874db5f02d6861ed0e"] === "CGPA" || applicationDetails["66a687874db5f02d6861ed0e"] === "Grade"  ? (
                        <label
                          className="inputBoxHeadForm upload-head-gap mt-2"
                          style={style.label}
                        >
                          {label}
                          {input.values.isRequired && label && (
                            <span className="mx-1 text-danger star">*</span>
                          )}
                        </label>
                      ) : <></>
                    ) :

                      camelCaseKey === "67270267df49515ae469b46d" ? (
                        applicationDetails["67270267df49515ae469b465"] === "CGPA" ? (
                          <label
                            className="inputBoxHeadForm upload-head-gap mt-2"
                            style={style.label}
                          >
                            {label}
                            {input.values.isRequired && label && (
                              <span className="mx-1 text-danger star">*</span>
                            )}
                          </label>
                        ) : <></>

                      ) :


                        camelCaseKey === "673b18a3b182bc6699a164a4" ? (
                          applicationDetails["66a687874db5f02d6861ed04"] === "Grade" ? (
                            <label
                              className="inputBoxHeadForm upload-head-gap mt-2"
                              style={style.label}
                            >
                              {label}
                              {input.values.isRequired && label && (
                                <span className="mx-1 text-danger star">*</span>
                              )}
                            </label>
                          ) : <></>

                        ) :

                          camelCaseKey === "673b3b84b182bc6699a175fd" ? (
                            applicationDetails["66a728ec9680c25e280d6bf6"] === "Grade" ? (
                              <label
                                className="inputBoxHeadForm upload-head-gap mt-2"
                                style={style.label}
                              >
                                {label}
                                {input.values.isRequired && label && (
                                  <span className="mx-1 text-danger star">*</span>
                                )}
                              </label>
                            ) : <></>

                          ) :
                            camelCaseKey === "673c52c4b182bc6699a19cae" ? (
                              applicationDetails["66a687874db5f02d6861ed0e"] === "Grade" ? (
                                <label
                                  className="inputBoxHeadForm upload-head-gap mt-2"
                                  style={style.label}
                                >
                                  {label}
                                  {input.values.isRequired && label && (
                                    <span className="mx-1 text-danger star">*</span>
                                  )}
                                </label>
                              ) : <></>

                            )
                              :
                              (
                                <label
                                  className="inputBoxHeadForm upload-head-gap mt-2"
                                  style={style.label}
                                >
                                  {label}
                                  {input.values.isRequired && label && (
                                    <span className="mx-1 text-danger star">*</span>
                                  )}
                                </label>
                              )


              }

              {renderInput(
                fieldValue,
                camelCaseKey,
                label,
                input,
                applicationDetails,
                setApplicationDetails,
                checkedValues,
                handleCheckboxChange,
                handleUploadImage
              )}


            </>
            </Col>
          );
        }
      });
      if (currentGroup.length > 0) {
        groupedElements.push(
          <Card key={currentSubheading} className="mb-2 dynamicFormcards">
            <Row>{currentGroup}</Row>
          </Card>
        );
      }

      return groupedElements;
    };

    return <Row className="ml-1">{renderGroupedInputs(item.values)}</Row>;
  };

  const toggleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleTabClick = () => {
    setIsMobileMenuOpen(false);
  };




  const [tablesRendered, setRendered] = useState(false)
  const [tablesRenderedquali, setTablesRenderedquali] = useState(false)

  // const renderTableOfIsArray = (item) => {
  //   if (!tablesRendered) {
  //     function getUniqueTabNamesAsync(data) {
  //       const tabNames = new Set();
  //       for (let i = 0; i < data.length; i++) {
  //         if (data[i].tab) tabNames.add(data[i].tab);
  //       }
  //       return Array.from(tabNames);
  //     }

  //     const RenderTableForFieldsWithTab = (data, targetTab) => {
  //       const matchingItems = data.filter(
  //         (item) =>
  //           Array.isArray(item.fields) &&
  //           item.fields.some((field) => field.tab === targetTab)
  //       );

  //       if (matchingItems.length === 0) return <div></div>;

  //       // Filter headers and ensure only matching fields are displayed
  //       const filteredHeaders = matchingItems[0].fields
  //         .filter(
  //           (field) =>
  //             field.tab === targetTab &&
  //             field.values.inputFieldType !== "SubHeading" &&
  //             !['Certificates', 'Mark sheets', 'Letter Of Appointment', 'Release Letter'].includes(field.values.inputFieldName)
  //         )
  //         .map((field) => field.values.inputFieldName);

  //       const heading = matchingItems[0].fields
  //         .filter(
  //           (field) =>
  //             field.tab === targetTab &&
  //             field.values.inputFieldType === "SubHeading"
  //         )
  //         .map((field) => field.values.inputFieldName);

  //       return (
  //         <Card className="mt-2 mb-0 dynamicFormcards ms-3 me-3" style={{ width: '97%' }}>
  //           <Row className="mt-3">
  //             <label className="form-label form-heading-main">{heading[0]}</label>
  //             <Table
  //               className="ms-2 me-2"
  //               bordered
  //               style={{ border: '1px solid black', overflow: 'hidden', width: '98%', tableLayout: 'fixed' }}
  //             >
  //               <thead>
  //                 <tr>
  //                   {filteredHeaders.map((header, index) => (
  //                     <th
  //                       key={index}
  //                       style={{
  //                         border: '1px solid black',
  //                         whiteSpace: 'normal',
  //                         wordWrap: 'break-word',
  //                         width: `${98 / filteredHeaders.length}%`,
  //                       }}
  //                     >
  //                       <label className="form-label form-heading-main m-0 p-0" style={style.label}>
  //                         {header}
  //                       </label>
  //                     </th>
  //                   ))}
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 {matchingItems.map((item, rowIndex) => {
  //                   const filteredFields = item.fields.filter(
  //                     (field) =>
  //                       field.tab === targetTab &&
  //                       field.values.inputFieldType !== "SubHeading" &&
  //                       filteredHeaders.includes(field.values.inputFieldName)
  //                   );

  //                   return filteredFields.length > 0 ? (
  //                     <tr key={rowIndex}>
  //                       {filteredFields.map((field, colIndex) => {
  //                         const isLink = /^(http|https):\/\/[^ "]+$/.test(field.value);
  //                         return !isLink ? (
  //                           <td
  //                             key={colIndex}
  //                             style={{
  //                               border: '1px solid black',
  //                               whiteSpace: 'normal',
  //                               wordWrap: 'break-word',
  //                               width: `${98 / filteredHeaders.length}%`,
  //                             }}
  //                           >
  //                             <span style={{ fontSize: '13px', fontWeight: '500' }}>
  //                               {field.value || 'N/A'}
  //                             </span>
  //                           </td>
  //                         ) : null;
  //                       })}
  //                     </tr>
  //                   ) : null;
  //                 })}
  //               </tbody>
  //             </Table>
  //           </Row>
  //         </Card>
  //       );
  //     };

  //     const tabs = getUniqueTabNamesAsync(dynamicFields);
  //     return tabs.map(
  //       (tab) => tab === camelCase(item?.formTab) && RenderTableForFieldsWithTab(dynamicFields, tab)
  //     );
  //   }
  // };

  const renderTableOfIsArray = (item) => {
    if (!tablesRendered) {
      const getUniqueTabNamesSync = (data) => {
        const tabNames = new Set();
        data.forEach((item) => {
          if (item.tab) tabNames.add(item.tab);
        });
        return Array.from(tabNames);
      };

      const RenderTableForFieldsWithTab = (data, targetTab) => {
        const matchingItems = data.filter(
          (item) =>
            Array.isArray(item.fields) &&
            item.fields.some((field) => field.tab === targetTab)
        );

        // Return null if no matching items are found
        if (matchingItems.length === 0) return null;

        // Filter headers based on criteria
        const filteredHeaders = matchingItems[0].fields
          .filter(
            (field) =>
              field.tab === targetTab &&
              field.values.inputFieldType !== "SubHeading" &&
              !['Certificates', 'Mark sheets', 'Letter Of Appointment', 'Release Letter'].includes(field.values.inputFieldName)
          )
          .map((field) => field.values.inputFieldName);

        // Get the subheading if it exists
        const heading = matchingItems[0].fields
          .filter(
            (field) =>
              field.tab === targetTab &&
              field.values.inputFieldType === "SubHeading"
          )
          .map((field) => field.values.inputFieldName);

        return (
          <Card className="mt-2 mb-0 dynamicFormcards ms-3 me-3" style={{ width: '97%' }}>
            <Row className="mt-3">
              {/* Only render the label if heading is available */}
              {heading[0] && (
                <label className="form-label form-heading-main">{heading[0]}</label>
              )}
              <Table
                className="ms-2 me-2"
                bordered
                style={{ border: '1px solid black', overflow: 'hidden', width: '98%', tableLayout: 'fixed' }}
              >
                <thead>
                  <tr>
                    {filteredHeaders.map((header, index) => (
                      <th
                        key={index}
                        style={{
                          border: '1px solid black',
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          width: `${98 / filteredHeaders.length}%`,
                        }}
                      >
                        <label className="form-label form-heading-main m-0 p-0" style={style.label}>
                          {header}
                        </label>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {matchingItems.map((item, rowIndex) => {
                    const filteredFields = item.fields.filter(
                      (field) =>
                        field.tab === targetTab &&
                        field.values.inputFieldType !== "SubHeading" &&
                        filteredHeaders.includes(field.values.inputFieldName)
                    );

                    return filteredFields.length > 0 ? (
                      <tr key={rowIndex}>
                        {filteredFields.map((field, colIndex) => {
                          const isLink = /^(http|https):\/\/[^ "]+$/.test(field.value);
                          return !isLink ? (
                            <td
                              key={colIndex}
                              style={{
                                border: '1px solid black',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                width: `${98 / filteredHeaders.length}%`,
                              }}
                            >
                              <span style={{ fontSize: '13px', fontWeight: '500' }}>
                                {field.value || 'N/A'}
                              </span>
                            </td>
                          ) : null;
                        })}
                      </tr>
                    ) : null;
                  })}
                </tbody>
              </Table>
            </Row>
          </Card>
        );
      };

      // Ensure tabs are fetched before rendering tables
      const tabs = getUniqueTabNamesSync(dynamicFields);
      return tabs.map(
        (tab) => tab === camelCase(item?.formTab) && RenderTableForFieldsWithTab(dynamicFields, tab)
      );
    }
  };


  const renderTableForNonArrayData = (data) => {

    const subHeadings = data?.filter(item => item?.values?.inputFieldType === 'SubHeading');

    if (!subHeadings?.length) {
      return <div>No data available</div>;
    }

    const getFieldsBetweenSubHeadings = (startIndex, data) => {
      let fields = [];
      for (let i = startIndex + 1; i < data?.length; i++) {
        if (data[i]?.values?.inputFieldType === 'SubHeading') break;
        fields.push(data[i]);
      }
      return fields;
    };

    return subHeadings?.map((subHeading, index) => {
      const fields = getFieldsBetweenSubHeadings(data.indexOf(subHeading), data);

      return (
        <Card className="mt-2 mb-0 dynamicFormcards ms-0 me-0 ps-0 pe-0" style={{ width: '100%' }} key={index}>
          <Row className="mt-3 ms-0 me-0">
            <label className="form-label form-heading-main">{subHeading?.values?.inputFieldName}</label>
            {/* Render notes that come before the second subheading */}
            {fields.map((field, fieldIndex) => {
              if (field?.values?.inputFieldType === 'note') {
                return (
                  <div key={fieldIndex} className="note-field" style={{ margin: '10px 0', fontSize: '13px', fontWeight: '500', color: "brown" }} >
                    {applicationDetails[field?.values?._id] || ''}
                  </div>
                );
              }
              return null;
            })}

            {/* Render table for non-note fields */}
            {/* <Table className="ms-2 me-2" bordered style={{ border: '1px solid black', overflow: 'hidden', width: '98%', tableLayout: 'fixed' }}>
              <thead className="p-0 m-0">
                <tr className="p-0 m-0">

                  {fields?.map((field, headerIndex) => (
                    field?.values?.inputFieldType !== 'note' && (
                      <th
                        key={headerIndex}
                        style={{ border: '1px solid black', whiteSpace: 'normal', wordWrap: 'break-word', width: `${98 / fields.length}%` }}
                      >
                        <label className="form-label form-heading-main m-0 p-0" style={style.label}>
                          {field?.values?.inputFieldName}
                        </label>
                      </th>
                    )
                  ))}
                </tr>
              </thead>
              <tbody className="p-0 m-0">
                <tr className="p-0 m-0">
                  {fields?.map((field, fieldIndex) => (
                    field?.values?.inputFieldType !== 'note' && (
                      <td
                        className="p-0 m-2"
                        key={fieldIndex}
                        style={{ border: '1px solid black', whiteSpace: 'normal', wordWrap: 'break-word', width: `${98 / fields.length}%` }}
                      >
                        <span style={{ fontSize: '13px', fontWeight: '500', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                          {applicationDetails[field?.values?._id] || 'N/A'}
                        </span>
                      </td>
                    )
                  ))}
                </tr>
              </tbody>
            </Table> */}

            {/* Table with Removed unwanted cells for essential qualification */}

            <Table
              className="ms-2 me-2"
              bordered
              style={{ border: '1px solid black', overflow: 'hidden', width: '98%', tableLayout: 'fixed' }}
            >
              <thead className="p-0 m-0">
                <tr className="p-0 m-0">
                  {fields?.map((field, headerIndex) => {
                    const headerName = field?.values?.inputFieldName || '';

                    // Skip headers that contain 'Certificates' or 'Marksheets'
                    if (headerName.includes('Certificates') || headerName.includes('Marksheets') || headerName.includes('Mark Sheets') || headerName.includes('Attach the Conversion formula')) {
                      return null;
                    }

                    return (
                      field?.values?.inputFieldType !== 'note' && (
                        <th
                          key={headerIndex}
                          style={{
                            border: '1px solid black',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            width: `${98 / fields.length}%`
                          }}
                        >
                          <label
                            className="form-label form-heading-main m-0 p-0"
                            style={style.label}
                          >
                            {console.log(headerName, "Filtered Header")}
                            {headerName}
                          </label>
                        </th>
                      )
                    );
                  })}
                </tr>
              </thead>

              <tbody className="p-0 m-0">
                <tr className="p-0 m-0">
                  {fields?.map((field, fieldIndex) => {
                    const headerName = field?.values?.inputFieldName || '';
                    const fieldData = applicationDetails[field?.values?._id] || 'N/A';
                    const sanitizedData = fieldData.replace(/https?:\/\/[^\s]+/g, '');

                    // Skip rows that correspond to headers with 'Certificates' or 'Marksheets'
                    if (headerName.includes('Certificates') || headerName.includes('Marksheets') || headerName.includes('Mark Sheets') || headerName.includes('Attach the Conversion formula')) {
                      return null;
                    }

                    return (
                      field?.values?.inputFieldType !== 'note' && (
                        <td
                          className="p-2 m-2"
                          key={fieldIndex}
                          style={{
                            border: '1px solid black',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            width: `${98 / fields.length}%`
                          }}
                        >
                          <span
                            style={{
                              fontSize: '13px',
                              fontWeight: '500',
                              whiteSpace: 'normal',
                              wordWrap: 'break-word'
                            }}
                          >
                            {console.log(sanitizedData, "Filtered Data")}
                            {sanitizedData}
                          </span>
                        </td>
                      )
                    );
                  })}
                </tr>
              </tbody>
            </Table>


          </Row>
        </Card>
      );
    });
  };




  useEffect(() => {
    document.querySelectorAll('input[type="number"]').forEach(function (input) {
      input.addEventListener('wheel', function (event) {
        event.preventDefault();
      });
    });
    if (tabIndex && tabIndex === applicationForm.length) {
      setTimeout(() => {

        const today = new Date().toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });
        // Disable interactions for inputs and selects
        // const inputs = document.querySelectorAll("input, select");
        // const check = document.querySelectorAll("input, select");
        // if (!check) {
        //   inputs.forEach((input) => {
        //     if (input.type !== "checkbox") { // Skip checkboxes
        //       input.style.pointerEvents = "none";
        //       input.setAttribute("type", "text");
        //       input.style.marginTop = "0px";
        //       input.style.padding = "0px";
        //       input.style.appearance = "none";
        //       input.style.fontWeight = "600";
        //       input.style.border = "0";
        //       input.style["-webkit-appearance"] = "none";
        //       input.style["-moz-appearance"] = "none";
        //       input.style["appearance"] = "none";
        //       input.style["-webkit-calendar-picker-indicator"] = "none";
        //     }
        //   });
        // }
        

        // check.forEach((input) => {
        //   input.style.pointerEvents = "auto";  // Ensure pointer events are active for all inputs and selects
        //   // input.setAttribute('type', 'text');
        //   // input.style.marginTop = "0px";
        //   // input.style.paddingTop = "0px";
        //   // input.style.paddingBottom = "0px";
        //   // input.style.paddingLeft = "0px";
        //   // input.style.paddingRight = "0px";
        //   // input.style.height = "20px";
        //   input.style.appearance = "auto";
        //   input.style.fontWeight = "600";
        //   // input.style.border = "0";
        //   input.style["-webkit-appearance"] = "none";
        //   input.style["-moz-appearance"] = "none";
        //   input.style["appearance"] = "none";
        //   input.style["-webkit-calendar-picker-indicator"] = "none";
        // });

        // Target and hide elements where the label contains "Document Upload"
        const labels = document.querySelectorAll("label.form-label.form-heading-main");
        labels.forEach((label) => {
          if (label.textContent.includes("Document Upload")) {
            const parentDiv = label.closest("div.row"); // Get the closest parent div
            if (parentDiv) {
              parentDiv.style.display = "none"; // Hide the parent div
            }
          }
        });

        const uploadDocument = document.querySelectorAll('label.form-label');
        uploadDocument.forEach((label) => {
          if (label.textContent.includes("Upload Resident Certificate ")) {
            const parentDiv = label.closest("div"); // Get the closest parent div
            if (parentDiv) {
              parentDiv.style.display = "none"; // Hide the parent div
            }
          }
          if (label.textContent.includes("Upload Sports Certificate")) {
            const parentDiv = label.closest("div"); // Get the closest parent div
            if (parentDiv) {
              parentDiv.style.display = "none"; // Hide the parent div
            }
          }
          if (label.textContent.includes("Upload Certificate")) {
            const parentDiv = label.closest("div"); // Get the closest parent div
            if (parentDiv) {
              parentDiv.style.display = "none"; // Hide the parent div
            }
          }
        })


        // Select all <a> tags in the document
        var uploadLinks = document.querySelectorAll('a');

        // Loop through each <a> tag and remove the parent div with class 'col-md-2 col-12'
        uploadLinks.forEach(function (upload) {
          var parentDiv = upload.closest('.col-md-2.col-12');
          if (parentDiv) {
            parentDiv.remove();
          }
        });

        var imageTag = document.querySelectorAll('img');

        // Loop through each <a> tag and remove the parent div with class 'col-md-2 col-12'
        imageTag.forEach(function (upload) {
          var parentDiv = upload.closest('.col-md-1.col-12');
          if (parentDiv) {
            parentDiv.remove();
          }
        });

        var svgTag = document.querySelectorAll('svg');

        // Loop through each <a> tag and remove the parent div with class 'col-md-2 col-12'
        svgTag.forEach(function (upload) {
          var parentDiv = upload.closest('.col-md-2.col-12');
          if (parentDiv) {
            parentDiv.remove();
          }
        });



        const correspondAddressLabels = document.querySelectorAll('label');
        correspondAddressLabels.forEach((label) => {
          if (label.innerText.trim() === 'Same as correspondence address') {
            const parentDiv = label.parentElement;
            if (parentDiv) {
              parentDiv.style.display = 'none';
            }
          }
        });

        document.querySelectorAll("label").forEach((label) => {
          if (
            label.textContent.includes(
              "I confirm that I fulfill the physical requirement standards for the post as laid down in the advertisement. In case of any discrepency, my candidature is laible to be cancelled."
            )
          ) {
            label.style.display = "none";
          }
        });

        document.querySelectorAll("label").forEach((label) => {
          if (
            label.textContent.includes(
              "I do hereby declare that I shall submit date-wise details of my application (s) for various vacancy (ies), for which I have applied for, at the time of Document Verification, if shortlisted."
            )
          ) {
            label.style.display = "none";
          }
        });
        // const uploadResidentCertificate = document.querySelectorAll('label');
        // uploadResidentCertificate.forEach((label) => {
        //   if (label.innerText.trim() === 'Upload Resident Certificate ') {
        //     const parentDiv = label.parentElement;
        //     if (parentDiv) {
        //       console.log({parentDiv})
        //       parentDiv.style.display = 'none';
        //     }
        //   }
        // });

        // Find the label element by matching its text content



        // Hide or remove the asterisk
        const asterisks = document.querySelectorAll('span.text-danger');
        asterisks.forEach((asterisk) => {
          asterisk.style.display = "none"; // Hide the asterisk
          // asterisk.remove(); // Alternatively, you can use this to completely remove it from the DOM
        });


        const labelsToHide = ["Application ID", "Advertisement Details", "Post Name"];

        labelsToHide.forEach(labelText => {
          // Find all label elements
          const labels = document.querySelectorAll("label");

          labels.forEach(label => {
            if (label.textContent.trim().includes(labelText)) {
              // Hide the label
              label.style.display = "none";

              // Get the closest parent div containing the input
              const parentDiv = label.closest('div');
              if (parentDiv) {
                const input = parentDiv?.querySelector('input');
                if (input) {
                  input.value = ""; // Clear the input value
                  input.style.display = "none"; // Hide the input
                }
              }
            }
          });
        });


        // Get the specific div by ID to modify its content
        const specificDiv = document.getElementById("printDiv_1");

        if (specificDiv) {

          specificDiv.style.position = "relative"; // Ensure relative positioning for the parent

          // Add a header with an image
          const topSection = document.createElement("div");
          topSection.style.display = "flex";
          topSection.style.flexDirection = "row-reverse"
          topSection.style.gap = "20px"
          topSection.style.alignItems = "center";
          // topSection.style.justifyContent = ""; // Ensure the elements are side by side
          topSection.style.backgroundColor = "white";
          // topSection.style.padding = "10px";
          topSection.style.marginLeft = '20px'
          topSection.style.marginRight = '20px'

          topSection.style.height = "127px";
          topSection.style.marginBottom = "30px"; // Space below the top section

          // Create a new image element
          const newImage = document.createElement("img");
          newImage.src = "https://dg1ya6cdc7ief.cloudfront.net/Upload/Logo_Files/e4b1a69efc184de78ec6131fbbe4a42f_20221213133117014.png"; // Your image URL
          newImage.alt = "omc_logo";
          newImage.style.height = "40px";
          newImage.style.width = "120px";
          newImage.style.margin = "0 10px"; // Add some space between the images
          newImage.style.display = "block"; // Ensure it is treated as a block-level element

          // Target the specific image with alt text
          const specificImage = document?.querySelector('img[alt="Upload Scanned copy of your passport size photo"]');
          if (specificImage) {
            specificImage.style.display = "block";
            specificImage.style.width = "180px"; // Adjust the height if needed
            specificImage.style.height = "180px"; // Adjust the height if needed
            specificImage.style.position = "absolute"; // Float it to the top left
            specificImage.style.top = "25px"; // Adjust the top offset
            specificImage.style.right = "25px"; // Adjust the left offset
            specificImage.style.zIndex = "10"; // Ensure it's above other content
            specificImage.style.margin = "0"; // Remove any margin

          } else {
            console.log("Image with the specified alt text not found.");
          }

          // Create a div to insert between the two images
          const textDiv = document.createElement("div");
          textDiv.classList.add("text-div"); // Add a class for easier styling
          textDiv.style.flex = "1"; // Allow it to expand between the images
          textDiv.style.textAlign = "center"; // Center the text inside the div
          textDiv.style.fontFamily = "'Roboto', sans-serif";
          textDiv.style.fontSize = "20px"; // Font size


          // Add multiple lines of text inside the textDiv
          textDiv.innerHTML = `
           <div class="d-flex flex-column justify-content-center text-center w-50 ms-5">
    <h5 class="mt-0 fw-bold text-decoration-underline fontRoboto">Application Form</h5>
  </div>
           `;





          // Append the new image to the top section
          topSection.appendChild(specificImage);
          topSection.appendChild(textDiv);       // Add the div with text between the images
          topSection.appendChild(newImage);      // Add new image to top section


          // Append the top section div at the beginning of the specificDiv
          specificDiv.insertBefore(topSection, specificDiv.firstChild);

          // Create a new section to hold the additional text information
          const infoSection = document.createElement("div");
          infoSection.style.margin = "20px 0"; // Add some margin to separate it from other sections
          infoSection.style.padding = "10px";
          infoSection.style.backgroundColor = "#fff"; // Optional: Background color for distinction
          infoSection.style.borderBottom = "1px solid #ddd";   // Optional: Border for clarity
          infoSection.style.marginLeft = '20px';
          infoSection.style.marginRight = '20px';
          infoSection.style.fontFamily = "'Roboto', sans-serif";


          // Add your three pieces of text information
          infoSection.innerHTML = `
          <div class='d-flex justify-content-between w-75 pb-3'>
          
          <p class='fw-bold fontRoboto'><strong>Apply Date</strong><br/> ${today}</p>
          <p class='fw-bold fontRoboto'><strong>Application No.</strong><br/> ${applicationID}</p>
          <p class='fw-bold fontRoboto'><strong>Post</strong><br/> ${applicationDetails?.vacancyId?.grade?.designationPrefix} (${applicationDetails?.vacancyId?.department?.name}), Grade - ${applicationDetails?.vacancyId?.grade?.gradeName}</p>
          <p class='pb-2 fw-bold fontRoboto'> <strong>Advertisement No.</strong><br/> ${applicationDetails?.advertisement?.advertisementNumber}</p>
          
          <div/>

        `;

          // Insert the new info section below the topSection
          specificDiv.insertBefore(infoSection, specificDiv.firstChild.nextSibling);

          // Create the footer section
          const bottomSection = document.createElement("div");
          bottomSection.style.textAlign = "center";
          bottomSection.style.backgroundColor = "rgba(109, 110, 113, 1)";
          bottomSection.style.padding = "10px";
          bottomSection.style.marginTop = "30px";

          // Footer text
          const footerText = document.createElement("span");
          footerText.textContent = "© 2024 Odisha Mining Corporation Ltd. (O M C LTD)";
          footerText.style.fontSize = "14px";
          footerText.style.color = '#fff';
          footerText.style.fontFamily = "'Roboto', sans-serif";
          footerText.style.padding = "10px";

          // Append the footer text to the bottomSection div
          // bottomSection.appendChild(footerText);

          // Append the bottom section div to the specificDiv
          // specificDiv.appendChild(bottomSection);

          // Apply styles to cards, headings, and other elements
          const cards = specificDiv.querySelectorAll(".card");
          cards.forEach((card) => {
            card.style.opacity = "1";
            card.style.border = "none";
            card.style["box-shadow"] = "0px 0px 0px";
            card.style.margin = "0";
            card.style.padding = "10px";
            // card.style.width = "210mm"
          });

          const headings = specificDiv.querySelectorAll(".form-heading-main");
          headings.forEach((heading) => {
            // heading.style.margin = "10px";
            heading.style.paddingLeft = "5px";
          });

          const highlightedHeadings = specificDiv.querySelectorAll(".form-heading-main");

          highlightedHeadings.forEach((heading) => {
            // Check if the parent element is a <th>
            const isInsideTableHeader = heading.closest("th");

            // Skip the elements inside a <th>
            if (isInsideTableHeader) {
              return; // Skip styling if inside <th>
            }

            // Get the text content of the heading
            const headingText = heading.innerText.trim();

            // Apply different styles based on the heading text
            if (
              headingText === "Person With Benchmark Disability (PwBD) Details" ||
              headingText === "Additional Details" ||
              headingText === "Ex-Servicemen Details" ||
              headingText === "Permanent Address" ||
              headingText === "Matric/10th Details" ||
              headingText === "12th Details" ||
              headingText === "Graduation Details"
            ) {
              // Apply a different style for specific headings
              heading.style.marginLeft = "10px";
              heading.style.marginRight = "10px";
              heading.style.marginTop = "10px";
              heading.style.padding = "10px";
              heading.style.backgroundColor = "#00000012";  // Different background color
              heading.style.color = "black";               // Different font color
              heading.style.flexShrink = "inherit";
              heading.style.borderTopLeftRadius = "10px";  // Different border radius
              heading.style.borderTopRightRadius = "10px";
            } else {
              // Apply the default style for other headings
              heading.style.marginLeft = "10px";
              heading.style.marginRight = "10px";
              heading.style.marginTop = "20px";
              heading.style.padding = "10px";
              heading.style.backgroundColor = "#921D1E";
              heading.style.color = "#ffff";
              heading.style.flexShrink = "inherit";
              heading.style.borderTopLeftRadius = "10px";
              heading.style.borderTopRightRadius = "10px";
            }
          });



          // Remove unnecessary elements
          const star = specificDiv.querySelectorAll(".star");
          star.forEach((stars) => {
            stars.style.display = "none";
          });

          const allDiv = specificDiv.querySelectorAll("div");
          allDiv.forEach((div) => {
            div.style.marginTop = "0";
            div.style.marginBottom = "0";
            div.style.paddingTop = "0";
            div.style.paddingBottom = "0";
          });

          const allpdfDocuments = specificDiv.querySelectorAll(".renderPdf");
          allpdfDocuments.forEach((pdfElement) => {
            pdfElement.remove();
          });

 
          // .react-select__control
          const verifybutton = specificDiv.querySelectorAll(".verifybutton,.badge,.chooseFile,.btn,.Documentdploadvalidation,.react-select__indicators,.react-select__multi-value__remove");
          verifybutton.forEach((ver) => {
            ver.style.display = "none";
          });
    
          const departmentalJoiningDate = specificDiv.querySelectorAll('.react-select__control,#departmentalJoiningDate,#exServicemanJoiningDate,#exServicemanRetirementDate,#Percentage_of_disability,#issuedDate,#issueDateReser,#certificateNumberReser,.react-select__multi-value');
          departmentalJoiningDate.forEach((file) => {
            file.style["-webkit-calendar-picker-indicator"] = "none";

            file.style.border = "none";
            file.style.pointerEvents = "none"; 
                      file.style.appearance = "none";
                      file.style["-webkit-appearance"] = "none";
                      file.style["-moz-appearance"] = "none";
                      file.style["appearance"] = "none";
                      file.setAttribute("type", "text");

});

// react-select_multi-value




// Target the dropdown element
const dropdownElement = document?.querySelector('.css-181qgi4-control');

// Remove unwanted styles
if (dropdownElement) {
  dropdownElement.style.border = "none"; // Remove border
  dropdownElement.style.boxShadow = "none"; // Remove any shadow effects
  dropdownElement.style.backgroundColor = "transparent"; // Make background transparent
  dropdownElement.style.padding = "0"; // Remove padding
  dropdownElement.style.margin = "0"; // Remove margins
  dropdownElement.pointerEvents='none'
}

const dropdownInput = dropdownElement?.querySelector('input');
const indicatorContainer = dropdownElement?.querySelector('.css-9uufmq-IndicatorsContainer');

if (dropdownInput) {
  dropdownInput.style.border = "none"; // Remove input border
  dropdownInput.style.outline = "none"; // Remove input outline
  dropdownInput.style.background = "none"; // Remove input background
  dropdownInput.pointerEvents='none'

}

if (indicatorContainer) {
  indicatorContainer.style.display = "none"; // Hide dropdown arrow/icon
  indicatorContainer.pointerEvents='none'

}


// Target the input element by its ID or name
// const dateInput = specificDiv.querySelector('input[name="disabilityPercentage"]');
// dateInput.forEach((file) => {
//   file.style["-webkit-calendar-picker-indicator"] = "none";

//   file.style.border = "none";
//   file.style.pointerEvents = "none"; 
//             file.style.appearance = "none";
//             file.style["-webkit-appearance"] = "none";
//             file.style["-moz-appearance"] = "none";
//             file.style["appearance"] = "none";
//             file.setAttribute("type", "text");

// });
// // Remove the border
// dateInput.style.border = "none";

// // Hide the calendar icon by overriding its appearance
// dateInput.style.appearance = "none"; // For most browsers
// dateInput.style.webkitAppearance = "none"; // For WebKit-based browsers like Safari
// dateInput.style.mozAppearance = "none"; // For Firefox

// // Optionally, make further adjustments to ensure no residual styles
// dateInput.style.background = "transparent"; // Remove any background styles
// dateInput.style.paddingRight = "0px"; // Adjust padding if needed


          // const deleteTheExprienceFileDiv = specificDiv.querySelectorAll('#fileUpload')
          // deleteTheExprienceFileDiv.forEach((file) => {
          //   file.style.display = "none";
          // });
          const backgroundRemoveElements = specificDiv.querySelectorAll('.form-control');

          backgroundRemoveElements.forEach((bgRemove) => {
            bgRemove.style.backgroundColor = "transparent";
          });
          const experenceInputRemove = specificDiv.querySelectorAll('#experenceInputRemove,#declaration-checkbox,#fileUpload,#allExperienceDetailsRemove')
          experenceInputRemove.forEach((file) => {
            file.style.display = "none";
          });
          // declaration-checkbox
          // const declarationCheckbox = specificDiv.querySelectorAll('#declaration-checkbox')
          // declarationCheckbox.forEach((file) => {
          //   file.style.display = "none";
          // });

          const categorySelectRemove = specificDiv.querySelectorAll('.react-select')
          categorySelectRemove.forEach((file) => {
            file.style.border = "none";
            file.style.pointerEvents = "none"; 
                      file.style.appearance = "none";
                      file.style["-webkit-appearance"] = "none";
                      file.style["-moz-appearance"] = "none";
                      file.style["appearance"] = "none";
                      file.style["-webkit-calendar-picker-indicator"] = "none";
         });
        //  css-181qgi4-control



         const selectRemove = specificDiv.querySelectorAll('select,.form-control')
         selectRemove.forEach((file) => {
           file.style.border = "none";
           file.style.pointerEvents = "none"; 
                     file.style.appearance = "none";
                     file.style["-webkit-appearance"] = "none";
                     file.style["-moz-appearance"] = "none";
                     file.style["appearance"] = "none";
                     file.style["-webkit-calendar-picker-indicator"] = "none";
        });

          // const formControlRemoval = specificDiv.querySelectorAll('.form-control')
          // formControlRemoval.forEach((file) => {
          //   file.style.border = "none";
          //   file.style.pointerEvents = "none";
          //   // file.style.appearance = "none";
          //   file.style["-webkit-appearance"] = "none";
          // file.style["-moz-appearance"] = "none";
          // file.style["appearance"] = "none";
          // file.style["-webkit-calendar-picker-indicator"] = "none";
          // });

          // const allExperienceDetailsRemove = specificDiv.querySelectorAll('#allExperienceDetailsRemove')
          // allExperienceDetailsRemove.forEach((file) => {
          //   file.style.display = "none";
          // });
        }

      }, 300);
    }
  }, [tabIndex]);




  useEffect(() => {
    // Function to handle screen resize
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener on mount
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };



  const printRef = useRef();

  console.log("applicationDetails+++++", applicationDetails)

  return Loading ? (
    <>
      <Preloader />
    </>
  ) : (
    <>
      <HamburgerMenu />
      {!isLoading && !isLoading2 && (
        <div className="m-0 p-0 background__main">
          <Tabs
            className="job-article tf-tab single-job d-flex flex-md-row flex-column applyjob-tab"
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            {isMobileMenuOpen && (
              <div className="dropdown-background" onClick={toggleMenu} />
            )}




            <div className={`mobile-Tab-items dropdown-menu-transition ${isMobileMenuOpen ? "open" : ""}`}>
              <div
                className={`mobile-header bg-light px-3 py-2 ${isMobile ? "d-md-none" : "custom-card-tabSide"
                  }`}
              >
                {/* Button to toggle mobile menu, visible only on mobile */}
                {isMobile && (
                  <button className="toggle-icon-form-close" onClick={toggleMobileMenu}>
                    <IoMdClose />
                  </button>
                )}

                <TabList className={`${isMobile ? "menu-tab-mobile" : "menu-tab"} ps-0`}>
                  {applicationForm?.map((item, idx) => (
                    <Tab
                      className={`${isMobile ? "px-2 py-2" : "mb-4"}`}
                      style={{
                        // borderBottom: "1px solid #DCDCDC",
                        backgroundColor:
                          tabIndex === idx
                            ? "#921D1E"
                            : tabIndexFromDb >= idx
                              ? "#0B633612"
                              : "",
                        color:
                          tabIndex === idx
                            ? "#fff"
                            : tabIndexFromDb >= idx
                              ? "#0B6336"
                              : "#000",
                        border:
                          tabIndex === idx
                            ? "1px solid #921D1E00"
                            : tabIndexFromDb >= idx
                              ? "1px solid #0B6336"
                              : "1px solid #921D1E1A",
                        pointerEvents: tabIndexFromDb >= idx ? "" : "none",
                      }}
                      key={idx}
                      onClick={() => handleTabClick(idx)}
                    >
                      {/* Conditionally render tick mark next to formTab in mobile view */}
                      <div style={{
                        display: isMobile ? "flex" : "block",
                        justifyContent: isMobile ? "space-between" : "flex-start",
                        alignItems: "center",
                        width: "100%"
                      }}>
                        {item?.formTab}
                        {isMobile && tabIndexFromDb >= idx && <span>✔</span>}
                      </div>
                    </Tab>
                  ))}
                  {/* <Tab
                    className={`${isMobile ? "px-2 py-2" : "mb-4"}`}
                    // style={{ borderBottom: "1px solid #DCDCDC" }}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: tabIndex === "all_Document_Review" ? "green" : "",
                      color: tabIndex === "all_Document_Review" ? "#fff" : "#000"
                    }}
                    key={"all_Document_Review"}
                    // onClick={() => handleTabClick("review")}
                  >
                    {"ALL DOCUMENT REVIEW"}
                  </Tab> */}
                  <Tab
                    className={`${isMobile ? "px-2 py-2" : "mb-4"}`}
                    // style={{ borderBottom: "1px solid #DCDCDC" }}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: tabIndex === "review" ? "green" : "",
                      color: tabIndex === "review" ? "#fff" : "#000"
                    }}
                    key={"review"}
                    onClick={() => handleTabClick("review")}
                  >
                    {"APPLICATION REVIEW"}
                  </Tab>

                </TabList>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                // height:"90vh",
                // overflow: "scroll",
              }}
            >
              {applicationForm?.map((item, index) => {
                return (
                  <>
                    <TabPanel key={index}>
                      <Card className="pt-2 pb-2 mt-2 top-card-dynamic shadow-sm">
                        <ul className="d-flex justify-content-between align-items-center" style={{ listStyleType: "none", padding: 0 }}>
                          {applicationForm?.map((item, idx) => {
                            const isCompleted = tabIndex > idx;
                            const isActive = tabIndex === idx;

                            return (
                              <li
                                key={idx}
                                className="text-center dynamicFormBulletsWrap"
                                style={{
                                  color: isCompleted || isActive ? "rgba(0, 0, 0, 1)" : "gray", // Text color 
                                }}
                              >
                                <div
                                  className="dynamicFormBulletsTick"
                                  style={{
                                    backgroundColor: isCompleted ? "green" : isActive ? "brown" : "gray", // Color based on status
                                  }}
                                >
                                  ✓
                                </div>
                                <span className="dynamicFormBulletsFont" style={{ fontWeight: isActive ? "bold" : "normal" , fontSize:'12px'}}>{item?.formTab}</span>
                                {idx !== applicationForm.length - 1 && (
                                  <div
                                    className="dynamicFormBulletsText"
                                    style={{
                                      backgroundColor: isCompleted ? "lightgray" : "lightgray",
                                    }}
                                  />
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </Card>
                      <div className={"captureErrorFor_" + index} id="main-form-dynamic">
                        {/* Heading Of Each Page on Tabs */}
                        <div className="applyJobMainHead d-flex align-items-center gap-2">
                          <div
                            className="cursor-pointer d-md-none toggle-icon-form"
                            onClick={toggleMenu}
                          >
                            <i class="bi bi-list"></i>
                          </div>
                          <h5 className="applyJobMainHeadFont">
                            {item?.formTab}
                          </h5>
                        </div>

                        <Row className="m-0">
                          {
                            tabIndex === 3 && <>
                              <div className="custom-card-tabMain">
                                <Card className="mb-2 dynamicFormcards card">
                                  <div class="row">
                                    <label class="form-label form-heading-main">Note</label>
                                    <div class="mb-2 col-12">
                                      {/* <label class="inputBoxHeadForm upload-head-gap mt-2" >NOTE</label> */}
                                      <div class="d-flex flex-wrap inputBoxHeadForm">
                                        <div style={{ fontFamily: "'Roboto', sans-serif", color: "brown" }} className="fw-bolder mb-3">
                                          <p>
                                            <span >If you have any Additional Qualification kindly click "Add New" Button to provide the details. If you dont have any Additional Qualification you can simply click "Save & Next" to continue.
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Card>

                              </div>
                            </>
                          }

                          {
                            tabIndex === 5 && <>
                              <div className="custom-card-tabMain">
                                <Card className="mb-2 dynamicFormcards card">
                                  <div class="row">
                                    <label class="form-label form-heading-main">Note</label>
                                    <div class="mb-2 col-12">
                                      {/* <label class="inputBoxHeadForm upload-head-gap mt-2" >NOTE</label> */}
                                      <div class="d-flex flex-wrap inputBoxHeadForm">
                                        <div>

                                          <ol style={{ fontFamily: "'Roboto', sans-serif", color: "brown" }} className="fw-bolder mb-3">
                                            <li>
                                              1. If you have any Experience kindly click "Add New" Button to provide the details. If you don't have any Experience you can simply click "Save & Next" to continue.
                                            </li>
                                            <li>
                                              2. Please list your latest experience first, followed by the rest in chronological order.

                                            </li>
                                            <li>
                                              3. Experience as a trainee will not be considered as part of total or relevant experience
                                            </li>
                                            <li>
                                              4. Ensure that the total service period provided in post-wise details matches the total experience for the organization.
                                            </li>
                                            <li>
                                              5. Use the "Add Post Wise Details" button under post-wise experience to include multiple posts or designations held in the same organization during your mentioned experience period.
                                            </li>
                                            <li>
                                              6. Use the "Add Relevant Experience" button under the Relevant Experience section to include relevant experience(s) within the same organization during your mentioned experience period.
                                            </li>
                                          </ol>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Card>

                              </div>
                            </>
                          }

                          {item?.isArray ? (
                            dynamicFields?.map((fieldSet, indexOfFieldSet) => {
                              return (
                                <>
                                  {fieldSet?.tab === camelCase(item?.formTab) && (
                                    <div className="custom-card-tabMain">

                                      <Card className="mt-2 mb-0 dynamicFormcards">
                                        {/* {console.log({ fieldSet })} */}
                                        {/* <Col key={idx} xs={input.values.columnLength} className=""> */}
                                        <Row key={fieldSet.id} className="mt-3">

                                          {fieldSet?.fields.map(
                                            (field, fieldIndex) =>
                                              renderArrayFields(
                                                field,
                                                fieldIndex,
                                                item,
                                                fieldSet
                                              )
                                          )}
                                          <div className="text-end">
                                            <Button
                                              className="btn btn-danger my-1"
                                              onClick={() =>
                                                handleDeleteFieldSet(fieldSet, indexOfFieldSet)
                                              }
                                            >
                                              Delete
                                            </Button>
                                          </div>
                                        </Row>
                                        {/* </Col> */}
                                      </Card>
                                    </div>
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <div className="">
                              <Row>
                                {/* <Col sm="0" md="2" >

                            </Col> */}
                                <div>
                                  <Col sm="12" md="12" className="">
                                    <div className="custom-card-tabMain">
                                      {renderNonArrayFields(item)}
                                    </div>

                                  </Col>
                                </div>
                                {/* <Col sm="0" md="2" >

                            </Col> */}
                              </Row>
                            </div>
                          )}

                          {/* Additional logic for "Education Details" tab */}

                          {/* Conditionally display Add button based on formTab */}
                          {item?.isArray && (
                            <div className="text-center my-5 ">
                              {/* {handleBlankAddFieldSet(item?.values, item?.formTab, index)
                              }                               */}
                              <Button
                                className="bottom-card-submit-button registrationFont mt-4 "
                                onClick={() =>
                                  handleAddFieldSet(item?.values, item?.formTab, index)
                                }


                              >
                                Add New
                              </Button>
                            </div>
                          )}
                        </Row>
                      </div>
                    </TabPanel>
                  </>
                )
              })}
              {/* <TabPanel key={"all_Document_Review"}>
            <div className="d-flex align-items-center justify-content-center">
              <img src={paymentSuccess} alt="" />
            </div>
              </TabPanel> */}
              <TabPanel key={"review"}>
                <div className="applyJobMainHead d-flex align-items-center gap-2">
                  <div
                    className="cursor-pointer d-md-none"
                    onClick={toggleMenu}
                  >
                    <i class="bi bi-list"></i>
                  </div>
                  <h5 className="applyJobMainHeadFont">
                    {"APPLICATION REVIEW"}
                  </h5>
                </div>
                <div
                  style={{
                    width: '100%',
                    overflowX: 'auto',
                  }}
                >
                  <div ref={printRef} id="printDiv_1" className="pt-3"
                    style={{
                      minWidth: '795px',
                      margin: '0 auto'
                    }}
                  >

                    {applicationForm?.map((item, index) => {
                      return (
                        <>
                          <div>
                            {/* <TabPanel key={index}> */}
                            {/* Heading Of Each Page on Tabs */}

                            <Row className="m-0">
                              {item?.isArray ?
                                renderTableOfIsArray(item)
                                : (
                                  <div className="">
                                    <Row>
                                      <div className={item?.formTab === "ESSENTIAL QUALIFICATION" ? "ps-0 pe-0" : ""}>
                                        <Col xs="12" className="">
                                          <div className="custom-card-tabMain">
                                            <div className="p-0 m-0">
                                              {item?.formTab === "ESSENTIAL QUALIFICATION" ?
                                                renderTableForNonArrayData(item?.values)
                                                : renderNonArrayFields(item)}
                                            </div>

                                          </div>
                                        </Col>
                                      </div>
                                    </Row>
                                  </div>
                                )}

                            </Row>
                            
                          </div>
                          {/* </TabPanel> */}
                        </>
                      )
                    }

                    )}

<Card className="mt-2 mb-0 dynamicFormcards ms-3 me-3" style={{ width: '97%' }}>
                        <Row className="mt-3">
                          <label className="form-label form-heading-main" style={{ backgroundColor: "rgb(146, 29, 30)", color: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', padding: '10px' }}>Total Experience</label>
                        </Row>
                        <div>
                          <table bordered
                            className="specific-table"
                            style={{ overflow: "hidden", width: "98%", tableLayout: "fixed" }}>
                            <thead>
                              <tr style={{
                                border: '1px solid black',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                fontWeight: 'bold'
                              }}>
                                <th>Name of Organization</th>
                                <th>Type of Organization</th>
                                <th>Type of Service</th>
                                <th>Status of Service</th>
                                <th>Nature of Business</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Total Experience</th>
                              </tr>
                            </thead>
                            <tbody>
                              {applicationDetails?.newStaticExperience?.map((entry, index) => (
                                <tr key={index}>
                                  <td>{entry?.nameOfOrganization}</td>
                                  <td>{entry?.typeOfOrganization}</td>
                                  <td>{entry?.typeOfService}</td>
                                  <td>{entry?.statusOfService}</td>
                                  <td>{entry?.natureOfBusiness}</td>
                                  <td>{entry?.startDateOfService}</td>
                                  <td>{entry?.endDateOfService}</td>
                                  <td>{entry?.totalExperience}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        <div className="mt-3">
                          <p className="fw-bold">Total Experience (excluding trainee period):
                            <span style={{ color: "brown" }}>{applicationDetails?.finalSummeryExp?.total_postWiseTotalExperienceWithoutTrainee}</span>
                          </p>
                          <p className="fw-bold">Refer to Annexure I for post-wise experience details.</p>
                        </div>

                        <div className="mt-3">
                          <p className="fw-bold">Total Relevant Experience (excluding trainee period):
                           <span style={{ color: "brown" }}>{applicationDetails?.finalSummeryExp?.total_relevantTotalExperienceWithoutTrainee}</span>
                          </p>
                          <p className="fw-bold">Refer to Annexure II for relevant experience details.</p>
                        </div>

                        <div className="mt-3">
                          <p className="fw-bold">Annexure I: Post-wise Experience Details</p>
                          <table bordered
                            className="specific-table"
                            style={{ overflow: "hidden", width: "98%", tableLayout: "fixed" }}>
                            <thead>
                              <tr style={{
                                border: '1px solid black',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                fontWeight: 'bold'
                              }}>
                                <th>Name of Organization</th>
                                <th>Is Trainee</th>
                                <th>Designation</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Total Experience</th>
                              </tr>
                            </thead>
                            <tbody>
                              {applicationDetails?.newStaticExperience?.flatMap((entry, index) =>
                                entry?.postWiseDetails.map((post, postIndex) => (
                                  <tr key={`${index}-${postIndex}`}>
                                    <td>{entry?.nameOfOrganization}</td>
                                    <td>{post?.isTrainee}</td>
                                    <td>{post?.designation}</td>
                                    <td>{post?.startDateOfService}</td>
                                    <td>{post?.endDateOfService}</td>
                                    <td>{post?.totalExperience}</td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className="mt-3">
                          <p className="fw-bold">Annexure II: Relevant Experience Details</p>
                          <table bordered
                            className="specific-table"
                            style={{ overflow: "hidden", width: "98%", tableLayout: "fixed" }}>
                            <thead>
                              <tr style={{
                                border: '1px solid black',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                fontWeight: 'bold'
                              }}>
                                <th>Name of Organization</th>
                                <th>Is Trainee</th>
                                <th>Nature of Job Handled</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Total Experience</th>
                              </tr>
                            </thead>
                            <tbody>
                              {applicationDetails?.newStaticExperience?.flatMap((entry, index) =>
                                entry?.relevantExperience.map((post, postIndex) => (
                                  <tr key={`${index}-${postIndex}`}>
                                    <td>{entry?.nameOfOrganization}</td>
                                    <td>{post?.isTrainee}</td>
                                    <td>{post?.natureOfJobHandled}</td>
                                    <td>{post?.startDateOfService}</td>
                                    <td>{post?.endDateOfService}</td>
                                    <td>{post?.totalExperience}</td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Card>
                                            <Card className="mt-2 mb-0 dynamicFormcards ms-3 me-3" style={{ width: '97%' }}>
                        <Row className="mt-3">
                          <label className="form-label form-heading-main" style={{ backgroundColor: "rgb(146, 29, 30)", color: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', padding: '10px' }}>Documents Review</label>
                          <div>
                            <Col xs="12" className="">
                              <div className="custom-card-tabMain">
                                <div className="p-0 m-0">
                                  <Table bordered
                                    className="specific-table"
                                    style={{ overflow: "hidden", width: "98%", tableLayout: "fixed" }}>
                                    <thead>
                                      <tr>
                                        <th style={{
                                          border: '1px solid black',
                                          whiteSpace: 'normal',
                                          wordWrap: 'break-word',
                                          fontWeight: 'bold'

                                        }}>File Name</th>
                                        <th style={{
                                          border: '1px solid black',
                                          whiteSpace: 'normal',
                                          wordWrap: 'break-word',
                                          fontWeight: 'bold',
                                          textAlign: 'center'

                                        }}>Is Uploaded</th>
                                        <th
                                          style={{
                                            border: '1px solid black',
                                            whiteSpace: 'normal',
                                            wordWrap: 'break-word',
                                            fontWeight: 'bold',
                                            textAlign: 'center'

                                          }}
                                        >View</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        {applicationDetails.sportCertificateFile && (
                                          <>

                                            <td>Sport Certificate</td>
                                            <td className="docTableIcon">

                                              <IoCheckmarkCircleOutline color="green" size={"22"} />

                                            </td>

                                          </>
                                        )}
                                        {applicationDetails.sportCertificateFile && (
                                          <td className="docTableIcon">
                                            <div
                                              style={{ marginLeft: "0px" }}
                                              variant="outline"
                                              onClick={() =>
                                                handleViewFile(
                                                  "sportCertificateFile",
                                                  applicationDetails.sportCertificateFile
                                                )
                                              }
                                            >
                                              <IoEyeOutline size={"25"} />
                                            </div>
                                          </td>
                                        )}
                                      </tr>
                                      <tr>

                                        {applicationDetails.reservationCertificateFile && (

                                          <>
                                            <td>Reservation Certificate</td>
                                            <td className="docTableIcon">
                                              <IoCheckmarkCircleOutline color="green" size={"22"} />
                                            </td>
                                          </>
                                        )}
                                        {applicationDetails.reservationCertificateFile && (
                                          <td className="docTableIcon">
                                            <div
                                              style={{ marginLeft: "0px" }}
                                              variant="outline"
                                              onClick={() =>
                                                handleViewFile(
                                                  "reservationCertificateFile",
                                                  applicationDetails.reservationCertificateFile
                                                )
                                              }
                                            >
                                              <IoEyeOutline size={"25"} />
                                            </div>
                                          </td>
                                        )}
                                      </tr>
                                      <tr>
                                        {applicationDetails["6719d53d3ac83738a0ba587a"] && (
                                          <>
                                            <td>Proof of CGPA to percentage conversion Formula for 10th</td>
                                            <td className="docTableIcon">
                                              <IoCheckmarkCircleOutline color="green" size={"22"} />
                                            </td>
                                          </>
                                        )}
                                        {applicationDetails["6719d53d3ac83738a0ba587a"] && (
                                          <td className="docTableIcon">
                                            <div
                                              style={{ marginLeft: "0px" }}
                                              variant="outline"
                                              onClick={() =>
                                                handleViewFile(
                                                  "6719d53d3ac83738a0ba587a",
                                                  applicationDetails["6719d53d3ac83738a0ba587a"]
                                                )
                                              }
                                            >
                                              <IoEyeOutline size={"25"} />
                                            </div>
                                          </td>
                                        )}
                                      </tr>
                                      {applicationDetails["670573c622eac4495862458b"] &&
                                        applicationDetails["670573c622eac4495862458b"]
                                          .split(",") // Split the URLs by comma
                                          .map((file, index) => {
                                            // Extract the file name from the URL's query string (?name=)
                                            const fileName = new URL(file).searchParams.get("name") || "File";
                                            return (
                                              <tr key={index}>
                                                <td>{fileName}</td>
                                                <td className="docTableIcon">
                                                  <IoCheckmarkCircleOutline color="green" size={"22"} />
                                                </td>
                                                <td className="docTableIcon">
                                                  <div
                                                    style={{ marginLeft: "0px" }}
                                                    variant="outline"
                                                    onClick={() => handleViewFile("670573c622eac4495862458b", file)}
                                                  >
                                                    <IoEyeOutline size={"25"} />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}

                                      {applicationDetails["670573c622eac4495862458d"] &&
                                        applicationDetails["670573c622eac4495862458d"]
                                          .split(",") // Split the URLs by comma
                                          .map((file, index) => {
                                            // Extract the file name from the URL's query string (?name=)
                                            const fileName = new URL(file).searchParams.get("name") || "File";
                                            return (
                                              <tr key={index}>
                                                <td>{fileName}</td>
                                                <td className="docTableIcon">
                                                  <IoCheckmarkCircleOutline color="green" size={"22"} />
                                                </td>
                                                <td className="docTableIcon">
                                                  <div
                                                    style={{ marginLeft: "0px" }}
                                                    variant="outline"
                                                    onClick={() => handleViewFile("670573c622eac4495862458d", file)}
                                                  >
                                                    <IoEyeOutline size={"25"} />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                      <tr>
                                        {applicationDetails["6719d8603ac83738a0ba5fd7"] && (
                                          <>
                                            <td>Proof of CGPA to percentage conversion Formula for 12th</td>
                                            <td className="docTableIcon">
                                              <IoCheckmarkCircleOutline color="green" size={"22"} />
                                            </td>
                                          </>
                                        )}
                                        {applicationDetails["6719d8603ac83738a0ba5fd7"] && (
                                          <td className="docTableIcon">
                                            <div
                                              style={{ marginLeft: "0px" }}
                                              variant="outline"
                                              onClick={() =>
                                                handleViewFile(
                                                  "6719d8603ac83738a0ba5fd7",
                                                  applicationDetails["6719d8603ac83738a0ba5fd7"]
                                                )
                                              }
                                            >
                                              <IoEyeOutline size={"25"} />
                                            </div>
                                          </td>
                                        )}
                                      </tr>

                                      {applicationDetails["670573c622eac4495862458f"] &&
                                        applicationDetails["670573c622eac4495862458f"]
                                          .split(",") // Split the URLs by comma
                                          .map((file, index) => {
                                            const fileName = new URL(file).searchParams.get("name") || "File";
                                            return (
                                              <tr key={`cert-${index}`}>
                                                <td>{fileName}</td>
                                                <td className="docTableIcon">
                                                  <IoCheckmarkCircleOutline color="green" size={"22"} />
                                                </td>
                                                <td className="docTableIcon">
                                                  <div
                                                    style={{ marginLeft: "0px" }}
                                                    variant="outline"
                                                    onClick={() => handleViewFile("670573c622eac4495862458f", file)}
                                                  >
                                                    <IoEyeOutline size={"25"} />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}

                                      {applicationDetails["670573c622eac44958624591"] &&
                                        applicationDetails["670573c622eac44958624591"]
                                          .split(",") // Split the URLs by comma
                                          .map((file, index) => {
                                            const fileName = new URL(file).searchParams.get("name") || "File";
                                            return (
                                              <tr key={`marksheet-${index}`}>
                                                <td>{fileName}</td>
                                                <td className="docTableIcon">
                                                  <IoCheckmarkCircleOutline color="green" size={"22"} />
                                                </td>
                                                <td className="docTableIcon">
                                                  <div
                                                    style={{ marginLeft: "0px" }}
                                                    variant="outline"
                                                    onClick={() => handleViewFile("670573c622eac44958624591", file)}
                                                  >
                                                    <IoEyeOutline size={"25"} />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}




                                      <tr>
                                        {applicationDetails["6719d9653ac83738a0ba6155"] && (
                                          <>
                                            <td>Proof of CGPA to percentage conversion Formula for Graduation</td>
                                            <td className="docTableIcon">
                                              <IoCheckmarkCircleOutline color="green" size={"22"} />
                                            </td>
                                          </>
                                        )}
                                        {applicationDetails["6719d9653ac83738a0ba6155"] && (
                                          <td className="docTableIcon">
                                            <div
                                              style={{ marginLeft: "0px" }}
                                              variant="outline"
                                              onClick={() =>
                                                handleViewFile(
                                                  "6719d9653ac83738a0ba6155",
                                                  applicationDetails["6719d9653ac83738a0ba6155"]
                                                )
                                              }
                                            >
                                              <IoEyeOutline size={"25"} />
                                            </div>
                                          </td>
                                        )}
                                      </tr>
                                      {applicationDetails["670573c622eac44958624593"] &&
                                        applicationDetails["670573c622eac44958624593"]
                                          .split(",") // Split URLs by commas
                                          .map((file, index) => {
                                            const fileName = new URL(file).searchParams.get("name") || "File";
                                            return (
                                              <tr key={`grad-cert-${index}`}>
                                                <td>{fileName}</td>
                                                <td className="docTableIcon">
                                                  <IoCheckmarkCircleOutline color="green" size={"22"} />
                                                </td>
                                                <td className="docTableIcon">
                                                  <div
                                                    style={{ marginLeft: "0px" }}
                                                    variant="outline"
                                                    onClick={() => handleViewFile("670573c622eac44958624593", file)}
                                                  >
                                                    <IoEyeOutline size={"25"} />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}

                                      {applicationDetails["670573c622eac44958624595"] &&
                                        applicationDetails["670573c622eac44958624595"]
                                          .split(",") // Split URLs by commas
                                          .map((file, index) => {
                                            const fileName = new URL(file).searchParams.get("name") || "File";
                                            return (
                                              <tr key={`grad-marksheet-${index}`}>
                                                <td>{fileName}</td>
                                                <td className="docTableIcon">
                                                  <IoCheckmarkCircleOutline color="green" size={"22"} />
                                                </td>
                                                <td className="docTableIcon">
                                                  <div
                                                    style={{ marginLeft: "0px" }}
                                                    variant="outline"
                                                    onClick={() => handleViewFile("670573c622eac44958624595", file)}
                                                  >
                                                    <IoEyeOutline size={"25"} />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                     {applicationDetails["additionalQualification"] &&
  applicationDetails["additionalQualification"].map((qualification, qualIndex) => (
    qualification.certificates &&

          <tr key={`grad-cert-${qualIndex}`}>
            <td>Addl. Qualification Certificate</td>
            <td className="docTableIcon">
              <IoCheckmarkCircleOutline color="green" size={"22"} />
            </td>
            <td className="docTableIcon">
              <div
                style={{ marginLeft: "0px" }}
                variant="outline"
                onClick={() => handleViewFile(qualification.id || `qual-${qualIndex}`, "certificates")}
              >
                <IoEyeOutline size={"25"} />
              </div>
            </td>
          </tr>

  ))}     

{applicationDetails["additionalQualification"] &&
  applicationDetails["additionalQualification"].map((qualification, qualIndex) => (
    qualification.markSheets &&
    qualification.markSheets
      .split(",") // Split URLs by commas
      .map((file, index) => {
        const fileName = new URL(file).searchParams.get("name") || "File";
        return (
          <tr key={`grad-cert-${qualIndex}-${index}`}>
            <td>{fileName}</td>
            <td className="docTableIcon">
              <IoCheckmarkCircleOutline color="green" size={"22"} />
            </td>
            <td className="docTableIcon">
              <div
                style={{ marginLeft: "0px" }}
                variant="outline"
                onClick={() => handleViewFile(qualification.id || `qual-${qualIndex}`, file)}
              >
                <IoEyeOutline size={"25"} />
              </div>
            </td>
          </tr>
        );
      })
  ))}






                                      {applicationDetails["6704ff8c5d92c272e0e9f2a1"] &&
                                        applicationDetails["6704ff8c5d92c272e0e9f2a1"]
                                          .split(",") // Split URLs by commas
                                          .map((file, index) => {
                                            const fileName = new URL(file).searchParams.get("name") || "File";
                                            return (
                                              <tr key={`grad-cert-${index}`}>
                                                <td>{fileName}</td>
                                                <td className="docTableIcon">
                                                  <IoCheckmarkCircleOutline color="green" size={"22"} />
                                                </td>
                                                <td className="docTableIcon">
                                                  <div
                                                    style={{ marginLeft: "0px" }}
                                                    variant="outline"
                                                    onClick={() => handleViewFile("6704ff8c5d92c272e0e9f2a1", file)}
                                                  >
                                                    <IoEyeOutline size={"25"} />
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}

                                      {applicationDetails?.newStaticExperience &&
                                        applicationDetails?.newStaticExperience?.map((item) =>
                                          item?.proofOfExperienceDocument.map((expData) => (
                                            <tr key={expData?.customData}>
                                              <td>{expData?.customData}</td>
                                              <td className="docTableIcon">
                                                <IoCheckmarkCircleOutline color="green" size={"22"} />
                                              </td>
                                              <td className="docTableIcon">
                                                <div
                                                  style={{ marginLeft: "0px" }}
                                                  variant="outline"
                                                  onClick={() =>
                                                    handleViewFile("fileUrl", expData["fileUrl"])
                                                  }
                                                >
                                                  <IoEyeOutline size={"25"} />
                                                </div>
                                              </td>
                                            </tr>
                                          ))
                                        )}



                                    </tbody>
                                  </Table>
                                  {showIframe && (
                                    <Modal
                                      size="lg"
                                      show={showIframe === camelCaseKey}
                                      onHide={() => setShowIframe(false)}
                                      centered
                                      aria-labelledby="example-custom-modal-styling-title"
                                    >
                                      <Modal.Body className="text-center">
                                        <iframe
                                          src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(
                                            fieldValue
                                          )}`}
                                          width="100%"
                                          style={{ border: "none", height: "80vh" }}
                                          title="PDF Viewer"
                                        />
                                        {/* {!applicationDetails[camelCaseKey + "verified"] && (
                                          <Button
                                            className="mt-2"
                                            style={{ borderRadius: "10px" }}
                                            onClick={() => {
                                              setApplicationDetails({
                                                ...applicationDetails,
                                                [`${camelCaseKey}verified`]: true,
                                              });
                                              setShowIframe(false);
                                            }}
                                          >
                                            {"Confirm Verification"}
                                          </Button>
                                        )} */}
                                        <Button
                                          className="mt-2"
                                          style={{ borderRadius: "10px" }}
                                          variant="secondary"
                                          onClick={() => setShowIframe(false)}
                                        >
                                          Close
                                        </Button>
                                      </Modal.Body>
                                    </Modal>
                                  )}
                                </div>

                              </div>
                            </Col>
                          </div>
                        </Row>
                      </Card>

{/* <Declaration /> */}

<Card className="mt-2 mb-0 dynamicFormcards ms-3 me-3" style={{ width: "97%" }} id="declarationCheck">
                        <Row className="mt-3 align-items-center">
                        <Col
  xs={12}
  className="d-flex align-items-start"
  style={{ justifyContent: "flex-start", textAlign: "left" }}
>
  {/* Toggle switch */}
  <Form.Check
    type="checkbox"
    onChange={handleCheckboxChangeDeclare}
    checked={applicationDetails.finalSubmitDeclaration}
    style={{ marginRight: "10px" }} // Add a margin here for spacing
  />
  <label
    htmlFor="declaration"
    style={{ fontSize: "14px" }}
  >
    I <b>{applicationDetails["applicantSFullName"]}</b> Son/Daughter/Wife of <b>{applicationDetails["66a4cb29fc50e50a0b885535"]}</b> do hereby declare that all the statements made in the application are true and correct to the best of my knowledge and belief. In the event of any information being found false, my candidature/appointment is liable to be cancelled/terminated without any notice to me.
  </label>
</Col>



                        </Row>
                        <Row className="mt-3 align-items-center">
                        <Col xs={12} className="text-end">
                            {applicationDetails["66cb753a8d303737cc78cd5d"] && (
                              <img
                                src={applicationDetails["66cb753a8d303737cc78cd5d"]}
                                alt="Sign"
                                style={{ width: "100px", height: "auto", cursor: "pointer" }}
                              />
                            )}
                            <p className="fw-bolder">Candidate Signature</p>

                          </Col>
                        </Row>
                      </Card>
                  </div>
                  <div>
                    {/* <TabPanel key={index}> */}
                    {/* Heading Of Each Page on Tabs */}

                    <Row className="m-0">



                      {/* <Card className="mt-2 mb-0 dynamicFormcards ms-3 me-3" style={{ width: "97%" }} id="signature">
                        <Row className="mt-3 align-items-center">
                        <Col xs={12} className="text-end">
                            <p className="fw-bolder">Signature</p>
                            {applicationDetails["66cb753a8d303737cc78cd5d"] && (
                              <img
                                src={applicationDetails["66cb753a8d303737cc78cd5d"]}
                                alt="Sign"
                                style={{ width: "100px", height: "auto", cursor: "pointer" }}
                              />
                            )}
                          </Col>
                        </Row>
                      </Card> */}





                      {/* Additional logic for "Education Details" tab */}

                      {/* Conditionally display Add button based on formTab */}
                    </Row>
                  </div>
                </div>
              </TabPanel>

              <Row className="me-0">
                <div className="text-center my-2">
                  <Card className="mb-2 ms-3 pt-2 me-2 dynamicFormcards d-flex align-items-center flex-row justify-content-end ">
                    <Button
                      className="bottom-card-button registrationFont ms-5 mt-4"
                      onClick={() => setTabIndex(Math.max(0, tabIndex - 1))}
                      disabled={tabIndex === 0}
                    >
                      Back
                    </Button>
                    {tabIndex === applicationForm?.length ? (
                      <>
                        <ReactToPrint
                          trigger={() => (
                            <Button className="bottom-card-submit-button registrationFont mt-4">
                              Print
                            </Button>
                          )}
                          content={() => printRef.current}
                        />

                        <Button
                          className="bottom-card-submit-button registrationFont mt-4"
                          onClick={() => {
                            setFinalSubmitModal(true);
                          }}
                        >
                          Submit Application
                        </Button>

                        {finalSubmitModal && (
                          <FinalFormSubmitModal
                            handleSubmitForm={handleSubmitForm}
                            finalSubmitModal={finalSubmitModal}
                            setFinalSubmitModal={setFinalSubmitModal}
                          />
                        )}
                      </>
                    ) : (
                      <Button
                        className="bottom-card-submit-button registrationFont mt-4 "
                        disabled={saveAndNextLoader}
                        onClick={() => {
                          handleSubmitForm("Save", false);
                        }}
                      >
                        {saveAndNextLoader ? (
                          <>
                            <Spinner size="sm" /> Validating
                          </>
                        ) : (
                          "Save & Next"
                        )}
                      </Button>
                    )}
                  </Card>
                </div>
              </Row>
            </div>
          </Tabs>
        </div>
      )}
      {/* Bottom Card */}

      <div className='custom-footer'>
        <p className='custom-footer-text'>© 2024 Odisha Mining Corporation Ltd. (O M C LTD)</p>
      </div>

      {/* <Footer /> */}
      <Gotop />
      {showErrorModal && <Modal show={showErrorModal} onHide={handleModalClose} centered>
        <Modal.Header style={{ background: '#921D1E', color: 'white' }}>
          <div className='d-flex align-items-center justify-content-center w-100'>
            <img src={waringSign} alt="warn" />
          </div>
          {/* <Modal.Title></Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <h2 style={{ color: '#921D1E', fontSize: '28px', fontFamily: 'Roboto', textAlign: 'center' }}>Eligibility Errors</h2>
          {/* <p style={{ fontSize:'16px',marginTop:'20px', fontFamily:'Roboto', textAlign:'center'}}>

        </p> */}
          {errorFromBackend?.ageEligibilityError?.message && (
            <p style={{ fontSize: '16px', marginTop: '20px', fontFamily: 'Roboto', textAlign: 'center' }}>
              {errorFromBackend.ageEligibilityError.message}
            </p>
          )}
          {errorFromBackend?.experienceEligibilityError?.message && (
            <p style={{ fontSize: '16px', marginTop: '20px', fontFamily: 'Roboto', textAlign: 'center' }}>
              {errorFromBackend.experienceEligibilityError.message}
            </p>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="primary" onClick={handleModalClose} style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Roboto', textAlign: 'center', border: '1px solid #DEBBBB', color: '#921D1E', background: '#F9F3F4' }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>}
    </>
  );
};

export default DynamicApplicationFrom;