// PostWiseDetailEntry.js
import React, { useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import InputField from "./InputField";
import { RiDeleteBin6Line } from "react-icons/ri";

const PostWiseDetailEntry = ({
  entry,
  outerIndex,
  innerIndex,
  handleInputChange,
  deleteFormEntry,
  fieldErrors,
  style,
  checkErrorFn,
  parentStartDate,
  parentEndDate,
  setFieldErrors,
  calculateTotalExperience
}) => {


  useEffect(() => {
    const totalExperience = calculateTotalExperience(
      entry.startDateOfService,
      entry.endDateOfService
    );
    handleInputChange(outerIndex, innerIndex, "totalExperience", totalExperience);
    
  }, [entry.startDateOfService, entry.endDateOfService,]);


  const isDateWithinRange = (date, minDate, maxDate) => {
    const dateValue = new Date(date);
    const min = new Date(minDate);
    const max = new Date(maxDate);
    return dateValue >= min && dateValue <= max;
  };

  const localCheckErrorFn = (fieldKey, immediateValue) => {
    let errors = [];
    if (!immediateValue) {
      errors.push("This field is required.");
    } else if (
      (fieldKey.includes("startDateOfService") ||
        fieldKey.includes("endDateOfService")) &&
      !isDateWithinRange(immediateValue, parentStartDate, parentEndDate)
    ) {
      errors.push(
        `Date must be between ${parentStartDate} and ${parentEndDate}.`
      );
    }
    setFieldErrors((prevState) => ({
      ...prevState,
      [fieldKey]: {
        errors: errors,
        touched: true,
      },
    }));
    return errors.length === 0;
  };



  
  return (
    <Row> <hr className="mt-2"/>
              <Row>
        <Col xs={12} md={4}>
          <InputField
            label="As Trainee:"
            type="select"
            value={entry.isTrainee}
            onChange={(e) => {
              const value = e.target.value;
              handleInputChange(outerIndex, innerIndex, "isTrainee", value);
              checkErrorFn(`isTrainee${outerIndex}${innerIndex}`, value);
            }}
            onBlur={() =>
              checkErrorFn(
                `isTrainee${outerIndex}${innerIndex}`,
                entry.isTrainee
              )
            }
            error={fieldErrors?.[`isTrainee${outerIndex}${innerIndex}`]?.errors}
            style={style}
            required
            options={[
              { value: "Yes", label: "Yes" },
              { value: "No", label: "No" },
            ]}
          />
        </Col>
        <Col xs={12} md={8}>
          <InputField
            label="Designation:"
            value={entry.designation}
            onChange={(e) => {
              const value = e.target.value;
              handleInputChange(outerIndex, innerIndex, "designation", value);
              checkErrorFn(`designation${outerIndex}${innerIndex}`, value);
            }}
            onBlur={() =>
              checkErrorFn(
                `designation${outerIndex}${innerIndex}`,
                entry.designation
              )
            }
            error={
              fieldErrors?.[`designation${outerIndex}${innerIndex}`]?.errors
            }
            style={style}
            required
          />
        </Col>
      </Row>
    <Row className="mt-2">
      <Col xs={12} md={4}>
        <InputField
          label="From:"
          type="date"
          value={entry.startDateOfService}
          onChange={(e) => {
            const value = e.target.value;
            handleInputChange(
              outerIndex,
              innerIndex,
              "startDateOfService",
              value
            );
            localCheckErrorFn(
              `startDateOfService${outerIndex}${innerIndex}`,
              value
            );
            checkErrorFn(
              `startDateOfService${outerIndex}${innerIndex}`,
              value
            )
          }}
          onBlur={() =>
          {  localCheckErrorFn(
              `startDateOfService${outerIndex}${innerIndex}`,
              entry.startDateOfService
            );            checkErrorFn(
              `startDateOfService${outerIndex}${innerIndex}`,
              entry.startDateOfService
            )}
          }
          error={
            fieldErrors?.[
              `startDateOfService${outerIndex}${innerIndex}`
            ]?.errors
          }
          style={style}
          required
          min={parentStartDate}
          max={parentEndDate}
        />
      </Col>
      {/* To Date */}
      <Col xs={12} md={4}>
        <InputField
          label="To:"
          type="date"
          value={entry.endDateOfService}
          onChange={(e) => {
            const value = e.target.value;
            handleInputChange(
              outerIndex,
              innerIndex,
              "endDateOfService",
              value
            );
            localCheckErrorFn(
              `endDateOfService${outerIndex}${innerIndex}`,
              value
            );
            checkErrorFn(
              `endDateOfService${outerIndex}${innerIndex}`,
              value
            )
          }}
          onBlur={() =>{
            localCheckErrorFn(
              `endDateOfService${outerIndex}${innerIndex}`,
              entry.endDateOfService
            );
            checkErrorFn(
              `endDateOfService${outerIndex}${innerIndex}`,
              entry.endDateOfService
            )}
          }
          error={
            fieldErrors?.[`endDateOfService${outerIndex}${innerIndex}`]?.errors
          }
          style={style}
          required
          min={parentStartDate}
          max={parentEndDate}
        />
      </Col>

        <Col xs={12} md={4}>
          <InputField
            label="Total Experience:"
            value={entry.totalExperience}
            onChange={(e) => {
              const value = e.target.value;
              handleInputChange(
                outerIndex,
                innerIndex,
                "totalExperience",
                value
              );
              checkErrorFn(`totalExperience${outerIndex}${innerIndex}`, value);
            }}
            onBlur={() =>
              checkErrorFn(
                `totalExperience${outerIndex}${innerIndex}`,
                entry.totalExperience
              )
            }
            error={
              fieldErrors?.[`totalExperience${outerIndex}${innerIndex}`]?.errors
            }
            style={style}
            required
          />
        </Col>
      </Row>
      <Row>
        <Col xs={10} className="mb-2">
          <InputField
            label="Nature of Job Handled:"
            value={entry.natureOfJobHandled}
            max={500}
            onChange={(e) => {
              const value = e.target.value;
              handleInputChange(
                outerIndex,
                innerIndex,
                "natureOfJobHandled",
                value
              );
              checkErrorFn(
                `natureOfJobHandled${outerIndex}${innerIndex}`,
                value
              );
            }}
            onBlur={() =>
              checkErrorFn(
                `natureOfJobHandled${outerIndex}${innerIndex}`,
                entry.natureOfJobHandled
              )
            }
            error={
              fieldErrors?.[
                `natureOfJobHandled${outerIndex}${innerIndex}`
              ]?.errors
            }
            style={style}
            required
          />
        </Col>
        <Col xs={2} className="mt-2 text-end">
        <Button
        className="mt-2"
          variant="foreground-alternate"
          size="lg"
          onClick={() => deleteFormEntry(outerIndex, innerIndex)}
        >
          <RiDeleteBin6Line />
        </Button>
      </Col>
       
      </Row>
    
    </Row>
  );
};

export default PostWiseDetailEntry;
