import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element: Element  }) => {
    const userID = sessionStorage.getItem("token");
    console.log(userID,"here is user Id")

    return (
        userID ? <Element /> : <Navigate to="/login" />
    );
};

export default ProtectedRoute;
