import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Modal, Badge } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { MdDeleteOutline } from 'react-icons/md';
import { toast } from 'react-toastify';
import Upload, { AWSUpload } from "../../Upload/imageUpload";
// import pdf from "../../assets/images/pdf.png";
import { IoEyeOutline } from "react-icons/io5";

import pdf from "../../assets/images/pdf-icon.png";
import { UploadPdf } from '../../Upload/pdfupload';
const AreYouASportsMan = ({ handelApplicationDetailsChange, applicationDetails, style }) => {
  const [formData, setFormData] = useState({
    areYouASportsMan: '',
    sportCertificateNo: '',
    sportsCertificateIssueDate: '',
    isSportsCertificateIssuedByAuthority: '',
    sportCertificateFile: '',
    sportCertificateVerified: false, // Add verified state for the file
  });
  const [fieldErrors, setFieldErrors] = useState([]);
  const [showIframe, setShowIframe] = useState(null); // State to show iframe modal

  useEffect(() => {
    if (formData && formData?.areYouASportsMan) {
      handelApplicationDetailsChange(formData);
    }
  }, [formData]);

  useEffect(() => {
    if (applicationDetails) {
      setFormData({
        areYouASportsMan: applicationDetails?.areYouASportsMan,
        sportCertificateNo: applicationDetails?.sportCertificateNo,
        sportsCertificateIssueDate: applicationDetails?.sportsCertificateIssueDate,
        isSportsCertificateIssuedByAuthority: applicationDetails?.isSportsCertificateIssuedByAuthority,
        sportCertificateFile: applicationDetails?.sportCertificateFile || '',
        sportCertificateVerified: applicationDetails?.sportCertificateVerified || false, // Track verified status
      });
    }
  }, []);

  const handleUploadImage = async (event, name) => {
    event?.preventDefault();
    const file = event?.target?.files[0];
    const isPDF = file.type === "application/pdf";
    const isUnder2MB = file.size <= 2 * 1024 * 1024;
    if (!isPDF) {
      toast.warn("Invalid file type. Only PDF files are allowed.");
      return;
    }
    if (!isUnder2MB) {
      toast.warn("File size exceeds 2MB. Please upload a smaller file.");
      return;
    }
    if (file) {
      const imageUrl = await UploadPdf(file);
      setFormData({ ...formData, [name]: imageUrl });
    } else {
      setFormData({ ...formData, [name]: null });
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "sportCertificateFile") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0], // Store the selected file
        sportCertificateVerified: false, // Reset verified status when a new file is uploaded
      }));
    } else if (name === "areYouASportsMan" && value === "No") {
      checkErrorFn(name, e.target.value)

      setFormData({
        areYouASportsMan: "No",
        sportCertificateNo: "",
        sportsCertificateIssueDate: '',
        isSportsCertificateIssuedByAuthority: '',
        sportCertificateFile: '',
        sportCertificateVerified: false,
      });
    } else if (name === "isSportsCertificateIssuedByAuthority" && value === "No") {
      // toast.error("Not Eligible");
      checkErrorFn(name, e.target.value)

      setFormData((prevData) => ({
        ...prevData,
        areYouASportsMan: "No",
        sportCertificateNo: "",
        sportsCertificateIssueDate: '',
        isSportsCertificateIssuedByAuthority: '',
        sportCertificateFile: '',
        sportCertificateVerified: false,
      }));
    } else {
      checkErrorFn(name, e.target.value)

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const checkErrorFn = async (camelCaseKey, immediateValue) => {
    let errCount = 0;

    if (!immediateValue) {
      errCount++;
      setFieldErrors(prevState => ({
        ...prevState,
        [camelCaseKey]: {
          errors: [...new Set(["This field is required."])],
          touched: true,
        },
      }));
    } else {
      switch (camelCaseKey) {
        case "sportCertificateNo":
          const regex = /^[a-zA-Z0-9\s.,!@#$%^&*()_+=\-\\:;'"<>?/|]*$/;
          const isValidCertificateNo = regex.test(immediateValue);
          if (!isValidCertificateNo) {
            errCount++;
            setFieldErrors(prevState => ({
              ...prevState,
              [camelCaseKey]: {
                errors: [...new Set(["Invalid sport certificate number."])],
                touched: true,
              },
            }));
          } else {
            setFieldErrors(prevState => ({
              ...prevState,
              [camelCaseKey]: { errors: [], touched: true },
            }));
          }
          break;

        case "sportCertificateFile":
          const document = formData[camelCaseKey];
          const maxDocumentSize = 2 * 1024 * 1024; // 2MB size limit

          if (document) {
            if (document.size > maxDocumentSize) {
              errCount++;
              setFieldErrors(prevState => ({
                ...prevState,
                [camelCaseKey]: {
                  errors: [...new Set(["File size cannot exceed 2MB."])],
                  touched: true,
                },
              }));
            }

            if (!formData.sportCertificateVerified) {
              errCount++;
              setFieldErrors(prevState => ({
                ...prevState,
                [camelCaseKey]: {
                  errors: [...new Set(["Please Verify The File"])],
                  touched: true,
                },
              }));
            }
          } else {
            errCount++;
            setFieldErrors(prevState => ({
              ...prevState,
              [camelCaseKey]: {
                errors: [...new Set(["Please upload a valid document."])],
                touched: true,
              },
            }));
          }
          break;

        default:
          break;
      }
    }

    if (errCount < 1) {
      setFieldErrors(prevState => ({
        ...prevState,
        [camelCaseKey]: { errors: [], touched: true },
      }));
    }
  };

  return (
    <Row>
      <Col xs={12} md={3}>
        <div className="mb-2">
          <label className="form-label inputBoxHeadForm" style={style.label}>
            Are you a Sportsperson?<span className="mx-1 text-danger">*</span>
          </label>
          <select
            name="areYouASportsMan"
            value={formData.areYouASportsMan}
            onChange={handleInputChange}
            onBlur={() => checkErrorFn("areYouASportsMan", formData['areYouASportsMan'])}
            style={
              fieldErrors?.["areYouASportsMan"]?.errors?.length > 0 &&
                fieldErrors?.["areYouASportsMan"]?.touched
                ? { ...style.input, ...style.errorBox }
                : style.input
            }
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          {fieldErrors?.["areYouASportsMan"]?.errors?.length > 0 &&
            fieldErrors?.["areYouASportsMan"]?.touched &&
            fieldErrors?.["areYouASportsMan"]?.errors.map((err) => (
              <span className="errorPresent" style={style.errorSpan}>
                {err}
                <br />
              </span>
            ))}
        </div>
      </Col>

      {formData.areYouASportsMan === "Yes" && (
        <>
          <Col xs={12} md={6}>
            <div className="mb-2">
              <label className="form-label inputBoxHeadForm" htmlFor="isSportsCertificateIssuedByAuthority" style={style.label}>
                Is the Sports Certificate issued by the competent Authority?<span className="mx-1 text-danger">*</span>
              </label>
              <select
                name="isSportsCertificateIssuedByAuthority"
                id="isSportsCertificateIssuedByAuthority"
                value={formData.isSportsCertificateIssuedByAuthority}
                onChange={handleInputChange}
                style={
                  fieldErrors?.["isSportsCertificateIssuedByAuthority"]?.errors?.length > 0 &&
                    fieldErrors?.["isSportsCertificateIssuedByAuthority"]?.touched
                    ? { ...style.input, ...style.errorBox }
                    : style.input
                }
                required
                onBlur={() => checkErrorFn("isSportsCertificateIssuedByAuthority", formData['isSportsCertificateIssuedByAuthority'])}

              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {fieldErrors?.["isSportsCertificateIssuedByAuthority"]?.errors?.length > 0 &&
                fieldErrors?.["isSportsCertificateIssuedByAuthority"]?.touched &&
                fieldErrors?.["isSportsCertificateIssuedByAuthority"]?.errors.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>
                    {err}
                    <br />
                  </span>
                ))}
            </div>
          </Col>

          {formData.isSportsCertificateIssuedByAuthority === "Yes" && (
            <>
              <Col xs={12} md={3}>
                <div className="mb-2">
                  <label className="form-label inputBoxHeadForm" htmlFor="sportCertificateNo" style={style.label}>
                    Sports Certificate No.<span className="mx-1 text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="sportCertificateNo"
                    name="sportCertificateNo"
                    value={formData.sportCertificateNo}
                    onChange={(e) => {
                      handleInputChange(e);
                      // fieldErrors?.["sportCertificateNo"]?.touched && checkErrorFn("sportCertificateNo");
                    }}
                    onBlur={() => checkErrorFn("sportCertificateNo", formData['sportCertificateNo'])}
                    required
                    style={fieldErrors?.["sportCertificateNo"]?.errors?.length > 0 && fieldErrors?.["sportCertificateNo"]?.touched ?
                      { ...style.input, ...style.errorBox } :
                      style.input}
                  />
                  {fieldErrors?.["sportCertificateNo"]?.errors?.length > 0 && fieldErrors?.["sportCertificateNo"]?.touched && (
                    fieldErrors?.["sportCertificateNo"]?.errors?.map((err, idx) => (
                      <span key={idx} className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                    ))
                  )}
                </div>
              </Col>

              <Col xs={12} md={3}>
                <div className="mb-2">
                  <label className="form-label inputBoxHeadForm" htmlFor="issuedDate" style={style.label}>
                    Sports Certificate Issue Date<span className="mx-1 text-danger">*</span>
                  </label>

                  <input
                    className="form-control"
                    type="date"
                    id="issuedDate"
                    name="sportsCertificateIssueDate"
                    value={formData.sportsCertificateIssueDate}
                    onChange={(e) => {
                      handleInputChange(e);
                      fieldErrors?.["sportsCertificateIssueDate"]?.touched && checkErrorFn("sportsCertificateIssueDate", e.target.value);
                    }}
                    onBlur={() => checkErrorFn("sportsCertificateIssueDate", formData['sportsCertificateIssueDate'])}
                    required
                    style={fieldErrors?.["sportsCertificateIssueDate"]?.errors?.length > 0 && fieldErrors?.["sportsCertificateIssueDate"]?.touched ?
                      { ...style.input, ...style.errorBox } :
                      style.input}
                    max={new Date().toISOString().split('T')[0]}
                  />
                  {fieldErrors?.["sportsCertificateIssueDate"]?.errors?.length > 0 && fieldErrors?.["sportsCertificateIssueDate"]?.touched && (
                    fieldErrors?.["sportsCertificateIssueDate"]?.errors?.map((err, idx) => (
                      <span key={idx} className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                    ))
                  )}
                </div>
              </Col>

              <Col xs={12} md={3}>
                <div className="mb-2">
                  <label className="form-label inputBoxHeadForm" htmlFor="sportCertificateFile" style={style.label}>
                    Upload Sports Certificate<span className="mx-1 text-danger">*</span>
                  </label><br />
                  <button
                    style={{
                  //     position: 'relative',
                  // top: '-50px',
                  // left: '250px',
                  // width:'200px',
                      borderRadius: '10px',
                      height: '40px',
                      padding: '5px 15px',
                      fontSize: '12px',
                      cursor: 'pointer',
                      margin: '15px 0',
                      marginTop:'0',
                    
                      marginLeft:'0px',
                      border: 'none',
                      backgroundColor:
                        fieldErrors?.["sportCertificateFile"]?.touched &&
                          fieldErrors?.["sportCertificateFile"]?.errors?.length > 0
                          ? '#921d1e'
                          : '#f0f0f0',
                      color:
                        fieldErrors?.["sportCertificateFile"]?.touched &&
                          fieldErrors?.["sportCertificateFile"]?.errors?.length > 0
                          ? '#fff'
                          : '#333',
                    }}
                    onClick={() => document.getElementById('sportCertificateFile_input').click()}  // Trigger the hidden input
                    onBlur={() => checkErrorFn("sportCertificateFile", formData['sportCertificateFile'])} // Validate on blur
                  >
                    Upload File
                  </button>

                  {/* Hidden input for file selection */}
                  <input
                    type="file"
                    id="sportCertificateFile_input"
                    accept=".pdf"
                    style={{ display: 'none' }} // Hidden file input
                    onChange={(e) => {
                      handleUploadImage(e, "sportCertificateFile"); // Handle file upload
                      // checkErrorFn("sportCertificateFile"); // Validate the file
                    }}
                    onBlur={() => checkErrorFn("sportCertificateFile", formData['sportCertificateFile'])} // Validate on blur
                  />

                  <br />
                  {/* Display validation errors */}
                  {fieldErrors?.["sportCertificateFile"]?.errors?.length > 0 &&
                    fieldErrors?.["sportCertificateFile"]?.touched &&
                    fieldErrors?.["sportCertificateFile"]?.errors.map((err, idx) => (
                      <span key={idx} className="errorPresent" style={style.errorSpan}>
                        {err}
                        <br />
                      </span>
                    ))
                  }
                </div>
              </Col>

              {formData.sportCertificateFile && (
                <React.Fragment className='docResponsive' >
                  <Col xs={12} md={1}>
                    <img
                      src={pdf} // Placeholder for the PDF
                      alt="PDF preview"
                      style={{ width: "40px", cursor: "pointer", marginTop: '48px' }}
                      onClick={() => setShowIframe("sportCertificateFile")}
                    />
                  </Col>

                  {!formData.sportCertificateVerified && (
                    <Col xs={12} md={1}>
                      <Button
                        // className="ms-2 verifybutton verifyBtn"
                        style={{ marginLeft: 'auto', marginTop: '50px' }}
                        onClick={() => setShowIframe("sportCertificateFile")}
                      >
                        <span className='ps-1'>Verify</span>
                      </Button>
                    </Col>
                  )}

                  {formData.sportCertificateVerified && (
                    
                    <Col xs={12} md={1} style={{marginTop:'50px'}}>
                      <Badge bg="success">
                        <FaCheckCircle size={"18"} className="me-2"  />
                        Verified
                      </Badge>
                    </Col>
                  )}

                  <Col xs={12} md={2}>
                    <Button
                      style={{ marginLeft: '0px', marginTop: '48px' }}
                      variant='outline'
                      onClick={() => setShowIframe("sportCertificateFile")}
                    >
                      <IoEyeOutline size={"20"} />

                    </Button>
                    <Button
                      variant="outline"
                      onClick={() => {
                        setFormData({
                          ...formData,
                          sportCertificateFile: null,
                          sportCertificateVerified: false,
                        });
                      }}
                      style={{ marginLeft: '0px', marginTop: '48px' }}
                    >
                      {/* <Badge bg="danger" > */}
                      <MdDeleteOutline size={"20"} />
                      {/* <span className='ps-2'>Delete</span> */}

                      {/* </Badge> */}
                      {/* <MdDeleteOutline size={"25"} /> */}
                    </Button>
                  </Col>

                  {showIframe && (
                    <Modal
                      size="lg"
                      show={showIframe === "sportCertificateFile"}
                      onHide={() => setShowIframe(null)}
                      centered
                    >
                      <Modal.Body className="text-center">
                        <iframe
                          src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(
                            formData.sportCertificateFile
                          )}`}
                          width="100%"
                          style={{ border: "none", height: "80vh" }}
                          title="PDF Viewer"
                        />
                        {!formData.sportCertificateVerified && (
                          <Button
                            className="mt-2"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                sportCertificateVerified: true,
                              });
                              setFieldErrors((prevErrors) => ({
                                ...prevErrors,
                                sportCertificateFile: {
                                  errors: [],
                                  touched: true,
                                },
                              }));
                              setShowIframe(null); // Close modal after verification
                            }}
                          >
                            Confirm Verification
                          </Button>
                        )}
                        <Button
                          className="mt-2"
                          style={{ borderRadius: "10px" }}
                          variant="secondary"
                          onClick={() => setShowIframe(false)}
                        >
                          Close
                        </Button>
                      </Modal.Body>
                    </Modal>
                  )}
                </React.Fragment>
              )}
            </>
          )}
        </>
      )}
    </Row>
  );
};

export default AreYouASportsMan;
