
// export const apiUrl = `http://localhost:8380/api/v1/`;
// export const apiUrl = `http://192.168.1.39:8380/api/v1/`
// export const apiUrl="http://ormsdb.odishamining.in"

//  api url for Old ip
// export const apiUrl = `https://be.omc.recruitment.teceads.co.in/api/v1/`
// api url for New  ip
export const apiUrl="https://omc.live.backend.teceads.co.in/api/v1/"




