import Cvimage from "../images/cv/cv.jpg"
const candidateInfo = {
    "personal_info": {
        "img": Cvimage,
        "name": "Sourav",
        "lastname": "Mahapatra",
        "email": "Sourav@example.com",
        "phone": "9090722736",
        "address": "456 Oak Street, Citytown, State, Country"
    },
    "summary": "Dedicated Environmental Engineer with a strong background in assessing, mitigating, and managing environmental impacts in industrial settings. Experienced in regulatory compliance, pollution prevention, and sustainable practices. Skilled in conducting environmental assessments and implementing effective remediation strategies.",
    "education": [
        {
            "degree": "Master of Science in Environmental Engineering",
            "institution": "University of Environmental Sciences",
            "location": "City, Country",
            "graduation_year": "2014-2016"
        },
        {
            "degree": "Bachelor of Science in Chemical Engineering",
            "institution": "City College of Engineering",
            "location": "City, Country",
            "graduation_year": "2010-2014"
        }
    ],
    "experience": [
        {
            "title": "Environmental Engineer",
            "company": "GreenTech Solutions",
            "location": "City, Country",
            "dates": "2017 - 2024",
            "responsibilities": [
                "Conduct environmental impact assessments for industrial projects, identifying potential risks and proposing mitigation measures.",
                "Develop and implement environmental management plans to ensure compliance with regulations and industry standards.",
                "Perform air quality monitoring, water sampling, and soil testing to assess environmental conditions.",
                "Collaborate with cross-functional teams to design and implement sustainable solutions for waste management and pollution prevention.",
                "Provide training and support to staff on environmental policies, procedures, and best practices."
            ]
        },
        {
            "title": "Environmental Consultant",
            "company": "EcoSolutions Inc.",
            "location": "City, Country",
            "dates": "2016 - 2017",
            "responsibilities": [
                "Conducted site assessments and audits to evaluate environmental risks and compliance with regulatory requirements.",
                "Prepared environmental impact statements and permit applications for clients in various industries.",
                "Provided technical expertise and recommendations to clients on environmental remediation and sustainability initiatives.",
                "Assisted in the development of environmental management systems and pollution prevention plans."
            ]
        }
    ],
    "skills": {
        "environmental_assessment": "Environmental Assessment",
        "regulatory_compliance": "Regulatory Compliance",
        "pollution_prevention": "Pollution Prevention",
        "sustainability_practices": "Sustainability Practices",
        "risk_management": "Risk Management",
        "project_management": "Project Management"
    },
    "certifications": [
        {
            "name": "Professional Engineer (PE) License",
            "authority": "State Board of Professional Engineers",
            "date": "2018"
        },
        {
            "name": "Certified Environmental Professional (CEP)",
            "authority": "National Association of Environmental Professionals",
            "date": "2017"
        }
    ]
}

export const candidateInfoFromRegistrationPage =
{
    "personalDetails": {
        "firstName": "Sourav",
        "lastName": "Mahapatra",
        "phoneNumber": "09668785793",
        "email": "souravmohapatra1920@gmail.com",
        "linkedin": "https://www.linkedin.com/in/souravmahapatra1998/",
        "aadharNumber": "12345678923",
        "password": "123456",
        "confirmPassword": "123456",
        "countryRegion": "Odisha",
        "townCity": "Sunabeda",
        "address": "Qtr. No.-3R 6/2,AEF COLONY, SUNABEDA-1",
        "postalCode": "763001",
        "languages": [
            "English",
            "Hindi",
            "Odia"
        ],
        "gender": "Male",
        "image": "https://blr1.digitaloceanspaces.com/esevabemc/lt977i4_uploads.png",
        "about": "I am a multifaceted individual with a passion for continuous growth and exploration. With a curious mind and an adventurous spirit, I thrive on encountering new challenges and embracing diverse experiences. My journey through life has been shaped by a blend of resilience, adaptability, and an unwavering commitment to personal development.",
        "cv": "https://blr1.digitaloceanspaces.com/esevabemc/fi194km_uploads.pdf"
    },
    "experience": [
        {
            "location": "Hyderabad",
            "position": "Mining Engineer",
            "responsibility": "As a Mining Engineer, I am responsible for designing and implementing efficient and safe mining operations. My duties include conducting geological surveys, designing mine layouts, overseeing drilling and blasting operations, and ensuring compliance with safety and environmental regulations.",
            "company": "National Mineral Development Corporation",
            "Startdate": "02/03/2023",
            "Enddate": "02/2024"
        },
        {
            "location": "Mumbai",
            "position": "Mining Intern",
            "responsibility": "During my internship at Vedanta, I supported senior engineers in geological data analysis, mine design software utilization, and field inspections. I actively participated in project meetings, contributed to technical reports, and gained hands-on experience in various mining operations.",
            "company": "Vedanta",
            "Startdate": "15/09/2021",
            "Enddate": "05/2022"
        },
        {
            "location": "Udaipur",
            "position": "Mining Engineer",
            "responsibility": "At Hindustan Zinc, I contributed to the planning and execution of mining operations, ensuring compliance with regulatory standards and environmental guidelines. My responsibilities included resource estimation, mine development, and safety management.",
            "company": "Hindustan Zinc",
            "Startdate": "02/2024",
            "Enddate": "Present"
        },

    ],
    "skills": [
        {
            "skill": "Mining Eng."
        },
        {
            "skill": "Mine Planning"
        },
        {
            "skill": "Geotech. Eng."
        },
        {
            "skill": "Ops. Management"
        },
        {
            "skill": "Env. Compliance"
        },
        {
            "skill": "Resource Estimation"
        },
        {
            "skill": "Equip. Management"
        }
    ],
    "certifications": [
        {
            "name": "Mining Engineer",
            "authority": "ISMSP",
            "date": "15/02/2024"
        },
        {
            "name": "Mine Safety",
            "authority": "MSHA",
            "date": "10/05/2023"
        },
        {
            "name": "Ventilation",
            "authority": "SME",
            "date": "21/09/2023"
        },
        {
            "name": "Blasting Engineer",
            "authority": "ISEE",
            "date": "03/11/2023"
        },
        {
            "name": "Mine Geology",
            "authority": "GSA",
            "date": "18/01/2024"
        },
        {
            "name": "Reclamation Specialist",
            "authority": "ASMR",
            "date": "29/03/2024"
        }
    ],
    "references": [
        {
            "name": "Sourav",
            "position": "React JS Developer",
            "institution": "Jspiders",
            "contact": "1234567892",
            "email": "souravmohapatra1920@gmail.com"
        }
    ],
    "academicInfo": [
        {
            "course": "10th",
            "board": "BSE",
            "certificate": "https://blr1.digitaloceanspaces.com/esevabemc/v27nt2b_uploads.pdf",
            "Startyear": "05/2001",
            "Endyear": "04/2013",
            "institution": "Sisu mandir",
            "mark": "462",
            "Percentage": "75"
        }, {
            "course": "12th",
            "board": "CHSE",
            "certificate": "https://example.com/12th_certificate.pdf",
            "Startyear": "05/2013",
            "Endyear": "04/2015",
            "institution": "BJB College",
            "mark": "440",
            "percentage": "72"
        },
        {
            "course": "Mining Engineering",
            "board": "University",
            "certificate": "https://example.com/mining_engineering_certificate.pdf",
            "Startyear": "08/2015",
            "Endyear": "06/2019",
            "institution": "National Institute of Technology, Rourkela",
            "mark": "CGPA 8.5",
            "percentage": "85"
        }
    ],
    "otherDocuments": [
        {
            "name": "Sourav Mahapatra",
            "document": "C:\\fakepath\\6593aecfd909df2199795172 (3).pdf"
        }
    ]
}


export default candidateInfo