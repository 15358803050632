import React, { useEffect } from "react";
import Header2 from "../components/header/Header2";
import Breadcrumb from "../components/breadcrumb";
import Footer from "../components/footer";
import Gotop from "../components/gotop";
import SignUp from "../components/signUp";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Collapse } from "react-collapse";
import logo from "../assets/images/logo.png";
import { useState } from "react";
import { Link } from "react-router-dom";
import RegiseterHeader from "./regiseterheader";
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import QRcode from "../assets/images/omcqrcode/omcQr.png"
import Col from 'react-bootstrap/Col'
import Select from "react-select"
import { candidateInfoFromRegistrationPage } from "../assets/fakeData/CandidateProfile";
import { callApi } from "../constant/callApi";
import { UploadToAWS } from "../Upload/pdfupload";
import { UploadPdf } from "../Upload/pdfupload";
import Upload from "../Upload/imageUpload";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import Card from 'react-bootstrap/Card';

function Register(props) {
    const navigate = useNavigate()
    const [toggle, setToggle] = useState({
        key: "",
        status: false,
    });
    const [isShowMobile, setShowMobile] = useState(false);
    const [selectedImg, setSelectedImg] = useState("");
    const [academicInfo, setAcademicInfo] = useState([{ course: '', board: "", certificate: "", institution: "", }])
    const [otherDocuments, setOtherDocuments] = useState([{ name: "", document: "" }])
    const [experience, setExperience] = useState([{ location: "", position: "", responsibility: "" }])
    const [skills, setSkills] = useState([{ skill: '' }]);
    const [certifications, setCertifications] = useState([{ name: '', authority: '', date: '' }]);
    const [references, setReferences] = useState([{ name: '', position: '', institution: '', contact: '', email: '' }]);
    const [personalDetails, setPersonalDetails] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        linkedin: '',
        aadharNumber: '',
        password: '',
        confirmPassword: '',
        countryRegion: '',
        townCity: '',
        address: '',
        postalCode: '',
        languages: [],
        gender: '',
        about: '',
        image: '',
        cv: '',
    });

    const [cv, setCv] = useState({})

    useEffect(() => {
        setPersonalDetails(candidateInfoFromRegistrationPage?.personalDetails)
        setAcademicInfo(candidateInfoFromRegistrationPage?.academicInfo)
        setExperience(candidateInfoFromRegistrationPage?.experience)
        setSkills(candidateInfoFromRegistrationPage?.skills)
        setCertifications(candidateInfoFromRegistrationPage?.certifications)
        setReferences(candidateInfoFromRegistrationPage?.references)
    }, [])

    const formWizardRef = React.createRef();

    const options = [
        { label: "English", value: "English" },
        { label: "Hindi", value: "Hindi" },
        { label: "Odia", value: "Odia" }
    ]

    const gender = [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
        { label: "Other", value: "Other" }
    ]

    const defaultOption = { label: "Choose Language", value: "" }

    const fileInputRef = React.useRef(null);
    const handleClick = () => {
        fileInputRef.current.click();

    };
    const handleUploadImage = async (event,key) => {
        event.preventDefault();
        const file = event.target.files[0];
        console.log("file", file)
        const imageUrl = await Upload(file)
        console.log(imageUrl, "imageUrl")
        setPersonalDetails(prevState => ({
            ...prevState,
            [key]: imageUrl
        }));
    };

    const handleToggle = (key) => {
        if (toggle.key === key) {
            setToggle({
                status: false,
            });
        } else {
            setToggle({
                status: true,
                key,
            });
        }
    };

    const handleMobile = () => {
        const getMobile = document.querySelector(".menu-mobile-popup");
        setShowMobile(!isShowMobile);
        !isShowMobile
            ? getMobile.classList.add("modal-menu--open")
            : getMobile.classList.remove("modal-menu--open");
    };

    const handleSubmit = async () => {
        // localStorage.setItem("cv", JSON.stringify(cv))

        const addData = {
            method: 'post',
            url: 'recruitment/cvCurd/addCv',
            data: {
                ...cv
            },
        };
        let response = await callApi(addData);
        if (response.data.code == 201) {
            navigate("/home")
        }
    }

    useEffect(() => {
        setCv(
            {
                personalDetails: personalDetails,
                experience: experience,
                skills: skills,
                certifications: certifications,
                references: references,
                academicInfo: academicInfo,
                otherDocuments: otherDocuments
            }
        )

    }, [personalDetails, experience, skills, certifications, references, academicInfo, otherDocuments])


    useEffect(() => {
        console.log("cvvvv", cv)
    }, [cv])

    const uploadCv = async (e) => {
        const file = e.target.files[0];
        const pdfurl = await UploadPdf(file)
        console.log(pdfurl, "pdfurl")
        setPersonalDetails(prevState => ({
            ...prevState,
            cv: pdfurl
        }));




    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setPersonalDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handelUploadCertificate = async (e, index) => {
        const file = e.target.files[0];
        const pdfurl = await UploadPdf(file)
        setAcademicInfo(prevState => {
            const updatedAcademicInfo = [...prevState];
            updatedAcademicInfo[index] = {
                ...updatedAcademicInfo[index],
                certificate: pdfurl
            };
            return updatedAcademicInfo;
        });
    }
    const handelOtherdocument = async (e, index) => {
        const file = e.target.files[0];
        const pdfurl = await UploadPdf(file)
        setOtherDocuments(prevState => {
            const updatedOtherdoc = [...prevState];
            updatedOtherdoc[index] = {
                ...updatedOtherdoc[index],
                document: pdfurl
            };
            return updatedOtherdoc;
        });
    }







    const handleAcademicChange = (index, e) => {
        const { name, value, type } = e.target;
        const updatedAcademicInfo = [...academicInfo];
        updatedAcademicInfo[index][name] = value;
        setAcademicInfo(updatedAcademicInfo);
    };


    const handleExperienceChange = (index, e) => {
        const { name, value } = e.target;
        const updatedExperience = [...experience];
        updatedExperience[index][name] = value;
        setExperience(updatedExperience);
    };




    const addAcademicInputs = () => {
        setAcademicInfo([...academicInfo, { course: '', board: '', institution: '', certificate: '', Startyear: '', Endyear: '', mark: "", Percentage: "", }]);
    };

    const addExperienceInputs = () => {
        setExperience([...experience, { company: '', location: '', position: '', date: '', responsibility: '' }]);
    };

    const deleteAcademicInputs = (index) => {
        const updatedAcademicInfo = [...academicInfo];
        updatedAcademicInfo.splice(index, 1);
        setAcademicInfo(updatedAcademicInfo);
    };

    const deleteExperienceInputs = (index) => {
        const updatedExperience = [...experience];
        updatedExperience.splice(index, 1);
        setExperience(updatedExperience);
    };


    const handleSkillChange = (index, e) => {
        const { value } = e.target;
        const updatedSkills = [...skills];
        updatedSkills[index].skill = value;
        setSkills(updatedSkills);
    };

    const handleCertificationChange = (index, e) => {
        const { name, value } = e.target;
        const updatedCertifications = [...certifications];
        updatedCertifications[index][name] = value;
        setCertifications(updatedCertifications);
    };

    const handleReferenceChange = (index, e) => {
        const { name, value } = e.target;
        const updatedReferences = [...references];
        updatedReferences[index][name] = value;
        setReferences(updatedReferences);
    };

    const addSkillInputs = () => {
        setSkills([...skills, { skill: '' }]);
    };

    const addCertificationInputs = () => {
        setCertifications([...certifications, { name: '', authority: '', date: '' }]);
    };

    const addReferenceInputs = () => {
        setReferences([...references, { name: '', position: '', institution: '', contact: '', email: '' }]);
    };

    const deleteSkillInputs = (index) => {
        const updatedSkills = [...skills];
        updatedSkills.splice(index, 1);
        setSkills(updatedSkills);
    };

    const deleteCertificationInputs = (index) => {
        const updatedCertifications = [...certifications];
        updatedCertifications.splice(index, 1);
        setCertifications(updatedCertifications);
    };

    const deleteReferenceInputs = (index) => {
        const updatedReferences = [...references];
        updatedReferences.splice(index, 1);
        setReferences(updatedReferences);
    };

    const handleLanguageChange = (selectedOptions) => {
        const selectedLanguages = selectedOptions.map(option => option.value);
        setPersonalDetails(prevState => ({
            ...prevState,
            languages: selectedLanguages
        }));
    };

    const deleteDocumentsInputs = (index) => {
        const updatedDocuments = [...otherDocuments];
        updatedDocuments.splice(index, 1);
        setOtherDocuments(updatedDocuments);
    };

    const addDocumentsInputs = () => {
        setOtherDocuments([...otherDocuments, { name: "", document: "" }]);
    };

    const handleDocumentsChange = (index, e) => {
        const { name, value } = e.target;
        const updatedDocuments = [...otherDocuments];
        updatedDocuments[index][name] = value;
        setOtherDocuments(updatedDocuments);
    };
    const handelGender = (selectedOption) => {
        setPersonalDetails(prevState => ({
            ...prevState,
            gender: selectedOption.value
        }));
    }

    console.log("jkhkjhjkhjkhkjh", personalDetails.image)

    return (
        <>
            <RegiseterHeader />

            <FormWizard
                ref={formWizardRef}
                color="brown"
                onTabChange={()=>{window.scrollTo({top:0,behavior:"smooth"})}}
            >
                <FormWizard.TabContent title="Personal details" icon="ti-user">
                    <div className="d-flex flex-wrap align-item-center justify-content-center">
                        <div className="shadow p-3 mb-5 bg-body-tertiary rounded" style={{ maxWidth: "100%", padding: "15px", boxSizing: "border-box" }}>

                            <form className="mt-2">
                                <div className="group-input">
                                    
                                    <div className="ip">

                                    
                                    <Col xs={12}>
                                        {
                                            <div className="text-center">
                                                <img src={personalDetails?.image} style={{ maxWidth: "200px", height: "auto", marginBottom: "20px", border: "1px solid #ddd", borderRadius: "5px" }} />
                                            </div>

                                        }
                                    </Col>
                                    <button
                                        style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100%", marginBottom: "20px" }}
                                        onClick={(e)=>{e.preventDefault();e.stopPropagation(); handleClick()}}
                                    >
                                        Upload Image
                                    </button>
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        accept="image"
                                        id="fileInput"
                                        style={{ display: "none" }}
                                        onChange={(e)=>handleUploadImage(e,'image')}
                                    />
                                    </div>

                                    <div className="ip">
                                        <div style={{ textAlign: "left" }}>
                                            <label>First Name</label>
                                        </div>
                                        <input type="text" name="firstName" placeholder="First Name" value={personalDetails.firstName} onChange={handleChange} style={{ width: "100%" }} />
                                    </div>
                                    <div className="ip">
                                        <div style={{ textAlign: "left" }}>
                                            <label>Last Name</label>
                                        </div>
                                        <input type="text" name="lastName" placeholder="Last Name" value={personalDetails.lastName} onChange={handleChange} style={{ width: "100%" }} />
                                    </div>
                                </div>
                                <div className="group-input-st1">
                                    <div style={{ textAlign: "left" }}>
                                        <label>Phone Number</label>
                                    </div>
                                    <input type="text" name="phoneNumber" placeholder="Phone Number" value={personalDetails.phoneNumber} onChange={handleChange} style={{ width: "100%" }} />
                                </div>
                                <div className="group-input-st1">
                                    <div style={{ textAlign: "left" }}>
                                        <label>Email</label>
                                    </div>
                                    <input type="email" name="email" placeholder="Email" value={personalDetails.email} onChange={handleChange} required style={{ width: "100%" }} />
                                </div>
                                <div className="group-input-st1">
                                    <div style={{ textAlign: "left" }}>
                                        <label>Linkedin</label>
                                    </div>
                                    <input type="text" name="linkedin" placeholder="Linkedin" value={personalDetails.linkedin} onChange={handleChange} style={{ width: "100%" }} />
                                </div>
                                <div className="group-input-st1">
                                    <div style={{ textAlign: "left" }}>
                                        <label>Aadhar Number</label>
                                    </div>
                                    <input type="text" name="aadharNumber" placeholder="Aadhar Number" value={personalDetails.aadharNumber} onChange={handleChange} style={{ width: "100%" }} />
                                </div>
                                <div className="group-input-st1">
                                    <div style={{ textAlign: "left" }}>
                                        <label>Password</label>
                                    </div>
                                    <input type="password" name="password" placeholder="Password" value={personalDetails.password} onChange={handleChange} style={{ width: "100%" }} />
                                </div>
                                <div className="group-input-st1">
                                    <div style={{ textAlign: "left" }}>
                                        <label>Confirm Password</label>
                                    </div>
                                    <input type="password" name="confirmPassword" placeholder="Confirm Password" value={personalDetails.confirmPassword} onChange={handleChange} style={{ width: "100%" }} />
                                </div>
                                <div className="group-input-st1">
                                    <div style={{ textAlign: "left" }}>
                                        <label>Country/Region</label>
                                    </div>
                                    <input type="text" name="countryRegion" placeholder="Country/Region" value={personalDetails.countryRegion} onChange={handleChange} style={{ width: "100%" }} />
                                </div>
                                <div className="group-input-st1">
                                    <div style={{ textAlign: "left" }}>
                                        <label>Town/City</label>
                                    </div>
                                    <input type="text" name="townCity" placeholder="Town/City" value={personalDetails.townCity} onChange={handleChange} style={{ width: "100%" }} />
                                </div>
                                <div className="group-input-st1">
                                    <div style={{ textAlign: "left" }}>
                                        <label>Address</label>
                                    </div>
                                    <input type="text" name="address" placeholder="Address" value={personalDetails.address} onChange={handleChange} style={{ width: "100%" }} />
                                </div>
                                <div className="group-input-st1">
                                    <div style={{ textAlign: "left" }}>
                                        <label>Postal Code</label>
                                    </div>
                                    <input type="text" name="postalCode" placeholder="Postal Code" value={personalDetails.postalCode} onChange={handleChange} style={{ width: "100%" }} />
                                </div>
                                <div className="group-input-st1">
                                    <div style={{ textAlign: "left" }}>
                                        <label>Choose Language</label>
                                    </div>
                                    <Select
                                        isMulti={true}
                                        options={options}
                                        className="react-dropdown select-location"
                                        onChange={handleLanguageChange}
                                        value={options.filter(option => personalDetails.languages.includes(option.value))}
                                    />
                                </div>
                                <div className="group-input-st1">
                                    <div style={{ textAlign: "left" }}>
                                        <label>Gender</label>
                                    </div>
                                    <Select

                                        options={gender}
                                        className="react-dropdown select-location"
                                        value={gender.filter(option => personalDetails.gender.includes(option.value))}
                                        onChange={handelGender}
                                    />
                                </div>
                                <div className="group-input-st1">
                                    <div style={{ textAlign: "left" }}>
                                        <label>About Yourself</label>
                                    </div>
                                    <textarea name="about" placeholder="About Yourself" value={personalDetails.about} onChange={handleChange} style={{ width: "100%", minHeight: "100px" }} />
                                </div>
                                <div className="group-input-st1">
                                    <div style={{ textAlign: "left" }}>
                                        <Form.Label>CV</Form.Label>
                                    </div>
                                    <Form.Control type="file" name="certificate" value={personalDetails.certificate} onChange={uploadCv} style={{ width: "100%" }} />
                                </div>
                            </form>
                        </div>
                    </div>
                </FormWizard.TabContent>

                <FormWizard.TabContent title="Academic details" icon="ti-book">
                    <div className="d-flex flex-wrap align-item-center justify-content-center">
                        <div className="shadow p-3 mb-5 bg-body-tertiary rounded" style={{ maxWidth: "100%", padding: "15px", boxSizing: "border-box" }}>

                            {academicInfo.map((item, index) => (
                                <div key={index} style={{ marginBottom: "20px" }}>
                                    <div className="ip">
                                        <div style={{ textAlign: "left" }}>
                                            <label>Course</label>
                                        </div>
                                        <input type="text" name="course" placeholder="Course" value={item.course} onChange={(e) => handleAcademicChange(index, e)} style={{ width: "100%" }} />
                                    </div>
                                    <div className="ip">
                                        <div style={{ textAlign: "left" }}>
                                            <label>Board</label>
                                        </div>
                                        <input type="text" name="board" placeholder="Board" value={item.board} onChange={(e) => handleAcademicChange(index, e)} style={{ width: "100%" }} />
                                    </div>
                                    <div className="ip">
                                        <div style={{ textAlign: "left" }}>
                                            <label>Institutions</label>
                                        </div>
                                        <input type="text" name="institution" placeholder="institution" value={item.institution} onChange={(e) => handleAcademicChange(index, e)} style={{ width: "100%" }} />
                                    </div>

                                    <div className="ip">
                                        <div style={{ textAlign: "left" }}>
                                            <label>Start Year</label>
                                        </div>
                                        <input type="text" name="Startyear" placeholder="Start Year" value={item.Startyear} onChange={(e) => handleAcademicChange(index, e)} style={{ width: "100%" }} />
                                    </div>
                                    <div className="ip">
                                        <div style={{ textAlign: "left" }}>
                                            <label>End Year</label>
                                        </div>
                                        <input type="text" name="Endyear" placeholder="End Year" value={item.Endyear} onChange={(e) => handleAcademicChange(index, e)} style={{ width: "100%" }} />
                                    </div>
                                    <div className="d-flex mt-2">
                                        <div className="ip">
                                            <div style={{ textAlign: "left" }}>
                                                <label>Mark</label>
                                            </div>
                                            <input type="text" name="mark" placeholder="Mark" value={item.mark} onChange={(e) => handleAcademicChange(index, e)} style={{ width: "100%" }} />
                                        </div>
                                        <div className="ip ms-1">
                                            <div style={{ textAlign: "left" }}>
                                                <label>Percentage% Or CGPA</label>
                                            </div>
                                            <input type="text" name="Percentage" placeholder="Percentage" value={item.Percentage} onChange={(e) => handleAcademicChange(index, e)} style={{ width: "100%" }} />
                                        </div>
                                    </div>

                                    <div style={{ textAlign: "left", marginTop: "10px" }}>
                                        <Form.Label>Certificate</Form.Label>
                                        <Form.Control type="file" name="certificate" onChange={(e) => handelUploadCertificate(e, index)} style={{ width: "100%" }} />
                                    </div>
                                    {index === 0 ? null : (
                                        <button className="ms-2 mt-2" onClick={() => deleteAcademicInputs(index)} style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}>
                                            Delete
                                        </button>
                                    )}
                                    {index === academicInfo.length - 1 && (
                                        <button className="ms-2 mt-2" onClick={addAcademicInputs} style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}>
                                            Add
                                        </button>
                                    )}
                                    <hr />
                                </div>
                            ))}

                        </div>
                    </div>
                </FormWizard.TabContent>


                <FormWizard.TabContent title="Experience details" icon="ti-medall">
                    <div className="d-flex flex-wrap align-item-center justify-content-center">
                        <div className="shadow p-3 mb-5 bg-body-tertiary rounded" style={{ width: "500px", marginTop: "40px" }}>

                            {experience.map((item, index) => (
                                <div key={index}>
                                    <div className="ip">
                                        <div style={{ textAlign: "left" }}>
                                            <label>Company</label>
                                        </div>
                                        <input type="text" name="company" placeholder="Company" value={item.company} onChange={(e) => handleExperienceChange(index, e)} />
                                    </div>
                                    <div className="ip">
                                        <div style={{ textAlign: "left" }}>
                                            <label>Location</label>
                                        </div>
                                        <input type="text" name="location" placeholder="Location" value={item.location} onChange={(e) => handleExperienceChange(index, e)} />
                                    </div>
                                    <div className="ip">
                                        <div style={{ textAlign: "left" }}>
                                            <label>Position</label>
                                        </div>
                                        <input type="text" name="position" placeholder="Position" value={item.position} onChange={(e) => handleExperienceChange(index, e)} />
                                    </div>
                                    <div>
                                        <div style={{ textAlign: "left" }}>
                                            <label> Start Date</label>
                                        </div>
                                        <input type="text" name="Startdate" placeholder="Start Date" value={item.Startdate} onChange={(e) => handleExperienceChange(index, e)} />
                                    </div>
                                    <div>
                                        <div style={{ textAlign: "left" }}>
                                            <label>End Date</label>
                                        </div>
                                        <input type="text" name="Enddate" placeholder="End Date" value={item.Enddate} onChange={(e) => handleExperienceChange(index, e)} />
                                    </div>
                                    <div>
                                        <div style={{ textAlign: "left" }}>
                                            <label>Responsibility</label>
                                        </div>
                                        <textarea name="responsibility" placeholder="Responsibility" value={item.responsibility} onChange={(e) => handleExperienceChange(index, e)} />
                                    </div>
                                    {index === 0 ? null : (
                                        <button className="ms-2 mt-2" onClick={() => deleteExperienceInputs(index)}
                                            style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                        >Delete</button>
                                    )}
                                    {index === experience.length - 1 && (
                                        <button className="ms-2 mt-2" onClick={addExperienceInputs}
                                            style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                        >Add</button>
                                    )}
                                    <hr />
                                </div>
                            ))}

                        </div>
                    </div>
                </FormWizard.TabContent>

                <FormWizard.TabContent title="Skills details" icon="ti-settings">
                    <div className="d-flex flex-wrap align-item-center justify-content-center">
                        <div className="shadow p-3 mb-5 bg-body-tertiary rounded" style={{ width: "500px", marginTop: "40px" }}>
                            <div className="product-list">
                                {skills.map((item, index) => (
                                    <div key={index}>
                                        <div className="ip">
                                            <div style={{ textAlign: "left" }}>
                                                <label>Skill</label>
                                            </div>
                                            <input type="text" placeholder="Skill" value={item.skill} onChange={(e) => handleSkillChange(index, e)} />
                                        </div>
                                        {index === 0 ? null : (
                                            <button className="ms-2 mt-2" onClick={() => deleteSkillInputs(index)}
                                                style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                            >Delete</button>
                                        )}
                                        {index === skills.length - 1 && (
                                            <button className="ms-2 mt-2" onClick={addSkillInputs}
                                                style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                            >Add</button>
                                        )}
                                        <hr />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </FormWizard.TabContent>

                <FormWizard.TabContent title="Certification details" icon="ti-receipt">
                    <div className="d-flex flex-wrap align-item-center justify-content-center">
                        <div className="shadow p-3 mb-5 bg-body-tertiary rounded" style={{ width: "500px", marginTop: "40px" }}>
                            <div className="product-list">
                                {certifications.map((item, index) => (
                                    <div key={index}>
                                        <div className="ip">
                                            <div style={{ textAlign: "left" }}>
                                                <label>Name</label>
                                            </div>
                                            <input type="text" placeholder="Name" name="name" value={item.name} onChange={(e) => handleCertificationChange(index, e)} />
                                        </div>
                                        <div className="ip">
                                            <div style={{ textAlign: "left" }}>
                                                <label>Authority</label>
                                            </div>
                                            <input type="text" placeholder="Authority" name="authority" value={item.authority} onChange={(e) => handleCertificationChange(index, e)} />
                                        </div>
                                        <div className="ip">
                                            <div style={{ textAlign: "left" }}>
                                                <label>Date</label>
                                            </div>
                                            <input type="text" placeholder="Date" name="date" value={item.date} onChange={(e) => handleCertificationChange(index, e)} />
                                        </div>
                                        {index === 0 ? null : (
                                            <button className="ms-2 mt-2" onClick={() => deleteCertificationInputs(index)}
                                                style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                            >Delete</button>
                                        )}
                                        {index === certifications.length - 1 && (
                                            <button className="ms-2 mt-2" onClick={addCertificationInputs}
                                                style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                            >Add</button>
                                        )}
                                        <hr />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </FormWizard.TabContent>

                <FormWizard.TabContent title="Reference details" icon="ti-gift">
                    <div className="d-flex flex-wrap align-item-center justify-content-center">
                        <div className="shadow p-3 mb-5 bg-body-tertiary rounded" style={{ width: "500px", marginTop: "40px" }}>
                            <div className="product-list">
                                {references.map((item, index) => (
                                    <div key={index}>
                                        <div className="ip">
                                            <div style={{ textAlign: "left" }}>
                                                <label>Name</label>
                                            </div>
                                            <input type="text" placeholder="Name" name="name" value={item.name} onChange={(e) => handleReferenceChange(index, e)} />
                                        </div>
                                        <div className="ip">
                                            <div style={{ textAlign: "left" }}>
                                                <label>Position</label>
                                            </div>
                                            <input type="text" placeholder="Position" name="position" value={item.position} onChange={(e) => handleReferenceChange(index, e)} />
                                        </div>
                                        <div className="ip">
                                            <div style={{ textAlign: "left" }}>
                                                <label>Institution</label>
                                            </div>
                                            <input type="text" placeholder="Institution" name="institution" value={item.institution} onChange={(e) => handleReferenceChange(index, e)} />
                                        </div>
                                        <div className="ip">
                                            <div style={{ textAlign: "left" }}>
                                                <label>Contact</label>
                                            </div>
                                            <input type="text" placeholder="Contact" name="contact" value={item.contact} onChange={(e) => handleReferenceChange(index, e)} />
                                        </div>
                                        <div className="ip">
                                            <div style={{ textAlign: "left" }}>
                                                <label>Email</label>
                                            </div>
                                            <input type="text" placeholder="Email" name="email" value={item.email} onChange={(e) => handleReferenceChange(index, e)} />
                                        </div>
                                        {index === 0 ? null : (
                                            <button className="ms-2 mt-2" onClick={() => deleteReferenceInputs(index)}
                                                style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                            >Delete</button>
                                        )}
                                        {index === references.length - 1 && (
                                            <button className="ms-2 mt-2" onClick={addReferenceInputs}
                                                style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                            >Add</button>
                                        )}
                                        <hr />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </FormWizard.TabContent>

                <FormWizard.TabContent title="Other Documents" icon="ti-bookmark-alt">
                    <div className="d-flex flex-wrap align-item-center justify-content-center">
                        <div className="shadow p-3 mb-5 bg-body-tertiary rounded" style={{ width: "500px", marginTop: "40px" }}>
                            <div className="product-list">
                                {otherDocuments.map((item, index) => (
                                    <div key={index}>
                                        <div className="ip">
                                            <div style={{ textAlign: "left" }}>
                                                <label>Document Name</label>
                                            </div>
                                            <input type="text" name="name" placeholder="Document Name" value={item.name} onChange={(e) => handleDocumentsChange(index, e)} />
                                        </div>
                                        <div>
                                            <div style={{ textAlign: "left" }}>
                                                <Form.Label>Documents</Form.Label>
                                            </div>
                                            <Form.Control type="file" name="certificate" onChange={(e) => handelOtherdocument(e, index)} />
                                        </div>
                                        {index === 0 ? null : (
                                            <button className="ms-2 mt-2" onClick={() => deleteDocumentsInputs(index)}
                                                style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                            >Delete</button>
                                        )}
                                        {index === otherDocuments.length - 1 && (
                                            <button className="ms-2 mt-2" onClick={addDocumentsInputs}
                                                style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                            >Add</button>
                                        )}
                                        <hr />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </FormWizard.TabContent>

            </FormWizard>

            {/* add style */}
            <style > {`
        @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
        .sample-refrence-button{
          margin: 5px;
          padding: 8px 15px;
          border: 1px solid transparent;
          border-radius: 5px;
          background-color: #2196f3;
          color: #fff;
          cursor: pointer;

        }
        .sample-refrence-button:hover{
          background-color: #fff;
          color: #2196f3;
          border: 1px solid #2196f3;
        }

      `}</style>


            {/* <section className="checkout-section">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-lg-4 sticky-sidebar mt-2">
                            <div className="bg-checkout shop-content  content-stc">
                                <div className="group-title">
                                    <h6>Register Here</h6>
                                    <hr />
                                </div>
                                <Col xs={12}>
                                    {
                                        <div className="text-center">
                                            <img src={personalDetails?.image} style={{ width: "130px", marginBottom: "50px", border: "1px" }} rounded />
                                        </div>
                                    }
                                </Col>
                                <button
                                    style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "500px" }}
                                    onClick={handleClick}
                                >
                                    Upload Image
                                </button>
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    accept="image"
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    onChange={handleUploadImage}
                                />
                                <form className="mt-2">
                                    <div className="group-input">
                                        <div className="ip">
                                            <label>First Name</label>
                                            <input type="text" name="firstName" placeholder="First Name" value={personalDetails.firstName} onChange={handleChange} />
                                        </div>
                                        <div className="ip">
                                            <label>Last Name</label>
                                            <input type="text" name="lastName" placeholder="Last Name" value={personalDetails.lastName} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="group-input-st1">
                                        <label>Phone Number</label>
                                        <input type="text" name="phoneNumber" placeholder="Phone Number" value={personalDetails.phoneNumber} onChange={handleChange} />
                                    </div>
                                    <div className="group-input-st1">
                                        <label>Email</label>
                                        <input type="email" name="email" placeholder="Email" value={personalDetails.email} onChange={handleChange} required />
                                    </div>
                                    <div className="group-input-st1">
                                        <label>Linkedin</label>
                                        <input type="text" name="linkedin" placeholder="Linkedin" value={personalDetails.linkedin} onChange={handleChange} />
                                    </div>
                                    <div className="group-input-st1">
                                        <label>Aadhar Number</label>
                                        <input type="text" name="aadharNumber" placeholder="Aadhar Number" value={personalDetails.aadharNumber} onChange={handleChange} />
                                    </div>
                                    <div className="group-input-st1">
                                        <label>Password</label>
                                        <input type="password" name="password" placeholder="Password" value={personalDetails.password} onChange={handleChange} />
                                    </div>
                                    <div className="group-input-st1">
                                        <label>Confirm Password</label>
                                        <input type="password" name="confirmPassword" placeholder="Confirm Password" value={personalDetails.confirmPassword} onChange={handleChange} />
                                    </div>
                                    <div className="group-input-st1">
                                        <label>Country/Region</label>
                                        <input type="text" name="countryRegion" placeholder="Country/Region" value={personalDetails.countryRegion} onChange={handleChange} />
                                    </div>
                                    <div className="group-input-st1">
                                        <label>Town/City</label>
                                        <input type="text" name="townCity" placeholder="Town/City" value={personalDetails.townCity} onChange={handleChange} />
                                    </div>
                                    <div className="group-input-st1">
                                        <label>Address</label>
                                        <input type="text" name="address" placeholder="Address" value={personalDetails.address} onChange={handleChange} />
                                    </div>
                                    <div className="group-input-st1">
                                        <label>Postal Code</label>
                                        <input type="text" name="postalCode" placeholder="Postal Code" value={personalDetails.postalCode} onChange={handleChange} />
                                    </div>
                                    <div className="group-input-st1">
                                        <label>Choose Language</label>
                                        <Select
                                            isMulti={true}
                                            options={options}
                                            className="react-dropdown select-location"
                                            onChange={handleLanguageChange}
                                            value={options.filter(option => personalDetails.languages.includes(option.value))}
                                        />
                                    </div>
                                    <div className="group-input-st1">
                                        <label>Gender</label>
                                        <Select
                                            options={gender}
                                            className="react-dropdown select-location"
                                        />
                                    </div>
                                    <div className="group-input-st1">
                                        <label>About Yourself</label>
                                        <textarea name="about" placeholder="About Yourself" value={personalDetails.about} onChange={handleChange} />
                                    </div>
                                    <div className="group-input-st1">
                                        <Form.Label>CV</Form.Label>
                                        <Form.Control type="file" name="certificate" value={personalDetails.certificate} onChange={uploadCv} />
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="col-lg-4 sticky-sidebar mt-2">
                            <div className="shop-content  content-stc">
                                <div className="wd-order">
                                    <div className="bg-checkout">
                                        <h5>Add Academic details</h5>
                                        <hr />

                                        <div className="product-list mt-2">
                                            {academicInfo.map((item, index) => (
                                                <div key={index}>
                                                    <div className="ip">
                                                        <label>Course</label>
                                                        <input type="text" name="course" placeholder="Course" value={item.course} onChange={(e) => handleAcademicChange(index, e)} />
                                                    </div>
                                                    <div className="ip">
                                                        <label>Board</label>
                                                        <input type="text" name="board" placeholder="Board" value={item.board} onChange={(e) => handleAcademicChange(index, e)} />
                                                    </div>
                                                    <div className="ip">
                                                        <label>Institutions</label>
                                                        <input type="text" name="institution" placeholder="institution " value={item.institution} onChange={(e) => handleAcademicChange(index, e)} />
                                                    </div>

                                                    <div className="ip">
                                                        <label> Start Year</label>
                                                        <input type="text" name="Startyear" placeholder="Start Year" value={item.Startyear} onChange={(e) => handleAcademicChange(index, e)} />
                                                    </div>
                                                    <div className="ip">
                                                        <label> End Year</label>
                                                        <input type="text" name="Endyear" placeholder="End Year" value={item.Endyear} onChange={(e) => handleAcademicChange(index, e)} />
                                                    </div>
                                                    <div className="d-flex mt-2">
                                                        <div className="ip">
                                                            <label>Mark</label>
                                                            <input type="text" name="mark" placeholder="Mark" value={item.mark} onChange={(e) => handleAcademicChange(index, e)} />
                                                        </div>
                                                        <div className="ip ms-1">
                                                            <label> Percentage% Or CGPA</label>
                                                            <input type="text" name="Percentage" placeholder="Percentage" value={item.Percentage} onChange={(e) => handleAcademicChange(index, e)} />
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <Form.Label>Certificate</Form.Label>
                                                        <Form.Control type="file" name="certificate" onChange={(e) => handelUploadCertificate(e, index)} />
                                                    </div>
                                                    {index === 0 ? null : (
                                                        <button className="ms-2 mt-2" onClick={() => deleteAcademicInputs(index)}
                                                            style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                                        >Delete</button>
                                                    )}
                                                    {index === academicInfo.length - 1 && (
                                                        <button className="ms-2 mt-2" onClick={addAcademicInputs}
                                                            style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                                        >Add</button>
                                                    )}
                                                    <hr />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="shop-content content-stc">
                                <div className="wd-order">
                                    <div className="bg-checkout">
                                        <h5>Add Work Experience</h5>
                                        <hr />
                                        <div className="product-list">
                                            {experience.map((item, index) => (
                                                <div key={index}>
                                                    <div className="ip">
                                                        <label>Company</label>
                                                        <input type="text" name="company" placeholder="Company" value={item.company} onChange={(e) => handleExperienceChange(index, e)} />
                                                    </div>
                                                    <div className="ip">
                                                        <label>Location</label>
                                                        <input type="text" name="location" placeholder="Location" value={item.location} onChange={(e) => handleExperienceChange(index, e)} />
                                                    </div>
                                                    <div className="ip">
                                                        <label>Position</label>
                                                        <input type="text" name="position" placeholder="Position" value={item.position} onChange={(e) => handleExperienceChange(index, e)} />
                                                    </div>
                                                    <div>
                                                        <label> Start Date</label>
                                                        <input type="text" name="Startdate" placeholder="Start Date" value={item.Startdate} onChange={(e) => handleExperienceChange(index, e)} />
                                                    </div>
                                                    <div>
                                                        <label>End Date</label>
                                                        <input type="text" name="Enddate" placeholder="End Date" value={item.Enddate} onChange={(e) => handleExperienceChange(index, e)} />
                                                    </div>
                                                    <div>
                                                        <label>Responsibility</label>
                                                        <textarea name="responsibility" placeholder="Responsibility" value={item.responsibility} onChange={(e) => handleExperienceChange(index, e)} />
                                                    </div>
                                                    {index === 0 ? null : (
                                                        <button className="ms-2 mt-2" onClick={() => deleteExperienceInputs(index)}
                                                            style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                                        >Delete</button>
                                                    )}
                                                    {index === experience.length - 1 && (
                                                        <button className="ms-2 mt-2" onClick={addExperienceInputs}
                                                            style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                                        >Add</button>
                                                    )}
                                                    <hr />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="shop-content content-stc">
                                <div className="wd-order">
                                    <div className="bg-checkout">
                                        <h5>Other Documents</h5>
                                        <hr />
                                        <div className="product-list">
                                            {otherDocuments.map((item, index) => (
                                                <div key={index}>
                                                    <div className="ip">
                                                        <label>Document Name</label>
                                                        <input type="text" name="name" placeholder="Document Name" value={item.name} onChange={(e) => handleDocumentsChange(index, e)} />
                                                    </div>
                                                    <div>
                                                        <Form.Label>Documents</Form.Label>
                                                        <Form.Control type="file" name="certificate" onChange={(e) => handelOtherdocument(e, index)} />
                                                    </div>
                                                    {index === 0 ? null : (
                                                        <button className="ms-2 mt-2" onClick={() => deleteDocumentsInputs(index)}
                                                            style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                                        >Delete</button>
                                                    )}
                                                    {index === otherDocuments.length - 1 && (
                                                        <button className="ms-2 mt-2" onClick={addDocumentsInputs}
                                                            style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                                        >Add</button>
                                                    )}
                                                    <hr />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 sticky-sidebar mt-2">
                            <div className="shop-content content-stc">
                                <div className="wd-order">
                                    <div className="bg-checkout">
                                        <h5>Add Skills</h5>
                                        <hr />
                                        <div className="product-list">
                                            {skills.map((item, index) => (
                                                <div key={index}>
                                                    <div className="ip">
                                                        <label>Skill</label>
                                                        <input type="text" placeholder="Skill" value={item.skill} onChange={(e) => handleSkillChange(index, e)} />
                                                    </div>
                                                    {index === 0 ? null : (
                                                        <button className="ms-2 mt-2" onClick={() => deleteSkillInputs(index)}
                                                            style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                                        >Delete</button>
                                                    )}
                                                    {index === skills.length - 1 && (
                                                        <button className="ms-2 mt-2" onClick={addSkillInputs}
                                                            style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                                        >Add</button>
                                                    )}
                                                    <hr />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="shop-content content-stc">
                                <div className="wd-order">
                                    <div className="bg-checkout">
                                        <h5>Add Certification</h5>
                                        <hr />
                                        <div className="product-list">
                                            {certifications.map((item, index) => (
                                                <div key={index}>
                                                    <div className="ip">
                                                        <label>Name</label>
                                                        <input type="text" placeholder="Name" name="name" value={item.name} onChange={(e) => handleCertificationChange(index, e)} />
                                                    </div>
                                                    <div className="ip">
                                                        <label>Authority</label>
                                                        <input type="text" placeholder="Authority" name="authority" value={item.authority} onChange={(e) => handleCertificationChange(index, e)} />
                                                    </div>
                                                    <div className="ip">
                                                        <label>Date</label>
                                                        <input type="text" placeholder="Date" name="date" value={item.date} onChange={(e) => handleCertificationChange(index, e)} />
                                                    </div>
                                                    {index === 0 ? null : (
                                                        <button className="ms-2 mt-2" onClick={() => deleteCertificationInputs(index)}
                                                            style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                                        >Delete</button>
                                                    )}
                                                    {index === certifications.length - 1 && (
                                                        <button className="ms-2 mt-2" onClick={addCertificationInputs}
                                                            style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                                        >Add</button>
                                                    )}
                                                    <hr />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="shop-content content-stc">
                                <div className="wd-order">
                                    <div className="bg-checkout">
                                        <h5>Add Reference</h5>
                                        <hr />
                                        <div className="product-list">
                                            {references.map((item, index) => (
                                                <div key={index}>
                                                    <div className="ip">
                                                        <label>Name</label>
                                                        <input type="text" placeholder="Name" name="name" value={item.name} onChange={(e) => handleReferenceChange(index, e)} />
                                                    </div>
                                                    <div className="ip">
                                                        <label>Position</label>
                                                        <input type="text" placeholder="Position" name="position" value={item.position} onChange={(e) => handleReferenceChange(index, e)} />
                                                    </div>
                                                    <div className="ip">
                                                        <label>Institution</label>
                                                        <input type="text" placeholder="Institution" name="institution" value={item.institution} onChange={(e) => handleReferenceChange(index, e)} />
                                                    </div>
                                                    <div className="ip">
                                                        <label>Contact</label>
                                                        <input type="text" placeholder="Contact" name="contact" value={item.contact} onChange={(e) => handleReferenceChange(index, e)} />
                                                    </div>
                                                    <div className="ip">
                                                        <label>Email</label>
                                                        <input type="text" placeholder="Email" name="email" value={item.email} onChange={(e) => handleReferenceChange(index, e)} />
                                                    </div>
                                                    {index === 0 ? null : (
                                                        <button className="ms-2 mt-2" onClick={() => deleteReferenceInputs(index)}
                                                            style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                                        >Delete</button>
                                                    )}
                                                    {index === references.length - 1 && (
                                                        <button className="ms-2 mt-2" onClick={addReferenceInputs}
                                                            style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "100px" }}
                                                        >Add</button>
                                                    )}
                                                    <hr />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex  justify-content-center align-items-center">
                        <button style={{ backgroundColor: "brown", color: "white", borderRadius: "10px", height: "50px", width: "200px" }} className="group-btn mt-3" onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </div>
            </section> */}
            <Gotop />
        </>
    );
}

export default Register;
