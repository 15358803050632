import React, { useEffect, useRef, useState } from "react";
import { Collapse, Button, Card, Spinner } from "react-bootstrap";
import { Row, Col, Form } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import CustomTextInput from "./SimpleInputComponents/simpleTextInput.jsx";
import { callApi } from "../constant/callApi.js";
import CryptoJS from "crypto-js";
import Preloader from "../components/preloader";
import { IoMdCheckmark } from "react-icons/io";

import ReCAPTCHA from "react-google-recaptcha";
import { Value } from "sass";

const ApplicantRegistration = () => {
  const [userInputs, setUserInputs] = useState({});
  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [mailOtpResponse, setMailOtpResponse] = useState({});
  const [phoneOtpResponse, setPhoneOtpResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [SendMobileBtnLoading, setSendMobileBtnLoading] = useState(false);
  const [confirmMObileOtpLoading, setConfirmMObileOtpLoading] = useState(false);
  const [mobileOtpConformation, setOtpMobileConfirmation] = useState(false);
  const [emailOtpLoading, setEmailOtpLoading] = useState(false);
  const [emailOtpConformation, setEmailOtpConformation] = useState(false);
  const [emailConformationLoading, setEmailConFormationLoading] =
    useState(false);

  const [isChecked, setIsChecked] = useState(false);
  const [reCAPTCHAtoken, setReCAPTCHAToken] = useState("");
  const [verified, setVerified] = useState(false);

  const inputRefs = useRef({});
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    console.log({ userInputs });
  }, [userInputs]);
  const setUserInputFn = (e) => {
    const { name, value } = e.target;

    console.log(name, value, "name-- is here")
    setUserInputs((prevInputs) => ({
      ...prevInputs,
      [name]: {
        ...prevInputs[name],

        value: value
      },
    }));
  };
  const handleEmailOtp = () => { };
  const handlePhoneOtp = () => { };
  function updateInputState(name, value, errors, touched) {
    console.log(name, "name");
    setUserInputs((prevInputs) => {
      const newInputs = { ...prevInputs };

      if (!newInputs[name]) {
        newInputs[name] = { value: "", errors: {} };
      }

      newInputs[name] = {
        ...newInputs[name],
        value,
        errors,
        touched,
      };

      return newInputs;
    });
  }
  function validateEmail(value) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(value);
  }

  function validateAlphaSpace(value) {
    const regex = /^[a-zA-Z ]+$/;
    return regex.test(value);
  }
  function validate10Digit(value) {
    // Regex to check for exactly 10 digits
    const regex = /^\d{10}$/;
    return regex.test(value);
  }
  function valid10RegistrationNo(value) {
    console.log(value, "not_AlphaSpace");
    const regex = /^(?=.*\S)[a-zA-Z0-9]+$/;
    console.log(regex.test(value));
    return regex.test(value);
  }
  function validate6Digit(value) {
    // Regex to check for exactly 10 digits
    const regex = /^\d{6}$/;
    return regex.test(value);
  }
  function validDob(value) {
    if (!value) {
      console.log("Date of Birth is required.");
      return false; // Required field is empty
    }

    // Additional checks can be added here to validate the date format
    const date = new Date(value);
    return date instanceof Date && !isNaN(date); // Validates that it's a proper date
  }

  const checkForInputError = (e) => {
    // console.log(, "called");
    const { name, value, required } = e.target;
    const trimmedValue = value;
    console.log(name, "required");

    if (!required && (trimmedValue === "" || trimmedValue == null)) {
      // No validation for non-required empty values
      console.log("StepsAre there");
      updateInputState(name, trimmedValue, {}, true);
    } else {
      const errors = {};

      switch (name) {
        case "fullName":
          if (!validateAlphaSpace(trimmedValue)) {
            errors["not_AlphaSpace"] =
              "Only Alphabet and Spaces are allowed in this input.";
          }
          break;

        case "confirmFullName":
          if (!validateAlphaSpace(trimmedValue)) {
            errors["confirm_FullName"] =
              "Only Alphabet and Spaces are allowed in this input.";
          }
          if (trimmedValue != userInputs?.["fullName"]?.value) {
            errors["not_matchFullName"] = "Candidate FullName DoesNot Match";
          }
          break;
        case "phoneNumber":
          if (!validate10Digit(trimmedValue)) {
            errors["not_10digit"] = "Only 10 Digits allowed in this input.";
          }
          break;
        case "confirmMobileNumber":
          if (!validate10Digit(trimmedValue)) {
            errors["not_10digit"] = "Only 10 Digits allowed in this input.";
          }
          if (trimmedValue != userInputs?.["phoneNumber"]?.value) {
            errors["notMatching"] = "Mobile Number Not Matching";
          }
          break;
        case "emailId":
          if (!validateEmail(trimmedValue)) {
            errors["not_email"] = "Please Enter a valid Email Id.";
          }
          break;
        case "confirmEmailId":
          if (!validateEmail(trimmedValue)) {
            errors["not_email"] = "Please Enter a valid Email Id.";
          }
          if (trimmedValue != userInputs?.["emailId"]?.value) {
            errors["notMatching"] = "Email Id Not Matching";
          }
          break;
        case "mobileOtp":
          if (!validate6Digit(trimmedValue)) {
            errors["not_email"] = "Only 6 Digits allowed in this input.";
          }
          break;
        case "emailOtp":
          if (!validate6Digit(trimmedValue)) {
            errors["not_email"] = "Only 6 Digits allowed in this input.";
          }
          break;
        case "registrationNo":
          console.log(trimmedValue, "trimmedValueof10th");
          if (!valid10RegistrationNo(trimmedValue)) {
            console.log("Step1");
            errors["not_registrationNo"] =
              "Only alphanumeric characters are allowed.";
            console.log("Step2");
          }
          break;

        case "confirm10registrationNo":
          if (!valid10RegistrationNo(trimmedValue)) {
            errors["confirm_10registration"] =
              "Only alphanumeric characters are allowed.";
          }
          if (trimmedValue != userInputs["registrationNo"]?.value) {
            errors["confirm10thDoesNotMatch"] =
              "Both  10th registration  Number And confirm 10th Registration Number Same ";
          }
          break;
        case "dob":
          if (!validDob(trimmedValue)) {
            errors["not_dob"] = "Date of Birth is required.";
          }
          break;

        case "confirmDob":
          {
            if (!validDob(trimmedValue)) {
              errors["not_valid_confirmDob"] =
                "Confirm Date of Birth is required.";
            }
            if (trimmedValue != userInputs["dob"]?.value) {
              errors["not_dateOfBirthmatch"] =
                "Date of Birth And Confirm Date of Birth  Same";
            }
          }
          break;

        default:
          break;
      }

      console.log(errors, "here is errors");
      updateInputState(name, trimmedValue, errors, true);
    }
  };
  const [buttonHandlers, setButtonHandlers] = useState({});
  useEffect(() => {
    if (buttonHandlers.sendMobileOtp) {
      setButtonHandlers((prv) => ({ ...prv, ["sendMobileOtp"]: false }));
      sendPhoneOtpFinalFunction();
    }
    if (buttonHandlers.verifyMobileOtp) {
      setButtonHandlers((prv) => ({ ...prv, ["verifyMobileOtp"]: false }));
      verifyOtpFinalFunction();
    }
    if (buttonHandlers.sendMailOtp) {
      setButtonHandlers((prv) => ({ ...prv, ["sendMailOtp"]: false }));
      sendMailFinalFunction();
    }
    if (buttonHandlers.verifyEmailOtp) {
      setButtonHandlers((prv) => ({ ...prv, ["verifyEmailOtp"]: false }));
      verifyOtpMailFunction();
    }
  }, [userInputs, buttonHandlers]);
  const sendPhoneOtp = async () => {
    await inputRefs.current["fullName"].focus();
    await inputRefs.current["fullName"].blur();

    await inputRefs.current["confirmFullName"].focus();
    await inputRefs.current["confirmFullName"].blur();
    await inputRefs?.current?.["phoneNumber"]?.focus();
    await inputRefs?.current?.["phoneNumber"]?.blur();
    await inputRefs?.current?.["confirmMobileNumber"]?.focus();
    await inputRefs?.current?.["confirmMobileNumber"]?.blur();
    await inputRefs?.current?.["registrationNo"]?.focus();
    await inputRefs?.current?.["registrationNo"]?.blur();
    await inputRefs?.current?.["dob"]?.focus();
    await inputRefs?.current?.["confirmDob"]?.focus();
    await inputRefs.current?.["confirmDob"]?.blur();
    await inputRefs.current?.["confirm10registrationNo"].focus()
    await inputRefs.current?.["confirm10registrationNo"].blur()
    await inputRefs?.current?.["dob"]?.blur();

    setButtonHandlers((prv) => ({ ...prv, ["sendMobileOtp"]: true }));
  };
  const sendPhoneOtpFinalFunction = async () => {
    let errors = [
      ...Object.keys(userInputs["fullName"].errors),
      ...Object.keys(userInputs["confirmMobileNumber"].errors),
      ...Object.keys(userInputs["phoneNumber"].errors),
    ];
    console.log({ errors });

    if (errors.length === 0) {
      try {
        setSendMobileBtnLoading(true);
        setOtpMobileConfirmation(false);
        let config = {
          method: "post",
          url: "Portal/portal/generatePhoneOtpForPortalRegistration",
          data: {
            phoneNumber: userInputs?.["phoneNumber"]?.value,
          },
        };

        const response = await callApi(config);

        // Check if the response code is 201
        if (response?.data?.code == 201) {
          setSendMobileBtnLoading(false);
          toast.success("OTP sent successfully", { autoClose: 3000 });
        }
      } catch (error) {
        console.log(error, "here is error");
        toast.error(error.message, { autoClose: 2000 });
      }
    }
  };

  const verifyPhoneOtp = async () => {
    await inputRefs.current["fullName"].focus();
    await inputRefs.current["fullName"].blur();
    await inputRefs.current["confirmFullName"].focus();
    await inputRefs.current["confirmFullName"].blur();
    await inputRefs?.current?.["phoneNumber"]?.focus();
    await inputRefs?.current?.["phoneNumber"]?.blur();
    await inputRefs?.current?.["confirmMobileNumber"]?.focus();
    await inputRefs?.current?.["confirmMobileNumber"]?.blur();
    await inputRefs?.current?.["mobileOtp"]?.focus();
    await inputRefs?.current?.["mobileOtp"]?.blur();
    await inputRefs?.current?.["registrationNo"]?.focus();
    await inputRefs?.current?.["registrationNo"]?.blur();
    await inputRefs?.current?.["dob"]?.focus();
    await inputRefs?.current?.["dob"]?.blur();
    await inputRefs?.current?.["confirmDob"]?.focus();
    await inputRefs.current?.["confirmDob"]?.blur();
    await inputRefs.current?.["confirm10registrationNo"].focus()
    await inputRefs.current?.["confirm10registrationNo"].blur()
    setButtonHandlers((prv) => ({ ...prv, ["verifyMobileOtp"]: true }));
  };
  const verifyOtpFinalFunction = async () => {
    console.log("btn input", { userInputs });
    let errors = [
      ...Object.keys(userInputs["fullName"].errors),
      ...Object.keys(userInputs["confirmMobileNumber"].errors),
      ...Object.keys(userInputs["phoneNumber"].errors),
      ...Object.keys(userInputs["mobileOtp"].errors),
    ];
    console.log({ errors });
    if (errors.length == 0) {
      const hashedOtp = CryptoJS.SHA256(
        userInputs?.["mobileOtp"]?.value
      ).toString(CryptoJS.enc.Hex);

      try {
        setConfirmMObileOtpLoading(true);
        let config = {
          method: "post",
          url: "Portal/portal/verifyOTPForPhone",
          data: {
            phoneNumber: userInputs?.["phoneNumber"]?.value,
            otp: hashedOtp,
          },
        };
        let phoneOtpRes = await callApi(config);
        setPhoneOtpResponse(phoneOtpRes?.data);
        if (phoneOtpRes?.data?.code == 201) {
          toast.success("OTP for Phone No. is verified successfully", {
            autoClose: 3000,
          });
          setOtpMobileConfirmation(true);
          setConfirmMObileOtpLoading(false);
        } else {
          setConfirmMObileOtpLoading(false);
          toast.error("Please enter a valid OTP");
        }
      } catch (error) {
        console.log(error, "here is error");
        toast.error(error.message, { autoClose: 2000 });
      }
    }
  };

  const sendMailOtp = async () => {
    await inputRefs?.current["fullName"].focus();
    await inputRefs?.current["fullName"].blur();
    await inputRefs?.current["confirmFullName"].focus();
    await inputRefs.current["confirmFullName"].blur();
    await inputRefs?.current?.["emailId"]?.focus();
    await inputRefs?.current?.["emailId"]?.blur();
    await inputRefs?.current?.["confirmEmailId"]?.focus();
    await inputRefs?.current?.["confirmEmailId"]?.blur();
    await inputRefs?.current?.["registrationNo"]?.focus();
    await inputRefs?.current?.["registrationNo"]?.blur();
    await inputRefs?.current?.["dob"]?.focus();
    await inputRefs?.current?.["dob"]?.blur();
    await inputRefs?.current?.["confirmDob"]?.focus();
    await inputRefs.current?.["confirmDob"]?.blur();
    await inputRefs.current?.["confirm10registrationNo"].focus()
    await inputRefs.current?.["confirm10registrationNo"].blur()
    setButtonHandlers((prv) => ({ ...prv, ["sendMailOtp"]: true }));
  };
  const sendMailFinalFunction = async () => {
    console.log("btn input", { userInputs });
    let errors = [
      ...Object.keys(userInputs["fullName"].errors),
      ...Object.keys(userInputs["confirmEmailId"].errors),
      ...Object.keys(userInputs["emailId"].errors),
    ];
    console.log({ errors });
    if (errors.length == 0) {
      try {
        setEmailOtpLoading(true);
        setEmailOtpConformation(false);
        let config = {
          method: "post",
          url: "Portal/portal/generateMailOtpForPortalRegistration",
          data: {
            email: userInputs?.["emailId"]?.value,
          },
        };
        const response = await callApi(config);
        if (response?.data?.code == 201) {
          setEmailOtpLoading(false);
          toast.success("OTP sent successfully", { autoClose: 3000 });
        } else {
          setEmailOtpLoading(false);
        }
      } catch (error) {
        console.log(error, "here is error");
        toast.error(error.message, { autoClose: 2000 });
      }
    }
  };
  const verifyMailOtp = async () => {
    await inputRefs.current["fullName"].focus();
    await inputRefs.current["fullName"].blur();
    await inputRefs.current["confirmFullName"].focus();
    await inputRefs.current["confirmFullName"].blur();
    await inputRefs?.current?.["emailId"]?.focus();
    await inputRefs?.current?.["emailId"]?.blur();
    await inputRefs?.current?.["confirmEmailId"]?.focus();
    await inputRefs?.current?.["confirmEmailId"]?.blur();
    await inputRefs?.current?.["emailOtp"]?.focus();
    await inputRefs?.current?.["emailOtp"]?.blur();
    await inputRefs?.current?.["registrationNo"]?.focus();
    await inputRefs?.current?.["registrationNo"]?.blur();
    await inputRefs?.current?.["dob"]?.focus();
    await inputRefs?.current?.["dob"]?.blur();
    await inputRefs?.current?.["confirmDob"]?.focus();
    await inputRefs.current?.["confirmDob"]?.blur();
    await inputRefs.current?.["confirm10registrationNo"].focus()
    await inputRefs.current?.["confirm10registrationNo"].blur()
    setButtonHandlers((prv) => ({ ...prv, ["verifyEmailOtp"]: true }));
  };
  const verifyOtpMailFunction = async () => {
    let errors = [
      ...Object.keys(userInputs["fullName"].errors),
      ...Object.keys(userInputs["emailId"].errors),
      ...Object.keys(userInputs["confirmEmailId"].errors),
      ...Object.keys(userInputs["emailOtp"].errors),
    ];
    if (errors.length == 0) {
      const hashedOtp = CryptoJS.SHA256(
        userInputs?.["emailOtp"]?.value
      ).toString(CryptoJS.enc.Hex);

      try {
        setEmailConFormationLoading(true);
        let config = {
          method: "post",
          url: "Portal/portal/verifyOTPForMail",
          data: {
            email: userInputs?.["emailId"]?.value,
            emailOtp: hashedOtp,
          },
        };
        const responseMailOtp = await callApi(config);
        setMailOtpResponse(responseMailOtp?.data);
        if (responseMailOtp?.data?.code == 201) {
          toast.success("OTP for Email is verified successfully", {
            autoClose: 3000,
          });
          setEmailOtpConformation(true);
          setEmailConFormationLoading(false);
        } else {
          setEmailConFormationLoading(false);
          toast.error("Please enter a valid OTP");
        }
      } catch (error) {
        console.log(error, "here is error");
        toast.error(error.message, { autoClose: 2000 });
      }
    }
  };

  useEffect(() => {
    console.log({ phoneOtpResponse });
    console.log({ mailOtpResponse });
  }, [mailOtpResponse, phoneOtpResponse]);

  const registerUserCheck = async () => {
    await inputRefs?.current?.["fullName"]?.focus();
    await inputRefs?.current?.["fullName"]?.blur();
    await inputRefs.current["confirmFullName"].focus();
    await inputRefs.current["confirmFullName"].blur();
    await inputRefs?.current?.["phoneNumber"]?.focus();
    await inputRefs?.current?.["phoneNumber"]?.blur();
    await inputRefs?.current?.["confirmMobileNumber"]?.focus();
    await inputRefs?.current?.["confirmMobileNumber"]?.blur();
    await inputRefs?.current?.["emailId"]?.focus();
    await inputRefs?.current?.["emailId"]?.blur();
    await inputRefs?.current?.["confirmEmailId"]?.focus();
    await inputRefs?.current?.["confirmEmailId"]?.blur();
    await inputRefs?.current?.["emailOtp"]?.focus();
    await inputRefs?.current?.["emailOtp"]?.blur();
    await inputRefs?.current?.["mobileOtp"]?.focus();
    await inputRefs?.current?.["mobileOtp"]?.blur();
    await inputRefs?.current?.["registrationNo"]?.focus();
    await inputRefs?.current?.["registrationNo"]?.blur();
    await inputRefs?.current?.["dob"]?.focus();
    await inputRefs?.current?.["dob"]?.blur();
    await inputRefs?.current?.["confirmDob"]?.focus();
    await inputRefs.current?.["confirmDob"]?.blur();
    await inputRefs.current?.["confirm10registrationNo"].focus()
    await inputRefs.current?.["confirm10registrationNo"].blur()

    if (mailOtpResponse?.code == 201 && phoneOtpResponse.code == 201)
      registerUser();
    else {
      toast.warning(
        "Kindly complete the form to verify your mobile number and email address for registration.",
        { autoClose: 5000 }
      );
    }
  };

  // Register function

  const registerUser = async () => {
    if (!isChecked) {
      toast.warn("Kindly complete the declarartion for registration.", {
        autoClose: 5000,
      });
      return;
    }
    if (!verified) {
      toast.warn("Kindly verify the Captcha for registration.", {
        autoClose: 5000,
      });
      return;
    }
    try {
      setLoading(true); // Set loading to true before starting the API call
      let config = {
        method: "post",
        url: "Portal/portal/register",
        data: {
          emailId: userInputs?.["emailId"]?.value,
          metricRegistration: userInputs?.["registrationNo"]?.value,
          phoneNumber: userInputs?.["phoneNumber"]?.value,
          fullName: userInputs?.["fullName"]?.value,
          DateOfBirth: userInputs?.["dob"]?.value,
          emailOtpId: mailOtpResponse?.data?.otpIdentification,
          phoneOtpId: phoneOtpResponse?.data?.otpIdentification,
          reCAPTCHAtoken,
          registrationDeclarationStatus: isChecked,
        },
      };

      const responseRegister = await callApi(config);
      if (responseRegister?.data?.code === 201) {
        toast.success(responseRegister?.data?.message, { autoClose: 1000 });

        navigate("/login");
      } else {
        toast.warning(responseRegister?.data?.message, { autoClose: 5000 });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false after the API call completes
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    setReCAPTCHAToken(value);
    setVerified(true);
  }

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div className="registrationFormMain">
          <Card className="registrationFont job-registration-card">
            Registration
          </Card>
          <div className="formContainerStyle">
            <div className="jobRegistrationCard-main">
              <Card className="p-3 mt-3 mb-1 jobRegistrationCard ">
                <div className="job-registration-instruction">
                  <h5 className="registrationFontHead mt-1 job-registration-title">
                    Instructions:
                  </h5>
                  <Button
                    variant="link"
                    onClick={() => setOpen1(!open1)}
                    aria-controls="instructions-collapse"
                    aria-expanded={open1}
                    className="dropdown-btn"
                  >
                    {open1 ? "-" : "+"}
                  </Button>
                </div>
                <Collapse in={open1}>
                  <div className="collapse-one">
                    <p className="applicationform-text">
                      <strong className="registrationFont-head">
                        Application Form:
                      </strong>
                    </p>
                    <strong>
                      <p className="registrationFont mt-2">
                        Important Information
                      </p>
                    </strong>
                    <p className="registrationFont mt-2">
                      <strong>
                        Read the below instructions carefully, before filling up
                        the form :
                      </strong>
                    </p>
                    <p className="registrationFont mt-2">
                      <strong>
                        Fields marked with{" "}
                        <strong className="text-danger">*</strong> are
                        mandatory.
                      </strong>
                    </p>
                    <ol className="headList mt-2">
                      <li style={{ color: "brown", fontWeight: "bolder" }} className="registrationFont">
                        <strong>
                          1. Candidates must fill in the required details below to complete the registration process.
                        </strong>
                      </li>
                      <li className="registrationFont" style={{ color: "brown", fontWeight: "bolder" }}>
                        <strong>
                          2. Upon successful registration, you will receive a confirmation email and SMS.
                        </strong>
                      </li>
                      <li className="registrationFont" style={{ color: "brown", fontWeight: "bolder" }}>
                        <strong>
                          3.	Candidates are required to provide accurate information, including:
                          <ol className="headList">
                            <li className="registrationFont">
                              a)	Full Name
                            </li>
                            <li className="registrationFont">
                              b)	Mobile Number
                            </li>
                            <li className="registrationFont">
                              c)	Email Address
                            </li>
                            <li className="registrationFont">
                              d)	10th Registration Number
                            </li>
                            <li className="registrationFont">
                              e)	Date of Birth
                            </li>
                          </ol>
                        </strong>
                      </li>
                      <li className="registrationFont" style={{ color: "brown", fontWeight: "bolder" }}>
                        <strong>
                          4.	Registered users can log in using their Mobile Number or Email ID by entering the OTP received on the same.
                        </strong>
                      </li>
                      <li className="registrationFont" style={{ color: "brown", fontWeight: "bolder" }}>
                        <strong>
                          5.	For any assistance, you may contact the Helpdesk at 0674-2377400 /0674-2377425 between 10:00 AM and 5:30 PM on working days
                        </strong>
                      </li>
                    </ol>
                  </div>
                </Collapse>
              </Card>
              <Card className="p-3 mt-3 mb-5 jobRegistrationCard">
                <div className="job-registration-instruction">
                  <h5 className="registrationFontHead mt-1 job-registration-title">
                    Basic Details
                  </h5>
                  <Button
                    variant="link"
                    onClick={() => setOpen2(!open2)}
                    aria-controls="instructions-collapse"
                    aria-expanded={open2}
                    className="dropdown-btn"
                  >
                    {open2 ? "-" : "+"}
                  </Button>
                </div>
                <div className="noteStyle fw-bolder">
                  NOTE: AS RECORDED IN HSC OR EQUIVALENT EXAM
                </div>
                <Collapse in={open2}>
                  <Form>
                    <Row className="mt-1">
                      <Col xs={4} md={4} className="register-col-data">
                        <CustomTextInput
                          className="register-main-placeholder registerPlaceHolder"
                          id="fullName"
                          type="password"
                          label="Enter Candidate's Full Name"
                          required
                          placeholder="Candidate's  Full Name"
                          name="fullName"
                          touched={userInputs?.["fullName"]?.touched}
                          value={userInputs?.["fullName"]?.value.toUpperCase()}
                          onChange={(e) => {
                            setUserInputFn(e);
                            userInputs?.["fullName"]?.touched &&
                              checkForInputError(e);
                          }}
                          onBlur={(e) => checkForInputError(e)}
                          errors={userInputs?.["fullName"]?.errors}
                          ref={(el) => (inputRefs.current["fullName"] = el)}
                          onCopy={(e) => e.preventDefault()} // Prevents copy
                          onPaste={(e) => e.preventDefault()} // Prevents paste
                          onCut={(e) => e.preventDefault()}
                        />
                      </Col>

                      <Col xs={4} md={4} className="register-col-data">
                        <CustomTextInput
                          className="register-main-placeholder registerPlaceHolder"
                          id="confirmFullName"
                          type="text"
                          label="Confirm Candidate FullName"
                          required
                          placeholder="Confirm Candidate FullName"
                          name="confirmFullName"
                          touched={userInputs?.["confirmFullName"]?.touched}
                          value={userInputs?.["confirmFullName"]?.value.toUpperCase()}
                          onChange={(e) => {
                            setUserInputFn(e);
                            userInputs?.["confirmFullName"]?.touched &&
                              checkForInputError(e);
                          }}
                          onBlur={(e) => checkForInputError(e)}
                          errors={userInputs?.["confirmFullName"]?.errors}
                          ref={(el) =>
                            (inputRefs.current["confirmFullName"] = el)
                          }
                          onCopy={(e) => e.preventDefault()} // Prevents copy
                          onPaste={(e) => e.preventDefault()} // Prevents paste
                          onCut={(e) => e.preventDefault()}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={4} md={4} className="register-col-data">
                        <CustomTextInput
                          className="register-main-placeholder registerPlaceHolder"
                          id="dob"
                          type="date"
                          label="Enter Candidate's Date Of birth."
                          required
                          placeholder="Date Of birth."
                          name="dob"
                          touched={userInputs?.["dob"]?.touched}
                          value={userInputs?.["dob"]?.value}
                          onChange={(e) => {
                            setUserInputFn(e);
                            userInputs?.["dob"]?.touched &&
                              checkForInputError(e);
                          }}
                          onBlur={(e) => checkForInputError(e)}
                          errors={userInputs?.["dob"]?.errors}
                          ref={(el) => (inputRefs.current["dob"] = el)}
                          onCopy={(e) => e.preventDefault()} // Prevents copy
                          onPaste={(e) => e.preventDefault()} // Prevents paste
                          onCut={(e) => e.preventDefault()}
                        />
                      </Col>
                      <Col xs={4} md={4} className="register-col-data">
                        <CustomTextInput
                          className="register-main-placeholder registerPlaceHolder"
                          id="confirmDob"
                          type="date"
                          label="Confirm Candidate Date of Birth"
                          required
                          placeholder="Date Of birth."
                          name="confirmDob"
                          touched={userInputs?.["confirmDob"]?.touched}
                          value={userInputs?.["confirmDob"]?.value}
                          onChange={(e) => {
                            setUserInputFn(e);
                            userInputs?.["confirmDob"]?.touched &&
                              checkForInputError(e);
                          }}
                          onCopy={(e) => e.preventDefault()} // Prevents copy
                          onPaste={(e) => e.preventDefault()} // Prevents paste
                          onCut={(e) => e.preventDefault()}
                          onBlur={(e) => checkForInputError(e)}
                          errors={userInputs?.["confirmDob"]?.errors}
                          ref={(el) => (inputRefs.current["confirmDob"] = el)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={4} xs={12} className="register-col-data">
                        <CustomTextInput
                          className="register-main-placeholder"
                          id="registrationNo"
                          type="password"
                          label="10th Registration No."
                          required
                          placeholder=" Registration No."
                          name="registrationNo"
                          touched={userInputs?.["registrationNo"]?.touched}
                          value={userInputs?.["registrationNo"]?.value}
                          onChange={(e) => {
                            setUserInputFn(e);
                            userInputs?.["registrationNo"]?.touched &&
                              checkForInputError(e);
                          }}
                          onBlur={(e) => checkForInputError(e)}
                          errors={userInputs?.["registrationNo"]?.errors}
                          ref={(el) =>
                            (inputRefs.current["registrationNo"] = el)
                          }
                          onCopy={(e) => e.preventDefault()} // Prevents copy
                          onPaste={(e) => e.preventDefault()} // Prevents paste
                          onCut={(e) => e.preventDefault()}
                        />
                      </Col>
                      <Col md={4} xs={12} className="register-col-data">
                        <CustomTextInput
                          className="register-main-placeholder"
                          id="confirm10registrationNo"
                          type="text"
                          label="Confirm 10th Registration No."
                          required
                          placeholder="Confirm Registration No."
                          name="confirm10registrationNo"
                          touched={
                            userInputs?.["confirm10registrationNo"]?.touched
                          }
                          value={userInputs?.["confirm10registrationNo"]?.value}
                          onChange={(e) => {
                            setUserInputFn(e);
                            userInputs?.["confirm10registrationNo"]?.touched &&
                              checkForInputError(e);
                          }}
                          onBlur={(e) => checkForInputError(e)}
                          errors={
                            userInputs?.["confirm10registrationNo"]?.errors
                          }
                          ref={(el) =>
                            (inputRefs.current["confirm10registrationNo"] = el)
                          }
                          onCopy={(e) => e.preventDefault()} // Prevents copy
                          onPaste={(e) => e.preventDefault()} // Prevents paste
                          onCut={(e) => e.preventDefault()}
                        />
                      </Col>
                    </Row>
                    <div className="noteStyle fw-bolder mt-3">
                      NOTE: YOU ARE REQUIRED TO PROVIDE YOUR CORRECT MOBILE
                      NUMBER AND E-MAIL ADDRESS, AS ALL IMPORTANT COMMUNICATIONS
                      WILL BE SENT TO YOU USING THE SAME.
                    </div>
                    <Row className="mt-2">
                      <Col md={4} xs={12} className="register-col-data">
                        <CustomTextInput
                          className="register-main-placeholder"
                          id="phoneNumber"
  
                          label="Mobile Number"
                          type="password"
                          required
                          placeholder="Enter Mobile Number"
                          name="phoneNumber"
                          touched={userInputs?.["phoneNumber"]?.touched}
                          value={userInputs?.["phoneNumber"]?.value}
                          onChange={(e) => {
                            setUserInputFn(e);
                            userInputs?.["phoneNumber"]?.touched &&
                              checkForInputError(e);
                          }}
                          onBlur={(e) => checkForInputError(e)}
                          errors={userInputs?.["phoneNumber"]?.errors}
                          ref={(el) => (inputRefs.current["phoneNumber"] = el)}
                          onCopy={(e) => e.preventDefault()} // Prevents copy
                          onPaste={(e) => e.preventDefault()} // Prevents paste
                          onCut={(e) => e.preventDefault()}
                        />
                      </Col>
                      <Col md={4} xs={7} className="register-col-data">
                        <CustomTextInput
                          className="register-main-placeholder"
                          id="confirmMobileNumber"
                          label="Confirm Mobile Number"
                          type="number"
                          required
                          placeholder="Enter Confirm Mobile Number"
                          name="confirmMobileNumber"
                          touched={userInputs?.["confirmMobileNumber"]?.touched}
                          value={userInputs?.["confirmMobileNumber"]?.value}
                          onChange={(e) => {
                            setUserInputFn(e);
                            userInputs?.["confirmMobileNumber"]?.touched &&
                              checkForInputError(e);
                          }}
                          onBlur={(e) => checkForInputError(e)}
                          errors={userInputs?.["confirmMobileNumber"]?.errors}
                          ref={(el) =>
                            (inputRefs.current["confirmMobileNumber"] = el)
                          }
                          onCopy={(e) => e.preventDefault()} // Prevents copy
                          onPaste={(e) => e.preventDefault()} // Prevents paste
                          onCut={(e) => e.preventDefault()}
                        />
                      </Col>
                      <Col md={4} xs={5}>
                        <div className="mt-4">
                          <>
                            <Button
                              variant="primary"
                              className="sendOtp-btn"
                              disabled={SendMobileBtnLoading}
                              onClick={() => {
                                sendPhoneOtp();
                              }}
                            >
                              {!SendMobileBtnLoading ? (
                                "Send OTP "
                              ) : (
                                <Spinner size="sm"></Spinner>
                              )}
                            </Button>
                          </>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4} xs={7} className="register-col-data">
                        <div className="position-relative">
                          <CustomTextInput
                            id="mobileOtp"
                            label="Mobile OTP"
                            type="text"
                            required
                            placeholder="Enter Mobile OTP"
                            name="mobileOtp"
                            touched={userInputs?.["mobileOtp"]?.touched}
                            onChange={(e) => {
                              setUserInputFn(e);
                              userInputs?.["mobileOtp"]?.touched &&
                                checkForInputError(e);
                            }}
                            onBlur={(e) => checkForInputError(e)}
                            value={userInputs?.["mobileOtp"]?.value}
                            errors={userInputs?.["mobileOtp"]?.errors}
                            ref={(el) => (inputRefs.current["mobileOtp"] = el)}
                            className="pr-4 register-main-placeholder"
                          />
                          {mobileOtpConformation && (
                            <IoMdCheckmark
                              className="position-absolute"
                              style={{
                                right: "10px",
                                top: "70%",
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                                color: "green",
                              }}
                            />
                          )}
                        </div>
                      </Col>

                      <Col md={6} xs={5}>
                        <div className="mt-4">
                          <Button
                            variant="primary"
                            className="sendOtp-btn"
                            disabled={confirmMObileOtpLoading}
                            onClick={() => verifyPhoneOtp()}
                          >
                            {!confirmMObileOtpLoading ? (
                              "Confirm OTP"
                            ) : (
                              <Spinner size="sm"></Spinner>
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col xs={12} md={4} className="register-col-data">
                        <CustomTextInput
                          className="register-main-placeholder"
                          id="emailId"
                          label="Email"
                          type="password"
                          required
                          placeholder="Enter Email"
                          name="emailId"
                          touched={userInputs?.["emailId"]?.touched}
                          value={userInputs?.["emailId"]?.value}
                          onChange={(e) => {
                            setUserInputFn(e);
                            userInputs?.["emailId"]?.touched &&
                              checkForInputError(e);
                          }}
                          onBlur={(e) => checkForInputError(e)}
                          errors={userInputs?.["emailId"]?.errors}
                          ref={(el) => (inputRefs.current["emailId"] = el)}
                        />
                      </Col>
                      <Col xs={7} md={4} className="register-col-data">
                        <CustomTextInput
                          className="register-main-placeholder"
                          id="confirmEmailId"
                          label="Confirm Email"
                          type="text"
                          required
                          placeholder="Enter Confirm Email"
                          name="confirmEmailId"
                          touched={userInputs?.["confirmEmailId"]?.touched}
                          value={userInputs?.["confirmEmailId"]?.value}
                          onChange={(e) => {
                            setUserInputFn(e);
                            checkForInputError(e);
                          }}
                          onBlur={(e) => checkForInputError(e)}
                          errors={userInputs?.["confirmEmailId"]?.errors}
                          ref={(el) =>
                            (inputRefs.current["confirmEmailId"] = el)
                          }
                        />
                      </Col>
                      <Col md={4} xs={5}>
                        <div className="mt-4">
                          <Button
                            variant="primary"
                            className="sendOtp-btn"
                            disabled={emailOtpLoading}
                            onClick={() => sendMailOtp()}
                          >
                            {!emailOtpLoading ? (
                              "Send OTP"
                            ) : (
                              <Spinner size="sm"></Spinner>
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4} xs={7} className="register-col-data">
                        <div className="position-relative">
                          <CustomTextInput
                            className="register-main-placeholder"
                            id="emailOtp"
                            type="text"
                            label="Email OTP"
                            required
                            placeholder="Enter Confirm Email"
                            name="emailOtp"
                            touched={userInputs?.["emailOtp"]?.touched}
                            value={userInputs?.["emailOtp"]?.value}
                            onChange={(e) => {
                              setUserInputFn(e);
                              userInputs?.["emailOtp"]?.touched &&
                                checkForInputError(e);
                            }}
                            onBlur={(e) => checkForInputError(e)}
                            errors={userInputs?.["emailOtp"]?.errors}
                            ref={(el) => (inputRefs.current["emailOtp"] = el)}
                          />
                          {emailOtpConformation && (
                            <IoMdCheckmark
                              className="position-absolute"
                              style={{
                                right: "10px",
                                top: "70%",
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                                color: "green",
                              }}
                            />
                          )}
                        </div>
                      </Col>

                      <Col>
                        <div className="mt-4">
                          <Button
                            variant="primary"
                            disabled={emailConformationLoading}
                            className="sendOtp-btn"
                            onClick={() => verifyMailOtp()}
                          >
                            {!emailConformationLoading ? (
                              "Confirm OTP"
                            ) : (
                              <Spinner size="sm"></Spinner>
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Collapse>

                <div style={{ display: "flex", marginTop: "30px" }}>
                  <input
                    type="checkbox"
                    id="declaration-checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    style={{ marginRight: "10px", marginTop: "-23px" }}
                  />
                  <label
                    htmlFor="declaration-checkbox"
                    style={{
                      fontSize: "14px",
                      lineHeight: "1.5",
                      fontFamily: "'Roboto', sans-serif",
                      color: "brown",
                    }}
                  >
                    <strong>

                      I hereby declare that the above information is true to the
                      best of my knowledge. I am aware that OMC will be sending
                      important information on my registered details. I declare
                      that I have referred the advertisement and ensured my
                      eligibility before applying. Also I declare that I have read
                      and understood all the instructions specified in the
                      Advertisement and undertake to abide by the same.
                    </strong>
                  </label>
                </div>

                <div className="mt-3">
                  <ReCAPTCHA
                    sitekey="6LeHznUqAAAAAA7_pR1fVuFICbbFAyxM0HtdnNu2"
                    onChange={onChange}
                  />
                </div>
              </Card>
            </div>
          </div>
          <Card className="registrationFont registrationForm-bottom-card">
            <div>
              <Button
                variant="primary"
                className="registrationFont registrationForm-bottom-card-cancel-btn"
                onClick={() => navigate("/login")}
              >
                CANCEL
              </Button>
              <Button
                variant="primary"
                className="registrationFont registrationForm-bottom-card-submit-btn"
                onClick={registerUserCheck}
              >
                SUBMIT
              </Button>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};
export default ApplicantRegistration;
