import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Modal, Badge } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { MdDeleteOutline } from 'react-icons/md';
import Upload, { AWSUpload } from "../../Upload/imageUpload";
// import pdf from "../../assets/images/pdf.png";
import pdf from "../../assets/images/pdf-icon.png"
import { IoEyeOutline } from "react-icons/io5";
import { toast } from 'react-toastify';
import { UploadPdf } from '../../Upload/newUploadLogic';

const ReservationCategory = ({ handelApplicationDetailsChange, applicationDetails, style, allowedCategory }) => {

  const [formData, setFormData] = useState({
    reservationCategory: applicationDetails?.reservationCategory || '',
    reservationCertificateNumber: applicationDetails?.reservationCertificateNumber || '',
    reservationCertificateIssuedDate: applicationDetails?.reservationCertificateIssuedDate || '',
    reservationCertificateIssued: applicationDetails?.reservationCertificateIssued || '',
    reservationCertificateFile: null,
    reservationCertificateVerified: false,
  });

  const [fieldErrors, setFieldErrors] = useState({});
  const [showIframe, setShowIframe] = useState(null); // State to show iframe modal

  useEffect(() => {
    if (formData && formData?.reservationCategory) {
      handelApplicationDetailsChange(formData);
    }
  }, [formData]);

  useEffect(() => {
    if (applicationDetails) {
      setFormData({
        reservationCategory: applicationDetails?.reservationCategory || '',
        reservationCertificateNumber: applicationDetails?.reservationCertificateNumber || '',
        reservationCertificateIssuedDate: applicationDetails?.reservationCertificateIssuedDate || '',
        reservationCertificateIssued: applicationDetails?.reservationCertificateIssued || '',
        reservationCertificateFile: applicationDetails?.reservationCertificateFile || null,
        reservationCertificateVerified: applicationDetails?.reservationCertificateVerified || false, // Track verified status
      });
    }
  }, []);

  const handleUploadImage = async (event, name) => {
    event?.preventDefault();

    const file = event?.target?.files[0];
    const isPDF = file.type === "application/pdf";
    const isUnder2MB = file.size <= 2 * 1024 * 1024;
    if (!isPDF) {
      toast.warn("Invalid file type. Only PDF files are allowed.");
      return;
    }
    if (!isUnder2MB) {
      toast.warn("File size exceeds 2MB. Please upload a smaller file.");
      return;
    }
    if (file) {
      const imageUrl = await UploadPdf(file);
      setFormData({ ...formData, [name]: imageUrl, reservationCertificateVerified: false }); // Reset verified status when new file is uploaded
    } else {
      setFormData({ ...formData, [name]: null });
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'reservationCertificateFile') {
      const file = files[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
        reservationCertificateVerified: false, // Reset verification when file changes
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const checkErrorFn = (fieldKey, immediateValue) => {
    let errCount = 0;

    if (!immediateValue) {
      errCount++;
      setFieldErrors((prevState) => ({
        ...prevState,
        [fieldKey]: {
          errors: ["This field is required."],
          touched: true,
        },
      }));
    } else {
      switch (fieldKey) {
        case "reservationCertificateNumber":
          const regexCertNo = /^[a-zA-Z0-9\s.,!@#$%^&*()_+=\-\\:;'"<>?/|]*$/;
          if (!regexCertNo.test(immediateValue)) {
            errCount++;
            setFieldErrors((prevState) => ({
              ...prevState,
              [fieldKey]: {
                errors: ["Invalid certificate number."],
                touched: true,
              },
            }));
          } else {
            setFieldErrors((prevState) => ({
              ...prevState,
              [fieldKey]: { errors: [], touched: true },
            }));
          }
          break;

        case "reservationCertificateIssuedDate":
          const regexDate = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
          if (!regexDate.test(immediateValue)) {
            errCount++;
            setFieldErrors((prevState) => ({
              ...prevState,
              [fieldKey]: {
                errors: ["Enter a valid date in the format YYYY-MM-DD."],
                touched: true,
              },
            }));
          } else {
            setFieldErrors((prevState) => ({
              ...prevState,
              [fieldKey]: { errors: [], touched: true },
            }));
          }
          break;

        case "reservationCertificateFile":
          if (!formData.reservationCertificateFile) {
            errCount++;
            setFieldErrors((prevState) => ({
              ...prevState,
              [fieldKey]: {
                errors: ["Please upload the reservation certificate."],
                touched: true,
              },
            }));
          } else if (!formData.reservationCertificateVerified) {
            errCount++;
            setFieldErrors((prevState) => ({
              ...prevState,
              [fieldKey]: {
                errors: ["Please Verify The File"],
                touched: true,
              },
            }));
          } else {
            setFieldErrors((prevState) => ({
              ...prevState,
              [fieldKey]: { errors: [], touched: true },
            }));
          }
          break;

        default:
          break;
      }
    }

    if (errCount < 1) {
      setFieldErrors((prevState) => ({
        ...prevState,
        [fieldKey]: { errors: [], touched: true },
      }));
    }
  };

  return (
    <Row>
      <Col xs={12} md={3}>
        <div className="mb-2">
          <label className="form-label inputBoxHeadForm" style={style.label}>
            Reservation Category<span className="mx-1 text-danger">*</span>
          </label>
          <select
            name="reservationCategory"
            value={formData.reservationCategory}
            onChange={(e) => {
              handleInputChange(e);
              checkErrorFn("reservationCategory", e.target.value);
            }}
            onBlur={() => checkErrorFn("reservationCategory", formData['reservationCategory'])}
            style={
              fieldErrors?.["reservationCategory"]?.errors?.length > 0 &&
                fieldErrors?.["reservationCategory"]?.touched
                ? { ...style.input, ...style.errorBox }
                : style.input
            }
            required
          >
            <option value="">Select</option>
            {allowedCategory?.map((item, idx) => (
              <option key={idx} value={item}>
                {item}
              </option>
            ))}
          </select>
          {fieldErrors?.["reservationCategory"]?.errors?.length > 0 &&
            fieldErrors?.["reservationCategory"]?.touched &&
            fieldErrors?.["reservationCategory"]?.errors.map((err, idx) => (
              <span key={idx} className="errorPresent" style={style.errorSpan}>
                {err}
                <br />
              </span>
            ))}
        </div>
      </Col>
      {/* ///////////////////////////////// */}

      {(formData.reservationCategory === "SC" ||
        formData.reservationCategory === "ST" ||
        formData.reservationCategory === "SEBC") && (
          <>
            <Col xs={12} md={6}>
              <div className="mb-3">
                <label className="form-label inputBoxHeadForm" style={style.label}>
                  Is the {formData.reservationCategory} certificate issued by competent authority?
                  <span className="mx-1 text-danger">*</span>
                </label>
                <select
                  name="reservationCertificateIssued"
                  value={formData.reservationCertificateIssued}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleInputChange(e);
                    checkErrorFn("reservationCertificateIssued", value);

                    if (value === "No") {
                      handleInputChange({
                        target: {
                          name: "reservationCategory",
                          value: allowedCategory.includes("UR") ? "UR" : ""
                        }
                      });
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        reservationCertificateNumber: '',
                        reservationCertificateIssuedDate: '',
                        reservationCertificateIssued: '',
                        reservationCertificateFile: null,
                        reservationCertificateVerified: false,
                      }));
                    }
                  }}
                  onBlur={() => checkErrorFn("reservationCertificateIssued", formData['reservationCertificateIssued'])}
                  style={
                    fieldErrors?.["reservationCertificateIssued"]?.errors?.length > 0 &&
                      fieldErrors?.["reservationCertificateIssued"]?.touched
                      ? { ...style.input, ...style.errorBox }
                      : style.input
                  }
                  required
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                {fieldErrors?.["reservationCertificateIssued"]?.errors?.length > 0 &&
                  fieldErrors?.["reservationCertificateIssued"]?.touched &&
                  fieldErrors?.["reservationCertificateIssued"]?.errors.map((err, idx) => (
                    <span key={idx} className="errorPresent" style={style.errorSpan}>
                      {err}
                      <br />
                    </span>
                  ))}
              </div>
            </Col>

            {formData.reservationCertificateIssued === "Yes" && (
              <>
                <Col xs={6} md={3}>
                  <div className="mb-2">
                    <label className="form-label inputBoxHeadForm" style={style.label}>
                      Certificate Number<span className="mx-1 text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id='certificateNumberReser'
                      name="reservationCertificateNumber"
                      value={formData.reservationCertificateNumber}
                      onChange={(e) => {
                        handleInputChange(e);
                        checkErrorFn("reservationCertificateNumber", e.target.value);
                      }}
                      onBlur={() => checkErrorFn("reservationCertificateNumber", formData['reservationCertificateNumber'])}
                      style={
                        fieldErrors?.["reservationCertificateNumber"]?.errors?.length > 0 &&
                          fieldErrors?.["reservationCertificateNumber"]?.touched
                          ? { ...style.input, ...style.errorBox }
                          : style.input
                      }
                      required
                    />
                    {fieldErrors?.["reservationCertificateNumber"]?.errors?.length > 0 &&
                      fieldErrors?.["reservationCertificateNumber"]?.touched &&
                      fieldErrors?.["reservationCertificateNumber"]?.errors.map((err, idx) => (
                        <span key={idx} className="errorPresent" style={style.errorSpan}>
                          {err}
                          <br />
                        </span>
                      ))}
                  </div>
                </Col>

                <Col xs={6} md={3}>
                  <div className="mb-2">
                    <label className="form-label inputBoxHeadForm" style={style.label}>
                      Issued Date<span className="mx-1 text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      id='issueDateReser'
                      name="reservationCertificateIssuedDate"
                      value={formData?.reservationCertificateIssuedDate}
                      onChange={(e) => {
                        handleInputChange(e);
                        checkErrorFn("reservationCertificateIssuedDate", e.target.value);
                      }}
                      onBlur={() => checkErrorFn("reservationCertificateIssuedDate", formData['reservationCertificateIssuedDate'])}
                      style={
                        fieldErrors?.["reservationCertificateIssuedDate"]?.errors?.length > 0 &&
                          fieldErrors?.["reservationCertificateIssuedDate"]?.touched
                          ? { ...style.input, ...style.errorBox }
                          : style.input
                      }
                      required
                      max={new Date().toISOString().split('T')[0]}
                    />
                    {fieldErrors?.["reservationCertificateIssuedDate"]?.errors?.length > 0 &&
                      fieldErrors?.["reservationCertificateIssuedDate"]?.touched &&
                      fieldErrors?.["reservationCertificateIssuedDate"]?.errors.map((err, idx) => (
                        <span key={idx} className="errorPresent" style={style.errorSpan}>
                          {err}
                          <br />
                        </span>
                      ))}
                  </div>
                </Col>

                <Col xs={12} md={3}>
                  <div className="mb-3">
                    <label className="form-label inputBoxHeadForm" htmlFor="reservationCertificateFile" style={style.label}>
                      Upload Certificate<span className="mx-1 text-danger">*</span>
                    </label><br />
                    <button
                      style={{
                        borderRadius: '10px',
                        height: '40px',
                        padding: '5px 15px',
                        fontSize: '12px',
                        cursor: 'pointer',
                        margin: '15px 0',
                        marginTop: '0',
                        border: 'none',
                        backgroundColor:
                          fieldErrors?.["reservationCertificateFile"]?.touched &&
                            fieldErrors?.["reservationCertificateFile"]?.errors?.length > 0
                            ? '#921d1e'
                            : '#f0f0f0',
                        color:
                          fieldErrors?.["reservationCertificateFile"]?.touched &&
                            fieldErrors?.["reservationCertificateFile"]?.errors?.length > 0
                            ? '#fff'
                            : '#333',
                      }}
                      onClick={() => document.getElementById('reservationCertificateFile_input').click()}  // Trigger the hidden input
                      onBlur={() => checkErrorFn("reservationCertificateFile", formData['reservationCertificateFile'])} // Validate on blur
                    >
                      Upload File
                    </button>

                    {/* Hidden input for file selection */}
                    <input
                      type="file"
                      id="reservationCertificateFile_input"
                      accept=".pdf,.jpg,.jpeg,.png"
                      style={{ display: 'none' }} // Hidden file input
                      onChange={(e) => {
                        handleUploadImage(e, "reservationCertificateFile");
                        checkErrorFn("reservationCertificateFile", e.target.value); // Validate the file
                      }}
                      onBlur={() => checkErrorFn("reservationCertificateFile", formData["reservationCertificateFile"])} // Validate on blur
                    />

                    <br />
                    {fieldErrors?.["reservationCertificateFile"]?.errors?.length > 0 &&
                      fieldErrors?.["reservationCertificateFile"]?.touched &&
                      fieldErrors?.["reservationCertificateFile"]?.errors.map((err, idx) => (
                        <span key={idx} className="errorPresent" style={style.errorSpan}>
                          {err}
                          <br />
                        </span>
                      ))}
                  </div>
                </Col>

                {formData.reservationCertificateFile && (
                  <React.Fragment className='docResponsive'>
                    {/* <Col xs={12} md={2}>
                    <div className="mb-2">
                      <a
                        href={formData.reservationCertificateFile}
                        download="Reservation-CertificateFile.pdf"
                        title="Download PDF"
                      >
                        <img
                          src={pdf}
                          style={{ height: '100px', width: 'auto', cursor: 'pointer' }}
                          className="p-2"
                          alt="PDF"
                        />
                      </a>
                    </div>
                  </Col> */}

                    <Col xs={12} md={1}>
                      <img
                        src={pdf} // Placeholder for the PDF
                        alt="PDF preview"
                        style={{ width: "40px", cursor: "pointer", marginTop: '18px' }}
                        onClick={() => setShowIframe("reservationCertificateFile")}
                      />
                    </Col>

                    {!formData.reservationCertificateVerified && (
                      <Col xs={12} md={1}>
                        <Button
                          // className="ms-2 verifybutton verifyBtn"
                          style={{ marginLeft: 'auto', marginTop: '20px' }}
                          onClick={() => setShowIframe("reservationCertificateFile")}
                        >
                          <span className='ps-1'>Verify</span>
                        </Button>
                      </Col>
                    )}

                    {formData.reservationCertificateVerified && (
                      <Col xs={12} md={1} className='mt-4'>
                        <Badge bg="success">
                          <FaCheckCircle size={"18"} className="me-2" />
                          Verified
                        </Badge>
                      </Col>
                    )}

                    <Col xs={12} md={2}>
                      <Button
                        style={{ marginLeft: '0px', marginTop: '20px' }}
                        variant='outline'
                        onClick={() => setShowIframe("reservationCertificateFile")}
                      >
                        <IoEyeOutline size={"20"} />
                      </Button>
                      <Button
                        variant="outline"
                        onClick={() => {
                          setFormData({
                            ...formData,
                            reservationCertificateFile: null,
                            reservationCertificateVerified: false,
                          });
                        }}
                        style={{ marginLeft: '0px', marginTop: '20px' }}
                      >
                        {/* <Badge bg="danger" > */}
                        <MdDeleteOutline size={"20"} />
                        {/* <span className='ps-2'>Delete</span> */}
                        {/* </Badge> */}
                        {/* <MdDeleteOutline size={"25"} /> */}
                      </Button>
                    </Col>

                    {showIframe && (
                      <Modal
                        size="lg"
                        show={showIframe === "reservationCertificateFile"}
                        onHide={() => setShowIframe(null)}
                        centered
                      >
                        <Modal.Body className="text-center">
                          <iframe
                            src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(
                              formData.reservationCertificateFile
                            )}`}
                            width="100%"
                            style={{ border: "none", height: "80vh" }}
                            title="PDF Viewer"
                          />
                          {!formData.reservationCertificateVerified && (
                            <Button
                              className="mt-2"
                              onClick={() => {
                                setFormData({
                                  ...formData,
                                  reservationCertificateVerified: true,
                                });
                                setFieldErrors((prevErrors) => ({
                                  ...prevErrors,
                                  reservationCertificateFile: { errors: [], touched: true },
                                }));
                                setShowIframe(null); // Close modal after verification
                              }}
                            >
                              Confirm Verification
                            </Button>

                          )}
                          <Button
                            className="mt-2"
                            style={{ borderRadius: "10px" }}
                            variant="secondary"
                            onClick={() => setShowIframe(false)}
                          >
                            Close
                          </Button>
                        </Modal.Body>

                      </Modal>
                    )}
                  </React.Fragment>
                )}
              </>
            )}
          </>
        )}
    </Row>
  );
};

export default ReservationCategory;
