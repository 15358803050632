import React, { useEffect, useRef, useState } from "react";
import { Collapse, Button, Card, Spinner, Container } from "react-bootstrap";
import { Row, Col, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import Preloader from "../components/preloader";
import CustomTextInput from "./SimpleInputComponents/simpleTextInput";
import { IoMdCheckmark } from "react-icons/io";
import { callApi } from "../constant/callApi";
import CryptoJS from "crypto-js";
import { useNavigate, useLocation } from "react-router-dom";

const AccountRecovery = () => {
  const inputRefs = useRef({});
  const navigate = useNavigate();
  const location = useLocation();
  const [userInputs, setUserInputs] = useState({});
  const [mailOtpResponse, setMailOtpResponse] = useState({});
  const [phoneOtpResponse, setPhoneOtpResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [SendMobileBtnLoading, setSendMobileBtnLoading] = useState(false);
  const [confirmMObileOtpLoading, setConfirmMObileOtpLoading] = useState(false);
  const [mobileOtpConformation, setOtpMobileConfirmation] = useState(false);
  const [emailOtpLoading, setEmailOtpLoading] = useState(false);
  const [emailOtpConformation, setEmailOtpConformation] = useState(false);
  const [emailConformationLoading, setEmailConFormationLoading] =
    useState(false);
  const [receivedUser_id, setReceivedUser_id] = useState("");

  const [applicantDataAvailable, setApplicantDataAvailable] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    fatherName: "",
    motherName: "",
    currentMobile: "",
    currentEmail: "",
    Dob: "",
    registrationNumber10th: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const setUserInputFn = (e) => {
    const { name, value } = e.target;

    setUserInputs((prevInputs) => ({
      ...prevInputs,
      [name]: {
        ...prevInputs[name],
        value: value,
      },
    }));
  };

  const handleEmailOtp = () => {};
  const handlePhoneOtp = () => {};

  function updateInputState(name, value, errors, touched) {
    setUserInputs((prevInputs) => {
      const newInputs = { ...prevInputs };

      if (!newInputs[name]) {
        newInputs[name] = { value: "", errors: {} };
      }

      newInputs[name] = {
        ...newInputs[name],
        value,
        errors,
        touched,
      };

      return newInputs;
    });
  }
  function validateEmail(value) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(value);
  }

  function validateAlphaSpace(value) {
    const regex = /^[a-zA-Z ]+$/;
    return regex.test(value);
  }
  function validate10Digit(value) {
    // Regex to check for exactly 10 digits
    const regex = /^\d{10}$/;
    return regex.test(value);
  }
  function validate6Digit(value) {
    // Regex to check for exactly 10 digits
    const regex = /^\d{6}$/;
    return regex.test(value);
  }

  const checkForInputError = (e) => {
    const { name, value, required } = e.target;
    const trimmedValue = value;

    if (!required && (trimmedValue === "" || trimmedValue == null)) {
      // No validation for non-required empty values
      updateInputState(name, trimmedValue, {}, true);
    } else {
      const errors = {};

      switch (name) {
        case "fullName":
          if (!validateAlphaSpace(trimmedValue)) {
            errors["not_AlphaSpace"] =
              "Only Alphabet and Spaces are allowed in this input.";
          }
          break;
        case "phoneNumber":
          if (!validate10Digit(trimmedValue)) {
            errors["not_10digit"] = "Only 10 Digits allowed in this input.";
          }
          break;
        case "confirmMobileNumber":
          if (!validate10Digit(trimmedValue)) {
            errors["not_10digit"] = "Only 10 Digits allowed in this input.";
          }
          if (trimmedValue != userInputs?.["phoneNumber"]?.value) {
            errors["notMatching"] = "Mobile Number Not Matching";
          }
          break;
        case "emailId":
          if (!validateEmail(trimmedValue)) {
            errors["not_email"] = "Please Enter a valid Email Id.";
          }
          break;
        case "confirmEmailId":
          if (!validateEmail(trimmedValue)) {
            errors["not_email"] = "Please Enter a valid Email Id.";
          }
          if (trimmedValue != userInputs?.["emailId"]?.value) {
            errors["notMatching"] = "Email Id Not Matching";
          }
          break;
        case "mobileOtp":
          if (!validate6Digit(trimmedValue)) {
            errors["not_email"] = "Only 6 Digits allowed in this input.";
          }
          break;
        case "emailOtp":
          if (!validate6Digit(trimmedValue)) {
            errors["not_email"] = "Only 6 Digits allowed in this input.";
          }
          break;

        default:
          break;
      }

      updateInputState(name, trimmedValue, errors, true);
    }
  };

  const [buttonHandlers, setButtonHandlers] = useState({});

  useEffect(() => {
    if (buttonHandlers.sendMobileOtp) {
      setButtonHandlers((prv) => ({ ...prv, ["sendMobileOtp"]: false }));
      sendPhoneOtpFinalFunction();
    }
    if (buttonHandlers.verifyMobileOtp) {
      setButtonHandlers((prv) => ({ ...prv, ["verifyMobileOtp"]: false }));
      verifyOtpFinalFunction();
    }
    if (buttonHandlers.sendMailOtp) {
      setButtonHandlers((prv) => ({ ...prv, ["sendMailOtp"]: false }));
      sendMailFinalFunction();
    }
    if (buttonHandlers.verifyEmailOtp) {
      setButtonHandlers((prv) => ({ ...prv, ["verifyEmailOtp"]: false }));
      verifyOtpMailFunction();
    }
  }, [userInputs, buttonHandlers]);
  const sendPhoneOtp = async () => {
    // await inputRefs.current["fullName"].focus();
    // await inputRefs.current["fullName"].blur();
    await inputRefs?.current?.["phoneNumber"]?.focus();
    await inputRefs?.current?.["phoneNumber"]?.blur();
    await inputRefs?.current?.["confirmMobileNumber"]?.focus();
    await inputRefs?.current?.["confirmMobileNumber"]?.blur();
    setButtonHandlers((prv) => ({ ...prv, ["sendMobileOtp"]: true }));
  };
  const sendPhoneOtpFinalFunction = async () => {
    let errors = [
      // ...Object.keys(userInputs["fullName"].errors),
      ...Object.keys(userInputs["confirmMobileNumber"].errors),
      ...Object.keys(userInputs["phoneNumber"].errors),
    ];
    console.log({ errors });

    if (errors.length === 0) {
      try {
        setSendMobileBtnLoading(true);
        setOtpMobileConfirmation(false);
        let config = {
          method: "post",
          url: "Portal/portal/generatePhoneOtpForPortalRegistration",
          data: {
            phoneNumber: userInputs?.["phoneNumber"]?.value,
          },
        };

        const response = await callApi(config);

        // Check if the response code is 201
        if (response?.data?.code == 201) {
          setSendMobileBtnLoading(false);
          toast.success("OTP sent successfully", { autoClose: 3000 });
        }
      } catch (error) {
        console.log(error, "here is error");
        toast.error(error.message, { autoClose: 2000 });
      }
    }
  };

  const verifyPhoneOtp = async () => {
    // await inputRefs.current["fullName"].focus();
    // await inputRefs.current["fullName"].blur();
    await inputRefs?.current?.["phoneNumber"]?.focus();
    await inputRefs?.current?.["phoneNumber"]?.blur();
    await inputRefs?.current?.["confirmMobileNumber"]?.focus();
    await inputRefs?.current?.["confirmMobileNumber"]?.blur();
    await inputRefs?.current?.["mobileOtp"]?.focus();
    await inputRefs?.current?.["mobileOtp"]?.blur();
    setButtonHandlers((prv) => ({ ...prv, ["verifyMobileOtp"]: true }));
  };
  const verifyOtpFinalFunction = async () => {
    console.log("btn input", { userInputs });
    let errors = [
      // ...Object.keys(userInputs["fullName"].errors),
      ...Object.keys(userInputs["confirmMobileNumber"].errors),
      ...Object.keys(userInputs["phoneNumber"].errors),
      ...Object.keys(userInputs["mobileOtp"].errors),
    ];
    console.log({ errors });
    if (errors.length == 0) {
      const hashedOtp = CryptoJS.SHA256(
        userInputs?.["mobileOtp"]?.value
      ).toString(CryptoJS.enc.Hex);

      try {
        setConfirmMObileOtpLoading(true);
        let config = {
          method: "post",
          url: "Portal/portal/verifyOTPForPhone",
          data: {
            phoneNumber: userInputs?.["phoneNumber"]?.value,
            otp: hashedOtp,
          },
        };
        let phoneOtpRes = await callApi(config);
        setPhoneOtpResponse(phoneOtpRes?.data);
        if (phoneOtpRes?.data?.code == 201) {
          toast.success("OTP for Phone No. is verified successfully", {
            autoClose: 3000,
          });
          setOtpMobileConfirmation(true);
          setConfirmMObileOtpLoading(false);
        } else {
          setConfirmMObileOtpLoading(false);
          toast.error("Please enter a valid OTP");
        }
      } catch (error) {
        console.log(error, "here is error");
        toast.error(error.message, { autoClose: 2000 });
      }
    }
  };

  const sendMailOtp = async () => {
    // await inputRefs.current["fullName"].focus();
    // await inputRefs.current["fullName"].blur();
    await inputRefs?.current?.["emailId"]?.focus();
    await inputRefs?.current?.["emailId"]?.blur();
    await inputRefs?.current?.["confirmEmailId"]?.focus();
    await inputRefs?.current?.["confirmEmailId"]?.blur();
    setButtonHandlers((prv) => ({ ...prv, ["sendMailOtp"]: true }));
  };
  const sendMailFinalFunction = async () => {
    console.log("btn input", { userInputs });
    let errors = [
      // ...Object.keys(userInputs["fullName"].errors),
      ...Object.keys(userInputs["confirmEmailId"].errors),
      ...Object.keys(userInputs["emailId"].errors),
    ];
    console.log({ errors });
    if (errors.length == 0) {
      try {
        setEmailOtpLoading(true);
        setEmailOtpConformation(false);
        let config = {
          method: "post",
          url: "Portal/portal/generateMailOtpForPortalRegistration",
          data: {
            email: userInputs?.["emailId"]?.value,
          },
        };
        const response = await callApi(config);
        if (response?.data?.code == 201) {
          setEmailOtpLoading(false);
          toast.success("OTP sent successfully", { autoClose: 3000 });
        } else {
          setEmailOtpLoading(false);
        }
      } catch (error) {
        console.log(error, "here is error");
        toast.error(error.message, { autoClose: 2000 });
      }
    }
  };
  const verifyMailOtp = async () => {
    // await inputRefs.current["fullName"].focus();
    // await inputRefs.current["fullName"].blur();
    await inputRefs?.current?.["emailId"]?.focus();
    await inputRefs?.current?.["emailId"]?.blur();
    await inputRefs?.current?.["confirmEmailId"]?.focus();
    await inputRefs?.current?.["confirmEmailId"]?.blur();
    await inputRefs?.current?.["emailOtp"]?.focus();
    await inputRefs?.current?.["emailOtp"]?.blur();

    setButtonHandlers((prv) => ({ ...prv, ["verifyEmailOtp"]: true }));
  };
  const verifyOtpMailFunction = async () => {
    let errors = [
      // ...Object.keys(userInputs["fullName"].errors),
      ...Object.keys(userInputs["emailId"].errors),
      ...Object.keys(userInputs["confirmEmailId"].errors),
      ...Object.keys(userInputs["emailOtp"].errors),
    ];
    if (errors.length == 0) {
      const hashedOtp = CryptoJS.SHA256(
        userInputs?.["emailOtp"]?.value
      ).toString(CryptoJS.enc.Hex);

      try {
        setEmailConFormationLoading(true);
        let config = {
          method: "post",
          url: "Portal/portal/verifyOTPForMail",
          data: {
            email: userInputs?.["emailId"]?.value,
            emailOtp: hashedOtp,
          },
        };
        const responseMailOtp = await callApi(config);
        setMailOtpResponse(responseMailOtp?.data);
        if (responseMailOtp?.data?.code == 201) {
          toast.success("OTP for Email is verified successfully", {
            autoClose: 3000,
          });
          setEmailOtpConformation(true);
          setEmailConFormationLoading(false);
        } else {
          setEmailConFormationLoading(false);
          toast.error("Please enter a valid OTP");
        }
      } catch (error) {
        console.log(error, "here is error");
        toast.error(error.message, { autoClose: 2000 });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({ ...prevData, [name]: value }));

    // Clear the error when user starts typing
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }

    // Additional validation for specific fields
    if (name === "currentMobile") {
      const mobilePattern = /^[6-9]\d{9}$/; // Starts with 6-9 and has 10 digits
      if (!mobilePattern.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Enter a valid 10-digit mobile number.",
        }));
      }
    } else if (name === "currentEmail") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation
      if (!emailPattern.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Enter a valid email address.",
        }));
      }
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    if (!value.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "This field is required.",
      }));
    } else {
      // Additional validation on blur for specific fields
      if (name === "mobile") {
        const mobilePattern = /^[6-9]\d{9}$/; // Starts with 6-9 and has 10 digits
        if (!mobilePattern.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "Enter a valid 10-digit mobile number.",
          }));
        }
      } else if (name === "currentEmail") {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation
        if (!emailPattern.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "Enter a valid email address.",
          }));
        }
      }
    }
  };

  const isFormValid = () => {
    const validationErrors = {};

    Object.entries(formData).forEach(([key, value]) => {
      if (!value.trim()) {
        validationErrors[key] = "This field is required.";
      } else {
        // Specific validations for certain fields
        if (key === "currentMobile") {
          const mobilePattern = /^[6-9]\d{9}$/; // Validates 10-digit mobile number starting with 6-9
          if (!mobilePattern.test(value)) {
            validationErrors[key] = "Enter a valid 10-digit mobile number.";
          }
        } else if (key === "currentEmail") {
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation
          if (!emailPattern.test(value)) {
            validationErrors[key] = "Enter a valid email address.";
          }
        }
      }
    });

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async () => {
    try {
      if (!isFormValid()) {
        return false;
      }
      setLoading(true);
      const config = {
        method: "post",
        url: "Portal/portal/accountRecoveryUserExistenceCheck",
        data: formData, // Ensure correct data structure
      };
      const response = await callApi(config);
      console.log("Response:", response); // Log response to verify

      if (response.data && response.data.code === 200) {
        setApplicantDataAvailable(true);
        setReceivedUser_id(response.data?.userId);
        setIsSubmitted(true);
        toast.success(response?.data?.message, { position: "top-right" });
      } else {
        toast.error(response?.data?.message, { position: "top-right" });
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Something went wrong. Please try again.", {
        position: "top-right",
      });
    } finally {
      setLoading(false); // Always stop loading regardless of outcome
    }
  };

  const registerUserCheck = async () => {
    // await inputRefs?.current?.["fullName"]?.focus();
    // await inputRefs?.current?.["fullName"]?.blur();
    await inputRefs?.current?.["phoneNumber"]?.focus();
    await inputRefs?.current?.["phoneNumber"]?.blur();
    await inputRefs?.current?.["confirmMobileNumber"]?.focus();
    await inputRefs?.current?.["confirmMobileNumber"]?.blur();
    await inputRefs?.current?.["emailId"]?.focus();
    await inputRefs?.current?.["emailId"]?.blur();
    await inputRefs?.current?.["confirmEmailId"]?.focus();
    await inputRefs?.current?.["confirmEmailId"]?.blur();
    await inputRefs?.current?.["emailOtp"]?.focus();
    await inputRefs?.current?.["emailOtp"]?.blur();
    await inputRefs?.current?.["mobileOtp"]?.focus();
    await inputRefs?.current?.["mobileOtp"]?.blur();
    if (mailOtpResponse?.code == 201 && phoneOtpResponse.code == 201)
      registerUser();
    else
      toast.warning(
        "Kindly complete the form to verify your mobile number and email address for registration.",
        { autoClose: 5000 }
      );
  };

  // Register function

  const registerUser = async () => {
    try {
      setLoading(true); // Set loading to true before starting the API call

      let config = {
        method: "post",
        url: "Portal/portal/updateForRecovery",
        data: {
          emailId: userInputs?.["emailId"]?.value,
          id: receivedUser_id,
          phoneNumber: userInputs?.["phoneNumber"]?.value,
          // fullName: userInputs?.["fullName"]?.value,
          emailOtpId: mailOtpResponse?.data?.otpIdentification,
          phoneOtpId: phoneOtpResponse?.data?.otpIdentification,
        },
      };

      const responseRegister = await callApi(config);
      if (responseRegister?.data?.code === 200) {
        toast.success(responseRegister?.data?.message, { autoClose: 1000 });
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      } else {
        toast.warning(responseRegister?.data?.message, { autoClose: 5000 });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="registrationFormMain">
        <Card className="registrationFont job-registration-card text-center">
          Account Recovery
        </Card>

        <div className="formContainerStyle">
          <div className="jobRegistrationCard-main">
            <Card
              className="p-3 mt-3 mb-5 jobRegistrationCard"
              style={{ maxWidth: "90%", margin: "0 auto" }}
            >
              <div className="job-registration-instruction">
                <h5 className="registrationFontHead mt-1 job-registration-title">
                  Instructions:
                </h5>
              </div>
              <p className="mt-3 registrationFont " style={{ color: "brown", fontWeight: "bolder" }}>
                Note: Please ensure that you provide accurate and valid details
                during registration (Full Name, Date of Birth, Mobile Number,
                Email, 10th Registration Number) and job application (Father's
                Name, Mother's Name). Incorrect information may prevent
                successful account recovery or updates.
              </p>
              <Form className="mt-4">
                <Container fluid="md">
                  <Row>
                    <Col xs={6} md={6} className="register-col-data">
                      <CustomTextInput
                        className="register-main-placeholder customInputStyles registerPlaceHolder"
                        id="fullName"
                        label="Enter Candidate's  Full Name"
                        required
                        placeholder="Candidate's Full Name"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isSubmitted}
                      />
                      {errors.fullName && (
                        <span className="errorValidationAcRecov">
                          {errors.fullName}
                        </span>
                      )}
                    </Col>
                    <Col xs={6} md={6} className="register-col-data">
                      <CustomTextInput
                        className="register-main-placeholder customInputStyles registerPlaceHolder"
                        id="Dob"
                        label="Date Of Birth"
                        required
                        type="date"
                        // placeholder="Candidate's / Applicant's Full Name"
                        name="Dob"
                        value={formData.Dob}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isSubmitted}
                      />
                      {errors.Dob && (
                        <span className="errorValidationAcRecov">
                          {errors.Dob}
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col xs={12} md={6} className="register-col-data">
                      <CustomTextInput
                        className="register-main-placeholder registerPlaceHolder"
                        id="fatherName"
                        label="Father's Name"
                        required
                        placeholder="Father's Name"
                        name="fatherName"
                        value={formData.fatherName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isSubmitted}
                      />
                      {errors.fatherName && (
                        <span className="errorValidationAcRecov">
                          {errors.fatherName}
                        </span>
                      )}
                    </Col>
                    <Col xs={12} md={6} className="register-col-data">
                      <CustomTextInput
                        className="register-main-placeholder registerPlaceHolder"
                        id="motherName"
                        label="Mother's Name"
                        required
                        placeholder="Mother's Name"
                        name="motherName"
                        value={formData.motherName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isSubmitted}
                      />
                      {errors.motherName && (
                        <span className="errorValidationAcRecov">
                          {errors.motherName}
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row className="">
                    <Col xs={12} md={6} className="register-col-data">
                      <CustomTextInput
                        type="number"
                        className="register-main-placeholder registerPlaceHolder"
                        id="currentMobile"
                        label="Current Mobile Number"
                        required
                        placeholder="Current Mobile Number"
                        name="currentMobile"
                        value={formData.currentMobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={10}
                        disabled={isSubmitted}
                      />
                      {errors.currentMobile && (
                        <span className="errorValidationAcRecov">
                          {errors.currentMobile}
                        </span>
                      )}
                    </Col>
                    <Col xs={12} md={6} className="register-col-data">
                      <CustomTextInput
                        className="register-main-placeholder registerPlaceHolder"
                        id="currentEmail"
                        label="Current Email"
                        required
                        placeholder="Current Email"
                        name="currentEmail"
                        value={formData.currentEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isSubmitted}
                      />
                      {errors.currentEmail && (
                        <span className="errorValidationAcRecov">
                          {errors.currentEmail}
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col xs={12} md={12} className="register-col-data">
                      <CustomTextInput
                        className="register-main-placeholder registerPlaceHolder"
                        id="registrationNumber"
                        label="10th Registration Number"
                        required
                        placeholder="10th Registration Number"
                        name="registrationNumber10th"
                        value={formData.registrationNumber10th}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isSubmitted}
                      />
                      {errors.registrationNumber10th && (
                        <span className="errorValidationAcRecov">
                          {errors.registrationNumber10th}
                        </span>
                      )}
                    </Col>
                  </Row>
                  {!applicantDataAvailable && (
                    <Row>
                      <Col className="mt-2 d-flex justify-content-end">
                        {/* <Button
                          onClick={handleSubmit}
                          disabled={applicantDataAvailable}
                        >
                          <span className="account-recovery-button">
                            Submit
                          </span>
                        </Button> */}

                        <Button
                          variant="primary"
                          className="registrationFont registrationForm-bottom-card-cancel-btn"
                          onClick={() => navigate("/login")}
                        >
                          <span className="account-recovery-button">
                            Go Back
                          </span>
                        </Button>
                        <Button
                          variant="primary"
                          className="registrationFont registrationForm-bottom-card-submit-btn"
                          onClick={handleSubmit}
                          disabled={applicantDataAvailable}
                        >
                          <span className="account-recovery-button">
                            SUBMIT
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  )}
                  {/* Open this when the First Submit Clicked  */}

                  {applicantDataAvailable && (
                    <>
                      <Row className="mt-2">
                        <Col md={4} xs={12} className="register-col-data">
                          <CustomTextInput
                            className="register-main-placeholder"
                            id="phoneNumber"
                            label="Mobile Number"
                            required
                            type="number"
                            placeholder="Enter Mobile Number"
                            name="phoneNumber"
                            touched={userInputs?.["phoneNumber"]?.touched}
                            value={userInputs?.["phoneNumber"]?.value}
                            onChange={(e) => {
                              setUserInputFn(e);
                              userInputs?.["phoneNumber"]?.touched &&
                                checkForInputError(e);
                            }}
                            onBlur={(e) => checkForInputError(e)}
                            errors={userInputs?.["phoneNumber"]?.errors}
                            ref={(el) =>
                              (inputRefs.current["phoneNumber"] = el)
                            }
                          />
                        </Col>
                        <Col md={4} xs={7} className="register-col-data">
                          <CustomTextInput
                            className="register-main-placeholder"
                            id="confirmMobileNumber"
                            label="Confirm Mobile Number"
                            required
                             type="number"
                            placeholder="Enter Confirm Mobile Number"
                            name="confirmMobileNumber"
                            touched={
                              userInputs?.["confirmMobileNumber"]?.touched
                            }
                            value={userInputs?.["confirmMobileNumber"]?.value}
                            onChange={(e) => {
                              setUserInputFn(e);
                              userInputs?.["confirmMobileNumber"]?.touched &&
                                checkForInputError(e);
                            }}
                            onBlur={(e) => checkForInputError(e)}
                            errors={userInputs?.["confirmMobileNumber"]?.errors}
                            ref={(el) =>
                              (inputRefs.current["confirmMobileNumber"] = el)
                            }
                          />
                        </Col>
                        <Col md={4} xs={5}>
                          <div className="mt-4">
                            <>
                              <Button
                                variant="primary"
                                className="sendOtp-btn"
                                disabled={SendMobileBtnLoading}
                                onClick={() => {
                                  sendPhoneOtp();
                                }}
                              >
                                {!SendMobileBtnLoading ? (
                                  "Send OTP "
                                ) : (
                                  <Spinner size="sm"></Spinner>
                                )}
                              </Button>
                            </>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4} xs={7} className="register-col-data">
                          <div className="position-relative">
                            <CustomTextInput
                              id="mobileOtp"
                              label="Mobile OTP"
                              required
                              type="number"
                              placeholder="Enter Mobile OTP"
                              name="mobileOtp"
                              maxLength={6}
                              touched={userInputs?.["mobileOtp"]?.touched}
                              onChange={(e) => {
                                setUserInputFn(e);
                                userInputs?.["mobileOtp"]?.touched &&
                                  checkForInputError(e);
                              }}
                              onBlur={(e) => checkForInputError(e)}
                              value={userInputs?.["mobileOtp"]?.value}
                              errors={userInputs?.["mobileOtp"]?.errors}
                              ref={(el) =>
                                (inputRefs.current["mobileOtp"] = el)
                              }
                              className="pr-4 register-main-placeholder"
                            />
                            {mobileOtpConformation && (
                              <IoMdCheckmark
                                className="position-absolute"
                                style={{
                                  right: "10px",
                                  top: "70%",
                                  transform: "translateY(-50%)",
                                  pointerEvents: "none",
                                  color: "green",
                                }}
                              />
                            )}
                          </div>
                        </Col>

                        <Col md={6} xs={5}>
                          <div className="mt-4">
                            <Button
                              variant="primary"
                              className="sendOtp-btn"
                              disabled={confirmMObileOtpLoading}
                              onClick={() => verifyPhoneOtp()}
                            >
                              {!confirmMObileOtpLoading ? (
                                "Confirm OTP"
                              ) : (
                                <Spinner size="sm"></Spinner>
                              )}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col xs={12} md={4} className="register-col-data">
                          <CustomTextInput
                            className="register-main-placeholder"
                            id="emailId"
                            label="Email"
                            required
                            placeholder="Enter Email"
                            name="emailId"
                            touched={userInputs?.["emailId"]?.touched}
                            value={userInputs?.["emailId"]?.value}
                            onChange={(e) => {
                              setUserInputFn(e);
                              userInputs?.["emailId"]?.touched &&
                                checkForInputError(e);
                            }}
                            onBlur={(e) => checkForInputError(e)}
                            errors={userInputs?.["emailId"]?.errors}
                            ref={(el) => (inputRefs.current["emailId"] = el)}
                          />
                        </Col>
                        <Col xs={7} md={4} className="register-col-data">
                          <CustomTextInput
                            className="register-main-placeholder"
                            id="confirmEmailId"
                            label="Confirm Email"
                            required
                            placeholder="Enter Confirm Email"
                            name="confirmEmailId"
                            touched={userInputs?.["confirmEmailId"]?.touched}
                            value={userInputs?.["confirmEmailId"]?.value}
                            onChange={(e) => {
                              setUserInputFn(e);
                              checkForInputError(e);
                            }}
                            onBlur={(e) => checkForInputError(e)}
                            errors={userInputs?.["confirmEmailId"]?.errors}
                            ref={(el) =>
                              (inputRefs.current["confirmEmailId"] = el)
                            }
                          />
                        </Col>
                        <Col md={4} xs={5}>
                          <div className="mt-4">
                            <Button
                              variant="primary"
                              className="sendOtp-btn"
                              disabled={emailOtpLoading}
                              onClick={() => sendMailOtp()}
                            >
                              {!emailOtpLoading ? (
                                "Send OTP"
                              ) : (
                                <Spinner size="sm"></Spinner>
                              )}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4} xs={7} className="register-col-data">
                          <div className="position-relative">
                            <CustomTextInput
                              className="register-main-placeholder"
                              id="emailOtp"
                              label="Email OTP"
                              required
                              type="number"
                              placeholder="Enter Confirm Email"
                              name="emailOtp"
                              touched={userInputs?.["emailOtp"]?.touched}
                              value={userInputs?.["emailOtp"]?.value}
                              onChange={(e) => {
                                setUserInputFn(e);
                                userInputs?.["emailOtp"]?.touched &&
                                  checkForInputError(e);
                              }}
                              onBlur={(e) => checkForInputError(e)}
                              errors={userInputs?.["emailOtp"]?.errors}
                              ref={(el) => (inputRefs.current["emailOtp"] = el)}
                            />
                            {emailOtpConformation && (
                              <IoMdCheckmark
                                className="position-absolute"
                                style={{
                                  right: "10px",
                                  top: "70%",
                                  transform: "translateY(-50%)",
                                  pointerEvents: "none",
                                  color: "green",
                                }}
                              />
                            )}
                          </div>
                        </Col>

                        <Col>
                          <div className="mt-4">
                            <Button
                              variant="primary"
                              disabled={emailConformationLoading}
                              className="sendOtp-btn"
                              onClick={() => verifyMailOtp()}
                            >
                              {!emailConformationLoading ? (
                                "Confirm OTP"
                              ) : (
                                <Spinner size="sm"></Spinner>
                              )}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </Container>
              </Form>
            </Card>
          </div>
        </div>
        {applicantDataAvailable && (
          <Card className="registrationFont registrationForm-bottom-card">
            <div>
              <Button
                variant="primary"
                className="registrationFont registrationForm-bottom-card-cancel-btn"
                onClick={() => navigate("/login")}
              >
                CANCEL
              </Button>
              <Button
                variant="primary"
                className="registrationFont registrationForm-bottom-card-submit-btn"
                onClick={registerUserCheck}
              >
                SUBMIT
              </Button>
            </div>
          </Card>
        )}
      </div>
    </>
  );
};

export default AccountRecovery;
