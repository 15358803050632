import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Card, Col, Row, Table, Tooltip, OverlayTrigger, Modal } from "react-bootstrap";
import HamburgerMenu from '../assets/Mobile Menu/HamburgerMenu';
import candidateImg from '../assets/images/candidateImg.png'
import Footer from '../components/footer';
import Gotop from '../components/gotop';
import { candidateInfoFromRegistrationPage } from "../assets/fakeData/CandidateProfile";
import applicantImg from '../assets/images/applicantImage.png'
import { callApi } from '../constant/callApi';
import { useAsyncDebounce } from 'react-table'
import Preloader from "../components/preloader"
import { Link, useNavigate } from "react-router-dom";
import { Tabs } from "react-tabs";
import { toast } from "react-toastify";
import progressImg from '../assets/images/progressImg.png'
import cancelled from '../assets/images/cross.png'

const CandidateDashboard = () => {
    const [value, setValue] = useState("")
    const [loading, setLoading] = useState(false)
    const [candidateInfo, setCandidateInfo] = useState([])
    const [keyDateEvent, setKeyDateEvent] = useState([])
    const [userDta, setUserData] = useState([])
    const [appliedJobDta, setAppliedJobDta] = useState([])
    const [applicationStatus, setApplicationStatus] = useState([])
    const [searchValue, setSearchValue] = useState([])
    const [cardHeight, setCardHeight] = useState('45vh');
    const [searchQueryOfAppliedJob, setSearchQueryOfAppliedJob] = useState("")
    const [searchQueryOfKeyDatesAndEventAdvertisementNumber, setSearchQueryOfKeyDatesAndEventAdvertisementNumber] = useState('')
    const [searchQueryOfKeyDatesAndEventPostName, SetSearchQueryOfKeyDatesAndEventPostName] = useState("")
    const [applicationStatusChartState, setApplicationStatusChartState] = useState("")

    const [searchApplicationStatusAdvt, setSearchApplicationStatusAdvt] = useState("")
    const [searchApplicationStatusPostName, setSearchApplicationStatusPostName] = useState("")
    const [ifUserInApplicationDetails, setIfUserInApplicationDetailsFun] = useState("")

    const [selectedRow, setSelectedRow] = useState(null);

    const handleRowClick = (eventId, vacancyId) => {
        setSelectedRow(vacancyId); // Set the clicked row's ID
        applicationStatusChart(eventId, vacancyId); // Call your existing function
    };
    const stages = [
        {
            title: "Application",
            steps: [
                { label: "Start", completed: true },
                { label: "Submit", completed: true },
            ],
        },
        {
            title: "Interview",
            steps: [
                { label: "Scheduled", completed: true },
                { label: "Interview Letter", completed: false },
            ],
        },
        {
            title: "Appointment",
            steps: [
                { label: "Shortlisted", completed: true },
                { label: "Appointment Letter", completed: false },
            ],
        },
    ];

    const navigate = useNavigate();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedApplication, setSelectedApplication] = useState(null); // Stores application data to be deleted

    // Function to open modal and set the selected application
    const openDeleteModal = (applicationId, postId) => {
        setSelectedApplication({ applicationId, postId });
        setIsDeleteModalOpen(true);
    };
    const handleDelete = () => {
        const { applicationId, postId } = selectedApplication;

        // Call the API to delete the application
        handelDeleteApplication(applicationId, postId);

        // Close the modal after deletion
        setIsDeleteModalOpen(false);
    };





    const getTimeGap = (item) => {
        console.log(item?.advertisementNumber, "and", item?.advertisementClosingDate, item?.advertisementClosingTime, "time")
        if (!item?.advertisementClosingDate || !item?.advertisementClosingTime) {
            console.log("data")
            return "Closing date or time is missing";
        }
        const closingDate = new Date(item.advertisementClosingDate);
        console.log(closingDate, "closingDate")
        const [hours, minutes] = item?.advertisementClosingTime?.split(':').map(Number);
        closingDate.setHours(hours, minutes, 0, 0);
        const now = new Date();
        const timeDifference = closingDate - now;
        console.log(item?.advertisementNumber, "and", item?.advertisementClosingDate, "time difference", timeDifference)
        if (timeDifference < 0) {
            console.log("priint")
            return "The advertisement has already closed.";
        }
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hoursLeft = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutesLeft = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        return `${days} days & ${hoursLeft} hr remaining.`;
    };


    const updateCardHeight = () => {
        if (window.innerWidth < 800) {
            setCardHeight('auto');
        } else {
            setCardHeight('45vh');
        }
    };

    useEffect(() => {
        updateCardHeight(); // Set initial height
        window.addEventListener('resize', updateCardHeight); // Update on resize

        return () => {
            window.removeEventListener('resize', updateCardHeight); // Clean up listener
        };
    }, []);

    const userProfile = async (id) => {
        try {
            const config = {
                method: "post",
                url: "Portal/profile/userProfile",

            };
            const response = await callApi(config);
            console.log("response", response.data.user)
            setUserData(response.data.user)
            setCandidateInfo(response.data.data)
        } catch (error) {
            console.log({ error })
        }
    };

    const keyDateEventFun = async (id) => {
        try {
            const config = {
                method: "post",
                url: "Portal/profile/KeyDatesAndEvents",

            };
            const response = await callApi(config);
            if (response.data.code == 200) {
                console.log(response.data.data)
                setKeyDateEvent(response.data.data)
            }

        } catch (error) {
            console.log({ error })
        }
    };

    const appliedJobDetails = async (id) => {
        try {
            const config = {
                method: "post",
                url: "Portal/profile/appliedJobDetails",

            };
            const response = await callApi(config);
            if (response.data.code == 200) {
                console.log("appliedJobDetails", response.data.data)
                setAppliedJobDta(response.data.data)
            }

        } catch (error) {
            console.log({ error })
        }
    };
    const ifUserInApplicationDetailsFun = async (id) => {
        try {
            const config = {
                method: "post",
                url: "Portal/profile/ifUserInApplicationDetails",

            };
            const response = await callApi(config);
            if (response.data.code == 200) {
                setIfUserInApplicationDetailsFun(response.data.data)
            }

        } catch (error) {
            console.log({ error })
        }
    };


    const applicationStatusDetails = async (id) => {
        try {
            const config = {
                method: "post",
                url: "Portal/profile/applicationStatusApi",

            };
            const response = await callApi(config);
            if (response.data.code == 200) {
                console.log("setApplicationStatus", response.data.data)
                setApplicationStatus(response.data.data)
            }

        } catch (error) {
            console.log({ error })
        }
    };

    useEffect(() => {
        setLoading(true)
        const fetchDataOfAllFourApi = async () => {
            await Promise.all([userProfile(), keyDateEventFun(), appliedJobDetails(), applicationStatusDetails()])
        }
        fetchDataOfAllFourApi()
        setLoading(false)
        ifUserInApplicationDetailsFun()
    }, [])



    const SearchAppliedJob = async () => {


        try {
            const config = {
                method: "post",
                url: "Portal/profile/searchAppliedJob",

                data: {
                    advertisementNumber: searchQueryOfAppliedJob
                }

            };
            const response = await callApi(config);
            if (response.data.code == 200) {
                console.log(response.data.data)
                setAppliedJobDta(response.data.data)
            }

        } catch (error) {
            console.log({ error })
        }

    }





    const SearchKeyDateAndEvent = async () => {


        try {
            const config = {
                method: "post",
                url: "Portal/profile/SearchKeyDatesAndEvents",

                data: {
                    advertisementNumber: searchQueryOfKeyDatesAndEventAdvertisementNumber,
                    postName: searchQueryOfKeyDatesAndEventPostName
                }

            };
            const response = await callApi(config);
            if (response.data.code == 200) {
                console.log(response.data.data)
                setKeyDateEvent(response.data.data)
            }

        } catch (error) {
            console.log({ error })
        }

    }
    const searchApplicationStatus = async () => {


        try {
            const config = {
                method: "post",
                url: "Portal/profile/searchApplicationStatus",

                data: {
                    advertisementNumber: searchApplicationStatusAdvt,
                    postName: searchApplicationStatusPostName
                }

            };
            const response = await callApi(config);
            if (response.data.code == 200) {
                console.log(response.data.data)
                setApplicationStatus(response.data.data)
            }

        } catch (error) {
            console.log({ error })
        }

    }




    const onChangeKeyDateAndEvent = useAsyncDebounce((valAdvertisement, valPost) => {
        if (valAdvertisement.trim() || valPost.trim()) {
            SearchKeyDateAndEvent(valAdvertisement, valPost);
        }
        // else if(valPost.trim()){
        //     searchPostNameFun(valPost);

        // }

        else {
            keyDateEventFun(); // Assuming this function is defined elsewhere in your component
        }
    }, 500);

    const onChangeApplicationSearch = useAsyncDebounce((valAdvertisement, valPost) => {
        if (valAdvertisement.trim() || valPost.trim()) {
            searchApplicationStatus(valAdvertisement, valPost);
        }
        else {
            keyDateEventFun(); // Assuming this function is defined elsewhere in your component
        }
    }, 500);


    useEffect(() => {
        if (searchQueryOfKeyDatesAndEventAdvertisementNumber || searchQueryOfKeyDatesAndEventPostName)
            onChangeKeyDateAndEvent(searchQueryOfKeyDatesAndEventAdvertisementNumber, searchQueryOfKeyDatesAndEventPostName)
    }, [searchQueryOfKeyDatesAndEventAdvertisementNumber, searchQueryOfKeyDatesAndEventPostName])

    useEffect(() => {
        if (searchApplicationStatusAdvt || searchApplicationStatusPostName)
            onChangeApplicationSearch(searchApplicationStatusAdvt, searchApplicationStatusPostName)
    }, [searchApplicationStatusAdvt, searchApplicationStatusPostName])

    const applicationStatusChart = async (advtId, vacancyId) => {


        try {
            const config = {
                method: "post",
                url: "Portal/profile/applicationStatusChart",

                data: {
                    advtId: advtId,
                    vacancyId: vacancyId
                }

            };
            const response = await callApi(config);
            if (response.data.code == 200) {
                console.log(response.data.data, "applicationStatusChartState")
                setApplicationStatusChartState(response.data.data)
            }

        } catch (error) {
            console.log({ error })
        }

    }







    const onChange = useAsyncDebounce((val) => {



        if (val.trim() !== "") {

            SearchAppliedJob(val);

        } else {
            appliedJobDetails()

        }
    }, 500);



    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const defaultImage = 'https://www.w3schools.com/howto/img_avatar2.png';

    // Extract values from candidateInfo safely with optional chaining
    const candidateImage = candidateInfo?.details?.['66cb753a8d303737cc78cd5c'] || defaultImage;
    const fullName = candidateInfo?.details?.applicantSFullName || userDta?.fullName;
    const dob = candidateInfo?.details?.['66a37bab1b102d1ba93eee2f'] || userDta?.DateOfBirth;
    const mobileNumber = candidateInfo?.details?.mobileNumber || userDta?.phoneNumber;
    const email = candidateInfo?.details?.email || userDta?.emailId;
    const Gender = candidateInfo?.details?.[`66a37bab1b102d1ba93eee2e`] || 'N/A';
    // const applicationNumber=candidateInfo?.details?.applicationNumber

    // Format the date of birth

    const formattedDob = formatDate(dob);

    const handleRegisterFinal = async (advtId, postId) => {
        let item;
        let post;

        // const userProfile = async (id) => {
        try {
            const config = {
                method: "post",
                url: "Portal/profile/advtById",
                data: {
                    id: advtId
                }

            };
            const response = await callApi(config);
            item = response?.data?.data
            post = response.data.data?.postAndVacancyConfig?.configurations?.filter(item => item?.posting?._id == postId)[0]

            console.log("handleRegisterFinal", response.data.data?.postAndVacancyConfig?.configurations?.filter(item => item?.posting?._id == postId)[0])
        } catch (error) {
            console.log({ error })
        }
        // };

        const userId = sessionStorage.getItem("token");

        try {
            if (!userId) {
                navigate("/login");
            } else {
                navigate(
                    `/apply-for-post?__application_id=${post?.jobApplicationForm}`,
                    {
                        state: { item, post },
                    }
                );

            }
        } catch (error) {
            console.error(error);
            toast.error("An error occurred. Please try again.");
        }
    };


    const handelDeleteApplication = async (advtId, vacancyId) => {
        try {
            const config = {
                method: "post",
                url: "Portal/profile/deleteAppliedJob",
                data: {
                    advtId: advtId,
                    vacancyId: vacancyId
                }
            }
            const response = await callApi(config)
            if (response?.data?.code == 200) {
                await appliedJobDetails()
            }
        } catch (error) {
            console.log(error)
        }
    }


    const redirectTOApplicantScoringSheet = async (advtId, postId, purpose) => {
        console.log("check1", postId);

        try {
            const config = {
                method: 'post',
                url: 'Portal/profile/getAllAdvertisementForInterviewScheduling',
                data: {
                    id: advtId,
                    postId: postId
                }
            };

            const response = await callApi(config);

            if (response.data.code === 200) {
                let advt = response.data.data;
                let PostId = response.data.data[0]?.postDetails?.posting;
                let applicationFormId = response.data?.data[0]?.postDetails?.jobApplicationForm;
                let applicationSerialIds = response.data.applicationSerialIds;

                // Navigate to the scoring screen with state
                console.log("Navigating to scoring screen");
                navigate('/applicantScoringScreen_Preview', {
                    state: {
                        item: advt,
                        applicationIds: applicationFormId,
                        post: PostId,
                        applicationDetailsId: applicationSerialIds,
                        purpose: purpose
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    };




    return (
        <>
            {

                loading ? (
                    <Preloader />
                ) : (
                    <div className='background__main'>
                        <HamburgerMenu />

                        <div className='candidateDashboard-main' style={{ minHeight: '90vh' }}>
                            <Row md='12' className='p-0 m-0 top-ScetionDashboard'>
                                <Col md='6' className='pt-0 order-md-1 order-mobile-2'>
                                    <h6 className='card-head-text'>Key Dates and Events</h6>
                                    <Card className='p-3 allrole-card' style={{ height: "45vh", display: 'flex', flexDirection: 'column' }}>
                                        <Row className='d-flex flex-wrap'>
                                            <Col xs="12" md="6" className='pb-1'>
                                                <div className='search-box-allroles'>
                                                    <div className="search-input-container allrole-search border-primary">
                                                        <input className="ps-4" placeholder="Advertisement No." value={searchQueryOfKeyDatesAndEventAdvertisementNumber}
                                                            onChange={(e) => setSearchQueryOfKeyDatesAndEventAdvertisementNumber(e.target.value)} />
                                                        {searchQueryOfKeyDatesAndEventAdvertisementNumber ? (
                                                            <span className="search-delete-icon-dash" onClick={() => { setSearchQueryOfKeyDatesAndEventAdvertisementNumber(""); onChangeKeyDateAndEvent("", searchQueryOfKeyDatesAndEventPostName) }}>
                                                                <i className="bi bi-x-lg"></i>
                                                            </span>
                                                        ) : (
                                                            <span className="search-magnifier-icon-dash pe-none">
                                                                <i className="bi bi-search"></i>
                                                            </span>
                                                        )}
                                                    </div>
                                                    <Button variant='foreground-alternate' className='p-0 m-0 fs-5' onClick={() => { setSearchQueryOfKeyDatesAndEventAdvertisementNumber(""); onChangeKeyDateAndEvent("", searchQueryOfKeyDatesAndEventPostName) }}>
                                                        <i className="bi bi-arrow-clockwise"></i>
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col xs="12" md="6">
                                                <div className='search-box-allroles'>
                                                    <div className="search-input-container allrole-search border-primary">
                                                        <input className="ps-4" placeholder="Post Name" value={searchQueryOfKeyDatesAndEventPostName} onChange={(e) => { SetSearchQueryOfKeyDatesAndEventPostName(e.target.value) }} />
                                                        {searchQueryOfKeyDatesAndEventPostName ? (
                                                            <span className="search-delete-icon-dash" onClick={() => { SetSearchQueryOfKeyDatesAndEventPostName(''); onChangeKeyDateAndEvent(searchQueryOfKeyDatesAndEventAdvertisementNumber, "") }}>
                                                                <i className="bi bi-x-lg"></i>
                                                            </span>
                                                        ) : (
                                                            <span className="search-magnifier-icon-dash pe-none">
                                                                <i className="bi bi-search"></i>
                                                            </span>
                                                        )}
                                                    </div>
                                                    <Button variant='foreground-alternate' className='p-0 m-0 fs-5' onClick={() => { SetSearchQueryOfKeyDatesAndEventPostName(''); onChangeKeyDateAndEvent(searchQueryOfKeyDatesAndEventAdvertisementNumber, "") }}>
                                                        <i className="bi bi-arrow-clockwise"></i>
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row style={{ flex: 1, overflowY: 'auto', marginBottom: '10px' }} className="table-wrapper">
                                            <Col>
                                                <Table responsive className='allrole-table'>
                                                    <thead className='roles-table-header'>
                                                        <tr>
                                                            <th className='text-start text-uppercase tableHeadFont-allJobs'>Advertisement No.</th>
                                                            <th className='text-start text-uppercase tableHeadFont-allJobs'>Post Name</th>
                                                            <th className='text-start text-uppercase tableHeadFont-allJobs'>Description</th>
                                                            <th className='text-start text-uppercase tableHeadFont-allJobs'>Dates</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ height: '100%', overflowY: 'scroll' }}>
                                                        {keyDateEvent?.length > 0 ? (
                                                            keyDateEvent?.map((eventData) => (
                                                                eventData?.vacancyDetails?.map((vacancy) => (
                                                                    <React.Fragment key={vacancy?._id}>
                                                                        <tr className="allTableRow">
                                                                            <td className="text-left fontSizetr">{eventData?.advertisementNumber}</td>
                                                                            <td className="text-left fontSizetr">{vacancy?.designationName}</td>
                                                                            <td className="text-left fontSizetr">Start of Job Application</td>
                                                                            <td className="text-left fontSizetr">{formatDate(eventData?.advertisementPublishDate)}</td>
                                                                        </tr>
                                                                        <tr className="allTableRow">
                                                                            <td className="text-left fontSizetr"></td>
                                                                            <td className="text-left fontSizetr"></td>
                                                                            <td className="text-left fontSizetr">End of Job Application</td>
                                                                            <td className="text-left fontSizetr">{formatDate(eventData?.advertisementClosingDate)}</td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ))
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="4" className="text-center">No data available</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>

                                    </Card>
                                </Col>
                                <Col md='6' className='order-md-2 order-mobile-1'>
                                    <h6 className='card-head-text'>Profile Overview</h6>
                                    <Card className='p-3 allrole-card' style={{ height: cardHeight, display: 'flex', flexDirection: 'column' }}>
                                        <Row className='pt-2 profileCardRes'>
                                            <Col md='6' xs='12' className='text-center img-container-profile'>
                                                <img
                                                    src={candidateImage ? candidateImage : 'https://www.w3schools.com/howto/img_avatar2.png'}
                                                    alt="candidate"
                                                    style={{
                                                        height: '100%',
                                                        width: '100%'
                                                    }}
                                                />
                                            </Col>
                                            <Col md='6' xs='12' className=' fontSizetr'>
                                                <div className='text-start'>
                                                    <p>Name: <strong>{fullName}</strong></p>
                                                    <p><>Date of Birth: </> <strong>{formattedDob}</strong></p>
                                                    {Gender == "N/A" ? "" : <p><>Gender: </> <strong>{Gender}</strong></p>}
                                                    <p><>Mob: </> <strong>{mobileNumber}</strong></p>
                                                    <p><>Email: </> <strong>{email}</strong></p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                            <Row md='12' className='p-0 m-0 mt-2'>
                                <Col md='6'>
                                    <h6 className='card-head-text'>Application Status </h6>
                                    <Card className="mt-1 card-container progress-card-div allrole-card" id='progressCardDash' style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Row className='d-flex flex-wrap border-bottom pb-2'>
                                            <Col xs="12" md="6" className='pb-1'>
                                                <div className='search-box-allroles'>
                                                    <div className="search-input-container allrole-search border-primary">

                                                        <input className="ps-4" placeholder="Advertisement No." value={searchApplicationStatusAdvt}

                                                            onChange={(e) => setSearchApplicationStatusAdvt(e.target.value)} />
                                                        {searchApplicationStatusAdvt ? (
                                                            <span className="search-delete-icon-dash"
                                                                onClick={() => { setSearchApplicationStatusAdvt(""); onChangeApplicationSearch("", searchApplicationStatusAdvt) }}

                                                            >
                                                                <i className="bi bi-x-lg"></i>
                                                            </span>
                                                        ) : (
                                                            <span className="search-magnifier-icon-dash pe-none">
                                                                <i className="bi bi-search"></i>
                                                            </span>
                                                        )}
                                                    </div>
                                                    <Button variant='foreground-alternate' className='p-0 m-0 fs-5' onClick={() => { setSearchApplicationStatusAdvt(""); onChangeApplicationSearch("", searchApplicationStatusAdvt) }}>
                                                        <i className="bi bi-arrow-clockwise"></i>
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col xs="12" md="6">
                                                <div className='search-box-allroles'>
                                                    <div className="search-input-container allrole-search border-primary">
                                                        <input className="ps-4" placeholder="Post Name" value={searchApplicationStatusPostName}

                                                            onChange={(e) => setSearchApplicationStatusPostName(e.target.value)} />
                                                        {searchApplicationStatusPostName ? (
                                                            <span className="search-delete-icon-dash"
                                                                onClick={() => { setSearchApplicationStatusPostName(""); onChangeApplicationSearch("", searchApplicationStatusPostName) }}
                                                            >
                                                                <i className="bi bi-x-lg"></i>
                                                            </span>
                                                        ) : (
                                                            <span className="search-magnifier-icon-dash pe-none">
                                                                <i className="bi bi-search"></i>
                                                            </span>
                                                        )}
                                                    </div>
                                                    <Button variant='foreground-alternate' className='p-0 m-0 fs-5' onClick={() => { setSearchApplicationStatusPostName(""); onChangeApplicationSearch("", searchApplicationStatusPostName) }}>
                                                        <i className="bi bi-arrow-clockwise"></i>
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='p-2 flex-grow-1' style={{ flexWrap: 'wrap', height: '90%' }}>
                                            <Col xs="12" md='6' className='table-wrapper' style={{ flex: 1, overflowY: 'auto' }}>
                                                <Table responsive className='allrole-table'>
                                                    <thead className='roles-table-header'>
                                                        <tr>
                                                            <th className='text-start text-uppercase tableHeadFont-allJobs w-50'>Advertisement No.</th>
                                                            <th className='text-start text-uppercase tableHeadFont-allJobs'>Post Name</th>
                                                            <th className='text-start text-uppercase tableHeadFont-allJobs'>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ height: '90%', overflowY: 'scroll' }}>
                                                        {applicationStatus?.length > 0 ? (
                                                            applicationStatus?.map((eventData) => {
                                                                console.log({ eventData })
                                                                return (
                                                                    eventData?.postDetails?.map((vacancy, index) => (
                                                                        <React.Fragment key={vacancy?.posting._id}>
                                                                            <tr className="allTableRow"
                                                                                style={{
                                                                                    backgroundColor: selectedRow === vacancy?.posting._id ? '#0B633612' : 'transparent',
                                                                                    border: selectedRow === vacancy?.posting._id ? '1px solid 921D1E1A' : '',
                                                                                }}
                                                                                onClick={() => handleRowClick(eventData._id, vacancy?.posting._id)}

                                                                            >
                                                                                <td className="text-left fontSizetr">{eventData?.advertisementNumber || 'N/A'}</td>
                                                                                <td className="text-left fontSizetr">{vacancy?.posting.postNameDetails?.designationName || 'N/A'}</td>
                                                                                <td className="text-left fontSizetr">
                                                                                    <Button variant='foreground-alternate' className='p-0 m-0 px-1'>
                                                                                        <i className="bi bi-eye" onClick={() => applicationStatusChart(eventData._id, vacancy?.posting._id)}></i>
                                                                                    </Button></td>

                                                                            </tr>

                                                                        </React.Fragment>
                                                                    ))
                                                                )
                                                            })
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="4" className="text-center">No data available</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            <Col xs="12" md='6' className='pb-2 ps-4 pe-4 progress-section' style={{ borderLeft: '1px solid rgba(230, 230, 230, 1)', overflow: 'hidden' }}>                                                <div className="step">
                                                <Row className='stepRow'>
                                                    <Col xs="12" md='5'><h3 className='text-table-heading'>Application</h3></Col>
                                                    <Col xs="12" md='7'>
                                                        <div className="step-progress">
                                                            {/* Conditionally applying classes based on step status */}
                                                            <div className={`step-item ${applicationStatusChartState?.isApplicantData ? 'completed' : 'pending'}`}>
                                                                <div className="circle" style={{ color: 'white' }}>✔</div>
                                                                <span>Start</span>
                                                            </div>
                                                            <div className={`step-item step-item-end ${applicationStatusChartState?.finalSubmitted ? 'completed' : 'pending'}`}>
                                                                <div className="circle" style={{ color: 'white' }}>✔</div>
                                                                <span style={{ lineHeight: '2', marginRight: '-5px' }}>Submit</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                                <div className="step">
                                                    <Row>
                                                        <Col xs="12" md='5'><h3 className='text-table-heading'>Interview</h3></Col>
                                                        <Col xs="12" md="7">
                                                            <div className="step-progress">
                                                                <div className={`step-item ${applicationStatusChartState?.interviewSchedule ? 'completed' : 'pending'}`}>
                                                                    <div className="circle" style={{ color: 'white' }}>✔</div>
                                                                    <span style={{ marginLeft: '-15px' }}> Shortlisted</span>
                                                                </div>
                                                                <a href={applicationStatusChartState?.interviewLetter}>
                                                                    <div className={`step-item step-item-end ${applicationStatusChartState?.interviewCancelStatus ? 'canceled' : (applicationStatusChartState?.interviewSchedule ? 'completed' : 'pending')}`}>
                                                                        <div className="circle">

                                                                            <img
                                                                                src={progressImg}

                                                                                alt=""
                                                                                style={{
                                                                                    width: '12px',
                                                                                    height: '12px',
                                                                                    filter: applicationStatusChartState?.interviewCancelStatus || applicationStatusChartState?.interviewSchedule ? 'invert(1)' : 'none',
                                                                                }}
                                                                            />
                                                                            {applicationStatusChartState?.interviewCancelStatus && (
                                                                                <img
                                                                                    src={cancelled}
                                                                                    alt="Canceled"
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        left: '14px',
                                                                                        width: '20px',
                                                                                        height: '20px',
                                                                                        filter: 'invert(1)'

                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </div>

                                                                        {/* Wrapping in a clickable div */}
                                                                        <span
                                                                            style={{ lineHeight: '1.2', marginRight: '-10px', marginTop: '5px', cursor: 'pointer' }}

                                                                        >

                                                                            {/* {applicationStatusChartState?.interviewSchedule &&
                                                                                applicationStatusChartState?.interviewCancelStatus && (
                                                                                    <>
                                                                                        Interview <br /> Cancel
                                                                                    </>
                                                                                )
                                                                            }

                                                                            {applicationStatusChartState?.interviewSchedule &&
                                                                                applicationStatusChartState?.InterviewReScheduled && (
                                                                                    <>
                                                                                        Interview <br /> Reschedule
                                                                                    </>
                                                                                )
                                                                            }

                                                                            {applicationStatusChartState?.interviewSchedule &&
                                                                                !applicationStatusChartState?.InterviewReScheduled &&
                                                                                !applicationStatusChartState?.interviewCancelStatus && (
                                                                                    <>
                                                                                        Interview <br /> Letter
                                                                                    </>
                                                                                )
                                                                            } */}

                                                                            {applicationStatusChartState?.interviewSchedule ? (
                                                                                applicationStatusChartState?.interviewCancelStatus ? (
                                                                                    <>
                                                                                        Interview <br /> Cancel
                                                                                    </>
                                                                                ) : applicationStatusChartState?.InterviewReScheduled ? (
                                                                                    <>
                                                                                        Interview <br /> Reschedule
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        Interview <br /> Letter
                                                                                    </>
                                                                                )
                                                                            ) : (
                                                                                <>
                                                                                    Interview <br /> Letter
                                                                                </>
                                                                            )}




                                                                        </span>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </Col>


                                                    </Row>
                                                </div>

                                                <div className="step">
                                                    <Row>
                                                        <Col xs="12" md='5'><h3 className='text-table-heading'>Appointment</h3></Col>
                                                        <Col xs="12" md='7'>
                                                            <div className="step-progress">
                                                                <div className={`step-item ${applicationStatusChartState?.sortListed || applicationStatusChartState?.finalSelect ? 'completed' : 'pending'}`}>
                                                                    <div className="circle" style={{ color: 'white' }}>✔</div>
                                                                    <span style={{ marginLeft: '-15px' }}>Selected</span>
                                                                </div>
                                                                <div className={`step-item step-item-end ${applicationStatusChartState?.finalSelect ? 'completed' : 'pending'}`}>
                                                                    <div className="circle" >
                                                                        <a href={applicationStatusChartState?.appointmentLetter}>
                                                                            <img src={progressImg} alt="" style={{ width: '12px', height: '12px', filter: applicationStatusChartState?.finalSelect ? 'invert(1)' : 'none', }} />
                                                                        </a>
                                                                    </div>
                                                                    <span style={{ lineHeight: '1.2', marginRight: '-20px' }}>Appointment <br /> Letter</span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>

                                </Col>
                                <Col md='6'>
                                    <div className=''>
                                        <h6 className='card-head-text'>Applied Job Details</h6>
                                    </div>
                                    <Card className='mt-1 p-3 allrole-card' style={{ height: '45vh', display: 'flex', flexDirection: 'column' }}>
                                        <Row className='d-flex flex-wrap'>
                                            <Col xs="12" md="6" className='search-box-allroles'>
                                                <div className="search-input-container allrole-search border-primary">
                                                    <input
                                                        className=""
                                                        value={searchQueryOfAppliedJob || ''}
                                                        onChange={(e) => {
                                                            setSearchQueryOfAppliedJob(e.target.value);
                                                            onChange(e.target.value);
                                                        }}
                                                        placeholder="Search..."
                                                    />
                                                    {searchQueryOfAppliedJob && searchQueryOfAppliedJob.length > 0 ? (
                                                        <span
                                                            className="search-delete-icon-dash"
                                                            onClick={() => {
                                                                setSearchQueryOfAppliedJob('');

                                                                onChange('');
                                                            }}
                                                        >
                                                            <i className="bi bi-x-lg"></i>
                                                        </span>
                                                    ) : (
                                                        <span className="search-magnifier-icon-dash pe-none" >
                                                            <i className="bi bi-search"></i>
                                                        </span>
                                                    )}
                                                </div>
                                                <Button variant='foreground-alternate' className='p-0 m-0 fs-5' onClick={() => {
                                                    setSearchQueryOfAppliedJob('');

                                                    onChange('');
                                                }}>
                                                    <i className="bi bi-arrow-clockwise"></i>
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row style={{ flex: 1, overflowY: 'auto', marginBottom: '10px' }} className="table-wrapper">
                                            <Col>
                                                <Table responsive className='table-responsive allrole-table'>
                                                    <thead className='roles-table-header'>
                                                        <tr>
                                                            <th className='text-start text-uppercase tableHeadFont-allJobs'>Advertisement No.</th>
                                                            <th className='text-start text-uppercase tableHeadFont-allJobs'>Post Name</th>
                                                            <th className='text-start text-uppercase tableHeadFont-allJobs'>Applied Date</th>
                                                            <th className='text-start text-uppercase tableHeadFont-allJobs'>Status</th>
                                                            <th className='text-end pe-5 text-uppercase tableHeadFont-allJobs'>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ height: '100%', overflowY: 'scroll' }}>
                                                        {appliedJobDta?.length > 0 ? (
                                                            appliedJobDta.map((item) => {
                                                                return item.postDetails.map((postDetail) => {
                                                                    // Check if the current item is in the 'submitted' array
                                                                    const isSubmitted = ifUserInApplicationDetails?.submitted?.some(
                                                                        (submittedItem) =>
                                                                            submittedItem.advertisement === item._id &&
                                                                            submittedItem.vacancyId === postDetail.posting._id
                                                                    );

                                                                    // Check if the job is inactive
                                                                    const isActive = getTimeGap(item) !== "The advertisement has already closed.";

                                                                    return (
                                                                        <tr key={postDetail.posting._id} className='allTableRow'>
                                                                            <td className='text-start tableBodyFont-top'>
                                                                                {item.advertisementNumber || 'N/A'}
                                                                            </td>
                                                                            <td className='text-start tableBodyFont-top'>
                                                                                {postDetail.posting.postNameDetails?.designationName || 'N/A'}
                                                                            </td>
                                                                            <td className='text-start tableBodyFont-top'>
                                                                                {formatDate(postDetail.posting.appliedDate?.updatedAt) || 'N/A'}
                                                                            </td>
                                                                            <td className='text-start tableBodyFont-top'>
                                                                                {isActive ? (
                                                                                    <span className="badge activeBadge pt-2 pb-2 ps-3 pe-3 tableBodyFont-top">
                                                                                        Active
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="badge closedBadge pt-2 pb-2 ps-3 pe-3 tableBodyFont-top">
                                                                                        Inactive
                                                                                    </span>
                                                                                )}
                                                                            </td>
                                                                            <td className='text-end tableBodyFont-top'>
                                                                                <ButtonGroup className='me-4'>
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={<Tooltip id="tooltip-edit">Edit Application</Tooltip>}
                                                                                    >
                                                                                        <Button
                                                                                            variant='foreground-alternate'
                                                                                            className='p-0 m-0 px-1'
                                                                                            disabled={isSubmitted || !isActive}
                                                                                            onClick={() => handleRegisterFinal(item._id, postDetail.posting._id)}
                                                                                        >
                                                                                            <i className="bi bi-pencil"></i>
                                                                                        </Button>
                                                                                    </OverlayTrigger>

                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={<Tooltip id="tooltip-delete">Delete Application</Tooltip>}
                                                                                    >
                                                                                        <Button
                                                                                            variant='foreground-alternate'
                                                                                            className='p-0 m-0 px-1'
                                                                                            disabled={isSubmitted || !isActive}
                                                                                            onClick={() => openDeleteModal(item._id, postDetail.posting._id)}  // Open modal on delete click
                                                                                        >
                                                                                            <i className="bi bi-trash3"></i>
                                                                                        </Button>
                                                                                    </OverlayTrigger>

                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={<Tooltip id="tooltip-preview">Preview Scoring Sheet</Tooltip>}
                                                                                    >
                                                                                        <Button
                                                                                            variant='foreground-alternate'
                                                                                            className='p-0 m-0 px-1'
                                                                                            disabled={!isSubmitted}
                                                                                            onClick={() => redirectTOApplicantScoringSheet(item._id, postDetail.posting._id, "preview")}
                                                                                        >
                                                                                            <i className="bi bi-eye"></i>
                                                                                        </Button>
                                                                                    </OverlayTrigger>
                                                                                </ButtonGroup>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                });
                                                            })
                                                        ) : (
                                                            <tr>
                                                                <td colSpan={5} className='text-center'>No applied jobs found.</td>
                                                            </tr>
                                                        )}
                                                    </tbody>


                                                </Table>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </div>

                        <div className='custom-footer mt-5'>
                            <p className='custom-footer-text'>© 2024 Odisha Mining Corporation Ltd. (O M C LTD)</p>
                        </div>
                        {/* <Footer /> */}
                        <Gotop />


                        <Modal show={isDeleteModalOpen} centered onHide={() => setIsDeleteModalOpen(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title><h6 className="fw-bold">Confirm Delete</h6></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="fw-bold">Are you sure you want to delete this application?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setIsDeleteModalOpen(false)}>
                                    No
                                </Button>
                                <Button variant="primary" onClick={handleDelete}>
                                    Yes, Delete
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                )


            }
        </>





    )
}

export default CandidateDashboard
