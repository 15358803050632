import React, { forwardRef } from "react";
import { Form } from "react-bootstrap";

// Reusable FormInput Component
const CustomTextInput = forwardRef(
  (
    {
      maxLength,
      id,
      label,
      required,
      placeholder,
      name,
      touched,
      value,
      onChange,
      onBlur,
      errors,
      disabled,
      type,
      onCopy,
      onPaste,
      onCut,
      ...props
    },
    ref
  ) => {
    const style = {};
    style.label = {
      fontWeight: "500",
      color: "#000",
      fontSize: "13.5px",
      marginBottom: "-10px",
    };
    style.input = {
      borderRadius: "5px",
      height: "35px",
      color: "black",
      padding: "5px",
      color: "#333",
      fontSize: "12px",
      // fontWeight: "600",
      border: "2px solid #E6E6E6",
      fontFamily: '"Roboto", sans-serif',
    };
    style.errorSpan = {
      color: "red",
      fontSize: "12px",
    };
    style.errorBox = {
      border: "1px solid red",
    };

    console.log(errors, "here is error in line47");
    return (
      <Form.Group controlId={id}>
        <Form.Label style={style.label} className="registrationFont">
          {label} {required && <span className="requiredMark">*</span>}
        </Form.Label>
        <Form.Control
          required={required}
          style={
            errors && Object.keys(errors).length > 0
              ? { ...style.input, ...style.errorBox }
              : style.input
          }
          type={type}
          placeholder={placeholder}
          name={name}
          value={value || ""}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          disabled={disabled}
          autoComplete="off"
          onCopy={onCopy} // Prevents copy
          onPaste={onPaste} // Prevents paste
          onCut={onCut}
          maxLength={maxLength}
        />
        {errors &&
          touched &&
          Object.keys(errors).length > 0 &&
          Object.keys(errors).map((errKey) => (
            <span key={errKey} style={style.errorSpan}>
              {errors[errKey]}
              <br />
            </span>
          ))}
      </Form.Group>
    );
  }
);
export default CustomTextInput;
