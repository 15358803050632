import { Row, Col } from 'react-bootstrap';

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { camelCase, capitalCase } from "change-case";

import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Upload, { AWSUpload } from "../Upload/imageUpload";
import { UploadPdf } from "../Upload/pdfupload";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
// import "./DynamicComplexInputs/shortcodes.css";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Form,
  ModalHeader,
  Spinner,
  Table,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HamburgerMenu from "../assets/Mobile Menu/HamburgerMenu";
import DomicileOfOdisha from "./DynamicComplexInputs/DomicileOfOdisha";
import PersonWithDisability from "./DynamicComplexInputs/PersonWithDisability";
import AreYouASportsMan from "./DynamicComplexInputs/AreYouASportsMan";
import ReservationCategory from "./DynamicComplexInputs/ReservationCategory";
import DepartmentalCandidate from "./DynamicComplexInputs/DepartmentalCandidate";
import ExServicemen from "./DynamicComplexInputs/Exservicemen";
import ReactToPrint from "react-to-print";
import FinalFormSubmitModal from "./finalFormSubmitModal";
import pdf from "../assets/images/pdf.png";
import countriesData from '../masterFunctions/countries.json'
import { callApi } from "../constant/callApi";
// import { useHistory } from 'react-router-dom';
import ExperienceInputs from "./DynamicComplexInputs/ExperienceInputs";

const ApplicantScoringScreen_Preview = () => {
  const [OpenImageModal, SetImageModalOpen] = useState(false);
  const [applicationDetails, setApplicationDetails] = useState(null);
  const [applicationForm, setApplicationForm] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [tabIndexFromDb, setTabIndexFromDb] = useState(0);
  const [checkedValues, setCheckedValues] = useState([]);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [isAICheck, setIsAICheck] = useState(false);
  const [responceAiCheck, setResponceAiCheck] = useState("");
  const [application, setApplication] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [applicationID, setApplicationID] = useState(null);
  const [Loading, setLoading] = useState(true);
  const [finalSubmitModal, setFinalSubmitModal] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [fieldErrors, setFieldErrors] = useState([]);
  const [saveAndNextLoader, setSaveAndNextLoader] = useState(false)
  const [errorFromBackend, setErrorFromBackend] = useState({})
  const [selectedCountry, setSelectedCountry] = useState('');
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedState, setSelectedState] = useState("");

  const navigate = useNavigate();
  let countryHolder="66a66fd74db5f02d6861e873"
  let stateHolder="66a6705e4db5f02d6861e894"
  function sentenceCase(text) {
    if (!text) return "";
    const sentences = text.split(/(?<=[.!?])\s+/);
    return sentences
      .map((sentence) => {
        return sentence
          .split("/")
          .map((part) => {
            return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
          })
          .join("/");
      })
      .join(" ");
  }

  // const navigate = useNavigate();
  // const history = useHistory()

  const getApplicationById = async (id) => {
    setLoading(true);
    try {
      const config = {
        method: "post",
        url: "application/application/getApplicationById",
        data: {
          id: id,
        },
      };
      const response = await callApi(config);

      if (response?.data?.code === 200) {
        setApplication(response?.data?.data);

        setLoading(false);
      } else {
        // navigate("/alljoblist");
      }
      setIsLoading2(false);
    } catch (error) {
      // console.log(error);
      // navigate("/alljoblist");
    } finally {
      setIsLoading2(false);
    }
  };

  const location = useLocation();
  const [state, setState] = useState()
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const applicationId = queryParams.get('__application_id'); // Replace 'paramName' with your parameter name
    console.log("__application_id", applicationId);
    const state_ = {
      item: location.state?.item,
      post: location?.state?.post,
      applicationId: location?.state?.applicationIds,
      applicationDetailsId: location?.state?.applicationDetailsId
    }
    if (state_?.applicationId && state_.applicationDetailsId) {

      setState(state_)
      console.log({ state_ })
      getApplicationById(state_.applicationId);
    } else {
      // navigate("/alljoblist");
    }
  }, []);

  const style = {};
  style.label = {
    color: "#000",
    marginBottom: "-10px",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "600",
    fontSize: "15px",
    // fontSize: "10px",
    // fontWeight: "400px",
  };
  style.input = {
    borderRadius: "5px",
    height: "35px",
    color: "black",
    padding: "5px",
    color: "#000",
    fontSize: "13px",
    fontWeight: "500",
    border: "2px solid #E6E6E6",
    marginTop: "4px",
    // fontSize: "10px",
    // fontWeight: "350",
  };
  style.errorSpan = {
    color: "red",
    fontSize: "12px",
  };
  style.errorBox = {
    border: "1px solid red",
  };

  const inputBoxStyle = {
    height: "35px",
    borderRadius: "5px",
    paddingLeft: "10px",
  };

  const handleCheckboxChange = (e, item) => {
    const { checked, value } = e.target; // Extract 'checked' status and 'value'

    if (checked) {
      setCheckedValues([...checkedValues, value]);
    } else {
      setCheckedValues(checkedValues.filter((v) => v !== value));
    }
  };

  const handleAddFieldSet = (template, formTab) => {
    const newFieldSet = {
      id: Math.random(),
      fields: JSON.parse(JSON.stringify(template)),
      tab: camelCase(formTab),
    };
    setDynamicFields((prev) => [...prev, newFieldSet]);
  };

  const handleBlankAddFieldSet = (template, formTab) => {
    const DataPresent = dynamicFields.filter((e) => e.tab == camelCase(formTab))
    const isDataPresent = DataPresent.length > 0

    if (!isDataPresent) {

      const newFieldSet = {
        id: Math.random(),
        fields: JSON.parse(JSON.stringify(template)),
        tab: camelCase(formTab),
      };
      setDynamicFields((prev) => [...prev, newFieldSet]);
    }


  };
  const handleAddFieldSetWithValuesDynamic = (values, formTab) => {
    let keyAll = application?.inputElements?.filter((e) => {
      return camelCase(e.formTab) == formTab;
    });
    let val_ = keyAll.map((e) => {
      return { key: camelCase(e.inputFieldName), valuess: e };
    });
    let template = [];
    for (let index = 0; index < val_?.length; index++) {
      const obKey = val_[index].key;
      let data = {
        tab: formTab,
        value: values[obKey] || " ",
        values: val_[index].valuess,
      };
      template.push(data);
    }

    const newFieldSet = {
      id: Math.random(),
      fields: JSON.parse(JSON.stringify(template)),
      tab: formTab,
    };
    setDynamicFields((prev) => [...prev, newFieldSet]);
  };

  // Handler to delete a specific field set
  const handleDeleteFieldSet = (id) => {
    setDynamicFields((prev) => prev.filter((fieldSet) => fieldSet?.id !== id));
  };


  const toSentenceCase = (str) => {
    if (!str) return str;
    return str
      .toLowerCase()
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  };
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    console.log("2nd dynamicFields", { dynamicFields });
  }, [dynamicFields]);

  const collectUserApplicationData = async (state) => {
    try {
      let config = {
        method: "post",
        url: "Portal/jobApplication/admin/getApplicationById",
        data: {
          applicationDetailsId: state.applicationDetailsId
        },
      };

      let response = await callApi(config);
      if (response?.data?.code === 200) {
        if (response?.data?.data == null) {
          setApplicationDetails([]);
        } else {
          setApplicationDetails(response?.data?.data?.details);
          if(response?.data?.data?.details?.[countryHolder]){
            const newStates = getStatesByCountry(response?.data?.data?.details?.[countryHolder]);
            setStates(newStates);
          }
          if(response?.data?.data?.details?.[stateHolder] && response?.data?.data?.details?.[countryHolder]){
            setSelectedState(response?.data?.data?.details?.[stateHolder]);
            const newDistricts = getDistrictsByState(response?.data?.data?.details?.[countryHolder], response?.data?.data?.details?.[stateHolder]);
            setDistricts(newDistricts);
          }
          setApplicationID(response?.data?.data?.applicationSerialIds);
          setResponceAiCheck(response?.data?.data?.isAICheck);
          let dyEl = Object.keys(response?.data?.data?.details);
          setTabIndex(response?.data?.data?.lastSubmittedIndex || 0)
          setTabIndexFromDb(response?.data?.data?.lastSubmittedIndex || 0)
          for (let index = 0; index < dyEl?.length; index++) {
            const obKey = dyEl?.[index];
            const sentenceCaseKey = camelCase(obKey);

            if (Array.isArray(response?.data?.data?.details?.[obKey])) {
              for (
                let innerIndex = 0;
                innerIndex < response?.data?.data?.details?.[obKey]?.length;
                innerIndex++
              ) {
                const value =
                  response?.data?.data?.details?.[obKey]?.[innerIndex];
                handleAddFieldSetWithValuesDynamic(value, sentenceCaseKey);
              }
            } else {
            }
          }
        }

        // toast.success(response?.data?.message, { autoClose: 2000 });
      } else {
        await handleSubmitForm("", true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateApplicationTabIndex = async (tResultArray) => {
    try {
      let config = {
        method: "post",
        url: "Portal/jobApplication/portal/getApplicationByUser",
        data: {
          AdVId: state?.item?._id,
          vacancyId: state?.post?.posting?._id,
        },
      };

      let response = await callApi(config);
      if (response?.data?.code === 200) {
        if (response?.data?.data == null) {
          setApplicationDetails([]);
        } else {
          setTabIndexFromDb(response?.data?.data?.lastSubmittedIndex || 0)
        }
      } else {
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setIsLoading(false);
    }
  };
  function getOrdinalSuffix(num) {
    if (num % 100 >= 11 && num % 100 <= 13) {
      return num + "th";
    }

    // Determine the suffix based on the last digit of the number
    switch (num % 10) {
      case 1:
        return num + "st";
      case 2:
        return num + "nd";
      case 3:
        return num + "rd";
      default:
        return num + "th";
    }
  }
  useEffect(() => {
    const func = async () => {
      if (state && application?.inputElements) {
        const groupedData = await groupByFormTab(application.inputElements);
        const resultArray = await convertGroupedDataToArray(groupedData);
        setApplicationForm(resultArray);
        collectUserApplicationData(state);
      }
    };

    func();
  }, [application?.inputElements, state]); // Ensure you only depend on specific changes

  const handleUploadImage = async (event, fieldName) => {
    event.preventDefault();
    const file = event.target.files[0];
    // console.log("file", file);
    const imageUrl = await AWSUpload(file);
    // const imageUrl = await Upload(file)
    // console.log(imageUrl, "imageUrl");
    setApplicationDetails({ ...applicationDetails, [fieldName]: imageUrl });
    // setImage(imageUrl)
  };

  const groupByFormTab = (data) => {
    const groupedData = {}; // Initialize an empty object for grouping

    data?.forEach((item) => {
      const { formTab, ...values } = item; // Destructure to get the key and value
      if (!groupedData[formTab]) {
        groupedData[formTab] = [];
      }
      groupedData[formTab].push({ values });
    });

    return groupedData; // Return the grouped data object
  };
  const convertGroupedDataToArray = (groupedData) => {
    const resultArray = [];
    for (const formTab in groupedData) {
      if (groupedData.hasOwnProperty(formTab)) {
        resultArray.push({
          formTab, // FormTab key,
          isArray: groupedData?.[formTab]?.[0]?.values?.isArray,
          values: groupedData[formTab], // Array of value objects
        });
      }
    }

    return resultArray;
  };

  const toCamelCaseKeys = (obj) => {
    // console.log({ obj });
    if (Array.isArray(obj)) {
      return obj.map(toCamelCaseKeys);
    } else if (
      obj !== null &&
      obj !== undefined &&
      obj?.constructor === Object
    ) {
      return Object.keys(obj).reduce((result, key) => {
        const camelKey = camelCase(key);
        result[camelKey] = toCamelCaseKeys(obj[key]);
        return result;
      }, {});
    }
    return obj;
  };

  const checkErrorsClass = () => {
    return new Promise((resolve) => {
      let Divid = 'captureErrorFor_' + tabIndex;
      const specificDivs = document.querySelectorAll(`.${Divid}`);
      let err = 0;
      let firstErrorElement = null; // To store the first error element
      console.log({ specificDivs }, { Divid });

      for (let i = 0; i < specificDivs.length; i++) {
        const div = specificDivs[i];
        const inputs = div.querySelectorAll("input, select");

        for (let j = 0; j < inputs.length; j++) {
          inputs[j].focus();
          inputs[j].blur();
        }
      }

      // Introduce a slight delay to allow DOM to update
      setTimeout(() => {
        const specificDivsWithError = document.querySelectorAll(`.${Divid}`);
        console.log({ specificDivsWithError }, { Divid });

        for (let i = 0; i < specificDivsWithError.length; i++) {
          const div = specificDivsWithError[i];
          const errorSpans = div.querySelectorAll("span.errorPresent");
          console.log({ errorSpans });

          for (let j = 0; j < errorSpans.length; j++) {
            err++;
            if (firstErrorElement === null) {
              firstErrorElement = errorSpans[j];
            }
          }
        }

        // If there's at least one error, focus on the input associated with the first error span
        if (firstErrorElement) {
          const inputToFocus = firstErrorElement.closest('div').querySelector("input, select");
          if (inputToFocus) {
            inputToFocus.focus();
          }
        }


        console.log({ err });
        resolve(err == 0);
      }, 100);
    });
  };

  const handleSubmitForm = async (value, isInitialSubmit) => {
    checkErrorsClass().then(async (noErrors) => {

      if (noErrors || isInitialSubmit) {
        try {
          const result = {};
          dynamicFields.forEach((entry) => {
            const record = {};
            entry?.fields.forEach((field) => {
              const tab = field?.tab;

              if (!result[tab]) {
                result[tab] = [];
              }
              const fieldName = camelCase(field.values.inputFieldName);
              record[fieldName] = field.value;
            });


            result[entry?.fields[1]?.tab]?.push(record); // Add only once
          });
          const camelCaseApplicationDetails = toCamelCaseKeys(applicationDetails);

          if (value === "Submit") {
            const config = {
              method: "post",
              url: "Portal/jobApplication/portal/findApplicationByIdAndUpdateOrCreate",
              data: {
                details: {
                  ...camelCaseApplicationDetails,
                  skills: checkedValues,
                  advertisement: state?.item?._id,
                  vacancyId: state?.post?.posting?._id,
                  finalSubmit: true,
                  ...result,
                },
                userId: userId,
                dynamicApplicationForm: application,
                isAICheck: true,
                tabIndex: Number(tabIndex)

              },
            };

            const response = await callApi(config);
            if (response?.data?.code == 200) {
              await collectUserApplicationData();
              // toast.success(response?.data?.message, { autoClose: 2000 });
              // navigate("/candidateDashboard");
            }
          } else {
            setSaveAndNextLoader(true)
            const config = {
              method: "post",
              url: "Portal/jobApplication/portal/findApplicationByIdAndUpdateOrCreate",
              data: {
                details: {
                  ...camelCaseApplicationDetails,
                  skills: checkedValues,
                  advertisement: state?.item?._id,
                  vacancyId: state?.post?.posting?._id,
                  ...result,
                  tabIndex: tabIndex
                },
                userId: userId,
                isAICheck: isAICheck,
                dynamicApplicationForm: application,
                tabIndex: Number(tabIndex)

              },
            };

            const response = await callApi(config);
            if (response?.data?.code == 201) {

              await collectUserApplicationData()
              // toast.success(response?.data?.message, { autoClose: 2000 });
            }
            setIsLoading(false);
            setSaveAndNextLoader(false)
            if (response?.data?.code == 200) {
              console.log("data", response?.data)
              await updateApplicationTabIndex()
              let allowFurtherEducation = response?.data?.ageEligibility?.isValidAgeForApplication

              let errorCount = 0
              if (!allowFurtherEducation) {
                errorCount++

                let ageEligibility = response?.data?.ageEligibility
                setErrorFromBackend(prevState => ({
                  ...prevState,
                  ageEligibilityError: { ...ageEligibility, message: `Your age is not eligible for this application, as the maximum base age / relaxed age for this vacancy is ${ageEligibility?.relaxedAge}, according to your inputs your age is ${ageEligibility?.applicantAge}.` }
                }));

              } else {

                setErrorFromBackend(prevState => {
                  const { ageEligibilityError, ...rest } = prevState;
                  return { ...rest };
                });
              }

              let allowFurtherExperience = response?.data?.experienceEligibility.eligible

              if (!allowFurtherExperience && tabIndex >= 4) {
                errorCount++

                let expEligibility = response?.data?.experienceEligibility
                setErrorFromBackend(prevState => ({
                  ...prevState,
                  experienceEligibilityError: { ...expEligibility, message: `Your experience is not eligible for this application, as the required experience is ${expEligibility?.experienceRequired} years, and according to your information your experience is ${expEligibility?.experienceHave} years.` }
                }));
              } else {

                setErrorFromBackend(prevState => {
                  const { experienceEligibilityError, ...rest } = prevState;
                  return { ...rest };
                });
              }
              if (errorCount < 1) {
                setTabIndex(
                  Math.min(applicationForm?.length, tabIndex + 1)
                );
              }
            }
          }
        } catch (error) {
          toast.error("SomeThing Went wrong Please try again", {
            autoClose: 2000,
          });
        } finally {
          setIsLoading(false);
        }
      } else {
        console.log("Errors found, cannot proceed.");
      }
    });



  };


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [tabIndex]);

  const renderArrayFields = (field, fieldIndex, item, fieldSet) => {
    const label = field?.values?.inputFieldName;
    // console.log({ field })
    const camelCaseKey = field?.values?._id + "__" + fieldSet.id

    const updateMarkValue = (value, tab) => {
      const updatedFields = dynamicFields.map((fs) => {
        if (fs.id === fieldSet.id) {
          let isCorrect = Number(fs.fields[fieldIndex - 1].value) >= Number(value)
          if (!isCorrect) setFieldErrors(prevState => ({
            ...prevState,
            [camelCaseKey]: {
              errors: [
                ...new Set([...(prevState[camelCaseKey]?.errors || []), "Total mark should be greater then obtained mark."])
              ], // Append the error message
              touched: prevState?.[camelCaseKey]?.touched
            }
          }));
          let percentMark = (Number(fs.fields[fieldIndex - 1].value) / Number(value)) * 100
          if (percentMark < 0 || percentMark > 100) percentMark = "Invalid"
          fs.fields[fieldIndex].value = value;

          fs.fields[fieldIndex + 1].value = percentMark;

          fs.fields[fieldIndex].tab = tab || camelCase(fs?.tab);
          return { ...fs };
        }
        return fs;
      });
      setDynamicFields(updatedFields);
    };

    const updateFieldValue = (value, tab) => {
      const updatedFields = dynamicFields.map((fs) => {
        if (fs.id === fieldSet.id) {
          fs.fields[fieldIndex].value = value;
          fs.fields[fieldIndex].tab = tab || camelCase(fs?.tab);
          return { ...fs };
        }
        return fs;
      });
      setDynamicFields(updatedFields);
    };
    const renderInput = (type, field, fieldIndex, fieldProps, item = null, idx = null) => {
      const commonProps = {
        className: "form-check-input",
        style: { border: "1px solid brown", borderRadius: "10px" },
        ...fieldProps,
      };

      const camelCaseKey = field?.values?._id + "__" + fieldSet.id
      if (type === "checkbox" || type === "radio") {
        return (
          <div className="form-check" key={`${item.label}.${idx}`}>
            <input {...commonProps} type={type} value={item.value} />
            <label
              className="form-check-label"
              htmlFor={commonProps.id}
              style={style.label}
            >
              {item.label}
            </label>
          </div>
        );


      }


      switch (label) {
        case "Maximum Marks":
          return <>
            <input
              className="form-control"
              style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
              type={type === "string" ? "text" : type}
              // {...fieldProps}
              value={field?.value}
              onChange={(e) => {
                //  updateFieldValue(e.target.value)
                updateMarkValue(e.target.value)
                fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(field, camelCaseKey, field?.value)
              }}
              onBlur={(e) => {
                checkErrorFn(field, camelCaseKey, field?.value)
              }}
            />
            {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
              fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
              ))
            )}
          </>

        case "Percentage of Marks/CGPA/Grade":
          return (
            <>
              <input
                className="form-control"
                style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                type={type === "string" ? "text" : type}
                // {...fieldProps}
                value={field?.value}
                // onChange={(e) => updateFieldValue(e.target.value)}
                onBlur={(e) => {
                  checkErrorFn(field, camelCaseKey, field?.value)
                }}
              />
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                ))
              )}
            </>)
          break;

        default:
          return (
            <>
              <input
                className="form-control"
                style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                type={type === "string" ? "text" : type}
                // {...fieldProps}
                value={field?.value}
                onChange={(e) => updateFieldValue(e.target.value)}
                onBlur={(e) => {
                  checkErrorFn(field, camelCaseKey, field?.value)
                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(field, camelCaseKey, field?.value)

                }}
              />
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                ))
              )}
            </>

          );
          break;
      }


    };

    const renderSelectOptionsForDesirableQualification = () =>
      applicationDetails?.vacancyId?.department?.desirableQualification?.map(
        (option, idx) => (
          <option key={`${option.qualification}.${idx}`} value={option.qualification}>
            {option.qualification}
          </option>
        )
      );

    const renderField = (field, fieldIndex) => {
      switch (field?.values?.inputFieldType) {
        case "checkbox":
          return field?.values?.inputFieldTags.map((item, idx) =>
            renderInput("checkbox", {
              checked: checkedValues.includes(item.value),
              onChange: (e) => handleCheckboxChange(e, item),
              id: `flexCheckboxDefault1.${idx}`,
            }, item, idx)
          );

        case "radio":
          return field?.values?.inputFieldTags.map((item, idx) =>
            renderInput("radio", {
              checked: field.value === item.value,
              name: field.values.inputFieldName,
              onChange: (e) => updateFieldValue(e.target.value, item?.formTab),
              id: `flexRadioDefault1.${idx}`,
            }, item, idx)
          );

        case "select":
          return (
            <>
              <select
                style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                value={field.value}
                onChange={(e) => updateFieldValue(e.target.value)}
                onBlur={(e) => {
                  checkErrorFn(field, camelCaseKey, field?.value)
                }}
              >
                <option value="">Select</option>
                {label == "Course Name" && renderSelectOptionsForDesirableQualification()}
                {label !== "Course Name" && field?.values?.inputFieldTags?.map(
                  (option, idx) => (
                    <option key={`${option.id}`} value={option.label}>
                      {option.label}
                    </option>
                  )
                )}
              </select>
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                ))
              )}
            </>
          );

        default:
          return renderInput(field?.values?.inputFieldType, field, fieldIndex, {
            value: field.value,
            onChange: (e) => updateFieldValue(e.target.value),
          });
      }
    };

    return (
      <Col key={fieldIndex} xs={field?.values?.columnLength}>
        {field?.values?.inputFieldType === "SubHeading" ? (
          <label className="ps-0 ms-0 form-label form-heading-main">{label}</label>
        ) : (
          <>
            <label className="inputBoxHeadForm upload-head-gap" style={style.label}>
              {label}
              {field?.values?.isRequired && label && (
                <span className="mx-1 text-danger star">*</span>
              )}
            </label>
            {renderField(field, fieldIndex)}
          </>
        )}
      </Col>
    );
  };


  const checkErrorFn = async (input, camelCaseKey, fieldValue) => {
    console.log("check this ", { input }, { camelCaseKey }, { fieldValue });
    fieldValue = fieldValue?.toString()?.trim()
    if (!input.values.isRequired && !fieldValue) {
      console.log({ fieldErrors })

      setFieldErrors(prevState => ({
        ...prevState,
        [camelCaseKey]: {
          errors: [],
          touched: true
        }
      }));
      return;
    }

    else if (input.values.isRequired && !fieldValue) {
      console.log({ fieldErrors })
      setFieldErrors(prevState => ({
        ...prevState,
        [camelCaseKey]: {
          errors: [
            ...new Set(["This field is required."])
          ], // Append the error message
          touched: true
        }
      }))
    }
    else {
      let errCount = 0
      console.log({ fieldErrors })

      let rExp = input.values.validationReg;
      console.log({ rExp });

      // rExp.forEach(reg => {
      for (let index = 0; index < rExp.length; index++) {
        const reg = rExp[index];


        // Assuming reg.applicable_regex contains the regular expression string
        const regex = new RegExp(reg.applicable_regex);

        if (!regex.test(fieldValue)) {
          errCount++
          // If the regex does not match the field value, update the fieldError state
          setFieldErrors(prevState => ({
            ...prevState,
            [camelCaseKey]: {
              errors: [
                ...new Set([...(prevState[camelCaseKey]?.errors || []), reg.error_message])
              ], // Append the error message
              touched: true
            }
          }));
        }
      };
      if (errCount < 1) {
        console.log({ fieldErrors })

        setFieldErrors(prevState => ({
          ...prevState,
          [camelCaseKey]: {
            errors: [],
            touched: true
          }
        }));
      }
    }
  };


  const getStatesByCountry = (countryName) => {
    const foundCountry = countriesData?.find((country) => country.name === countryName);
    return foundCountry ? foundCountry.states.map(state => state.state) : [];
  };

  const getDistrictsByState = (countryName, stateName) => {
    const foundCountry = countriesData?.find((country) => country.name === countryName);
    const foundState = foundCountry?.states?.find((state) => state.state === stateName);
    return foundState ? foundState.districts : [];
  };


  const [isSameAsCorrespondence, setIsSameAsCorrespondence] = useState(false);

  // Handle checkbox change
  const handleCheckboxChange_ = (e) => {
    setIsSameAsCorrespondence(e.target.checked);
  };

  //useEffects For Validations
  useEffect(() => {
    if (isSameAsCorrespondence) {
      setApplicationDetails((prev) => ({
        ...prev,
        // Add the properties from correspondence details you want to copy here
        ["66a672c14db5f02d6861e92f"]: prev?.["66a66fd74db5f02d6861e871"], // Example
        ["66a672c14db5f02d6861e930"]: prev?.["66a66fd74db5f02d6861e872"], // Example
        ["66a672c14db5f02d6861e931"]: prev?.["66a66fd74db5f02d6861e873"], // Example
        ["66a672c14db5f02d6861e932"]: prev?.["66a6705e4db5f02d6861e894"], // Example
        ["66a672c14db5f02d6861e933"]: prev?.["66a6705e4db5f02d6861e895"], // Example
        ["66a672c14db5f02d6861e934"]: prev?.["66a6705e4db5f02d6861e896"], // Example
      }));
    }

  }, [isSameAsCorrespondence]);

  useEffect(() => {
    let markTotalIn10thId = "66a687874db5f02d6861ed06"
    let markObtainedIn10thId = "66a687874db5f02d6861ed05"
    let percentageIn10thId = "66a687874db5f02d6861ed07"

    let markTotalIn12thId = "66a731359680c25e280d6e4b"
    let markObtainedIn12thId = "66a730c29680c25e280d6de8"
    let percentageIn12thId = "66a7359a9680c25e280d6f16"

    let markTotalInGrThId = "66a687874db5f02d6861ed10"
    let markObtainedInGrThId = "66a687874db5f02d6861ed0f"
    let percentageInGrThId = "66a687874db5f02d6861ed11"


    if (Number(applicationDetails?.[markTotalIn10thId] < Number(applicationDetails?.[markObtainedIn10thId]))) {
      setFieldErrors(prevState => ({
        ...prevState,
        [markTotalIn10thId]: {
          errors: [
            ...new Set([...(prevState[markTotalIn10thId]?.errors || []), "Total Mark should be greater then obtained mark"])
          ], // Append the error message
          touched: prevState?.[markTotalIn10thId]?.touched
        }
      }));
    }
    if (Number(applicationDetails?.[markTotalIn12thId] < Number(applicationDetails?.[markObtainedIn12thId]))) {
      setFieldErrors(prevState => ({
        ...prevState,
        [markTotalIn12thId]: {
          errors: [
            ...new Set([...(prevState[markTotalIn12thId]?.errors || []), "Total Mark should be greater then obtained mark"])
          ], // Append the error message
          touched: prevState?.[markTotalIn12thId]?.touched
        }
      }));
    }
    if (Number(applicationDetails?.[markTotalInGrThId] < Number(applicationDetails?.[markObtainedInGrThId]))) {
      setFieldErrors(prevState => ({
        ...prevState,
        [markTotalInGrThId]: {
          errors: [
            ...new Set([...(prevState[markTotalInGrThId]?.errors || []), "Total Mark should be greater then obtained mark"])
          ], // Append the error message
          touched: prevState?.[markTotalInGrThId]?.touched
        }
      }));
    }


  }, [applicationDetails]);
  useEffect(() => {
    let markTotalIn10thId = "66a687874db5f02d6861ed06"
    let markObtainedIn10thId = "66a687874db5f02d6861ed05"
    let percentageIn10thId = "66a687874db5f02d6861ed07"

    let markTotalIn12thId = "66a731359680c25e280d6e4b"
    let markObtainedIn12thId = "66a730c29680c25e280d6de8"
    let percentageIn12thId = "66a7359a9680c25e280d6f16"

    let markTotalInGrThId = "66a687874db5f02d6861ed10"
    let markObtainedInGrThId = "66a687874db5f02d6861ed0f"
    let percentageInGrThId = "66a687874db5f02d6861ed11"


    // Update percentage only if it's different
    setApplicationDetails((prevState) => {

      const calculatePercentage = (obtained, total) => {
        const obtainedMarks = Number(prevState?.[obtained]);
        const totalMarks = Number(prevState?.[total]);
        return isNaN(obtainedMarks) || isNaN(totalMarks) || totalMarks === 0
          ? ""
          : ((obtainedMarks / totalMarks) * 100).toFixed(2);
      };

      const newPercentage10th = calculatePercentage(markObtainedIn10thId, markTotalIn10thId);
      const newPercentage12th = calculatePercentage(markObtainedIn12thId, markTotalIn12thId);
      const newPercentageGr = calculatePercentage(markObtainedInGrThId, markTotalInGrThId);


      if (prevState?.[percentageIn10thId] !== newPercentage10th || prevState?.[percentageIn12thId] !== newPercentage12th || prevState?.[percentageInGrThId] !== newPercentageGr) {
        return {
          ...prevState,
          [percentageIn10thId]: newPercentage10th <= 100 ? newPercentage10th : "Invalid",
          [percentageIn12thId]: newPercentage12th <= 100 ? newPercentage12th : "Invalid",
          [percentageInGrThId]: newPercentageGr <= 100 ? newPercentageGr : "Invalid",
        };
      }
      return prevState;
    });



  }, [applicationDetails]);

  useEffect(() => {
    // Define the field IDs
    const yearofPassing10thId = "66a687874db5f02d6861ed02";
    const yearofPassing12thId = "66a727079680c25e280d6b2d";
    const yearofPassingGraduationId = "66a687874db5f02d6861ed0d";

    const year12 = Number(applicationDetails?.[yearofPassing12thId]);
    const year10 = Number(applicationDetails?.[yearofPassing10thId]);
    const yearGrad = Number(applicationDetails?.[yearofPassingGraduationId]);

    let newFieldErrors = { ...fieldErrors };

    // Check if year12 is less than year10
    if (year12 <= year10) {
      newFieldErrors = {
        ...newFieldErrors,
        [yearofPassing12thId]: {
          errors: [
            ...new Set([...(newFieldErrors[yearofPassing12thId]?.errors || []), "12th Passing year cannot be less than 10th Passing year"]),
          ],
          touched: newFieldErrors?.[yearofPassing12thId]?.touched
        },
        [yearofPassing10thId]: {
          errors: [
            ...new Set([...(newFieldErrors[yearofPassing10thId]?.errors || []), "10th Passing year cannot be greater than 12th Passing year"]),
          ],
          touched: newFieldErrors?.[yearofPassing10thId]?.touched
        }
      };
    } else {
      // Clear the error messages
      newFieldErrors = {
        ...newFieldErrors,
        [yearofPassing12thId]: {
          errors: newFieldErrors?.[yearofPassing12thId]?.errors?.filter(error => error !== "12th Passing year cannot be less than 10th Passing year"),
          touched: newFieldErrors?.[yearofPassing12thId]?.touched
        },
        [yearofPassing10thId]: {
          errors: newFieldErrors?.[yearofPassing10thId]?.errors?.filter(error => error !== "10th Passing year cannot be greater than 12th Passing year"),
          touched: newFieldErrors?.[yearofPassing10thId]?.touched
        }
      };
    }

    // Check if yearGrad is less than year10 or year12
    if (yearGrad <= year10) {
      newFieldErrors = {
        ...newFieldErrors,
        [yearofPassingGraduationId]: {
          errors: [
            ...new Set([...(newFieldErrors[yearofPassingGraduationId]?.errors || []), "Graduation Passing year cannot be less than 10th or 12th Passing year"]),
          ],
          touched: newFieldErrors?.[yearofPassingGraduationId]?.touched
        },
        ...(yearGrad <= year10 && {
          [yearofPassing10thId]: {
            errors: [
              ...new Set([...(newFieldErrors[yearofPassing10thId]?.errors || []), "10th Passing year cannot be greater than Graduation Passing year"]),
            ],
            touched: newFieldErrors?.[yearofPassing10thId]?.touched
          }
        }),
        ...(yearGrad <= year12 && {
          [yearofPassing12thId]: {
            errors: [
              ...new Set([...(newFieldErrors[yearofPassing12thId]?.errors || []), "12th Passing year cannot be greater than Graduation Passing year"]),
            ],
            touched: newFieldErrors?.[yearofPassing12thId]?.touched
          }
        }),
      };
    } else if (yearGrad <= year12 && yearGrad > year10) {
      newFieldErrors = {
        ...newFieldErrors,
        [yearofPassingGraduationId]: {
          errors: [
            ...new Set([...(newFieldErrors[yearofPassingGraduationId]?.errors || []), "Graduation Passing year cannot be less than 10th or 12th Passing year"]),
          ],
          touched: newFieldErrors?.[yearofPassingGraduationId]?.touched
        },
        [yearofPassing10thId]: {
          errors: newFieldErrors?.[yearofPassing10thId]?.errors?.filter(error => error !== "10th Passing year cannot be greater than Graduation Passing year"),
          touched: newFieldErrors?.[yearofPassing10thId]?.touched
        },
        ...(yearGrad < year12 && {
          [yearofPassing12thId]: {
            errors: [
              ...new Set([...(newFieldErrors[yearofPassing12thId]?.errors || []), "12th Passing year cannot be greater than Graduation Passing year"]),
            ],
            touched: newFieldErrors?.[yearofPassing12thId]?.touched
          }
        }),
      };
    }

    else {
      // Clear the error messages for Graduation check
      newFieldErrors = {
        ...newFieldErrors,
        [yearofPassingGraduationId]: {
          errors: newFieldErrors?.[yearofPassingGraduationId]?.errors?.filter(error => error !== "Graduation Passing year cannot be less than 10th or 12th Passing year"),
          touched: newFieldErrors?.[yearofPassingGraduationId]?.touched
        },
        [yearofPassing10thId]: {
          errors: newFieldErrors?.[yearofPassing10thId]?.errors?.filter(error => error !== "10th Passing year cannot be greater than Graduation Passing year"),
          touched: newFieldErrors?.[yearofPassing10thId]?.touched
        },
        [yearofPassing12thId]: {
          errors: newFieldErrors?.[yearofPassing12thId]?.errors?.filter(error => error !== "12th Passing year cannot be greater than Graduation Passing year"),
          touched: newFieldErrors?.[yearofPassing12thId]?.touched
        }
      };
    }

    setFieldErrors(newFieldErrors);

  }, [applicationDetails]);

  const renderNonArrayFields = (item) => {
    // Function to render select input
    const renderSelectInput = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage
    ) => {

      switch (label) {
        case "Course Name (Graduation)":

          return (
            // <select
            //   // className="inputBoxForm text-dark"
            //   style={style.input}
            //   placeholder={label}
            //   value={fieldValue}
            //   onChange={(e) =>
            //     setApplicationDetails({
            //       ...applicationDetails,
            //       [camelCaseKey]: e.target.value,
            //     })
            //   }
            // >

            // </select>

            <>
              <select
                // className="inputBoxForm text-dark"
                style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                placeholder={label}
                value={fieldValue}
                onChange={(e) => {
                  setApplicationDetails({
                    ...applicationDetails,
                    [camelCaseKey]: e.target.value,
                  })

                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, fieldValue)
                }}
                onBlur={(e) => {
                  checkErrorFn(input, camelCaseKey, fieldValue)
                }}
                max={new Date().toISOString().split('T')[0]}

              >

                <option value="" className="text-muted">Select</option>
                {applicationDetails?.vacancyId?.department?.essentialQualification?.map(
                  (item, idx) => (
                    <option key={idx} value={item.qualification}>
                      {item.qualification}
                    </option>
                  )
                )}
              </select>
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                ))
              )}
            </>
          );
          break;
        case "Country":
          return (
            <select
              style={style.input}
              placeholder={label}
              value={fieldValue}
              onChange={(e) => {
                setApplicationDetails({
                  ...applicationDetails,
                  [camelCaseKey]: e.target.value,
                });

                const selectedCountry = e.target.value;
                setSelectedCountry(selectedCountry);

                const newStates = getStatesByCountry(selectedCountry);
                console.log({ newStates })
                setStates(newStates);
                setSelectedState(''); // Clear selected state when country changes
                setDistricts([]); // Clear districts when country changes
              }}
            >
              <option value="">Select Country</option>
              {countriesData.map((country) => {
                return (
                  <option key={country.name} value={country.name}>
                    {country.name}
                  </option>
                )
              }
              )}
            </select>
          );
          break;

        case "State":
          return (
            <select
              style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
              placeholder={label}
              value={fieldValue}
              onChange={(e) => {
                setApplicationDetails({
                  ...applicationDetails,
                  [camelCaseKey]: e.target.value,
                });

                const selectedState = e.target.value;
                setSelectedState(selectedState);

                const newDistricts = getDistrictsByState(selectedCountry, selectedState);
                setDistricts(newDistricts);
              }}
              disabled={!selectedCountry}
            >
              <option value="">Select State</option>
              {states.map((state, idx) => (
                <option key={idx} value={state}>
                  {state}
                </option>
              ))}
            </select>
          );
          break;

        case "District":
          return (
            <select
              style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
              placeholder={label}
              value={fieldValue}
              onChange={(e) => {
                setApplicationDetails({
                  ...applicationDetails,
                  [camelCaseKey]: e.target.value,
                });

                fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, fieldValue);
              }}
              disabled={!selectedState}
            >
              <option value="">Select District</option>
              {districts.map((district, idx) => (
                <option key={idx} value={district}>
                  {district}
                </option>
              ))}
            </select>
          );
          break;
        default:
          return (
            <>
              <select
                // className="inputBoxForm text-dark"
                style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                placeholder={label}
                value={fieldValue}
                onChange={(e) => {
                  setApplicationDetails({
                    ...applicationDetails,
                    [camelCaseKey]: e.target.value,
                  })

                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, fieldValue)
                }}
                onBlur={(e) => {
                  checkErrorFn(input, camelCaseKey, fieldValue)
                }}
                max={new Date().toISOString().split('T')[0]}

              >

                <option value="" className="text-muted">Select</option>
                {input?.values?.inputFieldTags.map((item, idx) => (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                ))
              )}
            </>

          );
          break;
      }
    };

    // Function to render radio input
    const renderRadioInput = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage
    ) => (
      <div className="d-flex flex-wrap flex-row">
        {input?.values?.inputFieldTags.map((item, idx) => (
          <div
            className="form-check form-check-inline"
            key={`${item.label}.${idx}`}
          >
            <input
              className="form-check-input "
              // style={{ border: "1px solid black", borderRadius: "10px" }}
              type="radio"
              name={camelCaseKey}
              id={`flexRadioDefault1.${idx}`}
              value={item.value}
              checked={fieldValue === item.value}
              onChange={(e) =>
                setApplicationDetails({
                  ...applicationDetails,
                  [camelCaseKey]: e.target.value,
                })
              }
              max={new Date().toISOString().split('T')[0]}

            />
            <label
              className="form-check-label"
              htmlFor={`flexRadioDefault1.${idx}`}
            >
              {item.label}
            </label>
          </div>
        ))}
      </div>
    );

    // Function to render note
    const renderNote = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage
    ) => (
      <div className="d-flex flex-wrap inputBoxHeadForm">
        <div dangerouslySetInnerHTML={{ __html: input?.values?.note }}></div>
      </div>
    );

    // Function to render checkbox input
    const renderCheckboxInput = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage
    ) => (
      <>
        {input?.values?.inputFieldTags.map((item, idx) => (
          <div
            className="form-check form-switch mb-2"
            key={`${item.label}.${idx}`}
          >
            <input
              className="form-check-input "
              style={{ borderRadius: "10px", height: "16px" }}
              type="checkbox"
              value={item.value}
              checked={checkedValues.includes(item.value)}
              onChange={(e) => handleCheckboxChange(e, item)}
              id={`flexCheckboxDefault1.${idx}`}
              max={new Date().toISOString().split('T')[0]}

            />
            <label
              className="form-check-label inputBoxHeadForm"
              htmlFor={`flexCheckboxDefault1.${idx}`}
            >
              {item.label}
            </label>
          </div>
        ))}
      </>
    );

    const renderImageInput = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage
    ) => (
      <div className="imageDiv d-flex align-items-center gap-4">
        <div style={{ position: "relative", display: "inline-block" }}>
          <button
            style={{
              borderRadius: "10px",
              height: "40px",
              padding: "5px 15px",
              fontSize: "12px",
              cursor: "pointer",
              margin: '15px 0',
              border: "none",
              backgroundColor:
                fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                  ? '#921d1e' :
                  "#f0f0f0",

              color:
                fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                  ? "#fff"
                  : "#333",

            }}
            onClick={() => document.getElementById(`${camelCaseKey}_imageInput`).click()}
          >
            Choose Image
          </button>
          <input
            type="file"
            accept="image/*"
            id={`${camelCaseKey}_imageInput`}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              opacity: 0,
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
            onChange={(e) => {
              handleUploadImage(e, camelCaseKey);
              setApplicationDetails({
                ...applicationDetails,
                [`${camelCaseKey}verified`]: false, // Set verified status to false on image upload
              });
              setFieldErrors((prevErrors) => ({
                ...prevErrors,
                [camelCaseKey]: {
                  ...prevErrors[camelCaseKey],
                  errors: ["Please verify the image first"],
                  touched: true,
                },
              }));
              fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, fieldValue);
            }}
            onBlur={(e) => checkErrorFn(input, camelCaseKey, fieldValue)}
          />
        </div>

        {/* Error Display */}
        {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched &&
          fieldErrors?.[camelCaseKey]?.errors?.map((err, idx) => (
            <span key={idx} className="errorPresent" style={style.errorSpan}>
              {err}
              <br />
            </span>
          ))
        }

        {/* Image Preview and Verification Button */}
        {fieldValue && (
          <>
            <img
              key={label}
              src={fieldValue}
              alt={label}
              style={{
                border: "1px solid black",
                height: "200px",
                width: "200px",
              }}
            />
            {!applicationDetails[`${camelCaseKey}verified`] && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  className="ms-2 verifybutton verifyBtn"
                  onClick={() => SetImageModalOpen(camelCaseKey)}
                  style={{ marginLeft: 'auto' }}
                >
                  Verify
                </Button>
              </div>
            )}
          </>
        )}

        {/* Image Modal for Verification */}
        {OpenImageModal && (
          <Modal
            size="sm"
            show={OpenImageModal === camelCaseKey}
            onHide={() => SetImageModalOpen(false)}
            centered
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Body className="text-center">
              <img
                src={fieldValue}
                key={label}
                alt={label}
                style={{
                  height: "200px",
                  width: "200px",
                  objectFit: "contain",
                }}
              />
              <Button
                className="mt-2"
                style={{ borderRadius: "10px" }}
                onClick={() => {
                  setApplicationDetails({
                    ...applicationDetails,
                    [`${camelCaseKey}verified`]: true,
                  });
                  SetImageModalOpen(false);
                  setFieldErrors((prevErrors) => ({
                    ...prevErrors,
                    [camelCaseKey]: {
                      ...prevErrors[camelCaseKey],
                      errors: prevErrors[camelCaseKey]?.errors?.filter(
                        (err) => err !== "Please verify the image first"
                      ),
                    },
                  }));
                }}
              >
                Verified
              </Button>
            </Modal.Body>
          </Modal>
        )}
      </div>
    );





    const renderPdfInput = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage,

    ) => (
      <div className="d-flex flex-column gap-4 renderPdf docDiv">
        <div className="d-flex align-items-center gap-3">
          <div style={{ position: "relative", display: "inline-block" }}>
            <button
              style={{
                borderRadius: "10px",
                height: "40px",
                padding: "5px 15px",
                fontSize: "12px",
                cursor: "pointer",
                margin: '15px 0',
                border: "none",
                backgroundColor:
                  fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                    ? '#921d1e' :
                    "#f0f0f0", // Error background color

                // border:
                //   fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                //     ? "1px solid red"
                //     : "1px solid #ccc", // Error border    
                color:
                  fieldErrors?.[camelCaseKey]?.touched && fieldErrors?.[camelCaseKey]?.errors?.length > 0
                    ? "#fff"
                    : "#333", // Error background color
              }}
              onClick={() => document.getElementById(`${camelCaseKey}_fileInput`).click()}
            >
              Choose File
            </button>
            <input
              type="file"
              accept="application/pdf"
              id={`${camelCaseKey}_fileInput`}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0,
                width: "100%",
                height: "100%",
                cursor: "pointer",
              }}
              onChange={(e) => {
                handleUploadImage(e, camelCaseKey);
                setApplicationDetails({
                  ...applicationDetails,
                  [`${camelCaseKey}verified`]: false,
                });
                setFieldErrors((prevErrors) => ({
                  ...prevErrors,
                  [camelCaseKey]: {
                    ...prevErrors[camelCaseKey],
                    errors: ["Please verify the PDF first"],
                    touched: true,
                  },
                }));
                fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, fieldValue);
              }}
              onBlur={(e) => checkErrorFn(input, camelCaseKey, fieldValue)}
            />
          </div>


          {fieldValue && (
            <>
              <img
                src={pdf}
                alt="PDF preview"
                style={{ marginLeft: "10px", width: "100px", cursor: "pointer" }}
                onClick={() => {
                  setShowIframe(camelCaseKey);
                }}
              />
              {!applicationDetails[`${camelCaseKey}verified`] && (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    className="ms-2 verifybutton"
                    onClick={() => setShowIframe(camelCaseKey)}
                  >
                    Verify
                  </Button>
                </div>
              )}
            </>
          )}

          {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
            fieldErrors?.[camelCaseKey]?.errors?.map((err, idx) => (
              <span key={idx} className="errorPresent" style={style.errorSpan}>
                {err}
                <br />
              </span>
            ))
          )}

          {showIframe && (
            <Modal
              size="lg"
              show={showIframe === camelCaseKey}
              onHide={() => setShowIframe(false)}
              centered
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Body className="text-center">
                <iframe
                  src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(
                    fieldValue
                  )}`}
                  width="100%"
                  style={{ border: "none", height: "80vh" }}
                  title="PDF Viewer"
                />
                <Button
                  className="mt-2"
                  style={{ borderRadius: "10px" }}
                  onClick={() => {
                    setApplicationDetails({
                      ...applicationDetails,
                      [`${camelCaseKey}verified`]: true,
                    });
                    setShowIframe(false);
                    setFieldErrors((prevErrors) => ({
                      ...prevErrors,
                      [camelCaseKey]: {
                        ...prevErrors[camelCaseKey],
                        errors: prevErrors[camelCaseKey]?.errors?.filter(
                          (err) => err !== "Please verify the PDF first"
                        ),
                      },
                    }));
                  }}
                >
                  Verified
                </Button>
              </Modal.Body>
            </Modal>
          )}
        </div>
      </div>
    );


    // Function to render textarea input
    const renderTextareaInput = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage
    ) => {
      switch (label) {
        default:
          return (
            <Form.Control
              as="textarea"
              // style={{ border: "1px solid black", borderRadius: "10px", height: "100px" }}
              className=" "
              placeholder={label}
              value={fieldValue}
              onChange={(e) =>
                setApplicationDetails({
                  ...applicationDetails,
                  [camelCaseKey]: e.target.value,
                })
              }
              required={input.values.isRequired}
            />
          );
          break;
      }
    };

    const handelApplicationDetailsChange = (data) => {
      setApplicationDetails({
        ...applicationDetails,
        ...data,
      });
    };

    const renderTextInput = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage
    ) => {
      switch (label) {
        case "Percentage of Mark":
          return (
            <input
              className="form-control text-dark"
              style={style.input}
              type={input.values.inputFieldType}
              // placeholder={label}

              defaultValue={"test"}
              value={fieldValue || ""}
              // onChange={(e) =>

              //   setApplicationDetails({
              //     ...applicationDetails,
              //     [camelCaseKey]: `${applicationDetails?.vacancyId?.grade?.designationPrefix} (${applicationDetails?.vacancyId?.department?.name}), Grade - ${applicationDetails?.vacancyId?.grade?.gradeName}`,
              //   })
              // }
              required={input.values.isRequired}

            />

          )


        case "Application ID":
          return (
            <>
              <input
                className="form-control text-dark"
                style={style.input}
                type={input.values.inputFieldType}
                // placeholder={label}
                value={fieldValue || applicationID}
                onChange={(e) =>
                  setApplicationDetails({
                    ...applicationDetails,
                    [camelCaseKey]: applicationID,
                  })
                }

                required={input.values.isRequired}
                max={new Date().toISOString().split('T')[0]}

              />

            </>
          );
          break;

        case "Advertisement Details":
          return (
            <input
              className="form-control text-dark"
              style={style.input}
              type={input.values.inputFieldType}
              value={
                fieldValue ||
                `${applicationDetails?.advertisement?.advertisementHeading} (No. ${applicationDetails?.advertisement?.advertisementNumber})`
              }
              onChange={(e) =>
                setApplicationDetails({
                  ...applicationDetails,
                  [camelCaseKey]: `${applicationDetails?.advertisement?.advertisementHeading} (No. ${applicationDetails?.advertisement?.advertisementNumber})`,
                })
              }
              required={input.values.isRequired}
              max={
                input.values.inputFieldType === "date"
                  ? new Date().toISOString().split("T")[0]
                  : undefined
              }
            />
          );

          break;

        case "Post Name":
          return (
            <input
              className="form-control text-dark"
              style={style.input}
              type={input.values.inputFieldType}
              // placeholder={label}
              value={
                fieldValue ||
                `${applicationDetails?.vacancyId?.grade?.designationPrefix} (${applicationDetails?.vacancyId?.department?.name}), Grade - ${applicationDetails?.vacancyId?.grade?.gradeName}`
              }
              onChange={(e) =>
                setApplicationDetails({
                  ...applicationDetails,
                  [camelCaseKey]: `${applicationDetails?.vacancyId?.grade?.designationPrefix} (${applicationDetails?.vacancyId?.department?.name}), Grade - ${applicationDetails?.vacancyId?.grade?.gradeName}`,
                })
              }
              required={input.values.isRequired}
              max={
                input.values.inputFieldType === "date"
                  ? new Date().toISOString().split("T")[0]
                  : undefined
              }
            />
          );
          break;

        case "Age":
          return (
            <input
              className="form-control text-dark"
              style={style.input}
              type={input.values.inputFieldType}
              // placeholder={label}
              value={`${applicationDetails?.age}`}
              // onChange={(e) =>
              //   setApplicationDetails({
              //     ...applicationDetails,
              //     [camelCaseKey]: `${applicationDetails?.vacancyId?.grade?.designationPrefix} (${applicationDetails?.vacancyId?.department?.name}), Grade - ${applicationDetails?.vacancyId?.grade?.gradeName}`,
              //   })
              // }
              required={input.values.isRequired}
              max={
                input.values.inputFieldType === "date"
                  ? new Date().toISOString().split("T")[0]
                  : undefined
              }
            />
          );
          break;
        case "Date Of Birth":
          return (
            <>
              <input
                className="form-control text-dark"
                style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                type={input.values.inputFieldType}
                // placeholder={label}
                value={fieldValue}
                onChange={(e) => {
                  setApplicationDetails({
                    ...applicationDetails,
                    [camelCaseKey]: e.target.value,
                    ["age"]: Math.floor(
                      (new Date(
                        applicationDetails?.advertisement?.advertisementClosingDate
                      ) -
                        new Date(
                          e?.target?.value?.split("/").reverse().join("-")
                        )) /
                      (365.25 * 24 * 60 * 60 * 1000)
                    ),
                  })
                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, fieldValue)

                }
                }
                onBlur={(e) => {
                  checkErrorFn(input, camelCaseKey, fieldValue)
                }}
                required={input.values.isRequired}
                max={new Date().toISOString().split('T')[0]}

              />
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                ))
              )}
            </>
          );
          break;
        case "Applicant's Full Name":
        case "Mobile Number":
        case "Email":
          return (
            <input
              className="form-control text-dark"
              style={style.input}
              type={input.values.inputFieldType}
              // placeholder={label}
              value={applicationDetails?.[`${camelCase(label)}`]}
              required={input.values.isRequired}
              onChange={() => { }}
            />
          );
          break;

        default:
          return (
            <>
              <input
                className="form-control text-dark"
                style={fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched ? { ...style.input, ...style.errorBox } : style.input}
                type={input.values.inputFieldType}
                // placeholder={label}
                value={fieldValue}
                onChange={(e) => {
                  setApplicationDetails({
                    ...applicationDetails,
                    [camelCaseKey]: e.target.value,
                  })
                  fieldErrors?.[camelCaseKey]?.touched && checkErrorFn(input, camelCaseKey, fieldValue)
                }}
                onBlur={(e) => {
                  checkErrorFn(input, camelCaseKey, fieldValue)
                }}
                required={input.values.isRequired}
                max={new Date().toISOString().split('T')[0]}

              />
              {fieldErrors?.[camelCaseKey]?.errors?.length > 0 && fieldErrors?.[camelCaseKey]?.touched && (
                fieldErrors?.[camelCaseKey]?.errors?.map((err) => (
                  <span className="errorPresent" style={style.errorSpan}>{err}<br /></span>
                ))
              )}
            </>

          );
          break;
      }
    };


    const renderInput = (
      fieldValue,
      camelCaseKey,
      label,
      input,
      applicationDetails,
      setApplicationDetails,
      checkedValues,
      handleCheckboxChange,
      handleUploadImage
    ) => {
      switch (input.values.inputFieldType) {
        // case "date":
        //   return renderDateInput(
        //     fieldValue,
        //     camelCaseKey,
        //     label,
        //     input,
        //     applicationDetails,
        //     setApplicationDetails,
        //     checkedValues,
        //     handleCheckboxChange,
        //     handleUploadImage
        //   );
        case "select":
          return renderSelectInput(
            fieldValue,
            camelCaseKey,
            label,
            input,
            applicationDetails,
            setApplicationDetails,
            checkedValues,
            handleCheckboxChange,
            handleUploadImage
          );
        case "radio":
          return renderRadioInput(
            fieldValue,
            camelCaseKey,
            label,
            input,
            applicationDetails,
            setApplicationDetails,
            checkedValues,
            handleCheckboxChange,
            handleUploadImage
          );
        case "note":
          return renderNote(
            fieldValue,
            camelCaseKey,
            label,
            input,
            applicationDetails,
            setApplicationDetails,
            checkedValues,
            handleCheckboxChange,
            handleUploadImage
          );
        case "checkbox":
          return renderCheckboxInput(
            fieldValue,
            camelCaseKey,
            label,
            input,
            applicationDetails,
            setApplicationDetails,
            checkedValues,
            handleCheckboxChange,
            handleUploadImage
          );
        case "image":
          return renderImageInput(
            fieldValue,
            camelCaseKey,
            label,
            input,
            applicationDetails,
            setApplicationDetails,
            checkedValues,
            handleCheckboxChange,
            handleUploadImage
          );
        case "pdf":
          return renderPdfInput(
            fieldValue,
            camelCaseKey,
            label,
            input,
            applicationDetails,
            setApplicationDetails,
            checkedValues,
            handleCheckboxChange,
            handleUploadImage
          );
        case "textarea":
          return renderTextareaInput(
            fieldValue,
            camelCaseKey,
            label,
            input,
            applicationDetails,
            setApplicationDetails,
            checkedValues,
            handleCheckboxChange,
            handleUploadImage
          );
        case "DomicileOfOdisha":
          return (
            <DomicileOfOdisha
              handelApplicationDetailsChange={(d) =>
                handelApplicationDetailsChange(d)
              }
              applicationDetails={applicationDetails}
              style={style}
            />
          );
        case "DepartmentalCandidate":
          return (
            <DepartmentalCandidate
              handelApplicationDetailsChange={(d) =>
                handelApplicationDetailsChange(d)
              }
              applicationDetails={applicationDetails}
              style={style}
            />
          );
        case "AreYouASportsMan":
          return (
            <AreYouASportsMan
              handelApplicationDetailsChange={(d) =>
                handelApplicationDetailsChange(d)
              }
              applicationDetails={applicationDetails}
              style={style}
            />
          );
        case "ReservationCategory":
          return (
            <ReservationCategory
              handelApplicationDetailsChange={(d) =>
                handelApplicationDetailsChange(d)
              }
              applicationDetails={applicationDetails}
              style={style}
            />
          );
        case "ExServicemen":
          return (
            <ExServicemen
              handelApplicationDetailsChange={(d) =>
                handelApplicationDetailsChange(d)
              }
              applicationDetails={applicationDetails}
              style={style}
            />
          );
          case "PersonWithDisability":
            return (
              <PersonWithDisability
                handelApplicationDetailsChange={(d) =>
                  handelApplicationDetailsChange(d)
                }
                allowedPwbdType={state?.post?.posting?.allowedPwbdType}
                applicationDetails={applicationDetails}
                style={style}
              />
            );
          case "Experience-Static":
            return (
              <ExperienceInputs
                checkErrorFn={checkErrorFn}
                setApplicationDetails={setApplicationDetails}
                applicationDetails={applicationDetails}
                style={style}
                postandvacancyDetails={state.post?.posting}
                applicationID={applicationID}
              />
            );
        default:
          return renderTextInput(
            fieldValue,
            camelCaseKey,
            label,
            input,
            applicationDetails,
            setApplicationDetails,
            checkedValues,
            handleCheckboxChange,
            handleUploadImage
          );
      }
    };


    const renderGroupedInputs = (inputs) => {
      let groupedElements = [];
      let currentSubheading = null;
      let currentGroup = [];

      inputs.forEach((input, idx) => {
        const camelCaseKey = camelCase(input.values._id);
        const label = input.values.inputFieldName;
        const fieldValue = applicationDetails ? applicationDetails[camelCaseKey] : "";

        if (input.values.inputFieldType === "SubHeading") {
          // Push the current group to groupedElements if there's any
          if (currentGroup.length > 0) {
            groupedElements.push(
              <Card key={currentSubheading} className="mb-2 dynamicFormcards">
                <Col key={idx} xs={input.values.columnLength} className="">
                  <Row>{currentGroup}</Row>
                </Col>
              </Card>
            );
            currentGroup = [];
          }
          currentSubheading = idx;
          currentGroup.push(
            <label className="form-label form-heading-main">
              {label === " Document Upload"
                ? <>
                  {label}
                  <span style={{
                    color: 'rgba(255, 0, 0, 1)',
                    margin: "10px",
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    fontSize: '11px',
                    lineHeight: '10.55px'

                  }} className="Documentdploadvalidation">
                    (Only JPG and PDF files are accepted, with a maximum file size of 1 MB.)
                  </span>
                </>

                :
                label === "Permanent Address" ? <>
                  {label}
                  <div className="form-check m-0 p-0" key={`pA_checkbox`}>
                    <input id="pA_checkbox" type={"checkbox"} checked={isSameAsCorrespondence}
                      onChange={handleCheckboxChange_}
                    />
                    <label
                      htmlFor={"pA_checkbox"}
                      style={{ ...style.label, marginLeft: "5px", }}
                    >
                      Same as correspondence address
                    </label>
                  </div>
                </>
                  :
                  label}
            </label>



            //  </Col>
          );
        } else {
          currentGroup.push(
            <Col key={idx} xs={input.values.columnLength} className="mb-2">
              <>
                <label
                  className="inputBoxHeadForm upload-head-gap mt-2"
                  style={style.label}
                >
                  {label}
                  {input.values.isRequired && label && (
                    <span className="mx-1 text-danger star">*</span>
                  )}
                </label>
                {renderInput(
                  fieldValue,
                  camelCaseKey,
                  label,
                  input,
                  applicationDetails,
                  setApplicationDetails,
                  checkedValues,
                  handleCheckboxChange,
                  handleUploadImage
                )}


              </>
            </Col>
          );
        }
      });
      if (currentGroup.length > 0) {
        groupedElements.push(
          <Card key={currentSubheading} className="mb-2 dynamicFormcards">
            <Row>{currentGroup}</Row>
          </Card>
        );
      }

      return groupedElements;
    };

    return <Row className="ml-1">{renderGroupedInputs(item.values)}</Row>;
  };

  const toggleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleTabClick = () => {
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    if (!isLoading && !isLoading2 && !applicationDetails) {
      // handleSubmitForm()
    }
  }, [isLoading, isLoading2]);


  const [tablesRendered, setRendered] = useState(false)
  const [tablesRenderedquali, setTablesRenderedquali] = useState(false)

  const renderTableOfIsArray = (item) => {
    if (!tablesRendered) {
      function getUniqueTabNamesAsync(data) {
        const tabNames = new Set();
        for (let i = 0; i < data.length; i++) {
          if (data[i].tab) tabNames.add(data[i].tab);
        }
        return Array.from(tabNames);
      }

      const RenderTableForFieldsWithTab = (data, targetTab) => {
        const matchingItems = data.filter(
          (item) =>
            Array.isArray(item.fields) &&
            item.fields.some((field) => field.tab === targetTab)
        );

        if (matchingItems.length === 0) return <div></div>;

        // Filter headers and ensure only matching fields are displayed
        const filteredHeaders = matchingItems[0].fields
          .filter(
            (field) =>
              field.tab === targetTab &&
              field.values.inputFieldType !== "SubHeading" &&
              !['Certificates', 'Mark sheets', 'Letter Of Appointment', 'Release Letter'].includes(field.values.inputFieldName)
          )
          .map((field) => field.values.inputFieldName);

        const heading = matchingItems[0].fields
          .filter(
            (field) =>
              field.tab === targetTab &&
              field.values.inputFieldType === "SubHeading"
          )
          .map((field) => field.values.inputFieldName);

        return (
          <Card className="mt-2 mb-0 dynamicFormcards ms-3 me-3" style={{ width: '97%' }}>
            <Row className="mt-3">
              <label className="form-label form-heading-main">{heading[0]}</label>
              <Table
                className="ms-2 me-2"
                bordered
                style={{ border: '1px solid black', overflow: 'hidden', width: '98%', tableLayout: 'fixed' }}
              >
                <thead>
                  <tr>
                    {filteredHeaders.map((header, index) => (
                      <th
                        key={index}
                        style={{
                          border: '1px solid black',
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          width: `${98 / filteredHeaders.length}%`,
                        }}
                      >
                        <label className="form-label form-heading-main m-0 p-0" style={style.label}>
                          {header}
                        </label>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {matchingItems.map((item, rowIndex) => {
                    const filteredFields = item.fields.filter(
                      (field) =>
                        field.tab === targetTab &&
                        field.values.inputFieldType !== "SubHeading" &&
                        filteredHeaders.includes(field.values.inputFieldName)
                    );

                    return filteredFields.length > 0 ? (
                      <tr key={rowIndex}>
                        {filteredFields.map((field, colIndex) => {
                          const isLink = /^(http|https):\/\/[^ "]+$/.test(field.value);
                          return !isLink ? (
                            <td
                              key={colIndex}
                              style={{
                                border: '1px solid black',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                width: `${98 / filteredHeaders.length}%`,
                              }}
                            >
                              <span style={{ fontSize: '13px', fontWeight: '500' }}>
                                {field.value || 'N/A'}
                              </span>
                            </td>
                          ) : null;
                        })}
                      </tr>
                    ) : null;
                  })}
                </tbody>
              </Table>
            </Row>
          </Card>
        );
      };

      const tabs = getUniqueTabNamesAsync(dynamicFields);
      return tabs.map(
        (tab) => tab === camelCase(item?.formTab) && RenderTableForFieldsWithTab(dynamicFields, tab)
      );
    }
  };
  

  const renderTableForNonArrayData = (data) => {

    console.log("applicationDetails", applicationDetails);
    console.log("renderTableForNonArrayData 1st", data);

    const subHeadings = data?.filter(item => item?.values?.inputFieldType === 'SubHeading');

    if (!subHeadings?.length) {
      return <div>No data available</div>;
    }

    const getFieldsBetweenSubHeadings = (startIndex, data) => {
      let fields = [];
      for (let i = startIndex + 1; i < data?.length; i++) {
        if (data[i]?.values?.inputFieldType === 'SubHeading') break;
        fields.push(data[i]);
      }
      return fields;
    };

    return subHeadings?.map((subHeading, index) => {
      const fields = getFieldsBetweenSubHeadings(data.indexOf(subHeading), data);

      return (
        <Card className="mt-2 mb-0 dynamicFormcards ms-0 me-0 ps-0 pe-0" style={{ width: '100%' }} key={index}>
          <Row className="mt-3 ms-0 me-0">
            <label className="form-label form-heading-main">{subHeading?.values?.inputFieldName}</label>

            {/* Render notes that come before the second subheading */}
            {fields.map((field, fieldIndex) => {
              if (field?.values?.inputFieldType === 'note') {
                return (
                  <div key={fieldIndex} className="note-field" style={{ margin: '10px 0', fontSize: '13px', fontWeight: '500' }}>
                    {applicationDetails[field?.values?._id] || ''}
                  </div>
                );
              }
              return null;
            })}

            {/* Render table for non-note fields */}
            {/* <Table className="ms-2 me-2" bordered style={{ border: '1px solid black', overflow: 'hidden', width: '98%', tableLayout: 'fixed' }}>
              <thead className="p-0 m-0">
                <tr className="p-0 m-0">
                  {fields?.map((field, headerIndex) => (
                    field?.values?.inputFieldType !== 'note' && (
                      <th
                        key={headerIndex}
                        style={{ border: '1px solid black', whiteSpace: 'normal', wordWrap: 'break-word', width: `${98 / fields.length}%` }}
                      >
                        <label className="form-label form-heading-main m-0 p-0" style={style.label}>
                          {field?.values?.inputFieldName}
                        </label>
                      </th>
                    )
                  ))}
                </tr>
              </thead>
              <tbody className="p-0 m-0">
                <tr className="p-0 m-0">
                  {fields?.map((field, fieldIndex) => (
                    field?.values?.inputFieldType !== 'note' && (
                      <td
                        className="p-0 m-2"
                        key={fieldIndex}
                        style={{ border: '1px solid black', whiteSpace: 'normal', wordWrap: 'break-word', width: `${98 / fields.length}%` }}
                      >
                        <span style={{ fontSize: '13px', fontWeight: '500', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                          {applicationDetails[field?.values?._id] || 'N/A'}
                        </span>
                      </td>
                    )
                  ))}
                </tr>
              </tbody>
            </Table> */}

            {/* Table with Removed unwanted cells for essential qualification */}

            <Table
              className="ms-2 me-2"
              bordered
              style={{ border: '1px solid black', overflow: 'hidden', width: '98%', tableLayout: 'fixed' }}
            >
              <thead className="p-0 m-0">
                <tr className="p-0 m-0">
                  {fields?.map((field, headerIndex) => {
                    const headerName = field?.values?.inputFieldName || '';

                    // Skip headers that contain 'Certificates' or 'Marksheets'
                    if (headerName.includes('Certificates') || headerName.includes('Marksheets') || headerName.includes('Mark Sheets') || headerName.includes('Attach the Conversion formula')) {
                      return null;
                    }

                    return (
                      field?.values?.inputFieldType !== 'note' && (
                        <th
                          key={headerIndex}
                          style={{
                            border: '1px solid black',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            width: `${98 / fields.length}%`
                          }}
                        >
                          <label
                            className="form-label form-heading-main m-0 p-0"
                            style={style.label}
                          >
                            {console.log(headerName, "Filtered Header")}
                            {headerName}
                          </label>
                        </th>
                      )
                    );
                  })}
                </tr>
              </thead>

              <tbody className="p-0 m-0">
                <tr className="p-0 m-0">
                  {fields?.map((field, fieldIndex) => {
                    const headerName = field?.values?.inputFieldName || '';
                    const fieldData = applicationDetails[field?.values?._id] || 'N/A';
                    const sanitizedData = fieldData.replace(/https?:\/\/[^\s]+/g, '');

                    // Skip rows that correspond to headers with 'Certificates' or 'Marksheets'
                    if (headerName.includes('Certificates') || headerName.includes('Marksheets') || headerName.includes('Mark Sheets') || headerName.includes('Attach the Conversion formula')) {
                      return null;
                    }

                    return (
                      field?.values?.inputFieldType !== 'note' && (
                        <td
                          className="p-2 m-2"
                          key={fieldIndex}
                          style={{
                            border: '1px solid black',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            width: `${98 / fields.length}%`
                          }}
                        >
                          <span
                            style={{
                              fontSize: '13px',
                              fontWeight: '500',
                              whiteSpace: 'normal',
                              wordWrap: 'break-word'
                            }}
                          >
                            {console.log(sanitizedData, "Filtered Data")}
                            {sanitizedData}
                          </span>
                        </td>
                      )
                    );
                  })}
                </tr>
              </tbody>
            </Table>

          </Row>
        </Card>
      );
    });
  };




  useEffect(() => {
    document.querySelectorAll('input[type="number"]').forEach(function (input) {
      input.addEventListener('wheel', function (event) {
        event.preventDefault();
      });
    });
    if (tabIndex && tabIndex === applicationForm.length) {
      setTimeout(() => {

        const today = new Date().toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        });
        // Disable interactions for inputs and selects
        // const inputs = document.querySelectorAll("input, select");
        // const check = document.querySelectorAll("input, select");
        // if (!check) {
        //   inputs.forEach((input) => {
        //     if (input.type !== "checkbox") { // Skip checkboxes
        //       input.style.pointerEvents = "none";
        //       input.setAttribute("type", "text");
        //       input.style.marginTop = "0px";
        //       input.style.padding = "0px";
        //       input.style.appearance = "none";
        //       input.style.fontWeight = "600";
        //       input.style.border = "0";
        //       input.style["-webkit-appearance"] = "none";
        //       input.style["-moz-appearance"] = "none";
        //       input.style["appearance"] = "none";
        //       input.style["-webkit-calendar-picker-indicator"] = "none";
        //     }
        //   });
        // }
        

        // check.forEach((input) => {
        //   input.style.pointerEvents = "auto";  // Ensure pointer events are active for all inputs and selects
        //   // input.setAttribute('type', 'text');
        //   // input.style.marginTop = "0px";
        //   // input.style.paddingTop = "0px";
        //   // input.style.paddingBottom = "0px";
        //   // input.style.paddingLeft = "0px";
        //   // input.style.paddingRight = "0px";
        //   // input.style.height = "20px";
        //   input.style.appearance = "auto";
        //   input.style.fontWeight = "600";
        //   // input.style.border = "0";
        //   input.style["-webkit-appearance"] = "none";
        //   input.style["-moz-appearance"] = "none";
        //   input.style["appearance"] = "none";
        //   input.style["-webkit-calendar-picker-indicator"] = "none";
        // });

        // Target and hide elements where the label contains "Document Upload"
        const labels = document.querySelectorAll("label.form-label.form-heading-main");
        labels.forEach((label) => {
          if (label.textContent.includes("Document Upload")) {
            const parentDiv = label.closest("div.row"); // Get the closest parent div
            if (parentDiv) {
              parentDiv.style.display = "none"; // Hide the parent div
            }
          }
        });

        const uploadDocument = document.querySelectorAll('label.form-label');
        uploadDocument.forEach((label) => {
          if (label.textContent.includes("Upload Resident Certificate ")) {
            const parentDiv = label.closest("div"); // Get the closest parent div
            if (parentDiv) {
              parentDiv.style.display = "none"; // Hide the parent div
            }
          }
          if (label.textContent.includes("Upload Sports Certificate")) {
            const parentDiv = label.closest("div"); // Get the closest parent div
            if (parentDiv) {
              parentDiv.style.display = "none"; // Hide the parent div
            }
          }
          if (label.textContent.includes("Upload Certificate")) {
            const parentDiv = label.closest("div"); // Get the closest parent div
            if (parentDiv) {
              parentDiv.style.display = "none"; // Hide the parent div
            }
          }
        })


        // Select all <a> tags in the document
        var uploadLinks = document.querySelectorAll('a');

        // Loop through each <a> tag and remove the parent div with class 'col-md-2 col-12'
        uploadLinks.forEach(function (upload) {
          var parentDiv = upload.closest('.col-md-2.col-12');
          if (parentDiv) {
            parentDiv.remove();
          }
        });

        var imageTag = document.querySelectorAll('img');

        // Loop through each <a> tag and remove the parent div with class 'col-md-2 col-12'
        imageTag.forEach(function (upload) {
          var parentDiv = upload.closest('.col-md-1.col-12');
          if (parentDiv) {
            parentDiv.remove();
          }
        });

        var svgTag = document.querySelectorAll('svg');

        // Loop through each <a> tag and remove the parent div with class 'col-md-2 col-12'
        svgTag.forEach(function (upload) {
          var parentDiv = upload.closest('.col-md-2.col-12');
          if (parentDiv) {
            parentDiv.remove();
          }
        });



        const correspondAddressLabels = document.querySelectorAll('label');
        correspondAddressLabels.forEach((label) => {
          if (label.innerText.trim() === 'Same as correspondence address') {
            const parentDiv = label.parentElement;
            if (parentDiv) {
              parentDiv.style.display = 'none';
            }
          }
        });

        document.querySelectorAll("label").forEach((label) => {
          if (
            label.textContent.includes(
              "I confirm that I fulfill the physical requirement standards for the post as laid down in the advertisement. In case of any discrepency, my candidature is laible to be cancelled."
            )
          ) {
            label.style.display = "none";
          }
        });

        document.querySelectorAll("label").forEach((label) => {
          if (
            label.textContent.includes(
              "I do hereby declare that I shall submit date-wise details of my application (s) for various vacancy (ies), for which I have applied for, at the time of Document Verification, if shortlisted."
            )
          ) {
            label.style.display = "none";
          }
        });
        // const uploadResidentCertificate = document.querySelectorAll('label');
        // uploadResidentCertificate.forEach((label) => {
        //   if (label.innerText.trim() === 'Upload Resident Certificate ') {
        //     const parentDiv = label.parentElement;
        //     if (parentDiv) {
        //       console.log({parentDiv})
        //       parentDiv.style.display = 'none';
        //     }
        //   }
        // });

        // Find the label element by matching its text content



        // Hide or remove the asterisk
        const asterisks = document.querySelectorAll('span.text-danger');
        asterisks.forEach((asterisk) => {
          asterisk.style.display = "none"; // Hide the asterisk
          // asterisk.remove(); // Alternatively, you can use this to completely remove it from the DOM
        });


        const labelsToHide = ["Application ID", "Advertisement Details", "Post Name"];

        labelsToHide.forEach(labelText => {
          // Find all label elements
          const labels = document.querySelectorAll("label");

          labels.forEach(label => {
            if (label.textContent.trim().includes(labelText)) {
              // Hide the label
              label.style.display = "none";

              // Get the closest parent div containing the input
              const parentDiv = label.closest('div');
              if (parentDiv) {
                const input = parentDiv.querySelector('input');
                if (input) {
                  input.value = ""; // Clear the input value
                  input.style.display = "none"; // Hide the input
                }
              }
            }
          });
        });


        // Get the specific div by ID to modify its content
        const specificDiv = document.getElementById("printDiv_1");

        if (specificDiv) {

          specificDiv.style.position = "relative"; // Ensure relative positioning for the parent

          // Add a header with an image
          const topSection = document.createElement("div");
          topSection.style.display = "flex";
          topSection.style.flexDirection = "row-reverse"
          topSection.style.gap = "20px"
          topSection.style.alignItems = "center";
          // topSection.style.justifyContent = ""; // Ensure the elements are side by side
          topSection.style.backgroundColor = "white";
          // topSection.style.padding = "10px";
          topSection.style.marginLeft = '20px'
          topSection.style.marginRight = '20px'

          topSection.style.height = "127px";
          topSection.style.marginBottom = "30px"; // Space below the top section

          // Create a new image element
          const newImage = document.createElement("img");
          newImage.src = "https://dg1ya6cdc7ief.cloudfront.net/Upload/Logo_Files/e4b1a69efc184de78ec6131fbbe4a42f_20221213133117014.png"; // Your image URL
          newImage.alt = "omc_logo";
          newImage.style.height = "40px";
          newImage.style.width = "120px";
          newImage.style.margin = "0 10px"; // Add some space between the images
          newImage.style.display = "block"; // Ensure it is treated as a block-level element

          // Target the specific image with alt text
          const specificImage = document.querySelector('img[alt="Upload Scanned copy of your passport size photo"]');
          if (specificImage) {
            specificImage.style.display = "block";
            specificImage.style.width = "180px"; // Adjust the height if needed
            specificImage.style.height = "180px"; // Adjust the height if needed
            specificImage.style.position = "absolute"; // Float it to the top left
            specificImage.style.top = "25px"; // Adjust the top offset
            specificImage.style.right = "25px"; // Adjust the left offset
            specificImage.style.zIndex = "10"; // Ensure it's above other content
            specificImage.style.margin = "0"; // Remove any margin

          } else {
            console.log("Image with the specified alt text not found.");
          }

          // Create a div to insert between the two images
          const textDiv = document.createElement("div");
          textDiv.classList.add("text-div"); // Add a class for easier styling
          textDiv.style.flex = "1"; // Allow it to expand between the images
          textDiv.style.textAlign = "center"; // Center the text inside the div
          textDiv.style.fontFamily = "'Roboto', sans-serif";
          textDiv.style.fontSize = "20px"; // Font size


          // Add multiple lines of text inside the textDiv
          textDiv.innerHTML = `
           <div class="d-flex flex-column justify-content-center text-center w-50 ms-5">
    <h5 class="mt-0 fw-bold text-decoration-underline fontRoboto">Application Form</h5>
  </div>
           `;





          // Append the new image to the top section
          topSection.appendChild(specificImage);
          topSection.appendChild(textDiv);       // Add the div with text between the images
          topSection.appendChild(newImage);      // Add new image to top section


          // Append the top section div at the beginning of the specificDiv
          specificDiv.insertBefore(topSection, specificDiv.firstChild);

          // Create a new section to hold the additional text information
          const infoSection = document.createElement("div");
          infoSection.style.margin = "20px 0"; // Add some margin to separate it from other sections
          infoSection.style.padding = "10px";
          infoSection.style.backgroundColor = "#fff"; // Optional: Background color for distinction
          infoSection.style.borderBottom = "1px solid #ddd";   // Optional: Border for clarity
          infoSection.style.marginLeft = '20px';
          infoSection.style.marginRight = '20px';
          infoSection.style.fontFamily = "'Roboto', sans-serif";


          // Add your three pieces of text information
          infoSection.innerHTML = `
          <div class='d-flex justify-content-between w-75 pb-3'>
          
          <p class='fw-bold fontRoboto'><strong>Apply Date</strong><br/> ${today}</p>
          <p class='fw-bold fontRoboto'><strong>Application No.</strong><br/> ${applicationID}</p>
          <p class='fw-bold fontRoboto'><strong>Post</strong><br/> ${applicationDetails?.vacancyId?.grade?.designationPrefix} (${applicationDetails?.vacancyId?.department?.name}), Grade - ${applicationDetails?.vacancyId?.grade?.gradeName}</p>
          <p class='pb-2 fw-bold fontRoboto'> <strong>Advertisement No.</strong><br/> ${applicationDetails?.advertisement?.advertisementNumber}</p>
          
          <div/>

        `;

          // Insert the new info section below the topSection
          specificDiv.insertBefore(infoSection, specificDiv.firstChild.nextSibling);

          // Create the footer section
          const bottomSection = document.createElement("div");
          bottomSection.style.textAlign = "center";
          bottomSection.style.backgroundColor = "rgba(109, 110, 113, 1)";
          bottomSection.style.padding = "10px";
          bottomSection.style.marginTop = "30px";

          // Footer text
          const footerText = document.createElement("span");
          footerText.textContent = "© 2024 Odisha Mining Corporation Ltd. (O M C LTD)";
          footerText.style.fontSize = "14px";
          footerText.style.color = '#fff';
          footerText.style.fontFamily = "'Roboto', sans-serif";
          footerText.style.padding = "10px";

          // Append the footer text to the bottomSection div
          // bottomSection.appendChild(footerText);

          // Append the bottom section div to the specificDiv
          // specificDiv.appendChild(bottomSection);

          // Apply styles to cards, headings, and other elements
          const cards = specificDiv.querySelectorAll(".card");
          cards.forEach((card) => {
            card.style.opacity = "1";
            card.style.border = "none";
            card.style["box-shadow"] = "0px 0px 0px";
            card.style.margin = "0";
            card.style.padding = "10px";
            // card.style.width = "210mm"
          });

          const headings = specificDiv.querySelectorAll(".form-heading-main");
          headings.forEach((heading) => {
            // heading.style.margin = "10px";
            heading.style.paddingLeft = "5px";
          });

          const highlightedHeadings = specificDiv.querySelectorAll(".form-heading-main");

          highlightedHeadings.forEach((heading) => {
            // Check if the parent element is a <th>
            const isInsideTableHeader = heading.closest("th");

            // Skip the elements inside a <th>
            if (isInsideTableHeader) {
              return; // Skip styling if inside <th>
            }

            // Get the text content of the heading
            const headingText = heading.innerText.trim();

            // Apply different styles based on the heading text
            if (
              headingText === "Person With Benchmark Disability (PwBD) Details" ||
              headingText === "Additional Details" ||
              headingText === "Ex-Servicemen Details" ||
              headingText === "Permanent Address" ||
              headingText === "Matric/10th Details" ||
              headingText === "12th Details" ||
              headingText === "Graduation Details"
            ) {
              // Apply a different style for specific headings
              heading.style.marginLeft = "10px";
              heading.style.marginRight = "10px";
              heading.style.marginTop = "10px";
              heading.style.padding = "10px";
              heading.style.backgroundColor = "#00000012";  // Different background color
              heading.style.color = "black";               // Different font color
              heading.style.flexShrink = "inherit";
              heading.style.borderTopLeftRadius = "10px";  // Different border radius
              heading.style.borderTopRightRadius = "10px";
            } else {
              // Apply the default style for other headings
              heading.style.marginLeft = "10px";
              heading.style.marginRight = "10px";
              heading.style.marginTop = "20px";
              heading.style.padding = "10px";
              heading.style.backgroundColor = "#921D1E";
              heading.style.color = "#ffff";
              heading.style.flexShrink = "inherit";
              heading.style.borderTopLeftRadius = "10px";
              heading.style.borderTopRightRadius = "10px";
            }
          });



          // Remove unnecessary elements
          const star = specificDiv.querySelectorAll(".star");
          star.forEach((stars) => {
            stars.style.display = "none";
          });

          const allDiv = specificDiv.querySelectorAll("div");
          allDiv.forEach((div) => {
            div.style.marginTop = "0";
            div.style.marginBottom = "0";
            div.style.paddingTop = "0";
            div.style.paddingBottom = "0";
          });

          const allpdfDocuments = specificDiv.querySelectorAll(".renderPdf");
          allpdfDocuments.forEach((pdfElement) => {
            pdfElement.remove();
          });

 
          // .react-select__control
          const verifybutton = specificDiv.querySelectorAll(".verifybutton,.badge,.chooseFile,.btn,.Documentdploadvalidation,.react-select__indicators,.react-select__multi-value__remove");
          verifybutton.forEach((ver) => {
            ver.style.display = "none";
          });
    
          const departmentalJoiningDate = specificDiv.querySelectorAll('.react-select__control,#departmentalJoiningDate,#exServicemanJoiningDate,#exServicemanRetirementDate,#Percentage_of_disability,#issuedDate,#issueDateReser,#certificateNumberReser,.react-select__multi-value');
          departmentalJoiningDate.forEach((file) => {
            file.style["-webkit-calendar-picker-indicator"] = "none";

            file.style.border = "none";
            file.style.pointerEvents = "none"; 
                      file.style.appearance = "none";
                      file.style["-webkit-appearance"] = "none";
                      file.style["-moz-appearance"] = "none";
                      file.style["appearance"] = "none";
                      file.setAttribute("type", "text");

});

// react-select_multi-value




// Target the dropdown element
const dropdownElement = document.querySelector('.css-181qgi4-control');

// Remove unwanted styles
if (dropdownElement) {
  dropdownElement.style.border = "none"; // Remove border
  dropdownElement.style.boxShadow = "none"; // Remove any shadow effects
  dropdownElement.style.backgroundColor = "transparent"; // Make background transparent
  dropdownElement.style.padding = "0"; // Remove padding
  dropdownElement.style.margin = "0"; // Remove margins
  dropdownElement.pointerEvents='none'
}

const dropdownInput = dropdownElement.querySelector('input');
const indicatorContainer = dropdownElement.querySelector('.css-9uufmq-IndicatorsContainer');

if (dropdownInput) {
  dropdownInput.style.border = "none"; // Remove input border
  dropdownInput.style.outline = "none"; // Remove input outline
  dropdownInput.style.background = "none"; // Remove input background
  dropdownInput.pointerEvents='none'

}

if (indicatorContainer) {
  indicatorContainer.style.display = "none"; // Hide dropdown arrow/icon
  indicatorContainer.pointerEvents='none'

}


// Target the input element by its ID or name
// const dateInput = specificDiv.querySelector('input[name="disabilityPercentage"]');
// dateInput.forEach((file) => {
//   file.style["-webkit-calendar-picker-indicator"] = "none";

//   file.style.border = "none";
//   file.style.pointerEvents = "none"; 
//             file.style.appearance = "none";
//             file.style["-webkit-appearance"] = "none";
//             file.style["-moz-appearance"] = "none";
//             file.style["appearance"] = "none";
//             file.setAttribute("type", "text");

// });
// // Remove the border
// dateInput.style.border = "none";

// // Hide the calendar icon by overriding its appearance
// dateInput.style.appearance = "none"; // For most browsers
// dateInput.style.webkitAppearance = "none"; // For WebKit-based browsers like Safari
// dateInput.style.mozAppearance = "none"; // For Firefox

// // Optionally, make further adjustments to ensure no residual styles
// dateInput.style.background = "transparent"; // Remove any background styles
// dateInput.style.paddingRight = "0px"; // Adjust padding if needed


          // const deleteTheExprienceFileDiv = specificDiv.querySelectorAll('#fileUpload')
          // deleteTheExprienceFileDiv.forEach((file) => {
          //   file.style.display = "none";
          // });
          const backgroundRemoveElements = specificDiv.querySelectorAll('.form-control');

          backgroundRemoveElements.forEach((bgRemove) => {
            bgRemove.style.backgroundColor = "transparent";
          });
          const experenceInputRemove = specificDiv.querySelectorAll('#experenceInputRemove,#declaration-checkbox,#fileUpload,#allExperienceDetailsRemove')
          experenceInputRemove.forEach((file) => {
            file.style.display = "none";
          });
          // declaration-checkbox
          // const declarationCheckbox = specificDiv.querySelectorAll('#declaration-checkbox')
          // declarationCheckbox.forEach((file) => {
          //   file.style.display = "none";
          // });

          const categorySelectRemove = specificDiv.querySelectorAll('.react-select')
          categorySelectRemove.forEach((file) => {
            file.style.border = "none";
            file.style.pointerEvents = "none"; 
                      file.style.appearance = "none";
                      file.style["-webkit-appearance"] = "none";
                      file.style["-moz-appearance"] = "none";
                      file.style["appearance"] = "none";
                      file.style["-webkit-calendar-picker-indicator"] = "none";
         });
        //  css-181qgi4-control



         const selectRemove = specificDiv.querySelectorAll('select,.form-control')
         selectRemove.forEach((file) => {
           file.style.border = "none";
           file.style.pointerEvents = "none"; 
                     file.style.appearance = "none";
                     file.style["-webkit-appearance"] = "none";
                     file.style["-moz-appearance"] = "none";
                     file.style["appearance"] = "none";
                     file.style["-webkit-calendar-picker-indicator"] = "none";
        });

          // const formControlRemoval = specificDiv.querySelectorAll('.form-control')
          // formControlRemoval.forEach((file) => {
          //   file.style.border = "none";
          //   file.style.pointerEvents = "none";
          //   // file.style.appearance = "none";
          //   file.style["-webkit-appearance"] = "none";
          // file.style["-moz-appearance"] = "none";
          // file.style["appearance"] = "none";
          // file.style["-webkit-calendar-picker-indicator"] = "none";
          // });

          // const allExperienceDetailsRemove = specificDiv.querySelectorAll('#allExperienceDetailsRemove')
          // allExperienceDetailsRemove.forEach((file) => {
          //   file.style.display = "none";
          // });
        }

      }, 300);
    }
  }, [tabIndex]);




  const printRef = useRef();

  return Loading ? (
    <>
      <Spinner />
    </>
  ) : (
    <>
      {/* <HamburgerMenu /> */}
      {console.log(!isLoading, !isLoading2)}
      {!isLoading && !isLoading2 && (
        <div className="m-0 p-0 background__main">
          <Tabs
            className="job-article tf-tab single-job d-flex flex-md-row flex-column applyjob-tab"
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >



            <div

              style={{
                width: "80%",
                // height:"90vh",
                // overflow: "scroll",
                margin: "0 auto",
                padding: "0"
              }}
            >

              {applicationForm?.map((item, index) => {
                return (
                  <>
                    <TabPanel key={index}>
                      <Card className="pb-2 top-card-dynamic shadow-sm">
                        <ul className="d-flex justify-content-between align-items-center" >
                          {applicationForm?.map((item, idx) => {
                            const isCompleted = tabIndex > idx;
                            const isActive = tabIndex === idx;

                            return (
                              <li
                                key={idx}
                                className="text-center dynamicFormBulletsWrap"
                                style={{
                                  color: isCompleted || isActive ? "rgba(0, 0, 0, 1)" : "gray", // Text color 
                                }}
                              >
                                <div
                                  className="dynamicFormBulletsTick"
                                  style={{
                                    backgroundColor: isCompleted ? "green" : isActive ? "brown" : "gray", // Color based on status
                                  }}
                                >
                                  ✓
                                </div>
                                <span className="dynamicFormBulletsFont" style={{ fontWeight: isActive ? "bold" : "normal" }}>{item?.formTab}</span>
                                {idx !== applicationForm.length - 1 && (
                                  <div
                                    className="dynamicFormBulletsText"
                                    style={{
                                      backgroundColor: isCompleted ? "lightgray" : "lightgray",
                                    }}
                                  />
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </Card>
                      <div className={"captureErrorFor_" + index} id="main-form-dynamic">
                        {/* Heading Of Each Page on Tabs */}
                        <div className="applyJobMainHead d-flex align-items-center gap-2">
                          <div
                            className="cursor-pointer d-md-none"
                            onClick={toggleMenu}
                          >
                            <i class="bi bi-list"></i>
                          </div>
                          <h5 className="applyJobMainHeadFont">
                            {item?.formTab}
                          </h5>
                        </div>

                        <Row className="m-0">
                          {item?.isArray ? (
                            dynamicFields?.map((fieldSet) => {
                              return (
                                <>
                                  {fieldSet?.tab === camelCase(item?.formTab) && (
                                    <Card className="mt-2 mb-0 dynamicFormcards ms-3 me-3">
                                      {/* {console.log({ fieldSet })} */}
                                      {/* <Col key={idx} xs={input.values.columnLength} className=""> */}
                                      <Row key={fieldSet.id} className="mt-3">
                                        {fieldSet?.fields.map(
                                          (field, fieldIndex) =>
                                            renderArrayFields(
                                              field,
                                              fieldIndex,
                                              item,
                                              fieldSet
                                            )
                                        )}
                                        <div className="text-end">
                                          <Button
                                            className="btn btn-danger my-1"
                                            onClick={() =>
                                              handleDeleteFieldSet(fieldSet?.id)
                                            }
                                          >
                                            Delete
                                          </Button>
                                        </div>
                                      </Row>
                                      {/* </Col> */}
                                    </Card>
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <div className="">
                              <Row>
                                {/* <Col sm="0" md="2" >

                            </Col> */}
                                <div>
                                  <Col sm="12" md="12" className="">
                                    <div className="custom-card-tabMain">
                                      {renderNonArrayFields(item)}
                                    </div>
                                  </Col>
                                </div>
                                {/* <Col sm="0" md="2" >

                            </Col> */}
                              </Row>
                            </div>
                          )}

                          {/* Additional logic for "Education Details" tab */}

                          {/* Conditionally display Add button based on formTab */}
                          {item?.isArray && (
                            <div className="text-center my-5 ">
                              {handleBlankAddFieldSet(item?.values, item?.formTab)
                              }                              <Button
                                className="btn btn-md"
                                onClick={() =>
                                  handleAddFieldSet(item?.values, item?.formTab)
                                }


                              >
                                Add New
                              </Button>
                            </div>
                          )}
                        </Row>
                      </div>
                    </TabPanel>
                  </>
                )
              })}
              {/* <TabPanel key={"payment"}>
            <div className="d-flex align-items-center justify-content-center">
              <img src={paymentSuccess} alt="" />
            </div>
              </TabPanel> */}
              <TabPanel key={"review"}>

                <div ref={printRef} id="printDiv_1" className="p-0">
                  {applicationForm?.map((item, index) => {
                    console.log("item30303030", item)
                    return (
                      <>
                        <div>
                          {/* <TabPanel key={index}> */}
                          {/* Heading Of Each Page on Tabs */}

                          <Row className="m-0">
                            {item?.isArray ?
                              renderTableOfIsArray(item)
                              : (
                                <div className="">
                                  <Row>
                                    <div className={item?.formTab === "ESSENTIAL QUALIFICATION" ? "ps-0 pe-0" : ""}>
                                      <Col xs="12" className="">
                                        <div className="custom-card-tabMain">
                                          <div className="p-0 m-0">
                                            {item?.formTab === "ESSENTIAL QUALIFICATION" ?
                                              renderTableForNonArrayData(item?.values)
                                              : renderNonArrayFields(item)}
                                          </div>

                                        </div>
                                      </Col>
                                    </div>
                                  </Row>
                                </div>
                              )}

                            {/* Additional logic for "Education Details" tab */}

                            {/* Conditionally display Add button based on formTab */}
                          </Row>
                        </div>
                        {/* </TabPanel> */}
                      </>
                    )
                  }

                  )}

<Card className="mt-2 mb-0 dynamicFormcards ms-3 me-3" style={{ width: '97%' }}>
                        <Row className="mt-3">
                          <label className="form-label form-heading-main" style={{ backgroundColor: "rgb(146, 29, 30)", color: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', padding: '10px' }}>Total Experience</label>
                        </Row>
                        <div>
                          <table bordered
                            className="specific-table"
                            style={{ overflow: "hidden", width: "98%", tableLayout: "fixed" }}>
                            <thead>
                              <tr style={{
                                border: '1px solid black',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                fontWeight: 'bold'
                              }}>
                                <th>Name of Organization</th>
                                <th>Type of Organization</th>
                                <th>Type of Service</th>
                                <th>Status of Service</th>
                                <th>Nature of Business</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Total Experience</th>
                              </tr>
                            </thead>
                            <tbody>
                              {applicationDetails?.newStaticExperience?.map((entry, index) => (
                                <tr key={index}>
                                  <td>{entry?.nameOfOrganization}</td>
                                  <td>{entry?.typeOfOrganization}</td>
                                  <td>{entry?.typeOfService}</td>
                                  <td>{entry?.statusOfService}</td>
                                  <td>{entry?.natureOfBusiness}</td>
                                  <td>{entry?.startDateOfService}</td>
                                  <td>{entry?.endDateOfService}</td>
                                  <td>{entry?.totalExperience}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        <div className="mt-3">
                          <p className="fw-bold">Total Experience (excluding trainee period):
                            <span style={{ color: "brown" }}>{applicationDetails?.finalSummeryExp?.total_postWiseTotalExperienceWithoutTrainee}</span>
                          </p>
                          <p className="fw-bold">Refer to Annexure I for post-wise experience details.</p>
                        </div>

                        <div className="mt-3">
                          <p className="fw-bold">Total Relevant Experience (excluding trainee period):
                           <span style={{ color: "brown" }}>{applicationDetails?.finalSummeryExp?.total_relevantTotalExperienceWithoutTrainee}</span>
                          </p>
                          <p className="fw-bold">Refer to Annexure II for relevant experience details.</p>
                        </div>

                        <div className="mt-3">
                          <p className="fw-bold">Annexure I: Post-wise Experience Details</p>
                          <table bordered
                            className="specific-table"
                            style={{ overflow: "hidden", width: "98%", tableLayout: "fixed" }}>
                            <thead>
                              <tr style={{
                                border: '1px solid black',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                fontWeight: 'bold'
                              }}>
                                <th>Name of Organization</th>
                                <th>Is Trainee</th>
                                <th>Designation</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Total Experience</th>
                              </tr>
                            </thead>
                            <tbody>
                              {applicationDetails?.newStaticExperience?.flatMap((entry, index) =>
                                entry?.postWiseDetails.map((post, postIndex) => (
                                  <tr key={`${index}-${postIndex}`}>
                                    <td>{entry?.nameOfOrganization}</td>
                                    <td>{post?.isTrainee}</td>
                                    <td>{post?.designation}</td>
                                    <td>{post?.startDateOfService}</td>
                                    <td>{post?.endDateOfService}</td>
                                    <td>{post?.totalExperience}</td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className="mt-3">
                          <p className="fw-bold">Annexure II: Relevant Experience Details</p>
                          <table bordered
                            className="specific-table"
                            style={{ overflow: "hidden", width: "98%", tableLayout: "fixed" }}>
                            <thead>
                              <tr style={{
                                border: '1px solid black',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                fontWeight: 'bold'
                              }}>
                                <th>Name of Organization</th>
                                <th>Is Trainee</th>
                                <th>Nature of Job Handled</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Total Experience</th>
                              </tr>
                            </thead>
                            <tbody>
                              {applicationDetails?.newStaticExperience?.flatMap((entry, index) =>
                                entry?.relevantExperience.map((post, postIndex) => (
                                  <tr key={`${index}-${postIndex}`}>
                                    <td>{entry?.nameOfOrganization}</td>
                                    <td>{post?.isTrainee}</td>
                                    <td>{post?.natureOfJobHandled}</td>
                                    <td>{post?.startDateOfService}</td>
                                    <td>{post?.endDateOfService}</td>
                                    <td>{post?.totalExperience}</td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Card>
                </div>
              </TabPanel>
              <Row className="me-0">
                <div className="text-center my-2">
                  <Card className="mb-2 ms-3 pt-0 me-2 dynamicFormcards d-flex align-items-center flex-row justify-content-end ">
                    {tabIndex === applicationForm?.length && (
                      <>
                        <ReactToPrint
                          trigger={() => (
                            <Button
                              className="bottom-card-submit-button registrationFont mt-4 fs-6"
                            // onClick={() => handlePrint("Submit")}
                            >
                              Print
                            </Button>
                          )}
                          content={() => printRef.current}
                        />

                        <Button
                          className="bottom-card-submit-button registrationFont mt-4 fs-6"
                          // onClick={() => handleSubmitForm("Submit")}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(-1);
                          }}
                        >
                          Go Back
                        </Button>

                        {finalSubmitModal && (
                          <FinalFormSubmitModal
                            handleSubmitForm={handleSubmitForm}
                            finalSubmitModal={finalSubmitModal}
                            setFinalSubmitModal={setFinalSubmitModal}
                          />
                        )}
                      </>
                    )}
                  </Card>
                </div>
              </Row>
            </div>
          </Tabs>
        </div>
      )}
      {/* Bottom Card */}

      {/* <Footer /> */}
    </>
  );
};

export default ApplicantScoringScreen_Preview;
