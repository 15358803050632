import React from "react";
import Footer from "../components/footer";
import Preloader from "../components/preloader";
import Gotop from "../components/gotop";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tabs } from "react-tabs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from "react-dropdown";
import { useAsyncDebounce } from "react-table";
import Alldeptomc from "../assets/fakeData/alldepartmnetOfOmc";
import { callApi } from "../constant/callApi";
import { RxCross1 } from "react-icons/rx";

import { useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  InputGroup,
  Table,
  ButtonGroup,
} from "react-bootstrap";

import HamburgerMenu from "../assets/Mobile Menu/HamburgerMenu";

import Pagination from "../pages/paginationcomponent/pagination";
import PageSize from "../pages/paginationcomponent/pagesize";

function AllJobs() {
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [advertisements, setAdvertisements] = useState([]);
  const [dropdowndata, setDropDownData] = useState();
  const [isShowMobile, setShowMobile] = useState(false);
  const [value, setValue] = useState("");
  const [indexNo, setIndexNo] = useState(1);

  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedJob, setSelectedJobDetails] = useState({});
  const [viewApplicationPdf, setViewApplicationPdf] = useState(false);
  const [selectedPageSize, setSelectedPageSize] = useState(8);

  const navigate = useNavigate();
  const options = Alldeptomc?.map((item) => ({
    label: item.title,
    value: item.title,
  }));

  const getAllAdvertisements = async () => {
    try {
      setLoading(true);
      const config = {
        method: "post",
        url: "Portal/all-jobs/getAllAdvertisement",
        data: {
          pageSize: selectedPageSize,
          currentPage: currentPage,
        },
      };
      const response = await callApi(config);
      if (response.data.code === 200) {
        console.log(response?.data?.data,"here")
        setAdvertisements(response?.data?.data);
        setTotalPage(Math.ceil(response.data.totalCount / selectedPageSize));
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRegisterFinal = async (item, post) => {
    console.log("item to AllJobs", item, post);
    const userId = sessionStorage.getItem("token");

    try {
      if (!userId) {
        navigate("/login");
      } else {
        navigate(
          `/apply-for-post?__application_id=${post?.jobApplicationForm}`,
          {
            state: { item, post },
          }
        );
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    getAllAdvertisements();
  }, [selectedPageSize, currentPage]);

  const getTimeGap = (item) => {
    if (!item?.advertisementClosingDate || !item?.advertisementClosingTime) {
      return "Closing date or time is missing";
    }
    const closingDate = new Date(item.advertisementClosingDate);
    const [hours, minutes] = item.advertisementClosingTime
      .split(":")
      .map(Number);
    closingDate.setHours(hours, minutes, 0, 0);
    const now = new Date();
    const timeDifference = closingDate - now;
    if (timeDifference < 0) {
      return "The advertisement has already closed.";
    }
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutesLeft = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    return `${days} days & ${hoursLeft} hr remaining.`;
  };

  const searchByPostName = async (query) => {
    try {
      const config = {
        method: "post",
        url: "Portal/all-jobs/searchByPostNameInAllJob",
        data: {
          query,
          pageSize: selectedPageSize,
          currentPage: currentPage,
        },
      };
      const response = await callApi(config);
      if (response.data.code === 200) {
        console.log(response?.data?.data,"here")
        setAdvertisements(response?.data?.data);
        setTotalPage(Math.ceil(response.data.totalCount / selectedPageSize));

      }
    } catch (error) {
      console.error("Search Error:", error);
    }
  };

  const onPostNameSearch = useAsyncDebounce((item) => {
    if (item) {
      searchByPostName(item);
    } else {
      getAllAdvertisements(); // Reset data if input is empty
    }
  }, 500);

  useEffect(() => {
    if (value !== undefined) {
      onPostNameSearch(value); // Pass value to the debounce function
    }
  }, [value]);

  // Use a regex to match the search term in the content, case-insensitive

  return (
    <>
      <div className="allJobMain">
        <HamburgerMenu clname="alljob" />

        {!loading ? (
          <>
            {" "}
            <Card className="registrationFont main-head-card px-5">Jobs</Card>
            <section className="px-5 py-4 allJobContainer">
              <Card className="allJobCard">
                <div>
                  <div className="job-search-form job-search-form-responsive inner-form-map st1 pt-0 mt-0">
                    <Form id="top-form" className="pt-0 mt-0">
                      <Row className="align-items-center">
                        {/* Left Column */}
                        <Col md={4} className="d-flex align-items-center">
                          <div className="search-input-container allrole-search border-primary w-100 me-2 position-relative">
                            <input
                              style={{
                                paddingLeft: "35px",
                                paddingRight: "35px",
                              }}
                              value={value || ""}
                              onChange={(e) => setValue(e.target.value)}
                              placeholder="Post Name Search"
                              className="w-100"
                            />
                            <span
                              className="search-magnifier-icon-new pe-none position-absolute"
                              style={{
                                // left: "10px",
                                // top: "50%",
                                // transform: "translateY(-50%)",
                              }}
                            >
                              <i className="bi bi-search search-icon-alljobs"></i>
                            </span>

                            {value && (
                              <span
                                className="clear-icon position-absolute"
                                style={{
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                }}
                                onClick={() => setValue("")}
                              >
                                
                                <RxCross1 />
                              </span>
                            )}
                          </div>

                          <Button variant="" size="lg" className="p-0 m-0" onClick={()=>{getAllAdvertisements()}}>
                            <i className="bi bi-arrow-clockwise"></i>
                          </Button>
                        </Col>

                     
                        {/* Right Column */}
                        <Col md={8} xs={12} className="text-end">
                        <div className="dropdown-container">
                          <PageSize
                            setCurrentPage={setCurrentPage}
                            pageSize={selectedPageSize}
                            setSelectedPageSize={setSelectedPageSize}
                          />
                        </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                  <div>
                    <div className="alljob-table-container">
                      <div className="row">
                        <Tabs className="col-12">
                          <div className="">
                            <Card className="mb-2 no-shadow d-none d-md-block job-card-header">
                              <Card.Body className="pt-0 pb-0 px-0 h-100">
                                <Row className=" g-0 h-100 align-content-center pt-1 tableHeadText">
                                  <Col
                                    xs="12"
                                    md="1"
                                    className="d-flex text-uppercase align-items-center justify-content-center tableHeadFont-allJobs"
                                  >
                                    SL NO.
                                  </Col>
                                  <Col
                                    xs="12"
                                    md="2"
                                    className="d-flex text-uppercase align-items-center justify-content-start tableHeadFont-allJobs"
                                  >
                                    ADVERTISEMENT NO
                                  </Col>
                                  <Col
                                    xs="12"
                                    md="2"
                                    className="d-flex text-uppercase  align-items-center justify-content-start tableHeadFont-allJobs"
                                  >
                                    POST NAME
                                  </Col>
                                  <Col
                                    xs="6"
                                    md="2"
                                    className="d-flex text-uppercase align-items-center justify-content-center tableHeadFont-allJobs"
                                  >
                                    STATUS
                                  </Col>
                                  <Col
                                    xs="6"
                                    md="3"
                                    className="d-flex text-uppercase align-items-center justify-content-start tableHeadFont-allJobs"
                                  >
                                    VACANCY
                                  </Col>
                                  <Col
                                    xs="6"
                                    md="2"
                                    className="d-flex text-uppercase align-items-center justify-content-center tableHeadFont-allJobs"
                                  >
                                    ACTION
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>

                            <div className="">
                              <>
                                {advertisements?.map((item, index) => {
                                  console.log(item?.advertisementHeading);
                                  const slNo =
                                    (currentPage - 1) * selectedPageSize +
                                    index +
                                    1;
                                  let daysLeft = getTimeGap(item);
                                  return (
                                    <>
                                      <Card
                                        key={item?._id}
                                        className="mb-2 shadow-sm"
                                        style={{
                                          borderRadius: "10px",
                                        }}
                                      >
                                        <Card.Body className="pt-0 pb-0 px-0 h-100">
                                          <Row className="g-0 h-100 align-content-center pt-2 pb-2">
                                            <Col
                                              xs="6"
                                              md="1"
                                              className="d-flex flex-column job-card-col align-items-start align-items-md-center justify-content-center mb-1 mb-md-0 text-start text-md-center ps-xs-3"
                                            >
                                              <div className="job-data-card d-md-none">
                                                Sl No.
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <p className="mb-0">{slNo}</p>
                                              </div>
                                            </Col>

                                            <Col
                                              xs="6"
                                              md="2"
                                              className="d-flex flex-column job-card-col align-items-start justify-content-center mb-1 mb-md-0 text-start ps-xs-3"
                                            >
                                              <div className="job-data-card d-md-none">
                                                ADVERTISEMENT NO
                                              </div>
                                              <div className="tableBodyFont-top text-start text-md-start"
                                                   style={{ wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}
                                              >
                                                {item?.advertisementNumber}
                                                <a
                                                  href={
                                                    item?.advertisementDocument
                                                  }
                                                  target="__blank"
                                                  className="icon-download ms-2 mt-2"
                                                ></a>
                                              </div>
                                            </Col>
                                            <Col
                                              xs="8"
                                              md="2"
                                              className="d-flex flex-column job-card-col align-items-start justify-content-center mb-1 mb-md-0 text-start ps-xs-3"
                                            >
                                              <div className=" d-md-none job-data-card">
                                                POST NAME
                                              </div>
                                              <div
                                                to="#"
                                                className="tableBodyFont-top text-start text-md-start"
                                              >
                                                {`${item?.postDetails?.posting?.postName?.designationName}` ||
                                                  ""}
                                              </div>
                                            </Col>
                                            <Col
                                              xs="4"
                                              md="2"
                                              className="d-flex flex-column justify-content-center align-items-center text-center "
                                            >
                                              {daysLeft ===
                                              "The advertisement has already closed." ? (
                                                <span
                                                  className="badge closedBadge pt-2 pb-2 ps-3 pe-3 tableBodyFont-top "
                                                  // style={{ width: "100px" }}
                                                >
                                                  In Active
                                                </span>
                                              ) : (
                                                <span
                                                  className="badge activeBadge pt-2 pb-2 ps-3 pe-3 tableBodyFont-top "
                                                  // style={{ width: "100px" }}
                                                >
                                                  Active
                                                </span>
                                              )}
                                              {/* <div className=" text-small d-md-none job-data-card">STATUS</div> */}
                                            </Col>
                                            <Col
                                              xs="8"
                                              md="3"
                                              className=" job-card-col align-items-start text-start"
                                            >
                                              <div className=" text-small d-md-none job-data-card">
                                                VACANCY:
                                                <span className="d-md-none">
                                                  {item?.postDetails?.posting
                                                    ?.noOfVacancies || ""}
                                                </span>
                                              </div>
                                              <div className="tableBodyFont-top d-none d-md-inline">
                                                {item?.postDetails?.posting
                                                  ?.noOfVacancies || ""}
                                                <span className="d-none d-md-inline mx-2 tableBodyFont-top ">
                                                  No of Vacancy
                                                </span>{" "}
                                              </div>
                                              <div>
                                                <span className="icon-calendar me-2"></span>
                                                <span className="tableBodyFont-top ">
                                                  {daysLeft}
                                                </span>
                                              </div>
                                            </Col>
                                            <Col
                                              xs="4"
                                              md="2"
                                              className="d-flex justify-content-center flex-column align-items-center"
                                            >
                                              {daysLeft ===
                                              "The advertisement has already closed." ? (
                                                <Button
                                                  variant="primary"
                                                  size="sm"
                                                  disabled
                                                  // onClick={(e) => {
                                                  // e.preventDefault();
                                                  // handleRegisterFinal(item, post);
                                                  // }}
                                                  className="alljobBtn ps-3 pe-3 pt-2 pb-2 close-btn"
                                                >
                                                  CLOSED
                                                </Button>
                                              ) : (
                                                <Button
                                                  variant="primary"
                                                  size="sm"
                                                  onClick={(e) => {
                                                    console.log(
                                                      "post",
                                                      item?.postDetails,
                                                      "item",
                                                      item
                                                    );

                                                    e.preventDefault();
                                                    handleRegisterFinal(
                                                      item,
                                                      item?.postDetails
                                                    );
                                                  }}
                                                  className="alljobBtn ps-3 pe-3 pt-2 pb-2 apply-btn"
                                                >
                                                  APPLY
                                                </Button>
                                              )}
                                            </Col>
                                          </Row>
                                        </Card.Body>
                                      </Card>
                                    </>
                                  );
                                })}
                              </>
                            </div>
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageCount={totalPage}
                />
              </Card>
            </section>
          </>
        ) : (
          <Preloader />
        )}
      </div>
      <div className="custom-footer pt-3 pb-3">
        <p className="custom-footer-text">
          © 2024 Odisha Mining Corporation Ltd. (O M C LTD)
        </p>
      </div>
      {/* <Footer /> */}
      <Gotop />
    </>
  );
}

export default AllJobs;
