import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Alert, Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import waringSign from '../../assets/images/warnOmc.png'

const DepartmentalCandidate = ({ handelApplicationDetailsChange, applicationDetails, closingDate, style }) => {
    const [formData, setFormData] = useState({
        departmentalCandidate: applicationDetails?.departmentalCandidate || '',
        departmentalEmployeeId: applicationDetails?.departmentalEmployeeId || '',
        departmentalTotalServicePeriod: applicationDetails?.departmentalTotalServicePeriod || '',
        departmentalJoiningDate: applicationDetails?.departmentalJoiningDate || '',
        departmentalSameSubCadre: applicationDetails?.departmentalSameSubCadre || '',
        // departmentalPreviouslyApplied: applicationDetails?.departmentalPreviouslyApplied || '',
        // departmentalPostName: applicationDetails?.departmentalPostName || '',
        // departmentalYearOfExperience: applicationDetails?.departmentalYearOfExperience || '',
        // departmentalAdvertisementNo: applicationDetails?.departmentalAdvertisementNo || ''
    });
    const [fieldErrors, setFieldErrors] = useState({});

    const [sameSubCaderModal, setSameSubCaderModal] = useState(false)

    useEffect(() => {
        handelApplicationDetailsChange(formData);
    }, [formData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'departmentalSameSubCadre' && value === 'No') {
            // toast.warn('You are not eligible');
            setSameSubCaderModal(true)
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const advertisementClosingDate = new Date(closingDate);

    const calculateServicePeriod = (joiningDate) => {
        console.log({ closingDate }, { joiningDate }, { advertisementClosingDate })
        if (!joiningDate) return;

        const startDate = new Date(joiningDate);
        const endDate = new Date(advertisementClosingDate);

        const totalYears = endDate.getFullYear() - startDate.getFullYear();
        const totalMonths = endDate.getMonth() - startDate.getMonth();
        const totalDays = endDate.getDate() - startDate.getDate();

        let years = totalYears;
        let months = totalMonths;
        let days = totalDays;

        // Adjust for negative months or days
        if (days < 0) {
            months -= 1;
            days += new Date(endDate.getFullYear(), endDate.getMonth(), 0).getDate(); // days in the previous month
        }

        if (months < 0) {
            years -= 1;
            months += 12;
        }

        const totalServicePeriod = `${years} Years, ${months} Months, ${days} Days`;

        // Update the formData with the calculated value
        setFormData((prevData) => ({
            ...prevData,
            departmentalTotalServicePeriod: totalServicePeriod,
        }));
    };


    const checkErrorFn = async (camelCaseKey, immediateValue) => {
        let errCount = 0;


        if (!immediateValue) {
            errCount++;
            setFieldErrors(prevState => ({
                ...prevState,
                [camelCaseKey]: {
                    errors: ["This field is required."],
                    touched: true
                }
            }));
        } else {
            switch (camelCaseKey) {
                case "departmentalEmployeeId":
                    const regexEmployeeId = /^[a-zA-Z0-9]+$/; // Alphanumeric regex
                    if (!regexEmployeeId.test(immediateValue)) {
                        errCount++;
                        setFieldErrors(prevState => ({
                            ...prevState,
                            [camelCaseKey]: {
                                errors: ["Invalid Employee ID."],
                                touched: true
                            }
                        }));
                    } else {
                        setFieldErrors(prevState => ({
                            ...prevState,
                            [camelCaseKey]: {
                                errors: [],
                                touched: true
                            }
                        }));
                    }
                    break;

                case "departmentalTotalServicePeriod":
                    const regexServicePeriod = /^[0-9]+$/; // Only numbers for service period
                    if (!regexServicePeriod.test(immediateValue)) {
                        errCount++;
                        setFieldErrors(prevState => ({
                            ...prevState,
                            [camelCaseKey]: {
                                errors: ["Invalid service period."],
                                touched: true
                            }
                        }));
                    } else {
                        setFieldErrors(prevState => ({
                            ...prevState,
                            [camelCaseKey]: {
                                errors: [],
                                touched: true
                            }
                        }));
                    }
                    break;


                case "departmentalSameSubCadre":
                    if (immediateValue === "No") {
                        // If "No" is selected, set an error message indicating ineligibility
                        errCount++;
                        setFieldErrors((prevState) => ({
                            ...prevState,
                            [camelCaseKey]: {
                                errors: ["You are not eligible."], // Add error message for "No" case
                                touched: true,
                            },
                        }));
                    } else {
                        setFieldErrors(prevState => ({
                            ...prevState,
                            [camelCaseKey]: {
                                errors: [],
                                touched: true
                            }
                        }));
                    }
                    break;

                case "departmentalJoiningDate":
                    const regexDate = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/; // Date in YYYY-MM-DD format
                    if (!regexDate.test(immediateValue)) {
                        errCount++;
                        setFieldErrors(prevState => ({
                            ...prevState,
                            [camelCaseKey]: {
                                errors: ["Enter a valid date in YYYY-MM-DD format."],
                                touched: true
                            }
                        }));
                    } else {
                        setFieldErrors(prevState => ({
                            ...prevState,
                            [camelCaseKey]: {
                                errors: [],
                                touched: true
                            }
                        }));
                    }
                    break;

                case "departmentalPostName":
                case "departmentalYearOfExperience":
                case "departmentalAdvertisementNo":
                    if (!immediateValue) {
                        errCount++;
                        setFieldErrors(prevState => ({
                            ...prevState,
                            [camelCaseKey]: {
                                errors: ["This field is required."],
                                touched: true
                            }
                        }));
                    } else {
                        setFieldErrors(prevState => ({
                            ...prevState,
                            [camelCaseKey]: {
                                errors: [],
                                touched: true
                            }
                        }));
                    }
                    break;

                default:
                    break;
            }
        }

        if (errCount < 1) {
            setFieldErrors(prevState => ({
                ...prevState,
                [camelCaseKey]: {
                    errors: [],
                    touched: true
                }
            }));
        }
    };

    return (
        <>
            <Row>
                <Col xs={12} md={4}>
                    <div className="mb-1">
                        <label className="form-label inputBoxHeadForm" style={style.label}>
                            Are you a departmental candidate of OMC?<span className="mx-1 text-danger">*</span>
                        </label>
                        <select
                            // className="form-select"
                            name="departmentalCandidate"
                            value={formData.departmentalCandidate}
                            onChange={(e) => {
                                handleInputChange(e);
                                checkErrorFn("departmentalCandidate", e.target.value);
                            }}
                            onBlur={() => checkErrorFn("departmentalCandidate", formData['departmentalCandidate'])}
                            style={
                                fieldErrors?.["departmentalCandidate"]?.errors?.length > 0 &&
                                    fieldErrors?.["departmentalCandidate"]?.touched
                                    ? { ...style.input, ...style.errorBox }
                                    : style.input
                            }
                        >
                            <option value="" disabled>Select an option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                        {fieldErrors?.["departmentalCandidate"]?.errors?.length > 0 &&
                            fieldErrors?.["departmentalCandidate"]?.touched &&
                            fieldErrors?.["departmentalCandidate"]?.errors.map((err) => (
                                <span className="errorPresent" style={style.errorSpan}>
                                    {err}
                                    <br />
                                </span>
                            ))}
                    </div>
                </Col>

                {formData.departmentalCandidate === "Yes" && (
                    <>
                        <Col xs={12} md={4}>
                            <div className="mb-1">
                                <label className="form-label inputBoxHeadForm" htmlFor="departmentalEmployeeId" style={style.label}>
                                    Employee ID <span className="mx-1 text-danger">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="departmentalEmployeeId"
                                    name="departmentalEmployeeId"
                                    value={formData.departmentalEmployeeId}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                        fieldErrors?.["departmentalEmployeeId"]?.touched && checkErrorFn("departmentalEmployeeId", e.target.value);
                                    }}
                                    onBlur={() => checkErrorFn("departmentalEmployeeId", formData['departmentalEmployeeId'])}
                                    style={
                                        fieldErrors?.["departmentalEmployeeId"]?.errors?.length > 0 &&
                                            fieldErrors?.["departmentalEmployeeId"]?.touched
                                            ? { ...style.input, ...style.errorBox }
                                            : style.input
                                    }
                                />
                                {fieldErrors?.["departmentalEmployeeId"]?.errors?.length > 0 &&
                                    fieldErrors?.["departmentalEmployeeId"]?.touched &&
                                    fieldErrors?.["departmentalEmployeeId"]?.errors.map((err) => (
                                        <span className="errorPresent" style={style.errorSpan}>
                                            {err}
                                            <br />
                                        </span>
                                    ))}
                            </div>
                        </Col>



                        <Col xs={12} md={4}>
                            <div className="mb-1">
                                <label className="form-label inputBoxHeadForm" htmlFor="departmentalJoiningDate" style={style.label}>
                                    Joining Date <span className="mx-1 text-danger">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="date"
                                    id="departmentalJoiningDate"
                                    name="departmentalJoiningDate"
                                    value={formData.departmentalJoiningDate}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                        calculateServicePeriod(e.target.value); // Call the function to calculate service period
                                        fieldErrors?.["departmentalJoiningDate"]?.touched && checkErrorFn("departmentalJoiningDate", e.target.value);
                                    }}
                                    onBlur={() => checkErrorFn("departmentalJoiningDate", formData['departmentalJoiningDate'])}
                                    max={new Date().toISOString().split('T')[0]} // Max is today
                                    style={
                                        fieldErrors?.["departmentalJoiningDate"]?.errors?.length > 0 &&
                                            fieldErrors?.["departmentalJoiningDate"]?.touched
                                            ? { ...style.input, ...style.errorBox }
                                            : style.input
                                    }
                                />
                                {fieldErrors?.["departmentalJoiningDate"]?.errors?.length > 0 &&
                                    fieldErrors?.["departmentalJoiningDate"]?.touched &&
                                    fieldErrors?.["departmentalJoiningDate"]?.errors.map((err) => (
                                        <span className="errorPresent" style={style.errorSpan}>
                                            {err}
                                            <br />
                                        </span>
                                    ))}
                            </div>
                        </Col>

                        <Col xs={12} md={4}>
                            <div className="mb-1">
                                <label className="form-label inputBoxHeadForm" htmlFor="departmentalTotalServicePeriod" style={style.label}>
                                    Total Service Period <span className="mx-1 text-danger">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="departmentalTotalServicePeriod"
                                    name="departmentalTotalServicePeriod"
                                    value={formData.departmentalTotalServicePeriod} // This will be auto-populated
                                    disabled
                                    style={style.input}
                                />
                            </div>
                        </Col>

                        <Col xs={12} md={4}>
                            <div className="mb-1">
                                <label className="form-label inputBoxHeadForm" htmlFor="departmentalSameSubCadre" style={style.label}>
                                    Do you belong to the same sub-cadre ? <span className="mx-1 text-danger">*</span>
                                </label>
                                <select
                                    // className="form-select"
                                    id="departmentalSameSubCadre"
                                    name="departmentalSameSubCadre"
                                    value={formData.departmentalSameSubCadre}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        handleInputChange(e);
                                        checkErrorFn("departmentalSameSubCadre", e.target.value);
                                        if (value === "No") {
                                            // Clear all specified fields except `departmentalCandidate`
                                            setFormData((prevFormData) => ({
                                                ...prevFormData,
                                                departmentalEmployeeId: '',
                                                departmentalTotalServicePeriod: '',
                                                departmentalJoiningDate: '',
                                                departmentalSameSubCadre: '',
                                                departmentalCandidate: 'No',
                                            }));
                                        }
                                    }}
                                    onBlur={() => checkErrorFn("departmentalSameSubCadre", formData['departmentalSameSubCadre'])}
                                    style={
                                        fieldErrors?.["departmentalSameSubCadre"]?.errors?.length > 0 &&
                                            fieldErrors?.["departmentalSameSubCadre"]?.touched
                                            ? { ...style.input, ...style.errorBox }
                                            : style.input
                                    }
                                >
                                    <option value="" disabled>Select an option</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                                {fieldErrors?.["departmentalSameSubCadre"]?.errors?.length > 0 &&
                                    fieldErrors?.["departmentalSameSubCadre"]?.touched &&
                                    fieldErrors?.["departmentalSameSubCadre"]?.errors.map((err) => (
                                        <span className="errorPresent" style={style.errorSpan}>
                                            {err}
                                            <br />
                                        </span>
                                    ))}
                            </div>
                        </Col>
                    </>
                )}
            </Row>

            {/* <Row>
                <Col xs={12} md={5}>
                    <div className="mb-1">
                        <label className="form-label inputBoxHeadForm" style={style.label}>
                            Have you previously applied for any position at OMC? <span className="mx-1 text-danger">*</span>
                        </label>
                        <select
                            // className="form-select"
                            name="departmentalPreviouslyApplied"
                            value={formData.departmentalPreviouslyApplied}
                            onChange={(e) => {
                                handleInputChange(e);
                                checkErrorFn("departmentalPreviouslyApplied",e.target.value);
                            }}
                            onBlur={() => checkErrorFn("departmentalPreviouslyApplied",formData['departmentalPreviouslyApplied'])}
                            style={
                                fieldErrors?.["departmentalPreviouslyApplied"]?.errors?.length > 0 &&
                                    fieldErrors?.["departmentalPreviouslyApplied"]?.touched
                                    ? { ...style.input, ...style.errorBox }
                                    : style.input
                            }
                        >
                            <option value="" disabled>Select an option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                        {fieldErrors?.["departmentalPreviouslyApplied"]?.errors?.length > 0 &&
                            fieldErrors?.["departmentalPreviouslyApplied"]?.touched &&
                            fieldErrors?.["departmentalPreviouslyApplied"]?.errors.map((err) => (
                                <span className="errorPresent" style={style.errorSpan}>
                                    {err}
                                    <br />
                                </span>
                            ))}
                    </div>
                </Col>

                {formData.departmentalPreviouslyApplied === "Yes" && (
                    <>
                        <Col xs={12} md={3}>
                            <div className="mb-1">
                                <label className="form-label inputBoxHeadForm" htmlFor="departmentalPostName" style={style.label}>
                                    Post Name <span className="mx-1 text-danger">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="departmentalPostName"
                                    name="departmentalPostName"
                                    value={formData.departmentalPostName}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                        fieldErrors?.["departmentalPostName"]?.touched && checkErrorFn("departmentalPostName",e.target.value);
                                    }}
                                    onBlur={() => checkErrorFn("departmentalPostName",formData['departmentalPostName'])}
                                    style={
                                        fieldErrors?.["departmentalPostName"]?.errors?.length > 0 &&
                                            fieldErrors?.["departmentalPostName"]?.touched
                                            ? { ...style.input, ...style.errorBox }
                                            : style.input
                                    }
                                />
                                {fieldErrors?.["departmentalPostName"]?.errors?.length > 0 &&
                                    fieldErrors?.["departmentalPostName"]?.touched &&
                                    fieldErrors?.["departmentalPostName"]?.errors.map((err) => (
                                        <span className="errorPresent" style={style.errorSpan}>
                                            {err}
                                            <br />
                                        </span>
                                    ))}
                            </div>
                        </Col>

                        <Col xs={12} md={2}>
                            <div className="mb-1">
                                <label className="form-label inputBoxHeadForm" htmlFor="departmentalYearOfExperience" style={style.label}>
                                    Year of Experience <span className="mx-1 text-danger">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="departmentalYearOfExperience"
                                    name="departmentalYearOfExperience"
                                    value={formData.departmentalYearOfExperience}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                        fieldErrors?.["departmentalYearOfExperience"]?.touched && checkErrorFn("departmentalYearOfExperience",e.target.value);
                                    }}
                                    onBlur={() => checkErrorFn("departmentalYearOfExperience",formData['departmentalYearOfExperience'])}
                                    style={
                                        fieldErrors?.["departmentalYearOfExperience"]?.errors?.length > 0 &&
                                            fieldErrors?.["departmentalYearOfExperience"]?.touched
                                            ? { ...style.input, ...style.errorBox }
                                            : style.input
                                    }
                                />
                                {fieldErrors?.["departmentalYearOfExperience"]?.errors?.length > 0 &&
                                    fieldErrors?.["departmentalYearOfExperience"]?.touched &&
                                    fieldErrors?.["departmentalYearOfExperience"]?.errors.map((err) => (
                                        <span className="errorPresent" style={style.errorSpan}>
                                            {err}
                                            <br />
                                        </span>
                                    ))}
                            </div>
                        </Col>

                        <Col xs={12} md={2}>
                            <div className="mb-1">
                                <label className="form-label inputBoxHeadForm" htmlFor="departmentalAdvertisementNo" style={style.label}>
                                    Advertisement No. <span className="mx-1 text-danger">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="departmentalAdvertisementNo"
                                    name="departmentalAdvertisementNo"
                                    value={formData.departmentalAdvertisementNo}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                        fieldErrors?.["departmentalAdvertisementNo"]?.touched && checkErrorFn("departmentalAdvertisementNo",e.target.value);
                                    }}
                                    onBlur={() => checkErrorFn("departmentalAdvertisementNo",formData['departmentalAdvertisementNo'])}
                                    style={
                                        fieldErrors?.["departmentalAdvertisementNo"]?.errors?.length > 0 &&
                                            fieldErrors?.["departmentalAdvertisementNo"]?.touched
                                            ? { ...style.input, ...style.errorBox }
                                            : style.input
                                    }
                                />
                                {fieldErrors?.["departmentalAdvertisementNo"]?.errors?.length > 0 &&
                                    fieldErrors?.["departmentalAdvertisementNo"]?.touched &&
                                    fieldErrors?.["departmentalAdvertisementNo"]?.errors.map((err) => (
                                        <span className="errorPresent" style={style.errorSpan}>
                                            {err}
                                            <br />
                                        </span>
                                    ))}
                            </div>
                        </Col>
                    </>
                )}
            </Row> */}

            {sameSubCaderModal && <Modal show={sameSubCaderModal} onHide={() => setSameSubCaderModal(false)} centered>
                <Modal.Header style={{background:'#921D1E', color:'white'}}>
                    <div className='d-flex align-items-center justify-content-center w-100'>
                        <img src={waringSign} alt="warn" />
                    </div>
                </Modal.Header>
                <Modal.Body>
                <h2 style={{color:'#921D1E', fontSize:'28px', fontFamily:'Roboto', textAlign:'center'}}>Warning !</h2>
                 <p style={{ fontSize:'16px',marginTop:'20px', fontFamily:'Roboto', textAlign:'center'}}>
                 You are not eligible for availing age relaxation as a departmental candidate and have to apply as an outside candidate as per your own category. </p>
                    {/* <Alert variant="warning" className="fw-bolder">
                        You are not eligible for availing age relaxation as a departmental candidate and have to apply as an outside candidate as per your own category.
                    </Alert> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSameSubCaderModal(false)} style={{ fontSize:'16px',fontWeight:'600', fontFamily:'Roboto', textAlign:'center', border:'1px solid #DEBBBB', color:'#921D1E', background:'#F9F3F4'}}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
        </>

    );
};

export default DepartmentalCandidate;
