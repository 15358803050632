import AWS from 'aws-sdk';

export async function UploadPdf(filepath) {
    const file_name = Math.random().toString(36).substring(6) + '_uploads.pdf';

    const config = {
        endpoint: new AWS.Endpoint('blr1.digitaloceanspaces.com'),
        accessKeyId: 'DO002LEBDZP7CX647E47',
        secretAccessKey: '+7A2cDpxBhzPAo9OY+TCvLNLeVFzQInTY/dKZzBd+tc',
        signatureVersion: 'v4',
    };

    const s3 = new AWS.S3(config);

    try {
        const params = {
            Bucket: 'esevabemc',
            Key: file_name,
            Body: filepath,
            ContentType: 'application/pdf',
            ACL: 'public-read',
        };

        await s3.upload(params).promise();

        const fileUrl = `https://${config.endpoint.host}/${params.Bucket}/${params.Key}`;
        console.log("fileurl==", fileUrl)
        return fileUrl;
    } catch (error) {
        console.error('Error uploading the file:', error);
        return null;
    }
}

export async function UploadToAWS(file) {
    try {
        const a = await UploadPdf(file);
        console.log({ a })
        return a;
    } catch (error) {
        console.log(error);
    }
}