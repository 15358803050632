import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { callApi } from "../constant/callApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from 'crypto-js';


function ForgotPasswordModal({ show, handleClose }) {
  const [showPassword, setShowPassword] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [otpDetails, setOtpDetails] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otpFieldVisible, setOtpFieldVisible] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);

  const navigate = useNavigate();

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?]).{8,}$/;

  const isValidPassword = (password) => {
    if (!passwordRegex.test(password)) {
      return "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one special character, and one number.";
    }
    const uniqueChars = new Set(password);
    // if (uniqueChars.size !== password.length) {
    //   return "Password must not have repeated characters.";
    // }
    for (let i = 0; i < password.length - 2; i++) {
      const char1 = password.charCodeAt(i);
      const char2 = password.charCodeAt(i + 1);
      const char3 = password.charCodeAt(i + 2);
      if (char1 + 1 === char2 && char2 + 1 === char3) {
        return "Password must not have serial characters (e.g., '1234' or 'abcd').";
      }
    }
    return "";
  };

  const handleCheckForgot = (event) => {
    event.preventDefault();

    const passwordValidationMessage = isValidPassword(password);
    if (passwordValidationMessage) {
      setPasswordError(passwordValidationMessage);
      return;
    }

    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }
    setPasswordError("");
    handleClose();
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const generateOtp = async () => {
    if (!/^[6-9]\d{9}$/.test(phoneNumber)) {
      setPhoneError("Only 10 digit numbers allowed");
      return;
    }
    setPhoneError("");
    setOtpFieldVisible(true);
    try {
      let config = {
        method: "post",
        url: "Portal/portal/portal_generateOtpForForgotPassword",
        data: {
          phoneNumber: phoneNumber,
        },
      };

      let response = await callApi(config);
      if (response.data.code === 201) {
        toast.success(response.data.message, { autoClose: 2000 });
      } else {
        toast.error(response.data.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error, "here is error");
      toast.error(error.message, { autoClose: 2000 });
    }
  };

  const submitForgotPassword = async () => {
    try {
      const hashedPassword = CryptoJS.SHA256(password).toString();

      let config = {
        method: "post",
        url: "users/forgetPassword",
        data: {
          phoneNumber: phoneNumber,
          password: hashedPassword,
          otp: otpDetails,
        },
      };

      let response = await callApi(config);
      console.log(response.data, "here");
      if (response?.data?.code === 200) {
        localStorage.setItem("userId", response?.data?.userBasicDetails?._id);
        localStorage.setItem("token", response.data.token);
        toast.success(response.data.message, { autoClose: 2000 });
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        toast.error(response.data.message, { autoClose: 2000 });
        navigate("/login");
      }
    } catch (error) {
      console.log(error, "here is error of signUp function");
      toast(error.message, { className: "danger", autoClose: 2000 });
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }
  };

  const handleOtpChecker = async () => {
    try {
      let config = {
        method: "post",
        url: "Portal/portal/generateOtpForPortalRegistration",
        data: {
          phoneNumber:phoneNumber,
          otp: otpDetails,
        },
      };

      let response = await callApi(config);
      if (response.data.code === 201) {
        toast.success(response.data.message, { autoClose: 2000 });
        setVerifyOtp(true);
      } else {
        toast.error(response.data.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error, "here is error");
      toast.error(error.message, { autoClose: 2000 });
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Forgot Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleCheckForgot}>
          <Form.Group className="mb-3">
            <Form.Label>Enter Your Register Phone Number</Form.Label>
            <Form.Control
              type="number"
              name="phone"
              placeholder="Enter phone number"
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              pattern="[0-9]*"
              required
            />
            {phoneError && <div className="text-danger">{phoneError}</div>}
          </Form.Group>

          <Button variant="primary" onClick={generateOtp} className="mb-3">
            {otpFieldVisible ? "ReSend OTP" : "Send OTP"}
          </Button>
          {otpFieldVisible && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>OTP</Form.Label>
                <Form.Control
                  type="text"
                  name="otp"
                  placeholder="Enter OTP"
                  onChange={(e) => {
                    setOtpDetails(e.target.value);
                  }}
                  maxLength="6"
                  required
                />
                  <Form.Label>Password</Form.Label>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholder="Enter password"
                  required
                />
                <FontAwesomeIcon
                  icon={showPassword ? faEye : faEyeSlash}
                  onClick={toggleShowPassword}
                  className="password-addon"
                  style={{
                    position: "absolute",
                    right: "30px",
                    top: "75%",
                    transform: "translateY(-450%)",
                    cursor: "pointer",
                  }}
                />
                 <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  placeholder="Confirm password"
                  required
                />
                {passwordError && (
                  <div className="text-danger">{passwordError}</div>
                )}
              </Form.Group>
              
              {/* <Button
                variant="primary"
                onClick={handleOtpChecker}
                className="mb-3"
              >
                {otpFieldVisible ? "Verify OTP" : "Verified"}
              </Button> */}
              {/* {verifyOtp & <i class="bi bi-check-circle"></i>} */}
            </>
          )}

          {/* {verifyOtp && ( */}
            {/* <>
              <Form.Group className="mb-3 position-relative">
              
              </Form.Group>
              <Form.Label>
                <ul style={{ listStyleType: "circle" }} className="ps-5">
                  <li>At least one uppercase letter</li>
                  <li>At least one lowercase letter</li>
                  <li>At least one special character</li>
                  <li>At least one number</li>
                  <li>
                    No repeated characters (consecutive or non-consecutive)
                  </li>
                  <li>
                    No serial numbers or serial characters (e.g., "1234" or
                    "abcd")
                  </li>
                </ul>
              </Form.Label>
              <Form.Group className="mb-3">
               
              </Form.Group>
            </> */}
          {/* // )} */}

          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="submit"
              onClick={submitForgotPassword}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ForgotPasswordModal;
