import React, { useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import InputField from "./InputField";
import { RiDeleteBin6Line } from "react-icons/ri";

const RelevantExperienceEntry = ({
  entry,
  outerIndex,
  relevantIndex,
  handleInputChange,
  deleteRelevantExperience,
  fieldErrors,
  style,
  checkErrorFn,
  parentStartDate,
  parentEndDate,
  setFieldErrors,
  calculateTotalExperience
}) => {
  // Function to check if a date is within the parent date range
  const isDateWithinRange = (date, minDate, maxDate) => {
    const dateValue = new Date(date);
    const min = new Date(minDate);
    const max = new Date(maxDate);
    return dateValue >= min && dateValue <= max;
  };

  // Function to calculate total experience between two dates
  // const calculateTotalExperience = (startDate, endDate) => {
  //   if (!startDate || !endDate) return "";

  //   const start = new Date(startDate);
  //   const end = new Date(endDate);

  //   if (end < start) return "";

  //   let years = end.getFullYear() - start.getFullYear();
  //   let months = end.getMonth() - start.getMonth();
  //   let days = end.getDate() - start.getDate();

  //   if (days < 0) {
  //     months -= 1;
  //     days += new Date(end.getFullYear(), end.getMonth(), 0).getDate();
  //   }

  //   if (months < 0) {
  //     years -= 1;
  //     months += 12;
  //   }

  //   return `${years} years, ${months} months, ${days} days`;
  // };

  // Effect to recalculate total experience when dates change
  useEffect(() => {
    if (entry.startDateOfService && entry.endDateOfService) {
      if (new Date(entry.endDateOfService) < new Date(entry.startDateOfService)) {
        // Set error if end date is before start date
        setFieldErrors((prevState) => ({
          ...prevState,
          [`endDateOfService${outerIndex}${relevantIndex}`]: {
            errors: ["End date cannot be before start date."],
            touched: true,
          },
        }));
        // Clear totalExperience
        handleInputChange(
          outerIndex,
          null,
          "totalExperience",
          "",
          relevantIndex
        );
      } else {
        // Clear error and calculate total experience
        setFieldErrors((prevState) => ({
          ...prevState,
          [`endDateOfService${outerIndex}${relevantIndex}`]: {
            errors: [],
            touched: true,
          },
        }));
        const totalExperience = calculateTotalExperience(
          entry.startDateOfService,
          entry.endDateOfService
        );
        handleInputChange(
          outerIndex,
          null,
          "totalExperience",
          totalExperience,
          relevantIndex
        );
      }
    } else {
      // Clear totalExperience if dates are incomplete
      handleInputChange(
        outerIndex,
        null,
        "totalExperience",
        "",
        relevantIndex
      );
    }
  }, [entry.startDateOfService, entry.endDateOfService,entry.isTrainee]);

  // Local validation function
  const localCheckErrorFn = (fieldKey, immediateValue) => {
    let errors = [];
    if (!immediateValue) {
      errors.push("This field is required.");
    } else if (
      (fieldKey.includes("startDateOfService") ||
        fieldKey.includes("endDateOfService"))
    ) {
      // Validate date within parent range
      if (
        parentStartDate &&
        parentEndDate &&
        !isDateWithinRange(immediateValue, parentStartDate, parentEndDate)
      ) {
        errors.push(
          `Date must be between ${parentStartDate} and ${parentEndDate}.`
        );
      }

      // Validate that start date is before end date
      if (entry.startDateOfService && entry.endDateOfService) {
        if (
          new Date(entry.endDateOfService) < new Date(entry.startDateOfService)
        ) {
          errors.push("End date cannot be before start date.");
        }
      }
    }
    setFieldErrors((prevState) => ({
      ...prevState,
      [fieldKey]: {
        errors: errors,
        touched: true,
      },
    }));
    return errors.length === 0;
  };

  return (
    <Row className="mt-2">
      {/* Is Trainee */}
      <hr className="mt-3"/>
      <Row>

      <Col xs={12} md={4}>
        <InputField
          label="Is Trainee:"
          type="select"
          value={entry.isTrainee}
          onChange={(e) => {
            const value = e.target.value;
            handleInputChange(
              outerIndex,
              null,
              "isTrainee",
              value,
              relevantIndex
            );
            checkErrorFn(
              `isTrainee${outerIndex}${relevantIndex}`,
              value,
              outerIndex,
              relevantIndex
            );
          }}
          onBlur={() =>
            checkErrorFn(
              `isTrainee${outerIndex}${relevantIndex}`,
              entry.isTrainee,
              outerIndex,
              relevantIndex
            )
          }
          error={
            fieldErrors?.[`isTrainee${outerIndex}${relevantIndex}`]?.errors
          }
          style={style}
          required
          options={[
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" },
          ]}
        />
      </Col>

      <Col xs={12} md={8}>
        <InputField
          label="Nature of Job Handled:"
          type="text"
          value={entry.relevantNatureOfJobHandled}
          max={500}
          onChange={(e) => {
            const value = e.target.value;
            handleInputChange(
              outerIndex,
              null,
              "relevantNatureOfJobHandled",
              value,
              relevantIndex
            );
            checkErrorFn(
              `relevantNatureOfJobHandled${outerIndex}${relevantIndex}`,
              value,
              outerIndex,
              relevantIndex
            );
          }}

          onBlur={() =>
            checkErrorFn(
              `relevantNatureOfJobHandled${outerIndex}${relevantIndex}`,
              entry.relevantNatureOfJobHandled,
              outerIndex,
              relevantIndex
            )
          }
          error={
            fieldErrors?.[`relevantNatureOfJobHandled${outerIndex}${relevantIndex}`]?.errors
          }
          style={style}
          required
        
        />
      </Col>
      </Row>
      <Row>

      <Col xs={12} md={4}>
        <InputField
          label="From:"
          type="date"
          value={entry.startDateOfService}
          onChange={(e) => {
            const value = e.target.value;
            handleInputChange(
              outerIndex,
              null,
              "startDateOfService",
              value,
              relevantIndex
            );
            localCheckErrorFn(
              `startDateOfService${outerIndex}${relevantIndex}`,
              value
            );
          }}
          onBlur={() =>
            localCheckErrorFn(
              `startDateOfService${outerIndex}${relevantIndex}`,
              entry.startDateOfService
            )
          }
          error={
            fieldErrors?.[
              `startDateOfService${outerIndex}${relevantIndex}`
            ]?.errors
          }
          style={style}
          required
          min={parentStartDate}
        />
      </Col>

      {/* To Date */}
      <Col xs={12} md={4}>
        <InputField
          label="To:"
          type="date"
          value={entry.endDateOfService}
          onChange={(e) => {
            const value = e.target.value;
            handleInputChange(
              outerIndex,
              null,
              "endDateOfService",
              value,
              relevantIndex
            );
            localCheckErrorFn(
              `endDateOfService${outerIndex}${relevantIndex}`,
              value
            );
          }}
          onBlur={() =>
            localCheckErrorFn(
              `endDateOfService${outerIndex}${relevantIndex}`,
              entry.endDateOfService
            )
          }
          error={
            fieldErrors?.[
              `endDateOfService${outerIndex}${relevantIndex}`
            ]?.errors
          }
          style={style}
          required
          min={parentStartDate}
          max={parentEndDate}
        />
      </Col>

      {/* Total Experience */}
      <Col xs={10} md={3}>
        <InputField
          label="Total Experience:"
          value={entry.totalExperience}
          onChange={() => {
            // Read-only field; no action needed
          }}
          style={style}
          required
          readOnly={true}
        />
      </Col>
      <Col xs={1} className="mt-4 text-end">
        <Button
          variant="foreground-alternate"
          size="lg"
          className="mt-0 pt-0"
          onClick={() => deleteRelevantExperience(outerIndex, relevantIndex)}
        >
          <RiDeleteBin6Line />
        </Button>
      </Col>
      </Row>

      {/* From Date */}
     

      {/* Delete Button */}
     
    </Row>
  );
};

export default RelevantExperienceEntry;
