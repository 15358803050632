import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';

async function Upload(file) {
    try {
        const file_name = Math.random().toString(36).substring(6) + '_uploads.png';

        const config = {
            endpoint: new AWS.Endpoint('blr1.digitaloceanspaces.com'), // e.g., 'nyc3.digitaloceanspaces.com'
            accessKeyId: 'DO002LEBDZP7CX647E47',
            secretAccessKey: '+7A2cDpxBhzPAo9OY+TCvLNLeVFzQInTY/dKZzBd+tc',
            signatureVersion: 'v4',
        };

        const s3 = new AWS.S3(config);

        const params = {
            Bucket: 'esevabemc',
            Key: file_name,
            Body: file,
            ContentType: file.type,
            ACL: 'public-read',
        };

        const uploadResult = await s3.upload(params).promise();

        const fileUrl = `https://${config.endpoint.host}/${params.Bucket}/${params.Key}`;
        console.log("file url: ", fileUrl);
        return fileUrl;
    } catch (error) {
        console.error('Error uploading the file:', error);
        return null;
    }
}




export async function UploadPdf(filepath) {

    const blob = await fetch(filepath).then((r) => r.blob());
    const file_name = Math.random().toString(36).substring(6) + '_uploads.pdf';
    const file_object = new File([blob], file_name, { type: 'application/pdf' });

    const config = {
        endpoint: new AWS.Endpoint('blr1.digitaloceanspaces.com'),
        accessKeyId: 'DO002LEBDZP7CX647E47',
        secretAccessKey: '+7A2cDpxBhzPAo9OY+TCvLNLeVFzQInTY/dKZzBd+tc',
        signatureVersion: 'v4',
    };

    const s3 = new AWS.S3(config);

    try {
        const params = {
            Bucket: 'esevabemc',
            Key: file_name,
            Body: file_object,
            ContentType: 'application/pdf',
            ACL: 'public-read',
        };

        await s3.upload(params).promise();

        const fileUrl = `https://${config.endpoint.host}/${params.Bucket}/${params.Key}`;
        console.log("fileurl==", fileUrl)
        return fileUrl;
    } catch (error) {
        console.error('Error uploading the file:', error);
        return null;
    }
}


export async function UploadToAWS(file) {
    try {
        const a = await UploadPdf(file);
        console.log({ a })
        return a;
    } catch (error) {
        console.log(error);
    }
}

export async function AWSUpload(file) {
    try {
        AWS.config.update({
            endpoint: new AWS.Endpoint('blr1.digitaloceanspaces.com'), // e.g., 'nyc3.digitaloceanspaces.com'
            accessKeyId: 'DO002LEBDZP7CX647E47',
            secretAccessKey: '+7A2cDpxBhzPAo9OY+TCvLNLeVFzQInTY/dKZzBd+tc',
            signatureVersion: 'v4',
            region: 'ap-south-1',
        });

        const s3 = new AWS.S3();

        const params = {
            Bucket: 'esevabemc',
            Key: uuidv4() + file.name.split(".")[file.name.split(".").length-1],
            Body: file,
            ContentType: 'application/png',
            ACL: 'public-read',
        };
        const uploadedObject = await s3.upload(params).promise();
        const imageUrl = uploadedObject.Location;

        // const blobPath= handleFileConversion(file)
        // console.log("called aws", file)
        // const a = await Upload(blobPath);
        // // const ReactS3Client = new S3(config);
        // // await ReactS3Client.uploadFile(file, newFileName);
        // // const a = await ReactS3Client.uploadFile(file, newFileName);
        console.log({ imageUrl })
        return imageUrl;
    } catch (error) {
        console.log(error);
    }
}

export default Upload