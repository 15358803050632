import bhubaneswar from "../images/locations/bhubaneswar.jpeg";
import Sundargarh from "../images/locations/Sundargarh.jpg";
import jajpur from "../images/locations/jajpur.jpeg";
import keonjhar from "../images/locations/keo.jpg";
import khordha from "../images/locations/khordha.jpeg"
import koraput from "../images/locations/koraput.jpeg";
import Omcqr from "../images/omcqrcode/omcQr.png"


const datadept = [
  {
    "id": 1,
    "title": "Mining Department",
    "jobs": [
      {
        "id": 1,
        "img": Omcqr,
        "position": "Mining Engineer",
        "salary": "₹70,000 - ₹100,000 per month",
        "location": "Sundargarh",
        "days_left_to_apply": 25,
        "vacancies": 115,
        "start_date": "2024-04-01",
        "end_date": "2024-04-11",
        "no_of_people_applied": 100,
        "description": "The Mining Engineer is responsible for planning, designing, and supervising mining operations. They oversee the extraction of minerals, ensuring efficiency and compliance with safety and environmental regulations.",
        "qualification": "Bachelor's degree in Mining Engineering or a related field",
        "work_experience": "Experience in mine planning and operations",
        "job_details": [
          "Plan and design mining operations",
          "Supervise extraction of minerals",
          "Ensure compliance with safety and environmental regulations"
        ],
        "sc": 20,
        "st": 15,
        "ur": 50,
        "women": 30
      },
      {
        "id": 2,
        "img": Omcqr,
        "position": "Safety Officer",
        "salary": "₹60,000 - ₹85,000 per month",
        "location": "Koraput",
        "days_left_to_apply": 25,
        "vacancies": 200,
        "start_date": "2024-04-03",
        "end_date": "2024-04-11",
        "no_of_people_applied": 185,
        "description": "The Safety Officer ensures the safety of workers and compliance with safety regulations in mining operations.",
        "qualification": "Bachelor's degree in Occupational Health and Safety or a related field",
        "work_experience": "Relevant certifications and experience in safety management",
        "job_details": [
          "Conduct safety inspections",
          "Implement safety procedures",
          "Provide safety training to employees"
        ],
        "sc": 100,
        "st": 30,
        "ur": 50,
        "women": 20
      },
      {
        "id": 3,
        "img": Omcqr,
        "position": "Environmental Engineer",
        "salary": "₹65,000 - ₹95,000 per month",
        "location": "Khordha",
        "days_left_to_apply": 25,
        "vacancies": 350,
        "start_date": "2024-04-02",
        "end_date": "2024-04-11",
        "no_of_people_applied": 200,
        "description": "The Environmental Engineer is responsible for managing environmental aspects of mining operations.",
        "qualification": "Bachelor's degree in Environmental Engineering or a related field",
        "work_experience": "Knowledge of environmental regulations and experience in environmental management",
        "job_details": [
          "Assess environmental impacts",
          "Develop mitigation strategies",
          "Ensure compliance with environmental regulations"
        ],
        "sc": 150,
        "st": 50, 
        "ur": 100,
        "women": 50
      }
    ],
    "unit": "3 Jobs available",
    "portal": "OMC Job Portal"
  },

  {
    "id": 2,
    "title": "Exploration Department",
    "jobs": [
      {
        "id": 4,
        "position": "Geologist",
        "img": Omcqr,
        "salary": "₹60,000 - ₹90,000",
        "location": "Sundargarh",
        "days_left_to_apply": 25,
        "vacancies": "903",
        "start_date": "2024-04-03",
        "end_date": "2024-04-10",
        "no_of_people_applied": 400,
        "description": "The Geologist plays a crucial role in the Exploration Department by conducting geological surveys and analyzing rock formations to identify potential mineral deposits. They work both in the field and office settings, collecting and interpreting geological data. Candidates for this position should have a Bachelor's degree in Geology or a related field, strong analytical skills, and experience in fieldwork and geological mapping.",
        "qualification": "Bachelor's degree in Geology or a related field",
        "work_experience": "Experience in fieldwork and geological mapping",
        "job_details": [
          "Conduct geological surveys",
          "Analyze rock formations",
          "Interpret geological data"
        ],
        "sc": 150,
        "st": 50, 
        "ur": 100, 
        "women": 40 
      },
      {
        "id": 5,
        "position": "Exploration Geophysicist",
        "img": Omcqr,
        "salary": "₹70,000 - ₹100,000",
        "location": "Koraput",
        "days_left_to_apply": 25,
        "vacancies": 600,
        "start_date": "2024-04-01",
        "end_date": "2024-04-11",
        "no_of_people_applied": 750,
        "description": "The Exploration Geophysicist is responsible for conducting geophysical surveys and interpreting data to locate subsurface mineral resources. They work in the field and office, using specialized equipment and software to analyze seismic, gravity, and magnetic data. Ideal candidates should have a Bachelor's degree in Geophysics or a related field, proficiency in geophysical techniques, and experience in exploration geophysics.",
        "qualification": "Bachelor's degree in Geophysics or a related field",
        "work_experience": "Experience in exploration geophysics",
        "job_details": [
          "Conduct geophysical surveys",
          "Interpret seismic, gravity, and magnetic data",
          "Use specialized equipment and software"
        ],
        "sc": 250,
        "st": 100, 
        "ur": 200, 
        "women": 50 
      },
      {
        "id": 6,
        "position": "Field Technician",
        "img": Omcqr,
        "salary": "₹50,000 - ₹70,000",
        "location": "Bhubanswar",
        "days_left_to_apply": 25,
        "vacancies": 500,
        "start_date": "2024-04-02",
        "end_date": "2024-04-10",
        "no_of_people_applied": 200,
        "description": "The Field Technician supports exploration activities by assisting geologists and geophysicists in fieldwork and data collection. They are responsible for equipment setup, sample collection, and data recording in the field. Candidates for this position should have a diploma or associate degree in a relevant field and preferably some experience in fieldwork or geoscience research.",
        "qualification": "Diploma or associate degree in a relevant field",
        "work_experience": "Preferably some experience in fieldwork or geoscience research",
        "job_details": [
          "Assist geologists and geophysicists in fieldwork",
          "Set up equipment",
          "Collect and record data"
        ],
        "sc": 200,
        "st": 150, 
        "ur": 50, 
        "women": 100 
      },
      {
        "id": 7,
        "position": "Drilling Engineer",
        "img": Omcqr,
        "salary": "₹80,000 - ₹120,000",
        "location": "Jajpur",
        "days_left_to_apply": 25,
        "vacancies": 550,
        "start_date": "2024-04-04",
        "end_date": "2024-04-10",
        "no_of_people_applied": 50,
        "description": "The Drilling Engineer is responsible for planning and overseeing drilling operations to extract core samples and evaluate subsurface conditions. They design drilling programs, supervise drilling crews, and ensure safety and efficiency in drilling operations. Ideal candidates should have a Bachelor's degree in Engineering, preferably in Petroleum or Mining Engineering, along with experience in drilling operations and knowledge of drilling techniques and equipment.",
        "qualification": "Bachelor's degree in Engineering, preferably in Petroleum or Mining Engineering",
        "work_experience": "Experience in drilling operations and knowledge of drilling techniques and equipment",
        "job_details": [
          "Plan drilling operations",
          "Oversee drilling crews",
          "Ensure safety and efficiency"
        ],
        "sc": 300,
        "st": 100, 
        "ur": 50, 
        "women": 100 
      }
    ],
    "unit": "4 Jobs available",
    "portal": "OMC Job Portal"
  }
  ,
  {
    "id": 3,
    "title": "Production Department",
    "jobs": [
      {
        "id": 8,
        "img": Omcqr,
        "position": "Production Supervisor",
        "salary": "₹65,000 - ₹90,000",
        "location": "Keonjhar",
        "days_left_to_apply": 3,
        "vacancies": "615",
        "start_date": "2024-04-06",
        "end_date": "2024-04-30",
        "no_of_people_applied": 300,
        "description": "The Production Supervisor oversees the manufacturing process in a factory, ensuring efficiency, quality, and safety standards are met. They manage production schedules, allocate resources, and coordinate with other departments to meet production targets. Ideal candidates should have a Bachelor's degree in Engineering or relevant field, along with supervisory experience in a manufacturing environment.",
        "qualification": "Bachelor's degree in Engineering or relevant field",
        "work_experience": "Supervisory experience in a manufacturing environment",
        "job_details": [
          "Oversee manufacturing process",
          "Manage production schedules",
          "Ensure quality and safety standards"
        ]
        
      },
      {
        "id": 9,
        "img": Omcqr,
        "position": "Plant Operator",
        "salary": "₹45,000 - ₹60,000",
        "location": "Koraput",
        "days_left_to_apply": 3,
        "vacancies": "816",
        "start_date": "2024-04-09",
        "end_date": "2024-04-27",
        "no_of_people_applied": 400,
        "description": "The Plant Operator is responsible for operating and maintaining machinery and equipment in a factory. They monitor production processes, troubleshoot equipment malfunctions, and ensure smooth operations. Candidates for this position should have a high school diploma or equivalent, along with technical training or experience in industrial machinery operation.",
        "qualification": "High school diploma or equivalent",
        "work_experience": "Technical training or experience in industrial machinery operation",
        "job_details": [
          "Operate and maintain machinery",
          "Monitor production processes",
          "Troubleshoot equipment malfunctions"
        ]
      },
      {
        "id": 10,
        "img": Omcqr,
        "position": "Quality Control Technician",
        "salary": "₹50,000 - ₹70,000",
        "location": "Khordha",
        "days_left_to_apply": 3,
        "vacancies": "900",
        "start_date": "2024-04-05",
        "end_date": "2024-04-27",
        "no_of_people_applied": 250,
        "description": "The Quality Control Technician is responsible for inspecting and testing products to ensure they meet quality standards. They perform visual inspections, measurements, and tests on raw materials and finished goods, documenting results and identifying any defects or deviations from specifications. Candidates should have a diploma or degree in a related field, along with attention to detail and analytical skills.",
        "qualification": "Diploma or degree in a related field",
        "work_experience": "Attention to detail and analytical skills",
        "job_details": [
          "Inspect and test products",
          "Document results",
          "Identify defects or deviations"
        ]
      },
      {
        "id": 11,
        "img": Omcqr,
        "position": "Process Engineer",
        "salary": "₹75,000 - ₹110,000",
        "location": "Jajpur",
        "days_left_to_apply": 3,
        "vacancies": "900",
        "start_date": "2024-04-02",
        "end_date": "2024-04-30",
        "no_of_people_applied": 500,
        "description": "The Process Engineer designs, develops, and optimizes manufacturing processes and systems to improve efficiency and productivity. They analyze production data, identify areas for improvement, and implement process enhancements. Ideal candidates should have a Bachelor's degree in Engineering or relevant field, along with experience in process engineering and continuous improvement methodologies.",
        "qualification": "Bachelor's degree in Engineering or relevant field",
        "work_experience": "Experience in process engineering and continuous improvement methodologies",
        "job_details": [
          "Design and develop manufacturing processes",
          "Analyze production data",
          "Implement process enhancements"
        ]
      }
    ],
    "unit": "4 Jobs available",
    "portal": "OMC Job Portal"
  }
  ,
  {
    "id": 4,
    "title": "Marketing Department",
    "jobs": [
      {
        "id": 12,
        "img": Omcqr,
        "position": "Sales Manager",
        "salary": "₹80,000 - ₹120,000",
        "location": "Koraput",
        "days_left_to_apply": 25,
        "vacancies": "900",
        "start_date": "2024-04-02",
        "end_date": "2024-04-10",
        "no_of_people_applied": 300,
        "description": "The Sales Manager leads and coordinates the sales team to achieve revenue targets. They develop sales strategies, identify new business opportunities, and build strong relationships with clients. Ideal candidates should have a Bachelor's degree in Marketing or Business Administration, along with proven experience in sales management and a track record of meeting or exceeding sales goals.",
        "qualification": "Bachelor's degree in Marketing or Business Administration",
        "work_experience": "Proven experience in sales management",
        "job_details": [
          "Lead and coordinate sales team",
          "Develop sales strategies",
          "Build strong client relationships"
        ]
      },
      {
        "id": 13,
        "img": Omcqr,
        "position": "Marketing Specialist",
        "salary": "₹55,000 - ₹80,000",
        "location": "Bhubaneswar",
        "days_left_to_apply": 25,
        "vacancies": "500",
        "start_date": "2024-04-04",
        "end_date": "2024-04-10",
        "no_of_people_applied": 150,
        "description": "The Marketing Specialist designs and implements marketing campaigns to promote products or services. They conduct market research, analyze consumer behavior, and develop strategies to reach target audiences. Candidates should have a Bachelor's degree in Marketing or a related field, along with experience in marketing and strong communication skills.",
        "qualification": "Bachelor's degree in Marketing or a related field",
        "work_experience": "Experience in marketing",
        "job_details": [
          "Design and implement marketing campaigns",
          "Conduct market research",
          "Develop strategies to reach target audiences"
        ]
      },
      {
        "id": 14,
        "img": Omcqr,
        "position": "Customer Relationship Manager",
        "salary": "₹60,000 - ₹90,000",
        "location": "Khordha",
        "days_left_to_apply": 25,
        "vacancies": "940",
        "start_date": "2024-04-01",
        "end_date": "2024-04-10",
        "no_of_people_applied": 400,
        "description": "The Customer Relationship Manager maintains and enhances relationships with customers to maximize satisfaction and retention. They handle inquiries, resolve complaints, and ensure timely delivery of products or services. Candidates should have a Bachelor's degree in Business Administration or a related field, along with experience in customer service and relationship management.",
        "qualification": "Bachelor's degree in Business Administration or a related field",
        "work_experience": "Experience in customer service and relationship management",
        "job_details": [
          "Maintain and enhance customer relationships",
          "Handle inquiries and resolve complaints",
          "Ensure timely delivery of products or services"
        ]
      }
    ],
    "unit": "3 Jobs available",
    "portal": "OMC Job Portal"
  }
  ,
  {
    "id": 5,
    "title": "Finance Department",
    "jobs": [
      {
        "id": 15,
        "img": Omcqr,
        "position": "Financial Analyst",
        "salary": "$70,000 - $100,000",
        "location": "Koraput",
        "days_left_to_apply": 25,
        "vacancies": 1000,
        "start_date": "2024-04-03",
        "end_date": "2024-04-10",
        "no_of_people_applied": 500,
        "description": "The Financial Analyst analyzes financial data to provide insights and recommendations for strategic decision-making. They evaluate investment opportunities, assess financial risks, and prepare financial reports. Ideal candidates should have a Bachelor's degree in Finance or Accounting, along with strong analytical skills and proficiency in financial modeling.",
        "qualification": "Bachelor's degree in Finance or Accounting",
        "work_experience": "Strong analytical skills and proficiency in financial modeling",
        "job_details": [
          "Analyze financial data",
          "Evaluate investment opportunities",
          "Prepare financial reports"
        ]
      },
      {
        "id": 16,
        "img": Omcqr,
        "position": "Accountant",
        "salary": "₹55,000 - ₹80,000",
        "location": "Bhubaneswar",
        "days_left_to_apply": 25,
        "vacancies": 200,
        "start_date": "2024-04-05",
        "end_date": "2024-04-10",
        "no_of_people_applied": 100,
        "description": "The Accountant manages financial transactions, prepares financial statements, and ensures compliance with accounting standards. They handle accounts payable, accounts receivable, and reconcile financial records. Candidates should have a Bachelor's degree in Accounting or Finance, along with relevant experience and knowledge of accounting principles.",
        "qualification": "Bachelor's degree in Accounting or Finance",
        "work_experience": "Relevant experience and knowledge of accounting principles",
        "job_details": [
          "Manage financial transactions",
          "Prepare financial statements",
          "Ensure compliance with accounting standards"
        ]
      },
      {
        "id": 17,
        "img": Omcqr,
        "position": "Budget Analyst",
        "salary": "₹65,000 - ₹95,000",
        "location": "Jajpur",
        "days_left_to_apply": 25,
        "vacancies": 200,
        "start_date": "2024-04-02",
        "end_date": "2024-04-10",
        "no_of_people_applied": 80,
        "description": "The Budget Analyst assists in developing and managing organizational budgets. They analyze financial data, forecast future budget needs, and monitor budget performance. Candidates should have a Bachelor's degree in Finance, Economics, or a related field, along with experience in budgeting and financial analysis.",
        "qualification": "Bachelor's degree in Finance, Economics, or a related field",
        "work_experience": "Experience in budgeting and financial analysis",
        "job_details": [
          "Assist in developing organizational budgets",
          "Forecast future budget needs",
          "Monitor budget performance"
        ]
      },
      {
        "id": 18,
        "img": Omcqr,
        "position": "Tax Specialist",
        "salary": "₹60,000 - ₹85,000",
        "location": "Keonjhar",
        "days_left_to_apply": 25,
        "vacancies": 300,
        "start_date": "2024-04-04",
        "end_date": "2024-04-10",
        "no_of_people_applied": 150,
        "description": "The Tax Specialist prepares and files tax returns, ensures compliance with tax laws, and provides tax advice to individuals or organizations. They analyze tax regulations, identify tax-saving opportunities, and resolve tax-related issues. Candidates should have a Bachelor's degree in Accounting, Finance, or Taxation, along with relevant experience in tax preparation and compliance.",
        "qualification": "Bachelor's degree in Accounting, Finance, or Taxation",
        "work_experience": "Relevant experience in tax preparation and compliance",
        "job_details": [
          "Prepare and file tax returns",
          "Ensure compliance with tax laws",
          "Provide tax advice"
        ]
      }
    ],
    "unit": "4 Jobs available",
    "portal": "OMC Job Portal"
  }
  ,

  {
    "id": 6,
    "title": "Human Resources Department",
    "jobs": [
      {
        "id": 19,
        "img": Omcqr,
        "position": "HR Manager",
        "salary": "₹80,000 - ₹120,000",
        "location": "Jajpur",
        "days_left_to_apply": 25,
        "vacancies": 300,
        "start_date": "2024-04-02",
        "end_date": "2024-04-11",
        "no_of_people_applied": 120,
        "description": "The HR Manager oversees all aspects of human resources management, including recruitment, training, performance evaluation, and employee relations. They develop HR policies, ensure compliance with labor laws, and foster a positive work environment. Candidates should have a Bachelor's degree in Human Resources or a related field, along with proven experience in HR management.",
        "qualification": "Bachelor's degree in Human Resources or a related field",
        "work_experience": "Proven experience in HR management",
        "job_details": [
          "Oversee recruitment, training, and performance evaluation",
          "Develop HR policies",
          "Ensure compliance with labor laws"
        ]
      },
      {
        "id": 20,
        "img": Omcqr,
        "position": "Recruiter",
        "salary": "₹50,000 - ₹70,000",
        "location": "Keonjhar",
        "days_left_to_apply": 25,
        "vacancies": 870,
        "start_date": "2024-04-05",
        "end_date": "2024-04-11",
        "no_of_people_applied": 450,
        "description": "The Recruiter is responsible for sourcing, screening, and hiring qualified candidates to meet organizational staffing needs. They develop recruitment strategies, conduct interviews, and negotiate job offers. Candidates should have a Bachelor's degree in Human Resources, Psychology, or a related field, along with excellent communication and interpersonal skills.",
        "qualification": "Bachelor's degree in Human Resources, Psychology, or a related field",
        "work_experience": "Experience in recruitment",
        "job_details": [
          "Source and screen qualified candidates",
          "Develop recruitment strategies",
          "Conduct interviews and negotiate job offers"
        ]
      },
      {
        "id": 21,
        "img": Omcqr,
        "position": "Training Coordinator",
        "salary": "₹55,000 - ₹80,000",
        "location": "Koraput",
        "days_left_to_apply": 25,
        "vacancies": 450,
        "start_date": "2024-04-04",
        "end_date": "2024-04-11",
        "no_of_people_applied": 200,
        "description": "The Training Coordinator develops and implements training programs to enhance employee skills and performance. They assess training needs, design training materials, and coordinate training sessions. Candidates should have a Bachelor's degree in Training and Development, Human Resources, or a related field, along with experience in training coordination and instructional design.",
        "qualification": "Bachelor's degree in Training and Development, Human Resources, or a related field",
        "work_experience": "Experience in training coordination and instructional design",
        "job_details": [
          "Develop and implement training programs",
          "Assess training needs",
          "Coordinate training sessions"
        ]
      },
      {
        "id": 22,
        "img": Omcqr,
        "position": "Compensation and Benefits Specialist",
        "salary": "₹60,000 - ₹90,000",
        "location": "Jajpur",
        "days_left_to_apply": 25,
        "vacancies": 200,
        "start_date": "2024-04-03",
        "end_date": "2024-04-11",
        "no_of_people_applied": 80,
        "description": "The Compensation and Benefits Specialist designs and administers employee compensation and benefits programs. They conduct salary surveys, analyze compensation data, and ensure competitive pay and benefits offerings. Candidates should have a Bachelor's degree in Human Resources, Business Administration, or a related field, along with experience in compensation analysis and benefits administration.",
        "qualification": "Bachelor's degree in Human Resources, Business Administration, or a related field",
        "work_experience": "Experience in compensation analysis and benefits administration",
        "job_details": [
          "Design and administer employee compensation and benefits programs",
          "Conduct salary surveys",
          "Ensure competitive pay and benefits offerings"
        ]
      }
    ],
    "unit": "4 Jobs available",
    "portal": "OMC Job Portal"
  }
  ,
  {
    "id": 7,
    "title": "Environmental Management Department",
    "jobs": [
      {
        "id": 23,
        "img": Omcqr,
        "position": "Environmental Engineer",
        "salary": "₹65,000 - ₹95,000",
        "location": "Keonjhar",
        "days_left_to_apply": 25,
        "vacancies": "100",
        "start_date": "2024-04-01",
        "end_date": "2024-04-08",
        "no_of_people_applied": 40,
        "description": "The Environmental Engineer is responsible for designing and implementing environmental management plans to ensure compliance with regulatory requirements and minimize environmental impact. They conduct environmental assessments, develop pollution prevention strategies, and oversee remediation projects. Candidates should have a Bachelor's degree in Environmental Engineering or a related field, along with knowledge of environmental regulations and assessment techniques.",
        "qualification": "Bachelor's degree in Environmental Engineering or a related field",
        "work_experience": "Experience in environmental management",
        "job_details": [
          "Design and implement environmental management plans",
          "Conduct environmental assessments",
          "Oversee remediation projects"
        ]
      },
      {
        "id": 24,
        "img": Omcqr,
        "position": "Environmental Scientist",
        "salary": "₹60,000 - ₹85,000",
        "location": "Bhubaneswar",
        "days_left_to_apply": 25,
        "vacancies": "100",
        "start_date": "2024-04-02",
        "end_date": "2024-04-08",
        "no_of_people_applied": 30,
        "description": "The Environmental Scientist conducts research and analysis to assess environmental conditions and identify potential risks to ecosystems and human health. They collect and analyze samples, interpret data, and communicate findings to stakeholders. Candidates should have a Bachelor's degree in Environmental Science or a related field, along with strong analytical and problem-solving skills.",
        "qualification": "Bachelor's degree in Environmental Science or a related field",
        "work_experience": "Experience in environmental research and analysis",
        "job_details": [
          "Conduct research to assess environmental conditions",
          "Collect and analyze samples",
          "Communicate findings to stakeholders"
        ]
      },
      {
        "id": 25,
        "img": Omcqr,
        "position": "Environmental Compliance Officer",
        "salary": "₹55,000 - ₹80,000",
        "location": "jajpur",
        "days_left_to_apply": 25,
        "vacancies": "400",
        "start_date": "2024-04-01",
        "end_date": "2024-04-09",
        "no_of_people_applied": 100,
        "description": "The Environmental Compliance Officer ensures that organizations comply with environmental regulations and standards. They develop and implement compliance programs, conduct audits, and provide training to staff. Candidates should have a Bachelor's degree in Environmental Science, Environmental Management, or a related field, along with knowledge of environmental laws and regulations.",
        "qualification": "Bachelor's degree in Environmental Science, Environmental Management, or a related field",
        "work_experience": "Experience in environmental compliance",
        "job_details": [
          "Develop and implement compliance programs",
          "Conduct audits",
          "Provide training to staff on environmental regulations"
        ]
      }
    ],
    "unit": "3 Jobs available",
    "portal": "OMC Job Portal"
  },
  {
    "id": 8,
    "title": "Legal and Compliance Department",
    "jobs": [
      {
        "id": 26,
        "img": Omcqr,
        "position": "Legal Counsel",
        "salary": "₹90,000 - ₹150,000",
        "location": "khordha",
        "days_left_to_apply": 25,
        "vacancies": "100",
        "start_date": "2024-04-01",
        "end_date": "2024-04-11",
        "no_of_people_applied": 50,
        "description": "The Legal Counsel provides legal advice and representation to the organization on a wide range of legal issues. They draft and review contracts, agreements, and other legal documents, and represent the organization in legal proceedings. Candidates should have a law degree and be licensed to practice law, with experience in corporate law, contract law, and litigation.",
        "qualification": "Law degree and license to practice law",
        "work_experience": "Experience in corporate law, contract law, and litigation",
        "job_details": [
          "Provide legal advice and representation",
          "Draft and review legal documents",
          "Represent the organization in legal proceedings"
        ]
      },
      {
        "id": 27,
        "img": Omcqr,
        "position": "Compliance Officer",
        "salary": "₹70,000 - ₹110,000",
        "location": "Jajpur",
        "days_left_to_apply": 25,
        "vacancies": "400",
        "start_date": "2024-04-03",
        "end_date": "2024-04-11",
        "no_of_people_applied": 100,
        "description": "The Compliance Officer ensures that the organization complies with all relevant laws, regulations, and policies. They develop and implement compliance programs, conduct audits, and provide training to employees. Candidates should have a Bachelor's degree in law, business, or a related field, along with experience in compliance management.",
        "qualification": "Bachelor's degree in law, business, or a related field",
        "work_experience": "Experience in compliance management",
        "job_details": [
          "Develop and implement compliance programs",
          "Conduct audits",
          "Provide training to employees on compliance"
        ]
      },
      {
        "id": 28,
        "img": Omcqr,
        "position": "Contracts Specialist",
        "salary": "₹65,000 - ₹95,000",
        "location": "Jajpur",
        "days_left_to_apply": 25,
        "vacancies": "500",
        "start_date": "2024-04-04",
        "end_date": "2024-04-11",
        "no_of_people_applied": 80,
        "description": "The Contracts Specialist manages the organization's contracts and agreements, ensuring compliance with legal and regulatory requirements. They negotiate contract terms, review contract drafts, and facilitate contract execution. Candidates should have a Bachelor's degree in business, law, or a related field, along with experience in contract management.",
        "qualification": "Bachelor's degree in business, law, or a related field",
        "work_experience": "Experience in contract management",
        "job_details": [
          "Manage contracts and agreements",
          "Negotiate contract terms",
          "Facilitate contract execution"
        ]
      }
    ],
    "unit": "3 Jobs available",
    "portal": "OMC Job Portal"
  }

  ,

  {
    "id": 9,
    "title": "Information Technology (IT) Department",
    "jobs": [
      {
        "id": 26,
        "img": Omcqr,
        "position": "IT Manager",
        "salary": "₹85,000 - ₹130,000",
        "location": "Bhubaneswar",
        "days_left_to_apply": 25,
        "vacancies": "100",
        "no_of_people_applied": 50,
        "start_date": "2024-04-01",
        "end_date": "2024-04-20",
        "description": "The IT Manager oversees the organization's information technology initiatives and ensures that IT systems align with the organization's goals. They manage IT staff, develop IT policies and procedures, and oversee IT projects. Candidates should have a Bachelor's degree in computer science or a related field, along with several years of experience in IT management.",
        "qualification": "Bachelor's degree in computer science or a related field",
        "work_experience": "Several years of experience in IT management",
        "job_details": [
          "Oversee IT initiatives",
          "Manage IT staff",
          "Develop IT policies and procedures"
        ]
      },
      {
        "id": 27,
        "img": Omcqr,
        "position": "Network Administrator",
        "salary": "₹60,000 - ₹95,000",
        "location": "koraput",
        "days_left_to_apply": 25,
        "vacancies": "700",
        "no_of_people_applied": 200,
        "start_date": "2024-04-06",
        "end_date": "2024-04-10",
        "description": "The Network Administrator is responsible for designing, implementing, and managing the organization's computer networks. They ensure network security, troubleshoot network issues, and optimize network performance. Candidates should have a Bachelor's degree in computer science or a related field, along with certifications such as CCNA or CompTIA Network+.",
        "qualification": "Bachelor's degree in computer science or a related field",
        "work_experience": "Experience in network administration",
        "job_details": [
          "Design and implement computer networks",
          "Ensure network security",
          "Optimize network performance"
        ]
      },
      {
        "id": 28,
        "img": Omcqr,
        "position": "Software Developer",
        "salary": "₹70,000 - ₹110,000",
        "location": "Bhubaneswar",
        "days_left_to_apply": 25,
        "vacancies": "800",
        "no_of_people_applied": 400,
        "start_date": "2024-04-01",
        "end_date": "2024-04-20",
        "description": "The Software Developer designs, develops, and maintains software applications to meet the organization's needs. They collaborate with stakeholders to understand requirements, write code, and test software applications. Candidates should have a Bachelor's degree in computer science or a related field, along with proficiency in programming languages such as Java, Python, or C++.",
        "qualification": "Bachelor's degree in computer science or a related field",
        "work_experience": "Experience in software development",
        "job_details": [
          "Design and develop software applications",
          "Collaborate with stakeholders",
          "Test software applications"
        ]
      },
      {
        "id": 29,
        "img": Omcqr,
        "position": "Database Administrator",
        "salary": "₹75,000 - ₹120,000",
        "location": "keonjhar",
        "days_left_to_apply": 25,
        "vacancies": "900",
        "no_of_people_applied": 300,
        "start_date": "2024-04-01",
        "end_date": "2024-04-20",
        "description": "The Database Administrator is responsible for managing and maintaining the organization's databases. They design, implement, and optimize databases to ensure data integrity and performance. Candidates should have a Bachelor's degree in computer science or a related field, along with experience in database administration and knowledge of database management systems like MySQL, Oracle, or SQL Server.",
        "qualification": "Bachelor's degree in computer science or a related field",
        "work_experience": "Experience in database administration",
        "job_details": [
          "Manage and maintain databases",
          "Design and implement databases",
          "Optimize database performance"
        ]
      }
    ],
    "unit": "4 Jobs available",
    "portal": "OMC Job Portal"
  }
  ,
  {
    "id": 10,
    "title": "Safety Department",
    "jobs": [
      {
        "id": 30,
        "img": Omcqr,
        "position": "Safety Manager",
        "salary": "₹80,000 - ₹120,000",
        "location": "Khordha",
        "days_left_to_apply": 25,
        "vacancies": "900",
        "no_of_people_applied": 300,
        "start_date": "2024-04-01",
        "end_date": "2024-04-20",
        "description": "The Safety Manager oversees safety programs and initiatives to ensure a safe working environment for employees. They develop safety policies and procedures, conduct safety audits, and provide safety training. Candidates should have a Bachelor's degree in occupational health and safety or a related field, along with relevant experience in safety management.",
        "qualification": "Bachelor's degree in occupational health and safety or a related field",
        "work_experience": "Relevant experience in safety management",
        "job_details": [
          "Develop safety policies and procedures",
          "Conduct safety audits",
          "Provide safety training"
        ]
      },
      {
        "id": 31,
        "img": Omcqr,
        "position": "Safety Inspector",
        "salary": "₹55,000 - ₹85,000",
        "location": "Bhubaneswar",
        "days_left_to_apply": 25,
        "vacancies": "800",
        "no_of_people_applied": 150,
        "start_date": "2024-04-01",
        "end_date": "2024-04-20",
        "description": "The Safety Inspector conducts inspections and audits to ensure compliance with safety regulations and standards. They identify hazards, recommend corrective actions, and investigate accidents or incidents. Candidates should have a diploma or degree in occupational health and safety or a related field, along with experience in safety inspections.",
        "qualification": "Diploma or degree in occupational health and safety or a related field",
        "work_experience": "Experience in safety inspections",
        "job_details": [
          "Conduct safety inspections",
          "Identify hazards",
          "Investigate accidents or incidents"
        ]
      },
      {
        "id": 32,
        "img": Omcqr,
        "position": "Safety Trainer",
        "salary": "₹50,000 - ₹75,000",
        "location": "Khordha",
        "days_left_to_apply": 25,
        "vacancies": "600",
        "no_of_people_applied": 200,
        "start_date": "2024-04-01",
        "end_date": "2024-04-20",
        "description": "The Safety Trainer develops and delivers safety training programs to employees to promote safe work practices and compliance with safety regulations. They assess training needs, develop training materials, and conduct training sessions. Candidates should have experience in safety training and knowledge of safety regulations and procedures.",
        "qualification": "Experience in safety training",
        "work_experience": "Knowledge of safety regulations and procedures",
        "job_details": [
          "Develop safety training programs",
          "Assess training needs",
          "Conduct training sessions"
        ]
      }
    ],
    "unit": "3 Jobs available",
    "portal": "OMC Job Portal"
  },
  {
    "id": 8,
    "title": "Legal and Compliance Department",
    "jobs": [
      {
        "id": 26,
        "img": Omcqr,
        "position": "Legal Counsel",
        "salary": "₹90,000 - ₹150,000",
        "location": "khordha",
        "days_left_to_apply": 25,
        "vacancies": "100",
        "start_date": "2024-04-01",
        "end_date": "2024-04-11",
        "no_of_people_applied": 50,
        "description": "The Legal Counsel provides legal advice and representation to the organization on a wide range of legal issues. They draft and review contracts, agreements, and other legal documents, and represent the organization in legal proceedings. Candidates should have a law degree and be licensed to practice law, with experience in corporate law, contract law, and litigation.",
        "qualification": "Law degree and license to practice law",
        "work_experience": "Experience in corporate law, contract law, and litigation",
        "job_details": [
          "Provide legal advice and representation",
          "Draft and review legal documents",
          "Represent the organization in legal proceedings"
        ]
      },
      {
        "id": 27,
        "img": Omcqr,
        "position": "Compliance Officer",
        "salary": "₹70,000 - ₹110,000",
        "location": "Jajpur",
        "days_left_to_apply": 25,
        "vacancies": "400",
        "start_date": "2024-04-03",
        "end_date": "2024-04-11",
        "no_of_people_applied": 100,
        "description": "The Compliance Officer ensures that the organization complies with all relevant laws, regulations, and policies. They develop and implement compliance programs, conduct audits, and provide training to employees. Candidates should have a Bachelor's degree in law, business, or a related field, along with experience in compliance management.",
        "qualification": "Bachelor's degree in law, business, or a related field",
        "work_experience": "Experience in compliance management",
        "job_details": [
          "Develop and implement compliance programs",
          "Conduct audits",
          "Provide training to employees on compliance"
        ]
      },
      {
        "id": 28,
        "img": Omcqr,
        "position": "Contracts Specialist",
        "salary": "₹65,000 - ₹95,000",
        "location": "Jajpur",
        "days_left_to_apply": 25,
        "vacancies": "500",
        "start_date": "2024-04-04",
        "end_date": "2024-04-11",
        "no_of_people_applied": 80,
        "description": "The Contracts Specialist manages the organization's contracts and agreements, ensuring compliance with legal and regulatory requirements. They negotiate contract terms, review contract drafts, and facilitate contract execution. Candidates should have a Bachelor's degree in business, law, or a related field, along with experience in contract management.",
        "qualification": "Bachelor's degree in business, law, or a related field",
        "work_experience": "Experience in contract management",
        "job_details": [
          "Manage contracts and agreements",
          "Negotiate contract terms",
          "Facilitate contract execution"
        ]
      }
    ],
    "unit": "3 Jobs available",
    "portal": "OMC Job Portal"
  }

  ,

  {
    "id": 9,
    "title": "Information Technology (IT) Department",
    "jobs": [
      {
        "id": 26,
        "img": Omcqr,
        "position": "IT Manager",
        "salary": "₹85,000 - ₹130,000",
        "location": "Bhubaneswar",
        "days_left_to_apply": 25,
        "vacancies": "100",
        "no_of_people_applied": 50,
        "start_date": "2024-04-01",
        "end_date": "2024-04-20",
        "description": "The IT Manager oversees the organization's information technology initiatives and ensures that IT systems align with the organization's goals. They manage IT staff, develop IT policies and procedures, and oversee IT projects. Candidates should have a Bachelor's degree in computer science or a related field, along with several years of experience in IT management.",
        "qualification": "Bachelor's degree in computer science or a related field",
        "work_experience": "Several years of experience in IT management",
        "job_details": [
          "Oversee IT initiatives",
          "Manage IT staff",
          "Develop IT policies and procedures"
        ]
      },
      {
        "id": 27,
        "img": Omcqr,
        "position": "Network Administrator",
        "salary": "₹60,000 - ₹95,000",
        "location": "koraput",
        "days_left_to_apply": 25,
        "vacancies": "700",
        "no_of_people_applied": 200,
        "start_date": "2024-04-06",
        "end_date": "2024-04-10",
        "description": "The Network Administrator is responsible for designing, implementing, and managing the organization's computer networks. They ensure network security, troubleshoot network issues, and optimize network performance. Candidates should have a Bachelor's degree in computer science or a related field, along with certifications such as CCNA or CompTIA Network+.",
        "qualification": "Bachelor's degree in computer science or a related field",
        "work_experience": "Experience in network administration",
        "job_details": [
          "Design and implement computer networks",
          "Ensure network security",
          "Optimize network performance"
        ]
      },
      {
        "id": 28,
        "img": Omcqr,
        "position": "Software Developer",
        "salary": "₹70,000 - ₹110,000",
        "location": "Bhubaneswar",
        "days_left_to_apply": 25,
        "vacancies": "800",
        "no_of_people_applied": 400,
        "start_date": "2024-04-01",
        "end_date": "2024-04-20",
        "description": "The Software Developer designs, develops, and maintains software applications to meet the organization's needs. They collaborate with stakeholders to understand requirements, write code, and test software applications. Candidates should have a Bachelor's degree in computer science or a related field, along with proficiency in programming languages such as Java, Python, or C++.",
        "qualification": "Bachelor's degree in computer science or a related field",
        "work_experience": "Experience in software development",
        "job_details": [
          "Design and develop software applications",
          "Collaborate with stakeholders",
          "Test software applications"
        ]
      },
      {
        "id": 29,
        "img": Omcqr,
        "position": "Database Administrator",
        "salary": "₹75,000 - ₹120,000",
        "location": "keonjhar",
        "days_left_to_apply": 25,
        "vacancies": "900",
        "no_of_people_applied": 300,
        "start_date": "2024-04-01",
        "end_date": "2024-04-20",
        "description": "The Database Administrator is responsible for managing and maintaining the organization's databases. They design, implement, and optimize databases to ensure data integrity and performance. Candidates should have a Bachelor's degree in computer science or a related field, along with experience in database administration and knowledge of database management systems like MySQL, Oracle, or SQL Server.",
        "qualification": "Bachelor's degree in computer science or a related field",
        "work_experience": "Experience in database administration",
        "job_details": [
          "Manage and maintain databases",
          "Design and implement databases",
          "Optimize database performance"
        ]
      }
    ],
    "unit": "4 Jobs available",
    "portal": "OMC Job Portal"
  }
  ,
  {
    "id": 10,
    "title": "Safety Department",
    "jobs": [
      {
        "id": 30,
        "img": Omcqr,
        "position": "Safety Manager",
        "salary": "₹80,000 - ₹120,000",
        "location": "Khordha",
        "days_left_to_apply": 25,
        "vacancies": "900",
        "no_of_people_applied": 300,
        "start_date": "2024-04-01",
        "end_date": "2024-04-20",
        "description": "The Safety Manager oversees safety programs and initiatives to ensure a safe working environment for employees. They develop safety policies and procedures, conduct safety audits, and provide safety training. Candidates should have a Bachelor's degree in occupational health and safety or a related field, along with relevant experience in safety management.",
        "qualification": "Bachelor's degree in occupational health and safety or a related field",
        "work_experience": "Relevant experience in safety management",
        "job_details": [
          "Develop safety policies and procedures",
          "Conduct safety audits",
          "Provide safety training"
        ]
      },
      {
        "id": 31,
        "img": Omcqr,
        "position": "Safety Inspector",
        "salary": "₹55,000 - ₹85,000",
        "location": "Bhubaneswar",
        "days_left_to_apply": 25,
        "vacancies": "800",
        "no_of_people_applied": 150,
        "start_date": "2024-04-01",
        "end_date": "2024-04-20",
        "description": "The Safety Inspector conducts inspections and audits to ensure compliance with safety regulations and standards. They identify hazards, recommend corrective actions, and investigate accidents or incidents. Candidates should have a diploma or degree in occupational health and safety or a related field, along with experience in safety inspections.",
        "qualification": "Diploma or degree in occupational health and safety or a related field",
        "work_experience": "Experience in safety inspections",
        "job_details": [
          "Conduct safety inspections",
          "Identify hazards",
          "Investigate accidents or incidents"
        ]
      },
      {
        "id": 32,
        "img": Omcqr,
        "position": "Safety Trainer",
        "salary": "₹50,000 - ₹75,000",
        "location": "Khordha",
        "days_left_to_apply": 25,
        "vacancies": "600",
        "no_of_people_applied": 200,
        "start_date": "2024-04-01",
        "end_date": "2024-04-20",
        "description": "The Safety Trainer develops and delivers safety training programs to employees to promote safe work practices and compliance with safety regulations. They assess training needs, develop training materials, and conduct training sessions. Candidates should have experience in safety training and knowledge of safety regulations and procedures.",
        "qualification": "Experience in safety training",
        "work_experience": "Knowledge of safety regulations and procedures",
        "job_details": [
          "Develop safety training programs",
          "Assess training needs",
          "Conduct training sessions"
        ]
      }
    ],
    "unit": "3 Jobs available",
    "portal": "OMC Job Portal"
  }




];

export default datadept;
