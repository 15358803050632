import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal, Badge } from "react-bootstrap";
import pdfIcon from "../../assets/images/pdf-icon.png";
import { IoEyeOutline } from "react-icons/io5";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { UploadToAWS } from "../../Upload/newUploadLogic";

const MultipleFileUpload = ({
  data,
  outerIndex,
  handleInputChange,
  fieldErrors,
  setFieldErrors,
  style,
  checkErrorFn,
  applicationDetails,
  applicationID
}) => {
  const [uploads, setUploads] = useState(data.proofOfExperienceDocument || []);
  const [showIframe, setShowIframe] = useState(null);
  const [errorDoc, setErrorDoc] = useState([]);

  // Load uploads from localStorage on mount
  useEffect(() => {
    if (applicationID) {
      const savedUploads = localStorage.getItem(
        `proofOfExperience_${applicationID}_${outerIndex}`
      );
      if (savedUploads) {
        const parsedUploads = JSON.parse(savedUploads);
        setUploads(parsedUploads);
        // Update parent state
        // handleInputChange(
        //   outerIndex,
        //   null,
        //   "proofOfExperienceDocument",
        //   parsedUploads
        // );
      }
    }
  }, []);

  // Save uploads to localStorage whenever they change
  useEffect(() => {
    if (applicationID) {
      localStorage.setItem(
        `proofOfExperience_${applicationID}_${outerIndex}`,
        JSON.stringify(uploads)
      );
    }
    console.log("zpplidttt",{applicationID})
  }, [uploads]);

  // Update uploads when data.proofOfExperienceDocument changes from parent
  useEffect(() => {
    if (
      data.proofOfExperienceDocument &&
      data.proofOfExperienceDocument !== uploads
    ) {
      setUploads(data.proofOfExperienceDocument);
    }
  }, [data.proofOfExperienceDocument]);

  // Local validation function
  const localCheckErrorFn = (fieldKey, value) => {
    let errors = [];

    if (!value) {
      errors.push("This field is required.");
    }

    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [fieldKey]: {
        errors: errors,
        touched: true,
      },
    }));

    return errors.length === 0;
  };

  // Validate verification status on uploads change
  useEffect(() => {
    uploads.forEach((upload, index) => {
      if (!upload.proofofexperienceVerified && upload.fileUrl) {
        // Only set error if the user has uploaded a file but hasn't verified
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [`fileNotVerified${outerIndex}${index}`]: {
            errors: ["File must be verified."],
            touched: true,
          },
        }));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploads]);

  // Handle file upload
  const handleUploadFile = async (event, index) => {
    event.preventDefault();
    const file = event.target.files[0];
    const validTypes = ["application/pdf"];
    const maxSize = 2 * 1024 * 1024; // 2MB

    if (file) {
      // Validate file type
      if (!validTypes.includes(file.type)) {
        setErrorDoc((prevErrors) => {
          const errors = [...prevErrors];
          errors[index] = "*Please select a PDF file.";
          return errors;
        });
        event.target.value = null;
        return;
      }

      // Validate file size
      if (file.size > maxSize) {
        setErrorDoc((prevErrors) => {
          const errors = [...prevErrors];
          errors[index] = "*File size should be less than 2MB.";
          return errors;
        });
        event.target.value = null;
        return;
      }

      // Upload file to AWS and get URL
      const fileUrl = await UploadToAWS(file);

      // Update the specific upload data
      const updatedUploads = [...uploads];
      updatedUploads[index] = {
        ...updatedUploads[index],
        fileUrl,
        proofofexperienceVerified: false,
      };

      setUploads(updatedUploads);
      // Update parent state
      handleInputChange(
        outerIndex,
        null,
        "proofOfExperienceDocument",
        updatedUploads
      );

      // Clear any previous errors for this file
      setErrorDoc((prevErrors) => {
        const errors = [...prevErrors];
        errors[index] = "";
        return errors;
      });

      // Validate the fileUrl (mark as touched)
      localCheckErrorFn(
        `proofofexperience_${outerIndex}${index}`,
        fileUrl
      );
    }
  };

  // Handle input change for custom data (file name)
  const handleCustomDataChange = (event, index) => {
    const { value } = event.target;

    const updatedUploads = [...uploads];
    updatedUploads[index] = {
      ...updatedUploads[index],
      customData: value,
    };

    setUploads(updatedUploads);
    // Update parent state
    handleInputChange(
      outerIndex,
      null,
      "proofOfExperienceDocument",
      updatedUploads
    );

    // Validation will be triggered onBlur
  };

  // Add new upload data
  const handleAddUpload = () => {
    const updatedUploads = [
      ...uploads,
      { fileUrl: "", customData: "", proofofexperienceVerified: false },
    ];

    setUploads(updatedUploads);
    // Update parent state
    handleInputChange(
      outerIndex,
      null,
      "proofOfExperienceDocument",
      updatedUploads
    );

    setErrorDoc((prevErrors) => [...prevErrors, ""]);
  };

  // Delete upload data
  const handleDeleteUpload = (index) => {
    const updatedUploads = uploads.filter((_, i) => i !== index);

    setUploads(updatedUploads);
    // Update parent state
    handleInputChange(
      outerIndex,
      null,
      "proofOfExperienceDocument",
      updatedUploads
    );

    setErrorDoc((prevErrors) => prevErrors.filter((_, i) => i !== index));

    // Remove errors associated with this index
    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[`fileName${outerIndex}${index}`];
      delete updatedErrors[`proofofexperience_${outerIndex}${index}`];
      delete updatedErrors[`fileNotVerified${outerIndex}${index}`];
      return updatedErrors;
    });
  };

  // Confirm verification
  const confirmVerification = (index) => {
    const updatedUploads = [...uploads];
    updatedUploads[index].proofofexperienceVerified = true;

    setUploads(updatedUploads);
    // Update parent state
    handleInputChange(
      outerIndex,
      null,
      "proofOfExperienceDocument",
      updatedUploads
    );

    // Remove verification error
    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[`fileNotVerified${outerIndex}${index}`];
      return updatedErrors;
    });

    setShowIframe(null);
  };

  return (
    <>
      <div id="fileUpload">
        <label className="form-label inputBoxHeadForm" style={style.label}>
          Experience Certificate/Proof of Experience
          <span className="mx-1 text-danger">*</span>
        </label>
        {uploads.map((upload, index) => (
          <Row key={index} className="mt-1 pb-0 mb-0">
            <Col xs={12} md={2}>
              <div className="mb-0">
                <button
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    padding: "5px 15px",
                    fontSize: "12px",
                    cursor: "pointer",
                    margin: "15px 0",
                    border: "none",
                    backgroundColor:
                      fieldErrors?.[`proofofexperience_${outerIndex}${index}`]
                        ?.errors?.length > 0
                        ? "#921d1e" // Error color
                        : "#f0f0f0", // Default color
                    color:
                      fieldErrors?.[`proofofexperience_${outerIndex}${index}`]
                        ?.errors?.length > 0
                        ? "#fff"
                        : "#333",
                  }}
                  onClick={() =>
                    document
                      .getElementById(
                        `proofofexperience_${outerIndex}${index}`
                      )
                      .click()
                  }
                >
                  <FaPlus /> <span className="ps-2">Choose Files</span>
                </button>

                <input
                  type="file"
                  id={`proofofexperience_${outerIndex}${index}`}
                  accept=".pdf,.jpg,.jpeg,.png"
                  style={{ display: "none" }}
                  onChange={(e) => handleUploadFile(e, index)}
                  onBlur={() =>
                    localCheckErrorFn(
                      `proofofexperience_${outerIndex}${index}`,
                      uploads[index]?.fileUrl
                    )
                  }
                />

                {/* Display validation errors */}
                {fieldErrors?.[`proofofexperience_${outerIndex}${index}`]
                  ?.touched &&
                  fieldErrors?.[`proofofexperience_${outerIndex}${index}`]
                    ?.errors?.length > 0 && (
                    <span className="errorPresent" style={style.errorSpan}>
                      {
                        fieldErrors[
                          `proofofexperience_${outerIndex}${index}`
                        ]?.errors[0]
                      }
                      <br />
                    </span>
                  )}

                {/* Display file-specific errors */}
                {errorDoc[index] && (
                  <>
                    <span className="errorPresent" style={style.errorSpan}>
                      {errorDoc[index]}
                    </span>
                    <br />
                  </>
                )}
              </div>
            </Col>

            <Col xs={12} md={10}>
              {/* File preview, verification, and delete buttons */}
              {upload.fileUrl && (
                <>
                  <Row>
                    <Col xs={12} md={1}>
                      <img
                        src={pdfIcon}
                        alt="PDF preview"
                        style={{
                          width: "40px",
                          cursor: "pointer",
                          marginTop: "15px",
                        }}
                        onClick={() => setShowIframe(index)}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <input
                        style={style.input}
                        type="text"
                        className="mt-3"
                        placeholder="Enter File Name"
                        value={upload.customData}
                        onChange={(e) => handleCustomDataChange(e, index)}
                        onBlur={() =>
                          localCheckErrorFn(
                            `fileName${outerIndex}${index}`,
                            upload.customData
                          )
                        }
                      />
                      {fieldErrors?.[`fileName${outerIndex}${index}`]
                        ?.touched &&
                        fieldErrors?.[`fileName${outerIndex}${index}`]?.errors
                          ?.length > 0 && (
                          <span
                            className="errorPresent"
                            style={style.errorSpan}
                          >
                            {
                              fieldErrors[`fileName${outerIndex}${index}`]
                                ?.errors[0]
                            }
                          </span>
                        )}
                    </Col>
                    {!upload.proofofexperienceVerified && (
                      <Col xs={12} md={2}>
                        <Button
                          style={{ marginTop: "15px" }}
                          onClick={() => setShowIframe(index)}
                        >
                          <span className="ps-1">Verify</span>
                        </Button>
                      </Col>
                    )}
                    {upload.proofofexperienceVerified && (
                      <Col xs={12} md={2} style={{ marginTop: "18px" }}>
                        <Badge bg="success">
                          <FaCheckCircle size={"22"} className="me-2" />
                          Verified
                        </Badge>
                      </Col>
                    )}
                    <Col
                      xs={12}
                      md={2}
                      className="ms-0 ps-0 text-start"
                    >
                      <Button
                        style={{ marginTop: "12px" }}
                        variant="outline"
                        onClick={() => setShowIframe(index)}
                      >
                        <IoEyeOutline size={"22"} />
                      </Button>
                      <Button
                        variant="outline"
                        onClick={() => handleDeleteUpload(index)}
                        style={{ marginTop: "12px" }}
                      >
                        <MdDeleteOutline size={"22"} />
                      </Button>
                    </Col>

                    {showIframe === index && (
                      <Modal
                        size="lg"
                        show={showIframe === index}
                        onHide={() => setShowIframe(null)}
                        centered
                      >
                        <Modal.Body className="text-center">
                          <iframe
                            src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(
                              upload.fileUrl
                            )}`}
                            width="100%"
                            style={{ border: "none", height: "80vh" }}
                            title="PDF Viewer"
                          />
                          {!upload.proofofexperienceVerified && (
                            <Button
                              className="mt-2"
                              onClick={() => confirmVerification(index)}
                            >
                              Confirm Verification
                            </Button>
                          )}
                          <Button
                            className="mt-2"
                            variant="secondary"
                            onClick={() => setShowIframe(null)}
                          >
                            Close
                          </Button>
                        </Modal.Body>
                      </Modal>
                    )}
                  </Row>
                </>
              )}

              {/* Show error if file is not verified */}
              {fieldErrors?.[`fileNotVerified${outerIndex}${index}`]
                ?.touched &&
                fieldErrors?.[`fileNotVerified${outerIndex}${index}`]?.errors
                  ?.length > 0 && (
                  <span className="errorPresent" style={style.errorSpan}>
                    {
                      fieldErrors[`fileNotVerified${outerIndex}${index}`]
                        ?.errors[0]
                    }
                  </span>
                )}
            </Col>
          </Row>
        ))}
        <div className="text-end">
          <Button variant="primary" className="mt-3" onClick={handleAddUpload}>
            Add File
          </Button>
        </div>
      </div>
    </>
  );
};

export default MultipleFileUpload;
