import { toast } from 'react-toastify';
import { apiUrl } from './apiUrl';
import axios from 'axios';

// api call data required method,data,url as its parameter
export const callApi = async (apiCallData) => {
    var data = {
        ...apiCallData?.data, "api_key": "7gt67u7878b67bvr56"
    }
    
    const token = sessionStorage.getItem('token');
    let config = {
        method: apiCallData.method,
        url: `${apiUrl}${apiCallData.url}`,
        // url: https://backend.dev.bemc.teceads.co.in/api/v1/ramalingampark/event/getEvent,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*"
        },
        withCredentials: true,
        data: data
    }
    // axios.default.defaults.withCredentials = true;
    try {
        
        let response = await axios(config)
        console.log(response.data)
        if (response.data.code == 4001) {
            toast.error("Your session expired, login again to proceed",{autoClose:2000})
            setTimeout(() => {
                
                // window.location.assign(`/login`)
            }, 2000);
        } else {
            return (response)
        }
    } catch (error) {
        toast.error("User Authentication Failed, Signing Out.",{autoClose:2000})
            setTimeout(() => {
                
                // window.location.assign("/login")
            }, 2000);
    }

}