import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import waringSign from '../assets/images/warnOmc.png'


function FinalFormSubmitModal({ finalSubmitModal, setFinalSubmitModal, handleSubmitForm }) {
  console.log("abhisek")
  return (
    <>
      <Modal
        show={finalSubmitModal}
        onHide={() => setFinalSubmitModal(false)}
        dialogClassName="modal-20w"
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header style={{ background: '#921D1E', color: 'white' }}>
        <div className='d-flex align-items-center justify-content-center w-100'>
            <img src={waringSign} alt="warn" />
          </div>
          {/* <Modal.Title id="example-custom-modal-styling-title"></Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
        <h2 style={{ color: '#921D1E', fontSize: '28px', fontFamily: 'Roboto', textAlign: 'center' }}>Warning!</h2>
          <p className="text-center fw-bold" style={{ fontSize: '16px', marginTop: '20px', fontFamily: 'Roboto', textAlign: 'center' }}>
            Please verify all details. Once submitted, modifications are not allowed
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center align-items-center">
          <Button
            onClick={() => {
                setFinalSubmitModal(false);
              
            }}
            style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Roboto', textAlign: 'center', border: '1px solid #DEBBBB', color: '#921D1E', background: '#F9F3F4' }}
          >
            Go Back
          </Button>
          <Button
          
            onClick={() => {
                handleSubmitForm("Submit");
            }}
            style={{ fontSize: '16px', fontWeight: '600', fontFamily: 'Roboto', textAlign: 'center', border: '1px solid #DEBBBB', color: '#921D1E', background: '#F9F3F4' }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FinalFormSubmitModal;
