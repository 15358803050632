import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Alert, Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import waringSign from '../../assets/images/warnOmc.png'

const ExServicemen = ({ handelApplicationDetailsChange, applicationDetails, style, closingDate }) => {
    const [formData, setFormData] = useState({
        exServiceman: '',
        exServicemanDefenseService: '',
        exServicemanServiceStatus: '',
        exServicemanJoiningDate: '',
        exServicemanRetirementDate: '',
        exServicemanTotalServicePeriod: '',
        // exServicemanAvailedReservation: '',
        exServicemanAppliedForCivilEmployment: '',
        exServicemanDeclaration: false,
        exServicemanTotalServicePeriodContinuing: ""
    });
    const [fieldErrors, setFieldErrors] = useState({});

    const [sameSubCaderModal, setSameSubCaderModal] = useState(false)

    useEffect(() => {
        if (applicationDetails) {
            setFormData({
                exServiceman: applicationDetails?.exServiceman || '',
                exServicemanDefenseService: applicationDetails?.exServicemanDefenseService || '',
                exServicemanServiceStatus: applicationDetails?.exServicemanServiceStatus || '',
                exServicemanJoiningDate: applicationDetails?.exServicemanJoiningDate || '',
                exServicemanRetirementDate: applicationDetails?.exServicemanRetirementDate || '',
                exServicemanDeclaration: applicationDetails?.exServicemanDeclaration || "",
                exServicemanTotalServicePeriod: applicationDetails?.exServicemanTotalServicePeriod || '',
                // exServicemanAvailedReservation: applicationDetails?.exServicemanAvailedReservation || '',
                exServicemanAppliedForCivilEmployment: applicationDetails?.exServicemanAppliedForCivilEmployment || '',
                exServicemanTotalServicePeriodContinuing: applicationDetails?.exServicemanTotalServicePeriodContinuing || ""
            });
        }
    }, []);

    useEffect(() => {
        handelApplicationDetailsChange(formData);
    }, [formData]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const checkErrorFn = (camelCaseKey, immediateValue) => {
        let errCount = 0;

        // Check for required fields
        if (!immediateValue) {
            errCount++;
            setFieldErrors(prevState => ({
                ...prevState,
                [camelCaseKey]: {
                    errors: ["This field is required."],
                    touched: true
                }
            }));
        } else {
            switch (camelCaseKey) {
                case 'exServicemanJoiningDate':
                case 'exServicemanRetirementDate':
                    const regexDate = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
                    const isValidDate = regexDate.test(immediateValue);
                    if (!isValidDate) {
                        errCount++;
                        setFieldErrors(prevState => ({
                            ...prevState,
                            [camelCaseKey]: {
                                errors: ["Enter a valid date in the format YYYY-MM-DD."],
                                touched: true
                            }
                        }));
                    }
                    break;

                default:
                    setFieldErrors(prevState => ({
                        ...prevState,
                        [camelCaseKey]: {
                            errors: [],
                            touched: true
                        }
                    }));
                    break;
            }
        }

        if (errCount === 0) {
            setFieldErrors(prevState => ({
                ...prevState,
                [camelCaseKey]: {
                    errors: [],
                    touched: true
                }
            }));
        }
    };

    const advertisementClosingDate = new Date(closingDate);

    const calculateServicePeriodDischarge = (dischargeDate) => {
        if (!dischargeDate) return;

        const endDate = new Date(dischargeDate);
        const startDate = new Date(formData?.exServicemanJoiningDate);

        const totalYears = endDate.getFullYear() - startDate.getFullYear();
        const totalMonths = endDate.getMonth() - startDate.getMonth();
        const totalDays = endDate.getDate() - startDate.getDate();

        let years = totalYears;
        let months = totalMonths;
        let days = totalDays;

        if (days < 0) {
            months -= 1;
            days += new Date(endDate.getFullYear(), endDate.getMonth(), 0).getDate();
        }

        if (months < 0) {
            years -= 1;
            months += 12;
        }

        const totalServicePeriod = `${years} Years, ${months} Months, ${days} Days`;
        console.log("totalServicePeriod", totalServicePeriod)
        const totalMonth = (years * 12) + months + Math.floor(days / 30);

        setFormData((prevData) => ({
            ...prevData,
            exServicemanTotalServicePeriod: totalServicePeriod,
        }));

        if (totalMonth <= 6) {
            setSameSubCaderModal(true)
            setFormData((prevFormData) => ({
                ...prevFormData,
                exServiceman: 'No',
                exServicemanDefenseService: '',
                exServicemanServiceStatus: '',
                exServicemanJoiningDate: '',
                exServicemanRetirementDate: '',
                exServicemanTotalServicePeriod: '',
                exServicemanAppliedForCivilEmployment: '',
                exServicemanDeclaration: false,
                exServicemanTotalServicePeriodContinuing: ""
            }));
        }
    };

    function dateDifference(date2) {
        console.log({ date2 })
        let date1 = advertisementClosingDate
        let retirementDate = new Date(date2);

        if (date1 > retirementDate) {
            [date1, retirementDate] = [retirementDate, date1];
        }

        let years = retirementDate.getFullYear() - date1.getFullYear();
        let months = retirementDate.getMonth() - date1.getMonth();
        let days = retirementDate.getDate() - date1.getDate();

        if (days < 0) {
            months--;
            const prevMonth = new Date(retirementDate.getFullYear(), retirementDate.getMonth(), 0);
            days += prevMonth.getDate();
        }

        if (months < 0) {
            years--;
            months += 12;
        }

        const totalServicePeriod = `${years} Years, ${months} Months, ${days} Days`;
        console.log("totalServicePeriod", totalServicePeriod)

        ///////////////////////////////////////////////////////////////////////

        const endDate = new Date(formData?.exServicemanRetirementDate);
        const startDate = new Date(formData?.exServicemanJoiningDate);

        const totalYears = endDate.getFullYear() - startDate.getFullYear();
        const totalMonths = endDate.getMonth() - startDate.getMonth();
        const totalDays = endDate.getDate() - startDate.getDate();

        let year = totalYears;
        let month = totalMonths;
        let day = totalDays;

        if (days < 0) {
            months -= 1;
            days += new Date(endDate.getFullYear(), endDate.getMonth(), 0).getDate();
        }

        if (months < 0) {
            years -= 1;
            months += 12;
        }

        const totalServicePeriodContinue = `${year} Years, ${month} Months, ${day} Days`;
        console.log("totalServicePeriodContinue", totalServicePeriodContinue)
        const totalMonth = (year * 12) + month + Math.floor(days / 30);
        console.log("totalMonth", totalMonth)


        setFormData((prevData) => ({
            ...prevData,
            exServicemanTotalServicePeriodContinuing: totalServicePeriod,
        }));

        if ((totalMonth <= 6) || (years > 0 || months >= 6)) {
            setSameSubCaderModal(true)
            setFormData((prevFormData) => ({
                ...prevFormData,
                exServiceman: 'No',
                exServicemanDefenseService: '',
                exServicemanServiceStatus: '',
                exServicemanJoiningDate: '',
                exServicemanRetirementDate: '',
                exServicemanTotalServicePeriod: '',
                exServicemanAppliedForCivilEmployment: '',
                exServicemanDeclaration: false,
                exServicemanTotalServicePeriodContinuing: ""
            }));
        }
    }

    useEffect(() => {
        console.log("formdata in exservice man", formData)
    }, [formData])

    return (
        <>
            <Row>
                <Col xs={12} md={12}>
                    <div style={{ fontFamily: "'Roboto', sans-serif", color: "brown" }} className="fw-bolder mb-3">
                        <ol className="mt-2">
                            <li >
                                1. In case the applicant/candidate is currently serving in the defense forces, they may apply under the Ex-Serviceman category only if their total service period is six months or more and they can provide proof of discharge or retirement within six months from the last date of the advertisement. The total experience will be calculated as of the last date of application submission.
                            </li>
                            <li >
                                2. In case the applicant has already availed of age relaxation under the Ex-Serviceman category to secure a post, they will not be eligible for further age relaxation. However, they may still apply under the Ex-Serviceman category to avail of the reservation benefits.
                            </li>
                        </ol>
                    </div>
                </Col>
                <Col xs={window.innerWidth >= 793 ? 4 : 12} md={5}>
                    <div className="mb-1">
                        <label className="form-label inputBoxHeadForm" style={style.label}>
                            Are you an applicant under Ex-Serviceman Category?<span className="mx-1 text-danger">*</span>
                        </label>
                        <select
                            // className="form-control"
                            name="exServiceman"
                            value={formData.exServiceman}
                            onChange={(e) => {
                                handleInputChange(e);
                                checkErrorFn('exServiceman', e.target.value);
                            }}
                            onBlur={() => checkErrorFn('exServiceman', formData['exServiceman'])}
                            required
                            style={
                                fieldErrors?.['exServiceman']?.errors?.length > 0 && fieldErrors?.['exServiceman']?.touched
                                    ? { ...style.input, ...style.errorBox }
                                    : style.input
                            }
                        >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                        {fieldErrors?.['exServiceman']?.errors?.length > 0 && fieldErrors?.['exServiceman']?.touched &&
                            fieldErrors?.['exServiceman']?.errors.map((err) => (
                                <span className="errorPresent" style={style.errorSpan}>
                                    {err}
                                    <br />
                                </span>
                            ))
                        }
                    </div>
                </Col>

                {formData.exServiceman === "Yes" && (
                    <>
                        <Col xs={window.innerWidth >= 793 ? 4 : 12} md={4}>
                            <div className="mb-1">
                                <label className="form-label inputBoxHeadForm" htmlFor="exServicemanDefenseService" style={style.label}>
                                    Defence Service Wherein Served/Serving<span className="mx-1 text-danger">*</span>
                                </label>
                                <select
                                    // className="form-control"
                                    id="exServicemanDefenseService"
                                    name="exServicemanDefenseService"
                                    value={formData.exServicemanDefenseService}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                        checkErrorFn('exServicemanDefenseService', e.target.value);
                                    }}
                                    onBlur={() => checkErrorFn('exServicemanDefenseService', formData['exServicemanDefenseService'])}
                                    required
                                    style={
                                        fieldErrors?.['exServicemanDefenseService']?.errors?.length > 0 && fieldErrors?.['exServicemanDefenseService']?.touched
                                            ? { ...style.input, ...style.errorBox }
                                            : style.input
                                    }
                                >
                                    <option value="">Select</option>
                                    <option value="Army">Army</option>
                                    <option value="Navy">Navy</option>
                                    <option value="Air Force">Air Force</option>
                                </select>
                                {fieldErrors?.['exServicemanDefenseService']?.errors?.length > 0 && fieldErrors?.['exServicemanDefenseService']?.touched &&
                                    fieldErrors?.['exServicemanDefenseService']?.errors.map((err) => (
                                        <span className="errorPresent" style={style.errorSpan}>
                                            {err}
                                            <br />
                                        </span>
                                    ))
                                }
                            </div>
                        </Col>

                        <Col xs={window.innerWidth >= 793 ? 4 : 12} md={3}>
                            <div className="mb-1">
                                <label className="form-label inputBoxHeadForm" htmlFor="exServicemanServiceStatus" style={style.label}>
                                    Status of Defence Service<span className="mx-1 text-danger">*</span>
                                </label>
                                <select
                                    // className="form-control"
                                    id="exServicemanServiceStatus"
                                    name="exServicemanServiceStatus"
                                    value={formData.exServicemanServiceStatus}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                        checkErrorFn('exServicemanServiceStatus', e.target.value);
                                    }}
                                    onBlur={() => checkErrorFn('exServicemanServiceStatus', formData['exServicemanServiceStatus'])}
                                    required
                                    style={
                                        fieldErrors?.['exServicemanServiceStatus']?.errors?.length > 0 && fieldErrors?.['exServicemanServiceStatus']?.touched
                                            ? { ...style.input, ...style.errorBox }
                                            : style.input
                                    }
                                >
                                    <option value="">Select</option>
                                    <option value="Continuing">Continuing</option>
                                    <option value="Discharge">Discharged</option>
                                </select>
                                {fieldErrors?.['exServicemanServiceStatus']?.errors?.length > 0 && fieldErrors?.['exServicemanServiceStatus']?.touched &&
                                    fieldErrors?.['exServicemanServiceStatus']?.errors.map((err) => (
                                        <span className="errorPresent" style={style.errorSpan}>
                                            {err}
                                            <br />
                                        </span>
                                    ))
                                }
                            </div>
                        </Col>

                        <Col xs={window.innerWidth >= 793 ? 4 : 12} md={5}>
                            <div className="mb-1">
                                <label className="form-label inputBoxHeadForm" htmlFor="exServicemanJoiningDate" style={style.label}>
                                    Joining Date of Service<span className="mx-1 text-danger">*</span>
                                </label>
                                <input
                                    type="date"
                                    id="exServicemanJoiningDate"
                                    name="exServicemanJoiningDate"
                                    value={formData.exServicemanJoiningDate}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                        checkErrorFn('exServicemanJoiningDate', e.target.value);
                                        if ((formData.exServicemanServiceStatus === "Discharge") && (formData.exServicemanRetirementDate)) {
                                            calculateServicePeriodDischarge(formData.exServicemanRetirementDate)
                                        }
                                        if ((formData.exServicemanServiceStatus === "Continuing") && (formData.exServicemanRetirementDate)) {
                                            dateDifference(formData.exServicemanRetirementDate)
                                        }
                                    }}
                                    onBlur={() => checkErrorFn('exServicemanJoiningDate', formData['exServicemanJoiningDate'])}
                                    required
                                    style={
                                        fieldErrors?.['exServicemanJoiningDate']?.errors?.length > 0 && fieldErrors?.['exServicemanJoiningDate']?.touched
                                            ? { ...style.input, ...style.errorBox }
                                            : style.input
                                    }
                                    max={new Date().toISOString().split('T')[0]}
                                />
                                {fieldErrors?.['exServicemanJoiningDate']?.errors?.length > 0 && fieldErrors?.['exServicemanJoiningDate']?.touched &&
                                    fieldErrors?.['exServicemanJoiningDate']?.errors.map((err) => (
                                        <span className="errorPresent" style={style.errorSpan}>
                                            {err}
                                            <br />
                                        </span>
                                    ))
                                }
                            </div>
                        </Col>

                        <Col xs={window.innerWidth >= 793 ? 4 : 12} md={4}>
                            <div className="mb-1">
                                <label className="form-label inputBoxHeadForm" htmlFor="exServicemanRetirementDate" style={style.label}>
                                    Date of Discharge/Retirement<span className="mx-1 text-danger">*</span>
                                </label>
                                <input
                                    className="form-control"
                                    type="date"
                                    id="exServicemanRetirementDate"
                                    name="exServicemanRetirementDate"
                                    value={formData.exServicemanRetirementDate}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                        checkErrorFn('exServicemanRetirementDate', e.target.value);
                                        if (formData.exServicemanServiceStatus === "Continuing") {
                                            dateDifference(e.target.value)
                                        }
                                        if (formData.exServicemanServiceStatus === "Discharge") {
                                            calculateServicePeriodDischarge(e.target.value)
                                        }
                                    }}
                                    onBlur={() => checkErrorFn('exServicemanRetirementDate', formData['exServicemanJoiningDate'])}
                                    required
                                    style={
                                        fieldErrors?.['exServicemanRetirementDate']?.errors?.length > 0 && fieldErrors?.['exServicemanRetirementDate']?.touched
                                            ? { ...style.input, ...style.errorBox }
                                            : style.input
                                    }
                                    max={formData.exServicemanServiceStatus === "Discharge" ? new Date().toISOString().split('T')[0] : ""}
                                    min={formData.exServicemanServiceStatus === "Continuing" ? new Date().toISOString().split('T')[0] : ""}
                                />
                                {fieldErrors?.['exServicemanRetirementDate']?.errors?.length > 0 && fieldErrors?.['exServicemanRetirementDate']?.touched &&
                                    fieldErrors?.['exServicemanRetirementDate']?.errors.map((err) => (
                                        <span className="errorPresent" style={style.errorSpan}>
                                            {err}
                                            <br />
                                        </span>
                                    ))
                                }
                            </div>
                        </Col>

                        {formData.exServicemanServiceStatus === "Discharge" && (
                            <>
                                <Col xs={window.innerWidth >= 793 ? 4 : 12} md={3}>
                                    <div className="mb-1">
                                        <label className="form-label inputBoxHeadForm" htmlFor="exServicemanTotalServicePeriod" style={style.label}>
                                            Total Service Period<span className="mx-1 text-danger">*</span>
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="exServicemanTotalServicePeriod"
                                            name="exServicemanTotalServicePeriod"
                                            value={formData.exServicemanTotalServicePeriod}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                checkErrorFn('exServicemanTotalServicePeriod', e.target.value);
                                            }}
                                            onBlur={() => checkErrorFn('exServicemanTotalServicePeriod', formData['exServicemanTotalServicePeriod'])}
                                            required
                                            style={
                                                fieldErrors?.['exServicemanTotalServicePeriod']?.errors?.length > 0 && fieldErrors?.['exServicemanTotalServicePeriod']?.touched
                                                    ? { ...style.input, ...style.errorBox }
                                                    : style.input
                                            }
                                            min="0"
                                        />
                                        {fieldErrors?.['exServicemanTotalServicePeriod']?.errors?.length > 0 && fieldErrors?.['exServicemanTotalServicePeriod']?.touched &&
                                            fieldErrors?.['exServicemanTotalServicePeriod']?.errors.map((err) => (
                                                <span className="errorPresent" style={style.errorSpan}>
                                                    {err}
                                                    <br />
                                                </span>
                                            ))
                                        }
                                    </div>
                                </Col>
                            </>
                        )}

                        {formData.exServicemanServiceStatus === "Continuing" && (
                            <>
                                <Col xs={window.innerWidth >= 793 ? 4 : 12} md={3}>
                                    <div className="mb-1">
                                        <label className="form-label inputBoxHeadForm" htmlFor="exServicemanTotalServicePeriodContinuing" style={style.label}>
                                            Duration Since Closing Date<span className="mx-1 text-danger">*</span>
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="exServicemanTotalServicePeriodContinuing"
                                            name="exServicemanTotalServicePeriodContinuing"
                                            value={formData.exServicemanTotalServicePeriodContinuing}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                checkErrorFn('exServicemanTotalServicePeriodContinuing', e.target.value);
                                            }}
                                            onBlur={() => checkErrorFn('exServicemanTotalServicePeriodContinuing', formData['exServicemanTotalServicePeriodContinuing'])}
                                            required
                                            style={
                                                fieldErrors?.['exServicemanTotalServicePeriodContinuing']?.errors?.length > 0 && fieldErrors?.['exServicemanTotalServicePeriodContinuing']?.touched
                                                    ? { ...style.input, ...style.errorBox }
                                                    : style.input
                                            }
                                        />
                                        {fieldErrors?.['exServicemanTotalServicePeriodContinuing']?.errors?.length > 0 && fieldErrors?.['exServicemanTotalServicePeriodContinuing']?.touched &&
                                            fieldErrors?.['exServicemanTotalServicePeriodContinuing']?.errors.map((err) => (
                                                <span className="errorPresent" style={style.errorSpan}>
                                                    {err}
                                                    <br />
                                                </span>
                                            ))
                                        }
                                    </div>
                                </Col>
                            </>
                        )}

                        <Col xs={window.innerWidth >= 793 ? 12 : 12} md={12}>
                            <div className="mb-1">
                                <label className="form-label inputBoxHeadForm" htmlFor="exServicemanAppliedForCivilEmployment" style={style.label}>
                                    Have you availed Ex-Serviceman reservation earlier in any Govt Organization/Autonomous Bodies/PSU against any regular post?<span className="mx-1 text-danger">*</span>
                                </label>
                                <select
                                    // className="form-control"
                                    id="exServicemanAppliedForCivilEmployment"
                                    name="exServicemanAppliedForCivilEmployment"
                                    value={formData.exServicemanAppliedForCivilEmployment}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        handleInputChange(e);
                                        checkErrorFn('exServicemanAppliedForCivilEmployment', e.target.value);
                                        if (value === "Yes") {
                                            // Clear all specified fields except `departmentalCandidate`
                                            setFormData((prevFormData) => ({
                                                ...prevFormData,
                                                exServiceman: 'No',
                                                exServicemanDefenseService: '',
                                                exServicemanServiceStatus: '',
                                                exServicemanJoiningDate: '',
                                                exServicemanRetirementDate: '',
                                                exServicemanTotalServicePeriod: '',
                                                exServicemanAppliedForCivilEmployment: ''
                                            }));
                                        }
                                    }}
                                    onBlur={() => checkErrorFn('exServicemanAppliedForCivilEmployment', formData['exServicemanAppliedForCivilEmployment'])}
                                    required
                                    style={
                                        fieldErrors?.['exServicemanAppliedForCivilEmployment']?.errors?.length > 0 && fieldErrors?.['exServicemanAppliedForCivilEmployment']?.touched
                                            ? { ...style.input, ...style.errorBox }
                                            : style.input
                                    }
                                >
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                                {fieldErrors?.['exServicemanAppliedForCivilEmployment']?.errors?.length > 0 && fieldErrors?.['exServicemanAppliedForCivilEmployment']?.touched &&
                                    fieldErrors?.['exServicemanAppliedForCivilEmployment']?.errors.map((err) => (
                                        <span className="errorPresent" style={style.errorSpan}>
                                            {err}
                                            <br />
                                        </span>
                                    ))
                                }
                            </div>
                        </Col>

                        <div style={{ display: 'flex', marginTop: "30px" }}>
                            <input
                                type="checkbox"
                                id="declaration-checkbox"
                                name="exServicemanDeclaration"
                                checked={formData?.exServicemanDeclaration}
                                onChange={(e) => { handleInputChange(e); }}
                                onBlur={() => checkErrorFn('exServicemanDeclaration', formData['exServicemanDeclaration'])}
                                style={{ marginRight: '10px' }}
                            />
                            <label htmlFor="declaration-checkbox" style={{ fontSize: '14px', lineHeight: '1.5', fontFamily: "'Roboto', sans-serif", color: "brown" }}>
                                I do hereby declare that I shall submit date-wise details of my application (s) for various vacancy (ies), for which I have applied for, at the time of Document Verification, if shortlisted.
                            </label>
                        </div>
                        {fieldErrors?.['exServicemanDeclaration']?.errors?.length > 0 && fieldErrors?.['exServicemanDeclaration']?.touched &&
                            fieldErrors?.['exServicemanDeclaration']?.errors.map((err) => (
                                <span className="errorPresent" style={style.errorSpan}>
                                    {err}
                                    <br />
                                </span>
                            ))
                        }

                        {/* <Col xs={window.innerWidth >= 793 ? 5 : 12} md={5}>
                        <div className="mb-1">
                            <label className="form-label inputBoxHeadForm" htmlFor="exServicemanAvailedReservation" style={style.label}>
                                Have you applied any civil employment against Ex-Serviceman Category?<span className="mx-1 text-danger">*</span>
                            </label>
                            <select
                                // className="form-control"
                                id="exServicemanAvailedReservation"
                                name="exServicemanAvailedReservation"
                                value={formData.exServicemanAvailedReservation}
                                onChange={(e) => {
                                    handleInputChange(e);
                                    checkErrorFn('exServicemanAvailedReservation', e.target.value);
                                }}
                                onBlur={() => checkErrorFn('exServicemanAvailedReservation', formData['exServicemanAvailedReservation'])}
                                required
                                style={
                                    fieldErrors?.['exServicemanAvailedReservation']?.errors?.length > 0 && fieldErrors?.['exServicemanAvailedReservation']?.touched
                                        ? { ...style.input, ...style.errorBox }
                                        : style.input
                                }
                            >
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            {fieldErrors?.['exServicemanAvailedReservation']?.errors?.length > 0 && fieldErrors?.['exServicemanAvailedReservation']?.touched &&
                                fieldErrors?.['exServicemanAvailedReservation']?.errors.map((err) => (
                                    <span className="errorPresent" style={style.errorSpan}>
                                        {err}
                                        <br />
                                    </span>
                                ))
                            }
                        </div>
                    </Col> */}
                    </>
                )}
            </Row>

            {sameSubCaderModal && <Modal show={sameSubCaderModal} onHide={() => setSameSubCaderModal(false)} centered>
                <Modal.Header style={{ background: '#921D1E', color: 'white' }}>
                    <div className='d-flex align-items-center justify-content-center w-100'>
                        <img src={waringSign} alt="warn" />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <h2 style={{ color: '#921D1E', fontSize: '28px', fontFamily: 'Roboto', textAlign: 'center' }}>Warning!</h2>
                    <p style={{ fontSize: '16px', marginTop: '20px', fontFamily: 'Roboto', textAlign: 'center' }}>As per the data provided by you, the total duration of your service in defense is less than six months. Therefore, you are not eligible to apply under the Ex-Serviceman category. You may proceed to apply under your respective other category</p>
                    {/* <Alert variant="warning" className="fw-bolder">
                        Not considered as an Ex-service man.
                    </Alert> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSameSubCaderModal(false)} style={{ fontSize: '14px', fontWeight: '600', fontFamily: 'Roboto', textAlign: 'center', border: '1px solid #DEBBBB', color: '#921D1E', background: '#F9F3F4' }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
        </>
    );
};

export default ExServicemen;
