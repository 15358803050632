import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import InputField from "./InputField";
import PostWiseDetailEntry from "./PostWiseDetailEntry";
import RelevantExperienceEntry from "./RelevantExperienceEntry";
import { FaPlus } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import MultipleFileUpload from "../MultipleFileUpload";

const FieldGroup = ({
  allExpDetails,
  data,
  outerIndex,
  handleInputChange,
  addFormEntry,
  deleteFormEntry,
  addRelevantExperience,
  deleteRelevantExperience,
  fieldErrors,
  style,
  checkErrorFn,
  deleteFieldGroup,
  postandvacancyDetails,
  setFieldErrors,
  setFormData,
  formData,
  applicationDetails,
  applicationID
}) => {
  console.log({ postandvacancyDetails });




  const handelFileFunction = (d) => {
    console.log(d, "here is  d and  data ");
    // handleInputChange( outerIndex, innerIndex,'ProofOfExperience',d, null)
  };
  // Function to calculate total experience
  const calculateTotalExperience = (startDate, endDate) => {
    if (!startDate || !endDate) return "";
  
    // Parse the start and end dates
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    // Add one day to the end date to include it in the calculation
    end.setDate(end.getDate() + 1);
  
    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();
    let days = end.getDate() - start.getDate();
  
    // Adjust days and months if necessary
    if (days < 0) {
      months -= 1;
      // Get the number of days in the previous month
      const previousMonth = new Date(end.getFullYear(), end.getMonth(), 0);
      days += previousMonth.getDate();
    }
  
    if (months < 0) {
      years -= 1;
      months += 12;
    }
  
    return `${years} years, ${months} months, ${days} days`;
  };

  // Use effect to recalculate total experience whenever start or end date changes
  useEffect(() => {
    const totalExperience = calculateTotalExperience(
      data.startDateOfService,
      data.endDateOfService
    );
    handleInputChange(outerIndex, null, "totalExperience", totalExperience);
  }, [data.startDateOfService, data.endDateOfService]);

  const calculateTotalExperienceSum = (entries) => {
    let totalYears = 0, totalMonths = 0, totalDays = 0;
  
    entries.forEach((entry) => {
      if (entry.totalExperience) {
        const [years, months, days] = entry.totalExperience.match(/\d+/g).map(Number);
  
        totalYears += years;
        totalMonths += months;
        totalDays += days;
      }
    });
  
    // Adjust months and days
    totalMonths += Math.floor(totalDays / 30);
    totalDays = totalDays % 30;
    totalYears += Math.floor(totalMonths / 12);
    totalMonths = totalMonths % 12;
  
    return `${totalYears} years, ${totalMonths} months, ${totalDays} days`;
  };
  
  // Assuming 'entries' is the list of all service entries
 const [totalExperience,setTotalExperience]=useState("")
 const [totalExperienceRelevant,setTotalExperienceRelevant]=useState("")
 const [totalExperienceWithoutTrainee,setTotalExperienceWithoutTrainee]=useState("")
 const [totalExperienceWithoutTraineeRelevant,setTotalExperienceWithoutTraineeRelevant]=useState("")
useEffect(()=>{
  setTotalExperience(calculateTotalExperienceSum(data.postWiseDetails))
  setTotalExperienceRelevant(calculateTotalExperienceSum(data.relevantExperience))
  setTotalExperienceWithoutTrainee(calculateTotalExperienceSumWithoutTrainee(data.postWiseDetails)) // `entries` is the list of all entries
  setTotalExperienceWithoutTraineeRelevant(calculateTotalExperienceSumWithoutTrainee(data.relevantExperience)); // `entries` is the list of all entries


},[data])

useEffect(()=>{

  handleInputChange(outerIndex, null, "postWiseTotalExperience",calculateTotalExperienceSum(data.postWiseDetails) );
  handleInputChange(outerIndex, null, "postWiseTotalExperienceWithoutTrainee",calculateTotalExperienceSumWithoutTrainee(data.postWiseDetails))
  handleInputChange(outerIndex, null, "relevantTotalExperience",calculateTotalExperienceSum(data.relevantExperience));
  handleInputChange(outerIndex, null, "relevantTotalExperienceWithoutTrainee",calculateTotalExperienceSumWithoutTrainee(data.relevantExperience))
},[data.postWiseDetails, ,data.postWiseDetails,data.relevantExperience,data.relevantExperience])


  const calculateTotalExperienceSumWithoutTrainee = (entries) => {
    let totalYears = 0, totalMonths = 0, totalDays = 0;
  
    entries.forEach((entry) => {
      if (entry.isTrainee !== "Yes" && entry.totalExperience) { // Exclude trainees
        const [years, months, days] = entry.totalExperience.match(/\d+/g).map(Number);
  
        totalYears += years;
        totalMonths += months;
        totalDays += days;
      }
    });
  
    // Adjust months and days to avoid overflow
    totalMonths += Math.floor(totalDays / 30);
    totalDays = totalDays % 30;
    totalYears += Math.floor(totalMonths / 12);
    totalMonths = totalMonths % 12;
  
    return `${totalYears} years, ${totalMonths} months, ${totalDays} days`;
  };


  const formatIndianCurrency = (value) => {
    if (typeof value !== 'string' || !value) {
      return '';
    }

    let sanitizedInput = value.replace(/[^0-9]/g, '');
    let formattedAmount = Number(sanitizedInput).toLocaleString('en-IN');

    return formattedAmount;
  };
  // Example usage: 


  return (
    <>
      {/* Main Fields */}
      <Row>

      <Row>
       {/* Name of Organization */}
       <Col xs={12} md={4}>
          <InputField
            label="Name of Organization"
            value={data.nameOfOrganization}
            onChange={(e) => {
              const value = e.target.value;
              handleInputChange(outerIndex, null, "nameOfOrganization", value);
              checkErrorFn(`nameOfOrganization${outerIndex}`, value);
            }}
            onBlur={() =>
              checkErrorFn(
                `nameOfOrganization${outerIndex}`,
                data.nameOfOrganization
              )
            }
            error={fieldErrors?.[`nameOfOrganization${outerIndex}`]?.errors}
            style={style}
            required
          />
        </Col>

        {/* Type of Organization */}
        <Col xs={12} md={4}>
          <InputField
            label="Type of Organization"
            type="select"
            value={data.typeOfOrganization}
            onChange={(e) => {
              const value = e.target.value;
              handleInputChange(outerIndex, null, "typeOfOrganization", value);
              checkErrorFn(`typeOfOrganization${outerIndex}`, value);
            }}
            onBlur={() =>
              checkErrorFn(
                `typeOfOrganization${outerIndex}`,
                data.typeOfOrganization
              )
            }
            error={fieldErrors?.[`typeOfOrganization${outerIndex}`]?.errors}
            style={style}
            required
            options={[
              { value: "Private", label: "Private" },
              { value: "Government", label: "Government" },
            ]}
          />
        </Col>

        {/* Type of Service */}
        <Col xs={12} md={4}>
          <InputField
            label="Type of Service"
            type="select"
            value={data.typeOfService}
            onChange={(e) => {
              const value = e.target.value;
              handleInputChange(outerIndex, null, "typeOfService", value);
              checkErrorFn(`typeOfService${outerIndex}`, value);
            }}
            onBlur={() =>
              checkErrorFn(`typeOfService${outerIndex}`, data.typeOfService)
            }
            error={fieldErrors?.[`typeOfService${outerIndex}`]?.errors}
            style={style}
            required
            options={[
              { value: "Regular", label: "Regular" },
              { value: "Contractual", label: "Contractual" },
            ]}
          />
        </Col>

      </Row>


      <Row>
       {/* Status of Service */}
       <Col xs={12} md={4}>
          <InputField
            label="Status of Service"
            type="select"
            value={data.statusOfService}
            onChange={(e) => {
              const value = e.target.value;
              handleInputChange(outerIndex, null, "statusOfService", value);
              checkErrorFn(`statusOfService${outerIndex}`, value);
            }}
            onBlur={() =>
              checkErrorFn(`statusOfService${outerIndex}`, data.statusOfService)
            }
            error={fieldErrors?.[`statusOfService${outerIndex}`]?.errors}
            style={style}
            required
            options={[
              { value: "Completed", label: "Completed" },
              { value: "Continuing", label: "Continuing" },
            ]}
          />
        </Col>

        {/* Nature of Business */}
        <Col xs={12} md={4}>
        <InputField
            label="Nature of Business"
            type="select"
            value={data?.natureOfBusiness}
            onChange={(e) => {
              const value = e.target.value;
              handleInputChange(outerIndex, null, "natureOfBusiness", value);
              checkErrorFn(`natureOfBusiness${outerIndex}`, value);
            }}
            onBlur={() =>
              checkErrorFn(
                `natureOfBusiness${outerIndex}`,
                data.typeOfOrganization
              )
            }
            error={fieldErrors?.[`natureOfBusiness${outerIndex}`]?.errors}
            style={style}
            required

            options={
              postandvacancyDetails?.natureOfBusiness?.map((item)=>{return {value:item,label:item}})
              }
            />
        </Col>

        <Col xs={12} md={4}>
        <InputField
            label="Posting Location"
            type="text"
            value={data?.postingLocation}
            onChange={(e) => {
              const value = e.target.value;
              handleInputChange(outerIndex, null, "postingLocation", value);
              checkErrorFn(`postingLocation${outerIndex}`, value);
            }}
            onBlur={() =>
              checkErrorFn(
                `postingLocation${outerIndex}`,
                data.postingLocation
              )
            }
            error={fieldErrors?.[`postingLocation${outerIndex}`]?.errors}
            style={style}
            required
            />


            
        </Col>
      </Row>
       

       
        <Row xs={12} md={12}>
         {/* From Date */}
         <Col xs={12} md={4}>
          <InputField
            label="From"
            type="date"
            value={data.startDateOfService}
            onChange={(e) => {
              const value = e.target.value;
              handleInputChange(outerIndex, null, "startDateOfService", value);
              checkErrorFn(`startDateOfService${outerIndex}`, value);
            }}
            onBlur={() =>
              checkErrorFn(
                `startDateOfService${outerIndex}`,
                data.startDateOfService
              )
            }
            max={new Date().toISOString().split('T')[0]}
            error={fieldErrors?.[`startDateOfService${outerIndex}`]?.errors}
            style={style}
            required
          />
        </Col>

        {/* To Date */}
        <Col xs={12} md={4}>
          <InputField
            label="To"
            type="date"
            value={data.endDateOfService}
            onChange={(e) => {
              const value = e.target.value;
              handleInputChange(outerIndex, null, "endDateOfService", value);
              checkErrorFn(`endDateOfService${outerIndex}`, value);
            }}
            onBlur={() =>
              checkErrorFn(
                `endDateOfService${outerIndex}`,
                data.endDateOfService
              )
            }
            max={new Date().toISOString().split('T')[0]}
            error={fieldErrors?.[`endDateOfService${outerIndex}`]?.errors}
            style={style}
            required
          />
        </Col>

        {/* Total Experience */}
        <Col xs={12} md={4}>
          <InputField
            label="Total Experience"
            value={data.totalExperience}
            onChange={() => {
              // Make this field read-only since it's calculated
            }}
            style={style}
            required
            readOnly={true}
          />
        </Col>

        </Row>


        <Row>

        {/* Pay Scale */}
        <Col xs={12} md={4}>
          <InputField
      
      
            label="₹ Pay Scale"
            value={formatIndianCurrency(data.payScale)}
            onChange={(e) => {
              const value = e.target.value;
              handleInputChange(outerIndex, null, "payScale", value);
              checkErrorFn(`payScale${outerIndex}`, value);
            }}
            onBlur={() => checkErrorFn(`payScale${outerIndex}`, data.payScale)}
            error={fieldErrors?.[`payScale${outerIndex}`]?.errors}
            style={style}
            required
          />
        </Col>

        {/* Basic Pay */}
        <Col xs={12} md={4}>

          <InputField
            label="₹ Basic Pay"
            value={formatIndianCurrency(data.basicPay)}
            onChange={(e) => {
              const value = e.target.value;
              handleInputChange(outerIndex, null, "basicPay", value);
              checkErrorFn(`basicPay${outerIndex}`, value);
            }}
            onBlur={() => checkErrorFn(`basicPay${outerIndex}`, data.basicPay)}
            error={fieldErrors?.[`basicPay${outerIndex}`]?.errors}
            style={style}
            required
          />
        </Col>

        {/* CTC */}
        <Col xs={12} md={4}>
        
          <InputField
            label="₹ CTC"
            value={formatIndianCurrency(data.ctc)}
            onChange={(e) => {
              const value = e.target.value;
              handleInputChange(outerIndex, null, "ctc", value);
              checkErrorFn(`ctc${outerIndex}`, value);
            }}
            onBlur={() => checkErrorFn(`ctc${outerIndex}`, data.ctc)}
            error={fieldErrors?.[`ctc${outerIndex}`]?.errors}
            style={style}
            required
          />
        </Col>

        </Row>

       

        
      </Row>

      {/* Post Wise Details */}
      <Row className="mt-2">
        <Col xs={12}>
          <label className="experience-section-heading mt-4 mb-1 ps-0 ms-0 pb-1 " style={style.label}>
           <h6 className="experience-section-heading ms-0 ps-0 pb-0">Post Wise Details:</h6> 
          </label>
          
          {data.postWiseDetails?.map((entry, innerIndex) => (
            <PostWiseDetailEntry
            calculateTotalExperience={calculateTotalExperience}
              key={innerIndex}
              entry={entry}
              outerIndex={outerIndex}
              innerIndex={innerIndex}
              handleInputChange={handleInputChange}
              deleteFormEntry={deleteFormEntry}
              fieldErrors={fieldErrors}
              style={style}
              checkErrorFn={checkErrorFn}
              setFieldErrors={setFieldErrors}
              parentStartDate={data.startDateOfService}
              parentEndDate={data.endDateOfService}

            />
          ))}

<div className="">

          
          {/* <hr/> */}

          <Row  xs={12} md={12} className="mt-2">

          <Col xs={6} md={4}>
          <label className="form-label" style={style.label}>Total Experience: <br /> <span style={{color:"#921D1E"}}>{allExpDetails?.total_postWiseTotalExperience}</span> </label>
        </Col>
        <Col xs={6} md={4}>
          <label className="form-label" style={style.label}>Total Experience(Excluding Trainees): <br /> <span style={{color:"#921D1E"}}>{allExpDetails?.total_postWiseTotalExperienceWithoutTrainee}</span> </label>
        </Col>
        <Col md={4} className="d-flex justify-content-end">
        <Button variant="primary" className="mt-2" onClick={() => addFormEntry(outerIndex)}>
            <FaPlus /> Add Post Wise Detail
          </Button>
        </Col>

          </Row>
          <hr/>
</div>
        </Col>
      </Row>

      {/* Relevant Experience Checkbox */}
      <Row className=" mt-2">
        <Col xs={12} md={12}>
          <input
            type="checkbox"
            checked={data.isRelevantChecked || false}
            onChange={(e) => {
              handleInputChange(
                outerIndex,
                null,
                "isRelevantChecked",
                e.target.checked
              );
            }}
            id="isRelevant"

          />
          <label for="isRelevant" className="form-label ms-2" style={style.label}>
            Relevant experience in "
            {postandvacancyDetails?.relevantExperiences?.[0]?.experience}" if
            any in this experience
            <span className="mx-1 text-danger">*</span>
          </label>
        </Col>
      </Row>

      {/* Relevant Experience */}
      {data.isRelevantChecked && (
        <Row className="mt-2">
          <Col xs={12}>
            <label className="form-label" style={style.label}>
              Relevant Experience:
            </label>
           
            {data.relevantExperience?.map((entry, relevantIndex) => (
              <RelevantExperienceEntry
              calculateTotalExperience={calculateTotalExperience}
                key={relevantIndex}
                entry={entry}
                outerIndex={outerIndex}
                relevantIndex={relevantIndex}
                handleInputChange={handleInputChange}
                deleteRelevantExperience={deleteRelevantExperience}
                fieldErrors={fieldErrors}
                style={style}
                checkErrorFn={checkErrorFn}
                setFieldErrors={setFieldErrors}
                parentStartDate={data.startDateOfService}
                parentEndDate={data.endDateOfService}
              />
            ))}
            
                    
            {/* <hr/> */}

            <Row  xs={12} md={12} className="mt-4">

        <Col xs={4}>
          <label className="form-label" style={style.label}>Total Experience:<br/> <span style={{color:"#921D1E"}}> {allExpDetails?.total_relevantTotalExperience} </span> </label>
        </Col>
        <Col xs={4}>
          <label className="form-label" style={style.label}>Total Experience(Excluding Trainees):<br/> <span style={{color:"#921D1E"}}> {allExpDetails?.total_relevantTotalExperienceWithoutTrainee}</span></label>
        </Col>
        <Col xs={4} className="d-flex justify-content-end">
        <div >

            <Button
              variant="primary"
              onClick={() => addRelevantExperience(outerIndex)}
            >
              <FaPlus /> Add Relevant Experience for{" "}
              {postandvacancyDetails?.relevantExperiences?.[0]?.experience}
            </Button>
            </div>

        </Col>

          </Row>
          <hr/>
          </Col>

        </Row>



      )}


        


      <Row className="mt-2">

      <Row className="mt-2">
        <Col xs={12}>
          <MultipleFileUpload
            data={data}
            outerIndex={outerIndex}
            handleInputChange={handleInputChange}
            fieldErrors={fieldErrors}
            setFieldErrors={setFieldErrors}
            style={style}
            checkErrorFn={checkErrorFn}
            applicationDetails={applicationDetails}
            applicationID={applicationID}
          />
        </Col>
      </Row>



      </Row>

      {/* Delete Field Group Button */}
      <Row className="ms-1 text-center mb-4">
        <Col xs={12}>
          <Button variant="danger" onClick={() => deleteFieldGroup(outerIndex)}>
            <RiDeleteBin6Line /> Delete  Experience
          </Button>
        </Col>
      </Row>
      <hr/>
    </>
  );
};

export default FieldGroup;
