import React, { useState, useEffect } from "react";
import { Row, Button, Card, Col } from "react-bootstrap";
import FieldGroup from "./ExperienceInputs/FieldGroup";
import { FaPlus } from "react-icons/fa";
import { CloudWatchLogs } from "aws-sdk";
import MultipleFileUpload from "./MultipleFileUpload";

const ExperienceInputs = ({
  setApplicationDetails,
  applicationDetails,
  postandvacancyDetails,
  style,
  applicationID
}) => {
  
  const [formData, setFormData] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  console.log({fieldErrors})
  console.log("formData334",{formData})
  
  const calculateTotalExperienceSumWithoutTrainee = (entries) => {
    let totalYears = 0, totalMonths = 0, totalDays = 0;
  
    entries.forEach((entry) => {
      if (entry.isTrainee !== "Yes" && entry.totalExperience) { // Exclude trainees
        const [years, months, days] = entry.totalExperience.match(/\d+/g).map(Number);
  
        totalYears += years;
        totalMonths += months;
        totalDays += days;
      }
    });
  
    // Adjust months and days to avoid overflow
    totalMonths += Math.floor(totalDays / 30);
    totalDays = totalDays % 30;
    totalYears += Math.floor(totalMonths / 12);
    totalMonths = totalMonths % 12;
  
    return `${totalYears} years, ${totalMonths} months, ${totalDays} days`;
  };
  const calculateTotalExperienceSum = (entries) => {
    let totalYears = 0, totalMonths = 0, totalDays = 0;
  
    entries.forEach((entry) => {
      if (entry.totalExperience) {
        const [years, months, days] = entry.totalExperience.match(/\d+/g).map(Number);
  
        totalYears += years;
        totalMonths += months;
        totalDays += days;
      }
    });
  
    // Adjust months and days
    totalMonths += Math.floor(totalDays / 30);
    totalDays = totalDays % 30;
    totalYears += Math.floor(totalMonths / 12);
    totalMonths = totalMonths % 12;
  
    return `${totalYears} years, ${totalMonths} months, ${totalDays} days`;
  };


  const handleInputChange = (
    outerIndex,
    innerIndex,
    field,
    value,
    relevantIndex = null
  ) => {
    setFormData((prevData) => {
      const updatedData = [...prevData];
      if (outerIndex !== null && updatedData[outerIndex]) {
        if (innerIndex === null && relevantIndex === null) {
          updatedData[outerIndex] = {
            ...updatedData[outerIndex],
            [field]: value,
          };
        } else if (innerIndex !== null) {
          updatedData[outerIndex].postWiseDetails[innerIndex] = {
            ...updatedData[outerIndex].postWiseDetails[innerIndex],
            [field]: value,
          };
        } else if (relevantIndex !== null) {
          updatedData[outerIndex].relevantExperience[relevantIndex] = {
            ...updatedData[outerIndex].relevantExperience[relevantIndex],
            [field]: value,
          };
        }
      }
      return updatedData;
    });
  };


  const addFormEntry = (outerIndex) => {
    setFormData((prevData) => {
      const updatedData = [...prevData];

      // If `postWiseDetails` does not exist at `outerIndex`, initialize it as an empty array
      if (!updatedData[outerIndex].postWiseDetails) {
        updatedData[outerIndex].postWiseDetails = [];
      }

      // Add a new entry to `postWiseDetails` at the specified `outerIndex`
      updatedData[outerIndex].postWiseDetails.push({
        isTrainee: "",
        designation: "",
        startDateOfService: "",
        endDateOfService: "",
      });

      return updatedData;
    });
  };

  

  const addRelevantExperience = (outerIndex) => {
    setFormData((prevData) => {
      const updatedData = [...prevData];

      // If `postWiseDetails` does not exist at `outerIndex`, initialize it as an empty array
      if (!updatedData[outerIndex].relevantExperience) {
        updatedData[outerIndex].relevantExperience = [];
      }

      // Add a new entry to `postWiseDetails` at the specified `outerIndex`
      updatedData[outerIndex].relevantExperience.push({
        isTrainee: "",
        startDateOfService: "",
        endDateOfService: "",
        TotalExperience: "",
      });

      return updatedData;
    });
  };

  const deleteRelevantExperience = (outerIndex, innerIndex) => {
    setFormData((prevData) => {
      const updatedData = [...prevData];

      // Ensure the postWiseDetails exists for outerIndex, then filter out the entry at innerIndex
      updatedData[outerIndex].relevantExperience = updatedData[
        outerIndex
      ].relevantExperience.filter((_, i) => i !== innerIndex);

      return updatedData;
    });
  };
  const deleteFormEntry = (outerIndex, innerIndex) => {
    setFormData((prevData) => {
      const updatedData = [...prevData];

      // Ensure the postWiseDetails exists for outerIndex, then filter out the entry at innerIndex
      updatedData[outerIndex].postWiseDetails = updatedData[
        outerIndex
      ].postWiseDetails.filter((_, i) => i !== innerIndex);

      return updatedData;
    });
  };

  const addNewFieldGroup = () => {
    setFormData((prevState) => [
      ...prevState,
      {
        nameOfOrganization: "",
        typeOfOrganization: "",
        statusOfService: "",
        typeOfService: "",
        natureOfBusiness: "",
        startDateOfService: "",
        endDateOfService: "",
        payScale: "",
        basicPay: "",
        ctc: "",
        totalExperience: "",
        proofOfExperienceDocument: [],
        postWiseDetails: [],
        relevantExperience: [],
      },
    ]);
  };

  const deleteFieldGroup = (outerIndex) => {
    const updatedData = formData.filter((_, i) => i !== outerIndex);
    setFormData(updatedData);
  };

  const checkErrorFn = (
    fieldKey,
    immediateValue,
    outerIndex = null,
    relevantIndex = null
  ) => {
    console.log(fieldKey, "fieldKey");
    console.log(immediateValue, "immediateValue");

    let errCount = 0;

    // Required field validation
    if (!immediateValue) {
      errCount++;
      setFieldErrors((prevState) => ({
        ...prevState,
        [fieldKey]: {
          errors: ["This field is required."],
          touched: true,
        },
      }));
    } else {
      setFieldErrors((prevState) => ({
        ...prevState,
        [fieldKey]: { errors: [], touched: true },
      }));
    }

    // Specific validation for `relevantExperience` fields
    if (fieldKey.includes("startDateOfService") && relevantIndex !== null) {
      // Additional validation if needed for `startDateOfService`
    }

    if (fieldKey.includes("endDateOfService") && relevantIndex !== null) {
      // Additional validation if needed for `endDateOfService`
    }

    return errCount === 0; // Returns true if no errors, false otherwise
  };



  const calculateGrandTotalExperience = (data) => {
    return data.reduce((total, item) => {
      const exp = parseFloat(item.totalExperience) || 0;
      return total + exp;
    }, 0);
  };

  useEffect(()=>{
    setApplicationDetails((prev) => ({
      ...prev,
      new_staticExperience: formData
  }));
  
  },[formData])
  useEffect(()=>{
    if(applicationDetails?.newStaticExperience?.length>0)
    {
      setFormData(applicationDetails.newStaticExperience)

    }

    console.log("demount",applicationDetails.newStaticExperience)

  },[])


  const parseExperienceString = (experienceStr) => {
    const [years, months, days] = experienceStr.match(/\d+/g).map(Number);
    return { years, months, days };
  };

  const sumTotalExperiences = (exp_arr,key) => {
    let totalYears = 0, totalMonths = 0, totalDays = 0;
    exp_arr.forEach((item) => {
      if (item?.[key]) {
        const exp = parseExperienceString(item?.[key]);
        totalYears += exp.years;
        totalMonths += exp.months;
        totalDays += exp.days;
      }
      // Repeat for other totals (excluding trainees, relevant experiences)
    });
    // Adjust months and days to avoid overflow
    totalMonths += Math.floor(totalDays / 30);
    totalDays = totalDays % 30;
    totalYears += Math.floor(totalMonths / 12);
    totalMonths = totalMonths % 12;
    return `${totalYears} years, ${totalMonths} months, ${totalDays} days`;
  };
  
  
  const [allExpDetails, setAllExpDetails] = useState([]);

  useEffect(()=>{
    let allExpSummery= [ ]
    for (let index = 0; index < formData.length; index++){

      let experienceSummary={}
      experienceSummary.index=index
      const _formData = formData[index];
      if(_formData?.totalExperience){
        experienceSummary.baseTotal=_formData?.totalExperience
      }
      if(_formData?.postWiseDetails){
        experienceSummary.postWiseTotalExperience=calculateTotalExperienceSum(_formData.postWiseDetails)
        experienceSummary.postWiseTotalExperienceWithoutTrainee=calculateTotalExperienceSumWithoutTrainee(_formData.postWiseDetails)
      }
      if(_formData?.relevantExperience){
        experienceSummary.relevantTotalExperience=calculateTotalExperienceSum(_formData.relevantExperience)
        experienceSummary.relevantTotalExperienceWithoutTrainee=calculateTotalExperienceSumWithoutTrainee(_formData.relevantExperience)
      }
      console.log("formData334",{experienceSummary,index})
      allExpSummery.push(experienceSummary)

    }
    let total_BaseExp=sumTotalExperiences(allExpSummery,"baseTotal")
    let total_postWiseTotalExperience=sumTotalExperiences(allExpSummery,"postWiseTotalExperience")
    let total_postWiseTotalExperienceWithoutTrainee=sumTotalExperiences(allExpSummery,"postWiseTotalExperienceWithoutTrainee")
    let total_relevantTotalExperience=sumTotalExperiences(allExpSummery,"relevantTotalExperience")
    let total_relevantTotalExperienceWithoutTrainee=sumTotalExperiences(allExpSummery,"relevantTotalExperienceWithoutTrainee")
    let finalSummeryExp={
      total_BaseExp,
      total_postWiseTotalExperience,
      total_postWiseTotalExperienceWithoutTrainee,
      total_relevantTotalExperience,
      total_relevantTotalExperienceWithoutTrainee,
      allExpSummery
    }
    console.log("formData334",{finalSummeryExp})
    setApplicationDetails((prv) => ({
      ...prv,
      finalSummeryExp
    }));
    setAllExpDetails(finalSummeryExp)
  },[formData])
  useEffect(()=>{
    console.log("formData334",{allExpDetails})
  },[allExpDetails])
  return (
    <div id="experenceInputRemove">
      <Row>
        <h6 className="mb-2 experience-section-heading">Experience Details </h6>
        <hr/>
        {formData?.map((data, outerIndex) => (
          <FieldGroup
            allExpDetails={allExpDetails}
            key={outerIndex}
            data={data}
            outerIndex={outerIndex}
            handleInputChange={handleInputChange}
            fieldErrors={fieldErrors}
            style={style}
            checkErrorFn={checkErrorFn}
            addFormEntry={addFormEntry}
            deleteFormEntry={deleteFormEntry}
            addRelevantExperience={addRelevantExperience}
            deleteRelevantExperience={deleteRelevantExperience}
            deleteFieldGroup={deleteFieldGroup}
            postandvacancyDetails={postandvacancyDetails}
            setFieldErrors={setFieldErrors}
            setFormData={setFormData}
            formData={formData}
            applicationDetails={applicationDetails}
            applicationID={applicationID}
          />

        ))}
      </Row>
      
{/* <hr/> */}
      <Row  xs={12} md={12} className=" mt-2">
<div className=""><h6 className="mb-3"><u> 
  All Experience Details 
  </u></h6></div>
<Col xs={6} md={3}>
  <label className="form-label" style={style.label}><>Total Experience</><br/> <span style={{color:"#921D1E"}}> {allExpDetails.total_postWiseTotalExperience}</span></label>
</Col>
<Col xs={6} md={3}>
  <label className="form-label" style={style.label}><> Total Experience(Excluding Trainees)</><br/> <span style={{color:"#921D1E"}}>{allExpDetails.total_postWiseTotalExperienceWithoutTrainee}</span> </label>
</Col>
<Col xs={6} md={6} className="d-flex justify-content-end">
<div >
        <Button
        variant="primary"
          className="btn btn-success"
          onClick={addNewFieldGroup}
        >
          <FaPlus /> Add New Experience
        </Button>
      </div>
</Col>

  </Row>

  <Row  xs={12} md={12} className=" mt-4">

<Col xs={6} md={3}>
  <label className="form-label" style={style.label}><>Total Relevant Experience</><br/> <span style={{color:"#921D1E"}}>{allExpDetails.total_relevantTotalExperience}</span> </label>
</Col>
<Col xs={6} md={4}>
  <label className="form-label" style={style.label}><>Total Relevant Experience(Excluding Trainees)</><br/> <span style={{color:"#921D1E"}}>{allExpDetails.total_relevantTotalExperienceWithoutTrainee}</span> </label>
</Col>

  </Row>

      {/* <div className="form-label" style={style.label}>
        Grand Total Experience: {calculateGrandTotalExperience(formData)}
      </div> */}
    </div>
  );
};

export default ExperienceInputs;
