import React, { useState } from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';

const PageSize = ({ setSelectedPageSize, pageSize, setCurrentPage }) => {
    const options = [8,16,32,80,160,400,800,1600];

    const onSelectPageSize = async (size) => {
        setCurrentPage(1);
        setSelectedPageSize(size);
        // await getAllUsers(size, 1);
    };

    return (
        <OverlayTrigger placement="top"  delay={{ show: 1000, hide: 0 }} overlay={<Tooltip>Item Count</Tooltip>}>
            {({ ref, ...triggerHandler }) => (
                <Dropdown className="d-inline-block mx-1 dropdownComp" align="end">
                    <Dropdown.Toggle ref={ref} {...triggerHandler} className="" style={{backgroundColor:"brown"}}>
                        {pageSize} Items
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        className="shadow dropdown-menu-end"
                        popperConfig={{
                            modifiers: [
                                {
                                    name: 'computeStyles',
                                    options: {
                                        gpuAcceleration: false,
                                    },
                                },
                            ],
                        }}
                    >
                        {options.map((pSize) => (
                            <Dropdown.Item className='dropDownText' key={`pageSize.${pSize}`} active={pSize === pageSize} onClick={() => onSelectPageSize(pSize)}>
                                {pSize} Items
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </OverlayTrigger>
    );
};

export default PageSize;
