import React, { useEffect, useState } from 'react';
import Header2 from '../components/header/Header2';
import Footer from '../components/footer';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Link, useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-big-calendar/lib/css/react-big-calendar.css";
import event from '../assets/fakeData/calanderEvent';
import logo from "../assets/images/logo.png";
import HamburgerMenu from '../assets/Mobile Menu/HamburgerMenu';



const localizer = momentLocalizer(moment);

function Examcalander() {
    // State to hold events data
    const [toggle, setToggle] = useState({
        key: "",
        status: false,
      });
      const [isShowMobile, setShowMobile] = useState(false);
    const [events, setEvents] = useState([]);
    // State to hold selected date
    const [selectedDate, setSelectedDate] = useState(null);
    const [show, setShow] = useState(false)
    const navigate =useNavigate()
    // Function to handle click event on individual dates
    const handleDateClick = ({ start, end }) => {
        const title = window.prompt('Enter event title:');
        if (title) {
            const newEvent = {
                start,
                end,
                title,
            };
            setEvents([...events, newEvent]); // Adding the new event to the events state
        }
    };

    useEffect(() => {
        setEvents(event)
    }, [])

    const eventStyleGetter = (event) => {
        const backgroundColor = event ? 'brown' : 'inherit';
        const color = event ? "white" : "black";
        return {
            style: {
                backgroundColor,
                color
            },
        };
    };

    const handleToggle = (key) => {
        if (toggle.key === key) {
          setToggle({
            status: false,
          });
        } else {
          setToggle({
            status: true,
            key,
          });
        }
      };
    
      const handleMobile = () => {
        const getMobile = document.querySelector(".menu-mobile-popup");
        setShowMobile(!isShowMobile);
        !isShowMobile
          ? getMobile.classList.add("modal-menu--open")
          : getMobile.classList.remove("modal-menu--open");
      };
      const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        navigate("/login");
      };

    return (
        <div>
             <HamburgerMenu clname='exam-calender'/>


            <section className="candidates-section">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-lg-8 mt-4">
                            <Calendar
                                localizer={localizer}
                                startAccessor="start"
                                endAccessor="end"
                                events={events} // Pass events data to the Calendar component
                                style={{ height: 500 }}
                                selectable={true} // Enable selecting dates
                                onSelectSlot={handleDateClick} // Call handleDateClick function on date selection
                                onSelectEvent={(event, e) => setSelectedDate(event.start)} // Update selectedDate state when event is clicked
                                eventPropGetter={eventStyleGetter}
                            />
                        </div>

                        <div className="col-lg-4 mt-4">
                            <div className="cv-form-details po-sticky" style={{ maxWidth: '100%', maxHeight: '400px' ,border:"1px solid brown"}}>
                                {
                                    selectedDate ?
                                        <>
                                            <h6>Event</h6>
                                            {
                                                selectedDate && (
                                                    <div>
                                                        <p class="text-start">Selected Date: {moment(selectedDate).format('MMMM DD, YYYY')}</p>
                                                        <ul style={{ margin: '0', padding: '0', overflowY: 'auto' }}>
                                                            {events.filter(event => moment(event.start).isSame(selectedDate, 'day')).map((event, index) => (
                                                                <li key={index} style={{ overflowWrap: 'break-word'}}>{event.title}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                        </> :
                                        <>
                                            <div class="alert alert-danger" role="alert">
                                                Click the date to see details
                                            </div>
                                        </>
                                }

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Examcalander;
