const data = {
  "Job_Applications": [


    {
      "position": "Mining Engineer",
      "Description": "Mining Engineer position at ABC Mining Corporation",
      "Recruitment_Status": {
        "Notification_Release": {
          "Status": "Released",
          "Date": "2024-03-01",
          "Description": "The Mining Engineer position at ABC Mining Corporation has been announced! Visit the company's careers page to learn more about the position."
        },
        "Application_Submission": {
          "Status": "Submitted",
          "Date": "2024-03-15",
          "Description": "You have successfully submitted your application for the Mining Engineer position at ABC Mining Corporation. Now, await further updates regarding the recruitment process."
        },
        "Admit_Card_Issuance": {
          "Status": "Generated",
          "Date": "2024-04-25",
          "Description": "Admit cards for the Mining Engineer position at ABC Mining Corporation have been generated. Check your email or the company's website for downloading your admit card."
        },
        "Written_Examination": {
          "Status": "Completed",
          "Date": "2024-05-15",
          "Description": "Congratulations! You have successfully completed the written examination for the Mining Engineer position at ABC Mining Corporation. Await further instructions for the next stage."
        },
        "Interview": {
          "Status": "Completed",
          "Date": "2024-06-10",
          "Description": "You have completed the interview for the Mining Engineer position at ABC Mining Corporation. Now, wait for the final decision."
        },
        "Document_Verification": {
          "Status": "Completed",
          "Date": "2024-06-20",
          "Description": "Document verification for the Mining Engineer position at ABC Mining Corporation has been completed. Sit tight for the final merit list."
        },
        "Final_Merit_List": {
          "Status": "Qualified",
          "Date": "2024-07-01",
          "Description": "Congratulations! You have made it to the final merit list for the Mining Engineer position at ABC Mining Corporation. Await further instructions regarding the appointment and joining process."
        },
        "Appointment_and_Joining": {
          "Status": "Completed",
          "Date": "2024-07-15",
          "Description": "Congratulations! You have been appointed as a Mining Engineer at ABC Mining Corporation. Your joining formalities have been completed, and you're all set to start your new role."
        }
      }
    },
    {
      "position": "Geologist",
      "Description": "Geologist position at XYZ Geological Services",
      "Recruitment_Status": {
        "Notification_Release": {
          "Status": "Released",
          "Date": "2024-03-10",
          "Description": "The Geologist position at XYZ Geological Services has been announced! Check the company's careers page for more details."
        },
        "Application_Submission": {
          "Status": "Submitted",
          "Date": "2024-03-20",
          "Description": "You have successfully submitted your application for the Geologist position at XYZ Geological Services. Stay tuned for updates on the recruitment process."
        },
        "Admit_Card_Issuance": {
          "Status": "Generated",
          "Date": "2024-04-20",
          "Description": "Admit card issuance for the Geologist position at XYZ Geological Services is pending. Keep an eye on your email for further instructions."
        },
       
      }
    },


  ]
}
export default data
