import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap-icons/font/bootstrap-icons.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
console.log = () => {};
console.error = () => {};
console.debug = () => {};
console.warn=()=>{};
root.render(<App />);
