import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, ButtonGroup, Col, Form, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { callApi } from "../../constant/callApi";
import CryptoJS from "crypto-js";
import ForgotPasswordModal from "../../pages/ForgotPasswordModal";
import bgImage from "../../assets/images/loginBg.jpg";
import omcLogoWhite from "../../assets/images/logo-removebg-preview.png";
import loginBg from "../../assets/images/loginNewPortal.png";

function SignIn() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [otpFieldVisible, setOtpFieldVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [otpDetails, setOtpDetails] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [timer, setTimer] = useState(300); // 5 minute countdown timer
  const [SendMobileBtnLoading, setSendMobileBtnLoading] = useState(false);
  const [loginType, setLoginType] = useState("phone"); // default to phone login

  useEffect(() => {
    let countdown;
    if (isOtpSent && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsOtpSent(false);
      setOtpFieldVisible(false);
      setTimer(300); // Reset the timer to 5 minutes
    }

    return () => clearInterval(countdown);
  }, [isOtpSent, timer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const handleSignUp = (event) => {
    event.preventDefault();

    if (loginType === "phone") {
      if (!/^[6-9]\d{9}$/.test(phoneNumber)) {
        setPhoneError("Only 10 digit numbers allowed");
        return;
      }
      setPhoneError("");
    }

    if (loginType === "email") {
      if (!/\S+@\S+\.\S+/.test(email)) {
        setEmailError("Invalid email format");
        return;
      }
      setEmailError("");
    }
  };

  const generateOtp = async () => {
    if (loginType === "phone" && !/^[6-9]\d{9}$/.test(phoneNumber)) {
      setPhoneError("Only 10 digit numbers allowed");
      return;
    }

    if (loginType === "email" && !/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email format");
      return;
    }

    setPhoneError("");
    setEmailError("");

    try {
      if (loginType === "phone" && phoneNumber) {
        setSendMobileBtnLoading(true);
        let config = {
          method: "post",
          url: "Portal/portal/generatePhoneOtpForPortalLogin",
          data: {
            phoneNumber: phoneNumber,
          },
        };

        let response = await callApi(config);
        if (response?.data?.code === 201) {
          setSendMobileBtnLoading(false);
          toast.success(response.data.message, { autoClose: 2000 });
          setOtpFieldVisible(true);
          setIsOtpSent(true);
        } else {
          toast.warning(response?.data?.message, { autoClose: 2000 });
          setSendMobileBtnLoading(false);
        }
      }

      if (loginType === "email" && email) {
        setSendMobileBtnLoading(true);
        let config = {
          method: "post",
          url: "Portal/portal/generateMailOtpForPortalLogin",
          data: {
            email: email,
          },
        };

        let response = await callApi(config);
        if (response?.data?.code === 201) {
          setSendMobileBtnLoading(false);
          toast.success(response.data.message, { autoClose: 2000 });
          setOtpFieldVisible(true);
          setIsOtpSent(true);
        } else {
          toast.warning(response?.data?.message, { autoClose: 2000 });
          setSendMobileBtnLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message, { className: "danger", autoClose: 2000 });
      setSendMobileBtnLoading(false);
    }
  };

  const signUpFun = async () => {
    if (!otpDetails) {
      setOtpError("OTP is Required");
      return;
    }
    if (otpDetails.length !== 6) {
      setOtpError("OTP must be 6 digits");

      return;
    }
    setOtpError("");

    try {
      let hashedOtp = CryptoJS.SHA256(otpDetails).toString(CryptoJS.enc.Hex);
      let config =
        loginType === "phone"
          ? {
              method: "post",
              url: "Portal/portal/verifyOTPForPhoneAndLogin",
              data: {
                phoneNumber: phoneNumber,
                otp: hashedOtp,
              },
            }
          : {
              method: "post",
              url: "Portal/portal/verifyOTPForEmailAndLogin",
              data: {
                email: email,
                otp: hashedOtp,
              },
            };

      let response = await callApi(config);

      if (response.data.code === 200) {
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("sessionId", response.data.sessionId);
        toast.success(response.data.message, { autoClose: 2000 });
        navigate("/all-jobs");
      } else {
        // setPhoneNumber("");
        setOtpDetails("");
        toast.error(response.data.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message, { className: "danger", autoClose: 2000 });
    }
  };

  return (
    <section className="login-section">
      <img src={loginBg} alt="background" className="login-bg-img" />
      <div className="mainContainer">
        <div className="leftSection">
          <div className="omcLogoContainer"></div>
        </div>
        <div className="rightSection">
          <div className="login-form">
            <div className="omcLogoTop">
              <img src={omcLogoWhite} alt="omcLogo" />
            </div>
            <ButtonGroup className="buttonGroupLogin">
              <Button
                className={`login-option ${
                  loginType === "phone" ? "active" : ""
                } ps-2 pe-2 pt-1 pb-1`}
                onClick={() => setLoginType("phone")}
              >
                Phone Login
              </Button>
              <Button
                className={`login-option ${
                  loginType === "email" ? "active" : ""
                } ps-2 pe-2 pt-1 pb-1`}
                onClick={() => setLoginType("email")}
              >
                Email Login
              </Button>
            </ButtonGroup>

            <form onSubmit={handleSignUp} className="login-form-content">
              {loginType === "phone" && (
                <div className="form-data font-mukta">
                  <label>
                    Phone Number <span>*</span>
                  </label>
                  <input
                    type="number"
                    name="phone"
                    disabled={isOtpSent ? true : false}
                    placeholder="Enter phone number"
                    className="login-input"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value) && value.length <= 10) {
                        setPhoneNumber(value);
                      }
                    }}
                    required
                    value={phoneNumber}
                  />
                  {phoneError && (
                    <div className="text-danger">{phoneError}</div>
                  )}
                </div>
              )}

              {loginType === "email" && (
                <div className="form-data font-mukta">
                  <label>
                    Enter Email<span>*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    disabled={isOtpSent ? true : false}
                    placeholder="Enter email address"
                    className="login-input"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    value={email}
                  />
                  {emailError && (
                    <div className="text-danger">{emailError}</div>
                  )}
                </div>
              )}

              <div className="form-data font-mukta">
                {otpFieldVisible && (
                  <Form.Group className="mb-3">
                    <Form.Label>OTP</Form.Label>
                    <Form.Control
                      type="number"
                      name="otp"
                      placeholder="Enter OTP"
                      className="login-input"
                      onChange={(e) => setOtpDetails(e.target.value)}
                      maxLength="6"
                      required
                    />
                    {otpError && <div className="text-danger">{otpError}</div>}
                  </Form.Group>
                )}

                {!isOtpSent && (
                  <Button
                    variant="primary"
                    onClick={generateOtp}
                    disabled={SendMobileBtnLoading}
                    className="mb-3 w-100 mt-3 font-mukta signIn-login-Btn"
                  >
                    {!SendMobileBtnLoading ? (
                      "Send OTP "
                    ) : (
                      <Spinner size="sm" />
                    )}
                  </Button>
                )}

                {isOtpSent && (
                  <div className="mb-3 w-100 mt-3 font-mukta text-center">
                    Please wait {formatTime(timer)} to resend OTP.
                  </div>
                )}
              </div>

              {otpFieldVisible && isOtpSent && (
                <Button
                  type="submit"
                  className="w-100 pt-1 font-mukta signIn-login-Btn"
                  onClick={signUpFun}
                >
                  Login
                </Button>
              )}
              <div className="sign-up text-dark text-center mt-3 font-mukta signUpText">
                Not registered yet?{" "}
                <Link
                  to="/applicant-registration"
                  className="new-sign-up"
                  style={{ color: "brown" }}
                >
                  Register
                </Link>
              </div>
              <div className="sign-up text-dark text-center mt-0 font-mukta signUpText">
                <Link to="/account-recovery" className="new-sign-up text-dark">
                  Account Recovery
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignIn;
